import { gql } from "@apollo/client";

export const GET_DASH_TRANSACTION = gql`
  query GetDashTransactionPayment(
    $shopIds: [ID]
    $startDate: Date
    $endDate: Date
  ) {
    getDashTransactionPayment(
      shopIds: $shopIds
      startDate: $startDate
      endDate: $endDate
    ) {
      currentIncome
      previousIncome
      differenceIncomeAmount
      differenceIncomePercent
      currentExpense
      prevoiusExpense
      differenceExpensePercent
      differenceExpenseAmount
    }
  }
`;

export const GET_DASH_INCOME_PAYMENT_BY_MONTH = gql`
  query Query($shopIds: [ID], $startDate: Date, $endDate: Date) {
    getDashIncomePaymentByMonth(
      shopIds: $shopIds
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
