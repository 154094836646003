import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
// src
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_CASHIER, GET_CASHIER_BY_SHOT } from "../../../Schema/Shop";
import { SelectUserForCashier } from "../../../Include/Function/DynamicSelect";
import CreateIcons from "../../../Assets/add.png";
import BadgeAvatars from "./BadgeAvatars";
import CashierAction from "./CashierAction";
export const Cashier = ({ open, editData, setRefetch, handleClose }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserVal, setSelectedUserVal] = useState({ id: "", title: "" });

  //======================== Create Shop =========================
  const { refetch } = useQuery(GET_CASHIER_BY_SHOT, {
    variables: {
      shopId: editData?._id,
    },
    onCompleted: ({ getCashierByShop }) => {
      setLoading(false);
      if (getCashierByShop) setUserData(getCashierByShop);
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  const [createCashier] = useMutation(CREATE_CASHIER, {
    onCompleted: ({ createCashier }) => {
      setLoading(false);
      if (createCashier?.isSuccess === true) {
        setAlert(true, "success", createCashier?.message);
        setSelectedUserVal({ id: "", title: "" });
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createCashier?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    userId: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      userId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);
      createCashier({
        variables: {
          input: {
            ...value,
            shopId: editData?._id,
          },
        },
      });
    },
  });

  const { errors, touched, resetForm, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (open) {
      resetForm();
      setSelectedUserVal({ id: "", title: "" });
    }
  }, [open]);
  useEffect(() => {
    setFieldValue("userId", selectedUserVal?.id);
  }, [selectedUserVal]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_cashier")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center">
                  <Stack width="100%">
                    <SelectUserForCashier
                      shopId={editData?._id}
                      selectedVal={selectedUserVal}
                      setSelectedVal={setSelectedUserVal}
                    />
                    {!!errors.userId && touched.userId && (
                      <FormHelperText error id="outlined-adornment-password">
                        {errors.userId}
                      </FormHelperText>
                    )}
                  </Stack>

                  <Button
                    onClick={handleSubmit}
                    sx={{ margin: "0px  0px 0px 15px !important" }}
                    className="btn-create-style"
                    startIcon={
                      <img
                        src={CreateIcons}
                        alt="CreateIcons"
                        className="icon-add"
                      />
                    }
                  >
                    <Typography
                      className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                    >
                      {t("btn-add")}
                    </Typography>
                  </Button>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="column" spacing={2} paddingX={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      className={
                        language === "en" ? "field-title" : "field-title-kh"
                      }
                      width="60%"
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("user")}
                    </Typography>
                    <Typography
                      className={
                        language === "en" ? "field-title" : "field-title-kh"
                      }
                      width="20%"
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("thead_status")}
                    </Typography>
                    <Typography
                      className={
                        language === "en" ? "field-title" : "field-title-kh"
                      }
                      width="20%"
                      sx={{ fontWeight: "bold" }}
                    ></Typography>
                  </Stack>
                  {userData?.map((e, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1.5}
                        width="60%"
                      >
                        <BadgeAvatars
                          userProfile={e?.userId?.userProfile}
                          status={e?.status}
                        />
                        <Typography
                          className={"field-title-kh"}
                          sx={{ fontSize: "17px !important" }}
                        >
                          {language === "en"
                            ? e?.userId?.latinName
                            : e?.userId?.khmerName}
                        </Typography>
                      </Stack>
                      <Typography
                        className={"field-title-kh"}
                        width="20%"
                        color={
                          e?.status ? "#44b700 !important" : "red !important"
                        }
                      >
                        {e?.status ? "Active" : "Inactive"}
                      </Typography>
                      <Box width="20%">
                        <CashierAction editData={e} setRefetch={refetch} />
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
