import React from "react";
import { Box, Stack, Typography } from "@mui/material";

// src
import "./HeaderCard/headercard.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

const AboutExpired = () => {
  // ============== Change Language ===============
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <div className="unpaid-container">
      <Box className="box">
        <Stack direction={`column`} spacing={1}>
          <Typography className={language === "en" ? "title" : "title-kh"}>
            {t("about_expired")}
          </Typography>
          <Stack direction="row" className="header-table" alignItems={`center`}>
            <Typography
              className={language === "en" ? "header" : "header-kh"}
              width="5%"
            >
              {t("thead_no")}
            </Typography>
            <Typography
              className={language === "en" ? "header" : "header-kh"}
              width="35%"
            >
              {t("thead_customer")}
            </Typography>
            <Typography
              className={language === "en" ? "header" : "header-kh"}
              width="20%"
            >
              Services Plan
            </Typography>
            <Typography
              className={language === "en" ? "header" : "header-kh"}
              width="15%"
            >
              Expired Date
            </Typography>
            <Typography
              className={language === "en" ? "header" : "header-kh"}
              width="15%"
            >
              Remaining Days
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={`column`}
          spacing={1}
          mt={1}
          sx={{ overflowY: "auto", height: "340px" }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((item, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                alignItems={`center`}
                className="detail-table"
              >
                <Typography
                  className={language === "en" ? "header" : "header-kh"}
                  width="5%"
                >
                  1
                </Typography>
                <Typography
                  className={language === "en" ? "header" : "header-kh"}
                  width="35%"
                >
                  Sophearatanak Yown
                </Typography>
                <Typography
                  className={language === "en" ? "header" : "header-kh"}
                  width="20%"
                >
                  Model
                </Typography>
                <Typography
                  className={language === "en" ? "header" : "header-kh"}
                  width="15%"
                >
                  10-10-26
                </Typography>
                <Typography
                  className={language === "en" ? "header" : "header-kh"}
                  width="15%"
                >
                  1000 days
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </div>
  );
};

export default AboutExpired;
