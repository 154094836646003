import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { MultipleSelectShop } from "../../../Include/Function/DynamicSelect";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
import { UPDATE_TICKET_GROUP, CREATE_TICKET_GROUP } from "../../../Schema/TicketGroup";

export const TicketGroupForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [multipleSelectShop, setMultipleSelectShop] = useState([]);


  //======================== Create Bank =========================
  const [createTicketGroup] = useMutation(CREATE_TICKET_GROUP, {
    onCompleted: ({ createTicketGroup }) => {
      console.log("createTicketGroup::", createTicketGroup);
      setLoading(false);
      if (createTicketGroup?.isSuccess === true) {
        setAlert(true, "success", createTicketGroup?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createTicketGroup?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== update Bank =========================
  const [updateTicketGroup] = useMutation(UPDATE_TICKET_GROUP, {
    onCompleted: ({ updateTicketGroup }) => {
      console.log("updateTicketGroup::", updateTicketGroup);
      setLoading(false);
      if (updateTicketGroup?.isSuccess === true) {
        setAlert(true, "success", updateTicketGroup?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateTicketGroup?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    validFrom: Yup.string(),
    expireDate: Yup.string(),
    limit: dialogTitle === "Update" ? Yup.string() : Yup.string().required(t("require")),
    titleKh: Yup.string().required(t("require")),
    titleEn: Yup.string().required(t("require")),
    headerCode: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      limit: "",
      remark: "",
      titleKh: "",
      titleEn: "",
      validFrom: "",
      headerCode: "",
      expireDate: "",
    },
    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      console.log("value:::", value);
      setLoading(true);
      if (dialogTitle === "Create") {
        createTicketGroup({
          variables: {
            input: {
              ...value,
              limit: parseFloat(value?.limit),
              shopId: multipleSelectShop.map(e => e?.id),
            }
          },
        });
      } else {
        updateTicketGroup({
          variables: {
            id: editData?._id,
            input: {
              ...value,
              limit: parseFloat(value?.limit),
              shopId: multipleSelectShop.map(e => e?.id),
            }
          },
        });
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setMultipleSelectShop([]);
    }

    if (dialogTitle === "Update" && open) {
      const shopData = editData?.shopId?.map((e) => ({ id: e?._id, title: language === "en" ? e?.shopNameEn : e?.shopNameKh }));
      setMultipleSelectShop(shopData);
      setFieldValue("remark", editData?.remark);
      setFieldValue("titleKh", editData?.titleKh);
      setFieldValue("titleEn", editData?.titleEn);
      setFieldValue("validFrom", editData?.validFrom);
      setFieldValue("headerCode", editData?.headerCode);
      setFieldValue("expireDate", editData?.expireDate);
    }
  }, [open, editData]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_ticket")
                : t("dialog_title_update_ticket")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>

              <Grid item xs={dialogTitle === "Create" ? 6 : 12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_code")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("headerCode")}
                  error={Boolean(touched.headerCode && errors.headerCode)}
                  helperText={touched.headerCode && errors.headerCode}
                />
              </Grid>

              {
                dialogTitle === "Create" && <Grid item xs={6}>
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                  >
                    {t("thead_limit")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    className="text-field"
                    // placeholder={t("label_table_name")}
                    {...getFieldProps("limit")}
                    error={Boolean(touched.limit && errors.limit)}
                    helperText={touched.limit && errors.limit}
                  />
                </Grid>
              }
 

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t('thead_title')} ({t('thead_kh')})
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("titleKh")}
                  error={Boolean(touched.titleKh && errors.titleKh)}
                  helperText={touched.titleKh && errors.titleKh}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t('thead_title')} ({t('thead_en')})
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("titleEn")}
                  error={Boolean(touched.titleEn && errors.titleEn)}
                  helperText={touched.titleEn && errors.titleEn}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_shop")}
                </Typography>
                <MultipleSelectShop selectedVal={multipleSelectShop} setSelectedVal={setMultipleSelectShop} />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_valid_from")}
                </Typography>
                <SelectDatePicker
                  date={values?.validFrom}
                  setDate={(e) => setFieldValue("validFrom", e)}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_expiry_date")}
                </Typography>
                <SelectDatePicker
                  date={values?.expireDate}
                  setDate={(e) => setFieldValue("expireDate", e)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  className="text-field"
                  placeholder={t("remark")}
                  {...getFieldProps("remark")}
                />
              </Grid>

            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
