import React, { useContext, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import {
  GET_INVOICE_BY_ID,
  GET_PAYMENT_BY_INVOICES,
} from "../../Schema/Invoice";
import { useQuery } from "@apollo/client";
import { GET_EXCHANGE_RATE } from "../../Schema/ExchangeRate";
import ReactToPrint from "react-to-print";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { PiPrinterFill } from "react-icons/pi";
import { BiMinusCircle } from "react-icons/bi";
import "./viewinvoice.scss";
import RecentPayment from "../Pos/Payment/RecentPayment/RecentPayment";
import Invoice from "../Pos/Payment/Invoice/Invoice";
export default function ViewInvoice({ open, toggleDrawer, invoiceId }) {
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const componentRef = useRef(null);
  const reactToPrintContent = () => {
    return componentRef.current;
  };
  const [paymentByInvoices, setPaymentByInvoices] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [invoiceById, setInvoiceById] = useState();
  // const invoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));
  const { refetch: refetchExchangeRate } = useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangeRate }) => {
      setExchangeRate(getExchangeRate?.exchangeRate);
    },
  });
  const { refetch: refetchInvoiceById } = useQuery(GET_INVOICE_BY_ID, {
    variables: {
      id: [invoiceId],
    },
    onCompleted: ({ getInvoiceById }) => {
      if (getInvoiceById) setInvoiceById(getInvoiceById[0]);
    },
  });

  const { refetch: refetchPaymentByInvoices } = useQuery(
    GET_PAYMENT_BY_INVOICES,
    {
      variables: {
        invoiceIds: invoiceId,
      },
      onCompleted: ({ getPaymentByInvoices }) => {
        setPaymentByInvoices(getPaymentByInvoices);
      },
    }
  );
  const reactToPrintTrigger = () => {
    return (
      <Button
        className={language === "kh" ? "btn-create-kh" : "btn-create"}
        fullWidth
        startIcon={<PiPrinterFill />}
      >
        Print
      </Button>
    );
  };

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <Box sx={{ paddingX: 4, paddingTop: 2, maxWidth: 700 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className="create-container"
                sx={{ padding: "0px !important" }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <BiMinusCircle color="red" />
                </IconButton>
                <Box width={120}>
                  <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={"បញ្ជីឈ្មោះបុគ្គលិកត្រូវធ្វើបច្ចុប្បន្នភាព"}
                    removeAfterPrint
                    trigger={reactToPrintTrigger}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Invoice
                invoiceById={invoiceById}
                exchangeRate={exchangeRate}
                componentRef={componentRef}
              />
            </Grid>
            {paymentByInvoices?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <RecentPayment
                  paymentByInvoices={paymentByInvoices}
                  refetchPaymentByInvoices={refetchPaymentByInvoices}
                  refetchInvoiceById={refetchInvoiceById}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
}
