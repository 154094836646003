import { useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { useCallback, useContext, useState } from "react";
import { GET_PAYMENT_BY_INVOICES } from "../../Schema/Invoice";
import { translateLauguage } from "../../Include/Function/Translate";

const useGetPaymentByInvoices = (invoiceId) => {
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);

    const [paymentByInvoiceData, setPaymentByInvoiceData] = useState([]);

    const { refetch } = useQuery(
        GET_PAYMENT_BY_INVOICES,
        {
            variables: {
                invoiceIds: invoiceId,
            },
            onCompleted: ({ getPaymentByInvoices }) => {
                setPaymentByInvoiceData(getPaymentByInvoices);
            },
        }
    );


    const cashReceivedKHR = paymentByInvoiceData?.filter((e) => !e?.isVoid)?.reduce((a, b) => a + b?.paid?.khr || 0, 0);
    const cashReceivedUSD = paymentByInvoiceData?.filter((e) => !e?.isVoid)?.reduce((a, b) => a + b?.paid?.usd || 0, 0);
    const changeCashKHR = paymentByInvoiceData?.filter((e) => !e?.isVoid)?.reduce((a, b) => a + b?.change?.khr || 0, 0);
    const changeCashUSD = paymentByInvoiceData?.filter((e) => !e?.isVoid)?.reduce((a, b) => a + b?.change?.usd || 0, 0);


    // Refetch data whenever `invoiceId`,  changes
    const refetchPayment = useCallback(() => {
        refetch();
    }, [invoiceId]);

    return {
        changeCashKHR,
        changeCashUSD,
        refetchPayment,
        cashReceivedUSD,
        cashReceivedKHR,
        paymentByInvoiceData,
    };
};

export default useGetPaymentByInvoices;
