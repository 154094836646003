import { useQuery } from "@apollo/client";
import Countries from "../../Assets/countries.json";
import { AuthContext } from "../../Context/AuthContext";
import Nationalitys from "../../Assets/nationalitys.json";
import { useState, useEffect, useContext } from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
//graphql
import {
  SELECT_BANK,
  SELECT_SHOP,
  GET_SELECT_UNIT,
  SELECT_CUSTOMER,
  SELECT_SUPPLIER,
  SELECT_WAREHOUSE,
  SELECT_MAIN_PRODUCT,
  SELECT_CARD_FOR_MEMBER,
  SELECT_CHECK_IN_MEMBER,
  GET_CATEGORY_FOR_SELECT,
  SELECT_USER_FOR_CASHIER,
  SELECT_PRODUCT_BY_MAIN_PRODUCT,
  GET_SELECT_CATEGORY_FOR_MAIN_PRODUCT,
  GET_CATEGORY_FOR_SELECT_VALUE,
  SELECT_MAIN_PRODUCT_BY_SHOP,
  SELECT_MAIN_PRODUCT_FOR_INGREDIENT,
  SELECT_REFUND_INVOICES,
  SELECT_SHIFT,
} from "../../Schema/SelectSchema";
import { translateLauguage } from "./Translate";
import { CustomerForm } from "../../Components/Setting/Customer/CustomerForm";
import { padNumber } from "./DynamicFn";
import moment from "moment";

// ============================ Select List Category ============================
export function SelectListCategory({
  selectedVal,
  setSelectedVal,
  className,
  checked,
  selfId,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(GET_CATEGORY_FOR_SELECT_VALUE, {
    variables: {
      selfId: selfId,
    },
    onCompleted: ({ selectCategory }) => {
      if (selectCategory) {
        const Data = selectCategory?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      options={selectOptions}
      disabled={checked === false ? true : false}
      value={checked === true ? selectedVal : ""}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}
// ============================ Select List Category ============================
export function SelectUnit({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(GET_SELECT_UNIT, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectUnit }) => {
      if (selectUnit) {
        const Data = selectUnit?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.unitEnName : e?.unitKhName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectCustomer({
  label, title,
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_CUSTOMER, {
    onCompleted: ({ selectCustomer }) => {
      if (selectCustomer) {
        const Data = selectCustomer?.map((e) => ({
          id: e?._id,
          title: e?.name,
        }));
        if (Data.length > 0 && title !== "Suspend") setSelectedVal(Data[0]);
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);
  return (
    <Autocomplete
      fullWidth
      disabled={Boolean(disabled)}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      defaultValue={selectOptions.length > 0 ? selectOptions[0] : null}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          className={className}
          error={required && !selectedVal?.id}
          label={label}
          placeholder={!label ? t("select_val") : ""}
        />
      )}
    />
  );
}

export function SelectCheckInMember({
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_CHECK_IN_MEMBER, {
    onCompleted: ({ selectCheckInMember }) => {

      if (selectCheckInMember) {


        const Data = selectCheckInMember?.map((e) => ({
          id: e?._id || "",
          title: e?.cardNo || "",
          customerId: e?.checkInMemberId?.customerId?._id || "",
        }));
        setSelectOptions(Data);
      }
    },
    onError: ({ message }) => {
      console.log("Error selectCheckInMember  :", message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      fullWidth
      value={selectedVal}
      options={selectOptions}
      disabled={Boolean(disabled)}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          className={className}
          error={required && !selectedVal?.id}
          label={t("choose_card_number")}
        />
      )}
    />
  );
}

export function SelectUserForCashier({
  shopId,
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_USER_FOR_CASHIER, {
    variables: {
      shopId,
    },
    onCompleted: ({ selectUserForCashier }) => {

      if (selectUserForCashier) {
        const Data = selectUserForCashier?.map((e) => ({
          id: e?._id,
          userProfile: e?.userProfile,
          title: language === "en" ? e?.latinName : e?.khmerName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      value={selectedVal || null}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          className={className}
          placeholder={t("select_val")}
          error={required && !selectedVal?.id}
        />
      )}
    />
  );
}

export function SelectShop({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
  selfId,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_SHOP, {
    variables: {
      isUse: true,
      selfId: selfId,
    },
    onCompleted: ({ selectShop }) => {
      if (selectShop) {
        const Data = selectShop?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.shopNameKh : e?.shopNameEn,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}
export function SelectBank({
  require,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_BANK, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectBank }) => {
      if (selectBank) {
        const Data = selectBank?.map((e) => ({
          id: e?._id,
          title: e?.bankName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
          error={require && !selectedVal?.id}
        />
      )}
    />
  );
}

export function SelectPlatform({
  require,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const selectOptions = [
    {
      id: "1",
      title: "Facebook",
    },
    {
      id: "2",
      title: "Instagram",
    },
    {
      id: "3",
      title: "Twitter",
    },
    {
      id: "4",
      title: "TikTok",
    },
    {
      id: "5",
      title: "LinkedIn",
    },
  ];

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
          error={require && !selectedVal?.id}
        />
      )}
    />
  );
}

// ============================ Select List Category ============================
export function SelectCategoryForMainProduct({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(GET_SELECT_CATEGORY_FOR_MAIN_PRODUCT, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectCategoryForMainProduct }) => {
      if (selectCategoryForMainProduct) {
        let dataOptionCat = selectCategoryForMainProduct?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories:
            e?.subCategories &&
            e?.subCategories.map((sub1) => ({
              id: sub1?._id,
              title:
                language === "en" ? sub1?.categoryNameEn : sub1?.categoryNameKh,
              subCategories:
                sub1?.subCategories &&
                sub1?.subCategories.map((sub2) => ({
                  id: sub2?._id,
                  title:
                    language === "en"
                      ? sub2?.categoryNameEn
                      : sub2?.categoryNameKh,
                  subCategories:
                    sub2?.subCategories &&
                    sub2?.subCategories.map((sub3) => ({
                      id: sub3?._id,
                      title:
                        language === "en"
                          ? sub3?.categoryNameEn
                          : sub3?.categoryNameKh,
                      subCategories:
                        sub3?.subCategories &&
                        sub3?.subCategories.map((sub4) => ({
                          id: sub4?._id,
                          title:
                            language === "en"
                              ? sub4?.categoryNameEn
                              : sub4?.categoryNameKh,
                          subCategories:
                            sub4?.subCategories &&
                            sub4?.subCategories.map((sub5) => ({
                              id: sub5?._id,
                              title:
                                language === "en"
                                  ? sub5?.categoryNameEn
                                  : sub5?.categoryNameKh,
                            })),
                        })),
                    })),
                })),
            })),
        }));
        setSelectOptions(dataOptionCat);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  // console.log("selectOptions:::",selectOptions);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectSubCategoryForMainProduct({
  selectedVal,
  setSelectedVal,
  selectOptions,
  className,
  disabled,
}) {
  //Change language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}
// ============================ Select List Category ============================
// export function SelectMainProduct({
//   selectedVal,
//   handleChangeIngredient,
//   itemKey,
//   required,
//   setSelectedVal,
//   className,
//   disabled,
// }) {
//   //Change language
//   const [selectOptions, setSelectOptions] = useState([]);
//   const { language } = useContext(AuthContext);
//   const { t } = translateLauguage(language);

//   const { refetch } = useQuery(GET_SELECT_MAIN_PRODUCT, {
//     variables: {
//       isUse: true,
//     },
//     onCompleted: ({ selectMainProduct }) => {
//       if (selectMainProduct) {
//         const Data = selectMainProduct?.map((e) => ({
//           id: e?._id,
//           title:
//             language === "en" ? e?.mainProductEnName : e?.mainProductKhName,
//         }));
//         setSelectOptions(Data);
//       }
//     },
//     onError: (error) => {
//       console.log(error?.message);
//     },
//   });

//   useEffect(() => {
//     refetch();
//   }, [selectedVal]);

//   return (
//     <Autocomplete
//       disabled={disabled}
//       value={selectedVal}
//       options={selectOptions}
//       onChange={(event, val) => {
//         handleChangeIngredient(itemKey, val, "mainProductId");
//         // setSelectedVal(val);
//       }}
//       getOptionLabel={(option) => option?.title ?? ""}
//       isOptionEqualToValue={() => true}

//       renderInput={(params) => (
//         <TextField
//           error={required && !selectedVal?.id}
//           {...params}
//           size="small"
//           className={className}
//           placeholder={t("select_val")}
//         />
//       )}
//     />
//   );
// }

export function MultipleSelectCategory({ selectedVal, setSelectedVal }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(GET_CATEGORY_FOR_SELECT, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectCategory }) => {
      if (selectCategory) {
        const Data = selectCategory?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);
  return (
    <Stack spacing={3}>
      <Autocomplete
        limitTags={3}
        multiple
        size="small"
        id="tags-outlined"
        value={selectedVal}
        options={selectOptions}
        onChange={(event, val) => setSelectedVal(val)}
        getOptionLabel={(option) => option?.title}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} placeholder="Category" fullWidth />
        )}
      />
    </Stack>
  );
}

export function MultipleSelectShop({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_SHOP, {
    variables: {
      isUse: true,
      selfId: null,
    },
    onCompleted: ({ selectShop }) => {
      if (selectShop) {
        const Data = selectShop?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.shopNameKh : e?.shopNameEn,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);
  return (
    <Stack spacing={3}>
      <Autocomplete
        limitTags={3}
        multiple
        size="small"
        id="tags-outlined"
        disabled={disabled}
        value={selectedVal}
        className={className}
        options={selectOptions}
        onChange={(event, val) => setSelectedVal(val)}
        getOptionLabel={(option) => option?.title}
        isOptionEqualToValue={() => true}

        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("select_val")}
            fullWidth
            className={className}
          />
        )}
      />
    </Stack>
  );
}

// ============================ Select List Category ============================
export function SelectCountries({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={Countries}
      onChange={(event, val) => {
        setSelectedVal(val);
      }}
      getOptionLabel={(option) => option?.label ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

// ============================ Select List Category ============================
export function SelectNationalitys({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={Nationalitys}
      onChange={(event, val) => {
        setSelectedVal(val);
      }}
      getOptionLabel={(option) => option?.nationality ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectCardForMemeber({
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
  memberDataList,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { refetch } = useQuery(SELECT_CARD_FOR_MEMBER, {
    onCompleted: ({ selectCardForMemeber }) => {
      if (selectCardForMemeber) {
        const Data = selectCardForMemeber?.map((e) => ({
          id: e?._id,
          title: e?.cardNo,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      value={selectedVal}
      options={selectOptions.filter(
        (e) => !memberDataList?.map((e) => e?.cardId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          className={className}
          placeholder={t("select_val")}
          error={required && !selectedVal?.id}
        />
      )}
    />
  );
}

export function SelectCustomerWithAddNew({
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
  memberDataList,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const { refetch } = useQuery(SELECT_CUSTOMER, {
    onCompleted: ({ selectCustomer }) => {
      if (selectCustomer) {
        const Data = selectCustomer?.map((e) => ({
          id: e?._id,
          title: e?.name,
        }));
        setSelectOptions([
          {
            id: "Add New Customer",
            title: "Add New Customer",
          },
          ...Data,
        ]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <>
      <Autocomplete
        fullWidth
        disabled={disabled}
        value={selectedVal}
        // options={selectOptions}
        options={selectOptions.filter(
          (e) => !memberDataList?.map((e) => e?.customerId?.id)?.includes(e?.id)
        )}
        onChange={(event, val) => {
          if (val?.id === "Add New Customer") {
            handleOpen();
            setSelectedVal({ id: "", title: "" });
          } else setSelectedVal(val);
        }}
        getOptionLabel={(option) => option?.title ?? ""}
        isOptionEqualToValue={() => true}

        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            className={className}
            placeholder={t("select_val")}
            error={required && !selectedVal?.id}
          />
        )}
      />
      <CustomerForm
        setSelectedVal={setSelectedVal}
        open={openCreate}
        hideBackdrop={true}
        dialogTitle="Create"
        setRefetch={refetch}
        handleClose={handleClose}
      />
    </>
  );
}

export function SelectWarehouse({
  required,
  dataList,
  disabled,
  className,
  selectedVal,
  placeholder,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_WAREHOUSE, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectWarehouse }) => {
      if (selectWarehouse) {
        const Data = selectWarehouse?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.warehouseEnName : e?.warehouseKhName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      // options={selectOptions}
      options={selectOptions.filter(
        (e) => !dataList?.map((e) => e?.warehouseId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          error={required && !selectedVal?.id}
          placeholder={placeholder ? placeholder : t("select_val")}
        />
      )}
    />
  );
}

export function SelectSupplier({
  selectedVal,
  setSelectedVal,
  className,
  disabled,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_SUPPLIER, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectSupplier }) => {
      if (selectSupplier) {
        const Data = selectSupplier?.map((e) => ({
          id: e?._id,
          title: e?.name,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectMainProduct({
  required,
  dataList,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_MAIN_PRODUCT, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ selectMainProduct }) => {
      if (selectMainProduct) {
        const Data = selectMainProduct?.map((e) => ({
          id: e?._id,
          title:
            language === "en" ? e?.mainProductEnName : e?.mainProductKhName,
          unit:
            language === "en"
              ? e?.minimumUnitId?.unitEnName
              : e?.minimumUnitId?.unitKhName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      // options={selectOptions}
      options={selectOptions.filter(
        (e) => !dataList?.map((e) => e?.mainProductId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectMainProductForIngredient({
  ownId,
  required,
  dataList,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_MAIN_PRODUCT_FOR_INGREDIENT, {
    variables: {
      isUse: true,
      ownId: ownId || null,
    },
    onCompleted: ({ selectMainProductForIngredient }) => {
      if (selectMainProductForIngredient) {
        const Data = selectMainProductForIngredient?.map((e) => ({
          id: e?._id,
          title:
            language === "en" ? e?.mainProductEnName : e?.mainProductKhName,
          unit:
            language === "en"
              ? e?.minimumUnitId?.unitEnName
              : e?.minimumUnitId?.unitKhName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      // options={selectOptions}
      options={selectOptions.filter(
        (e) => !dataList?.map((e) => e?.mainProductId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectMainProductByShop({
  shopId,
  required,
  dataList,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_MAIN_PRODUCT_BY_SHOP, {
    variables: {
      shopId: shopId,
      isUse: true,
    },
    onCompleted: ({ selectMainProductByShop }) => {
      if (selectMainProductByShop) {
        const Data = selectMainProductByShop?.map((e) => ({
          id: e?._id,
          title:
            language === "en" ? e?.mainProductEnName : e?.mainProductKhName,
          unit:
            language === "en"
              ? e?.minimumUnitId?.unitEnName
              : e?.minimumUnitId?.unitKhName,
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      // options={selectOptions}
      options={selectOptions.filter(
        (e) => !dataList?.map((e) => e?.mainProductId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectRefundInvoices({
  shopId,
  required,
  dataList,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_REFUND_INVOICES, {
    variables: {
      shopId: shopId,
      isUse: true,
    },
    onCompleted: ({ selectRefundInvoices }) => {
      if (selectRefundInvoices) {
        const Data = selectRefundInvoices?.map((e) => ({
          id: e?._id,
          title: padNumber(
            e?.invoiceNo,
            5,
            moment(e?.createdAt).format("YYYY")
          ),
        }));
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      // options={selectOptions}
      options={selectOptions.filter(
        (e) => !dataList?.map((e) => e?.mainProductId?.id)?.includes(e?.id)
      )}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}

export function SelectSubProduct({
  required,
  disabled,
  className,
  selectedVal,
  mainProductId,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_PRODUCT_BY_MAIN_PRODUCT, {
    variables: {
      mainProductId: mainProductId,
    },
    onCompleted: ({ selectProductByMainProduct }) => {
      if (selectProductByMainProduct) {
        const Data = selectProductByMainProduct?.map((e) => ({
          id: e?._id,
          title:
            language === "en" ? e?.unitId?.unitEnName : e?.unitId?.unitKhName,
          qtyInMinimumUnit: e?.qtyInMinimumUnit,
        }));
        setSelectOptions(Data);
      } else setSelectOptions([]);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title || ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("thead_unit")}
        />
      )}
    />
  );
}

export function SelectCategory({
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(GET_CATEGORY_FOR_SELECT, {
    variables: {
      selfId: null,
    },
    onCompleted: ({ selectCategory }) => {
      if (selectCategory) {
        const Data = selectCategory
          ?.map((e) => ({
            id: e?._id,
            title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          }))
          .filter((e) => e?.title);
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}



export function SelectShift({
  date,
  required,
  disabled,
  className,
  selectedVal,
  setSelectedVal,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const { refetch } = useQuery(SELECT_SHIFT, {
    variables: {
      date: moment(date).format("YYYY-MM-DD")
    },
    skip: true,
    onCompleted: ({ selectShift }) => {
      if (selectShift) {
        const optionsData = selectShift?.map((e) => ({
          id: e?._id,
          title: `Time : ${moment(e?.createdAt).format("hh:mm a")}-${moment(e?.closeBy?.createdAt).format("hh:mm a")}`,
        }))

        if (optionsData.length > 0) setSelectedVal(optionsData[0]);
        else setSelectedVal({ id: "", title: "", });

        setSelectOptions(optionsData);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedVal, date]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedVal}
      className={className}
      options={selectOptions}
      onChange={(event, val) => setSelectedVal(val)}
      getOptionLabel={(option) => option?.title ?? ""}
      isOptionEqualToValue={() => true}

      renderInput={(params) => (
        <TextField
          error={required && !selectedVal?.id}
          {...params}
          size="small"
          className={className}
          placeholder={t("select_val")}
        />
      )}
    />
  );
}
