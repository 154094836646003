import { gql } from "@apollo/client";

export const CREATE_VOUCHER_GROUP = gql`
  mutation CreateVoucherGroup(
    $limit: Int
    $input: VoucherGroupInput
    $length: Int
    $paddingChar: String
  ) {
    createVoucherGroup(
      limit: $limit
      input: $input
      length: $length
      paddingChar: $paddingChar
    ) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_VOUCHER_GROUP = gql`
  mutation UpdateVoucherGroup($id: ID!, $input: VoucherGroupInput) {
    updateVoucherGroup(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_VOUCHER_GROUP = gql`
  mutation DeleteVoucherGroup($id: ID!) {
    deleteVoucherGroup(_id: $id) {
      message {
        messageEn
        messageKh
      }
      isSuccess
    }
  }
`;

export const GET_VOUCHER_GROUP_WITH_PAGINATION = gql`
  query GetVoucherGroupPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getVoucherGroupPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        voucherName
        mainCode
        validFrom
        expireDate
        shopId {
          _id
          shopNameKh
          shopNameEn
        }
        discountType
        value
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
