import React, { useContext } from "react";
import {
    Stack,
    Button,
    TextField,
    Typography,
    InputAdornment,
} from "@mui/material";
import "./productinvoice.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

export default function AccordionDiscount({ item, handleChangeDiscount }) {
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);


    return (
        <Stack direction="column" spacing={1} sx={{ width: "70%" }}>
            <Typography className="title-discount">
                Discount
            </Typography>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() => handleChangeDiscount(item?.productId, "type", "Cash")}
                    className={item?.discount?.type === "Cash" ? "btn-discount-active" : "btn-discount"}
                >
                    $
                </Button>
                <Button
                    onClick={() => handleChangeDiscount(item?.productId, "type", "Percentage")}
                    className={item?.discount?.type === "Percentage" ? "btn-discount-active" : "btn-discount"}
                >
                    %
                </Button>
                <TextField
                    fullWidth
                    size="small"
                    type="number"
                    onFocus={(e) => e.target?.select()}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {item?.discount?.type === "Cash" ? "$" : "%"}
                            </InputAdornment>
                        ),
                        inputProps: { min: 1 },
                    }}
                    value={item?.discount?.amount}
                    onChange={({ target }) => handleChangeDiscount(item?.productId, "amount", target?.value)}
                />
            </Stack>
            <TextField
                fullWidth
                multiline
                size="small"
                placeholder="note"
                value={item?.discount?.remark}
                onChange={({ target }) => handleChangeDiscount(item?.productId, "remark", target?.value)}
            />
        </Stack>


    );
}
