import React, { useContext, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs
import "../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CHECK_IN_OUT } from "../../Schema/CardNumber";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
export const UpdateCheckInOutStatus = ({
  open,
  editData,
  setRefetch,
  handleClose,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  //======================== update Bank =========================
  const [checkInOut] = useMutation(CHECK_IN_OUT, {
    onCompleted: ({ checkInOut }) => {
      setLoading(false);
      if (checkInOut?.isSuccess === true) {
        setAlert(true, "success", checkInOut?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", checkInOut?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });



  const handleSubmit = () => {
    // Waiting
    // In
    // Out
    checkInOut({
      variables: {
        id: editData?._id,
        status: editData?.status === "Waiting" ? "In" : "Out"
      }
    })
  }
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" width="100%">
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <Stack width="100%" direction="column" alignItems="center" justifyContent="center" spacing={1.5} >
              <IconButton  >
                <TaskAltIcon sx={{ width: 84, height: 84, color: "green" }} />
              </IconButton>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                {editData?.status === "Waiting" ? t("thead_please_check_in") : t("thead_please_check_out")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {editData?.status === "Waiting" ? t("btn-check-in") : t("btn-check-out")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
