import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import moment from "moment";
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import SelectRequestProducts from "./SelectRequestProducts";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_REQUEST, UPDATE_REQUEST } from "../../../Schema/InventoryInShop";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";

export const RequestProduct = ({ open, handleClose, hideBackdrop, dailogTitle, editData, setRefetch }) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [purchaseProductsData, setPurchaseProductsData] = useState([]);
  const newItem = {
    qty: null,
    unitPrice: null,
    subProductId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }
  //======================== Create User =========================
  const [createRequest] = useMutation(CREATE_REQUEST, {
    onCompleted: ({ createRequest }) => {
      setLoading(false);
      if (createRequest?.isSuccess === true) {
        setAlert(true, "success", createRequest?.message);
        handleClose();
        setRefetch();

        resetForm();
        setPurchaseProductsData([]);
      } else {
        setAlert(true, "error", createRequest?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  const [updateRequest] = useMutation(UPDATE_REQUEST, {
    onCompleted: ({ updateRequest }) => {
      setLoading(false);
      if (updateRequest?.isSuccess === true) {
        setAlert(true, "success", updateRequest?.message);
        handleClose();
        setRefetch();

        resetForm();
        setPurchaseProductsData([]);
      } else {
        setAlert(true, "error", updateRequest?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });



  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    priority: Yup.string().required(t("require")),
    needDate: Yup.string().required(t("require")),

  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      priority: "Medium",
      needDate: moment(),
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (purchaseProductsData?.length > 0) {
        const requiredProduct = purchaseProductsData?.map((e) => e?.mainProductId?.id && e?.qty ? true : false)?.filter((e) => !e);
        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }

        const purchaseProducts = purchaseProductsData?.map((e) => ({
          qty: parseFloat(e?.qty),
          mainProductId: e?.mainProductId?.id,
        }));

        if (dailogTitle === "Create") {
          setLoading(true);
          createRequest({
            variables: {
              input: {
                needDate: moment(value?.needDate).format("YYYY-MM-DD"),
                priority: value?.priority,
                requestBy: {
                  remark: value?.remark,
                },
                requestMainProducts: purchaseProducts
              }
            },
          });
        }

        if (dailogTitle === "Update") {
          setLoading(true);
          updateRequest({
            variables: {
              id: editData?._id,
              input: {
                needDate: moment(value?.needDate).format("YYYY-MM-DD"),
                priority: value?.priority,
                requestBy: {
                  remark: value?.remark,
                },
                requestMainProducts: purchaseProducts
              }
            },
          });
        }

      } else {
        setPurchaseProductsData([newItem]);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
    setPurchaseProductsData([newItem]);
  }, [open]);


  useEffect(() => {

    if (dailogTitle === "Update") {
      setFieldValue("needDate", editData?.needDate);
      setFieldValue("priority", editData?.priority);
      setFieldValue("remark", editData?.requestBy?.remark);

      const purchaseData = editData?.requestMainProducts.map((e) => ({
        qty: e?.qty,
        mainProductId: {
          id: e?.mainProductId?._id,
          title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
          unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,
        },
        key: Math.random() + Date.now(),
      }));
      setPurchaseProductsData(purchaseData);
    }
  }, [open]);



  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_request_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_priority")}
                </Typography>

                <FormControl fullWidth size="small" className="search-field">
                  <Select value={values?.priority} onChange={(event) => setFieldValue("priority", event.target.value)}>
                    <MenuItem value={"Urgent"}> Urgent </MenuItem>
                    <MenuItem value={"Important"}> Important </MenuItem>
                    <MenuItem value={"Medium"}> Medium </MenuItem>
                    <MenuItem value={"Low"}> Low </MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_date")}
                </Typography>

                <SelectDatePicker date={values?.needDate} setDate={(e) => setFieldValue("needDate", e)} />

                {!!errors.purchaseDate && touched.purchaseDate && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.purchaseDate}
                  </FormHelperText>
                )}
              </Grid>


              <Grid item xs={12}>
                <SelectRequestProducts 
                  required={required}
                  purchaseProductsData={purchaseProductsData}
                  setPurchaseProductsData={setPurchaseProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-request")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
