import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Stack,
  Button,
  TableRow,
  TextField,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  InputAdornment,
  TableContainer,
  FormControl,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../../../Style/pageStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import { translateLauguage } from "../../../Include/Function/Translate";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { GET_CARD_WITH_PAGINATION } from "../../../Schema/CardNumber";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { CardNumberForm } from "../../../Components/Setting/CardNumber/CardNumberForm";
import CardNumberAction from "../../../Components/Setting/CardNumber/CardNumberAction";
import UpdateStatusCardNumber from "../../../Components/Setting/CardNumber/UpdateStatusCardNumber";
export const CardNumber = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);


  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState();
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_CARD_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      pagination: true,
      cardNo: keyword,
      isUse: status === "All" ? null : status,
    },
    onCompleted: ({ getCardWithPagination }) => {
      setTableData(getCardWithPagination?.data);
      setPaginationData(getCardWithPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography
              className={language === "kh" ? "page-title-kh" : "page-title"}
            >
              {t(`page-setting`)}
            </Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-card-number`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              placeholder={t("label-search")}
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>


            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`thead_status`)}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>In-active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={6} xl={7.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
            <CardNumberForm
              dialogTitle="Create"
              open={openCreate}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell className={language === "en" ? "header-title-start" : "header-title-start-kh"} width="5%">
                  {t("thead_no")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="5%">
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="40%">
                  {t("thead_card_number")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="35%">
                  {t("remark")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="10%">
                  {t("thead_status")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title-end" : "header-title-end-kh"} width="5%" ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={8} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={8} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        // console.log("row::", row)
                        return (
                          <TableRow className="body-row" key={index}>

                            <TableCell className={language === "en" ? "body-cell-start" : "body-cell-start-kh"}  >
                              {index + paginationData?.slNo}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              <Avatar
                                alt="Remy Sharp"
                                src={row?.imageSrc}
                                sx={{ width: 42, height: 42 }}
                              />
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.cardNo}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.remark}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              <UpdateStatusCardNumber
                                editData={row}
                                setRefetch={refetch} />
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell-end" : "body-cell-end-kh"} align="right"  >
                              <CardNumberAction
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </div>
  );
};
