import { useState } from "react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import shop_logo from "../../Assets/market.png";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { Typography, Stack, Button } from "@mui/material";
import ShopAction from "../../Components/Shop/ShopAction";
import { CashInCashOut } from "./CashInCashOut/CashInCashOut";
import BackdropCloseShift from "./CloseShift/BackdropCloseShift";
import { translateLauguage } from "../../Include/Function/Translate";
import { GET_SHIFT_STATUS_BY_SHOP, GO_IN_TO_SHOP } from "../../Schema/Shop";

const handleSetLogo = (logo) => {
  window.localStorage.setItem("logo", logo);
};

export default function CardShop({ item, setRefetch }) {
  // Change Language
  const { language, setAlert } = React.useContext(AuthContext);
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const [shiftData, setShiftData] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { refetch } = useQuery(GET_SHIFT_STATUS_BY_SHOP, {
    fetchPolicy: "network-only",
    variables: { shopId: item?._id },
    onCompleted: ({ getShiftStatusByShop }) => {
      setShiftData(getShiftStatusByShop);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const [goIntoShop] = useMutation(GO_IN_TO_SHOP, {
    onCompleted: ({ goIntoShop }) => {
      if (goIntoShop?.isSuccess) {
        window.localStorage.setItem("shopId", item?._id);
        window.localStorage.setItem("shopStatus", shiftData?.status);
        window.localStorage.setItem("billingHead", item?.billingHead);
        window.localStorage.setItem("shiftId", shiftData?.shift?._id);
        navigate(`/pos?id=${item?._id}`);
        return;
      }
      setAlert(true, "error", goIntoShop?.message);
    },
    onError: ({ message }) => {
      console.log("error:::", message);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      className="item-container"
    >
      <img
        src={item?.logo ? item?.logo : shop_logo}
        alt="Shop logo"
        className="img"
      />

      <Stack direction="column" spacing={2} width={`100%`}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className="com-name">
            {language === "en" ? item?.shopNameEn : item?.shopNameKh}
          </Typography>

          <Stack direction="row" spacing={1}>
            {shiftData?.status === "Open" && (
              <Button
                className="btn-create-in-out"
                onClick={() => {
                  handleOpen();
                }}
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("cash_in_out")}
                </Typography>
              </Button>
            )}

            <CashInCashOut
              open={open}
              shiftData={shiftData}
              handleClose={handleClose}
            />
            <ShopAction editData={item} setRefetch={setRefetch} />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} width={`100%`}>
          <Stack direction="column" width={`30%`} spacing={1.5}>
            {item?.isInClose && item?.inCloseBy?._id !== userLogin?._id ? (
              <Typography
                className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
              >
                {t("thead_preparing_to_close")}
              </Typography>
            ) : (
              <>
                {shiftData?.status === "Open" && (
                  <>
                    <Button
                      className="btn-through-style"
                      onClick={() => {
                        handleSetLogo(item?.logo);
                        goIntoShop({ variables: { shopId: item?._id } });
                      }}
                    >
                      <Typography
                        className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                      >
                        {t("btn-opening-shop")}
                      </Typography>
                    </Button>

                    <BackdropCloseShift
                      item={item}
                      shopId={item?._id}
                      setRefetch={setRefetch}
                      shiftID={shiftData?.shift?._id}
                    />

                    {/* <Button
                      className="btn-close-sell-style"
                      onClick={() => {
                        getActiveCashier({ variables: { shopId: item?._id } });
                      }}
                    >
                      <Typography
                        className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                      >
                        {t("btn-stop-selling")}
                      </Typography>
                    </Button> */}
                  </>
                )}

                {shiftData?.status === "Close" && (
                  <Button
                    className="btn-sell-style"
                    onClick={() => {
                      handleSetLogo(item?.logo);
                      navigate(`/opensale?id=${item?._id}`);
                    }}
                  >
                    <Typography
                      className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                    >
                      {t("btn-open-shop")}
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Stack>
          <Stack direction="column" width={`70%`} spacing={1}>
            <Stack direction="row" spacing={1}>
              <Typography
                width={`30%`}
                className={language === "en" ? "txt-detail" : "txt-detail-kh"}
              >
                {t("last_closing_date")}
              </Typography>
              <Typography width={`5%`} className="txt-detail">
                :
              </Typography>
              <Typography width={`30%`} className="txt-detail">
                08-06-2024 5:30 pm
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography
                width={`30%`}
                className={language === "en" ? "txt-detail" : "txt-detail-kh"}
              >
                {t("last_closing_revenue")}
              </Typography>
              <Typography width={`5%`} className="txt-detail">
                :
              </Typography>
              <Typography width={`30%`} className="txt-detail">
                ៛ 0
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography
                width={`30%`}
                className={language === "en" ? "txt-detail" : "txt-detail-kh"}
              >
                {t("last_closing_revenue")}
              </Typography>
              <Typography width={`5%`} className="txt-detail">
                :
              </Typography>
              <Typography width={`30%`} className="txt-detail">
                $ 0
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
