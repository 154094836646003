import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Stack,
  Button,
  TableRow,
  TextField,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  InputAdornment,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData/EmptyData";
import { GET_UNFINISH_INVOICE } from "../../Schema/Invoice";
import LoadingPage from "../../Include/LoadingPage/LoadingPage";
import CheckPayment from "../../Components/Payment/CheckPayment";
import { translateLauguage } from "../../Include/Function/Translate";
import { currencyFormat, padNumber } from "../../Include/Function/DynamicFn";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";
import {
  MultipleSelectShop,
  SelectCustomer,
} from "../../Include/Function/DynamicSelect";
import MuiltiplePayment from "../../Components/Pos/Payment/MuiltiplePayment/MuiltiplePayment";
import DialogMuiltiplePayment from "../../Components/Pos/Payment/DialogMuiltiplePayment";

export const Payment = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [invoiceIDSelected, setInvoiceIDSelected] = useState([]);
  const [multipleSelectShopVal, setMultipleSelectShopVal] = useState([]);
  const [selectCustomerVal, setSelectCustomerVal] = useState({
    id: "",
    title: "",
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { refetch } = useQuery(GET_UNFINISH_INVOICE, {
    variables: {
      invoiceNo: parseInt(invoiceNo),
      customerId: selectCustomerVal?.id,
      endDate: moment(endDate).format("YYYY-MM-DD"),
      shopIds: multipleSelectShopVal.map((e) => e?.id),
      startDate: moment(startDate).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getUnfinishInvoice }) => {
      setLoading(false);
      setTableData(getUnfinishInvoice);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const totalRemainingAmount = tableData.reduce(
    (a, b) => a + b?.remainAmount,
    0
  );

  useEffect(() => {
    if (!selectCustomerVal?.id) setInvoiceIDSelected([]);
  }, [selectCustomerVal]);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-payment`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              size="small"
              type="number"
              className="search-field"
              placeholder={t("label_invoice_number")}
              onChange={(e) => setInvoiceNo(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_shop")}
            </Typography>
            <MultipleSelectShop
              className={"search-field"}
              selectedVal={multipleSelectShopVal}
              setSelectedVal={setMultipleSelectShopVal}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_customer")}
            </Typography>
            <SelectCustomer
              className={"search-field"}
              selectedVal={selectCustomerVal}
              setSelectedVal={setSelectCustomerVal}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_start_date")}
            </Typography>
            <SelectDatePicker
              date={startDate}
              setDate={(e) => setStartDate(e)}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_end_date")}
            </Typography>
            <SelectDatePicker
              date={endDate}
              setDate={(e) => setEndDate(e)}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={9} xl={2}>
            <Stack
              direction="column"
              alignItems="flex-end"
              justifyContent="flex-end"
              height="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Typography
                  className={
                    language === "kh" ? "header-text-kh" : "header-text"
                  }
                >
                  {t("thead_remaining")} :
                </Typography>
                <Stack
                  sx={{
                    bgcolor: "white",
                    width: "160px",
                    paddingX: 1,
                    paddingTop: 1.3,
                    paddingBottom: 0.7,
                    borderRadius: 1.5,
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={
                      language === "kh" ? "header-text-kh" : "header-text"
                    }
                    sx={{ fontWeight: "bold" }}
                  >
                    {currencyFormat(totalRemainingAmount)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px`, marginBottom: 6 }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="17%"
                >
                  {t("thead_invoice")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("thead_date")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="22%"
                >
                  {t("thead_customer")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("thead_total_amount")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("thead_remaining_amount")}
                </TableCell>

                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `body-cell-start`
                                  : `body-cell-start-kh`
                              }
                            >
                              {padNumber(
                                row?.invoiceNo,
                                5,
                                moment(row?.createdAt).format("YYYY")
                              )}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY HH:mm a"
                              )}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.customerId?.name}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {currencyFormat(row?.total || 0)}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {currencyFormat(row?.remainAmount || 0)}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en"
                                  ? `body-cell-end`
                                  : `body-cell-end-kh`
                              }
                              align="right"
                            >
                              <CheckPayment
                                selectCustomerVal={selectCustomerVal}
                                invoiceIDSelected={invoiceIDSelected}
                                setInvoiceIDSelected={setInvoiceIDSelected}
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>

      {tableData?.length > 0 && (
        <Stack
          width="100%"
          direction="column"
          sx={{ position: "fixed", bottom: 20, paddingRight: 7 }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <Button
                  onClick={handleOpen}
                  className="btn-create-style"
                  sx={{
                    width: "100%",
                    color: "white",
                  }}
                  disabled={invoiceIDSelected?.length === 0}
                >
                  <Typography
                    className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                    color={"white"}
                  >
                    Payment
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      {/* <MuiltiplePayment
        open={open}
        shiftId={shiftId}
        handleClose={handleClose}
        invoiceIDSelected={invoiceIDSelected}
      /> */}

      <DialogMuiltiplePayment
        open={open}
        handleClose={handleClose}
        invoiceIDSelected={invoiceIDSelected}
      />
    </div>
  );
};
