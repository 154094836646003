import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import "./cashIncashout.scss";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { GiMoneyStack } from "react-icons/gi";
import { BiMinusCircle } from "react-icons/bi";
import { CashKHR } from "./CashKHR";
import { CashUSD } from "./CashUSD";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { CASH_IN_SHIFT, CASH_OUT_SHIFT } from "../../../Schema/Shop";
import { GET_CASH_LABEL_BY_CURRENCY } from "../../../Schema/CashLabel";
import { translateLauguage } from "../../../Include/Function/Translate";

export const CashInCashOut = ({ open, handleClose, shiftData }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [typeCash, setTypeCash] = useState("IN");
  const [openCashUSD, setOpenCashUSD] = useState(false);
  const handleOpenCashUSD = () => setOpenCashUSD(true);
  const handleCloseCashUSD = () => setOpenCashUSD(false);

  const [openCashKHR, setOpenCashKHR] = useState(false);
  const handleOpenCashKHR = () => setOpenCashKHR(true);
  const handleCloseCashKHR = () => setOpenCashKHR(false);
  const [cashLabelKHR, setCashLabelKHR] = useState([]);
  const [cashLabelUSD, setCashLabelUSD] = useState([]);
  const { refetch } = useQuery(GET_CASH_LABEL_BY_CURRENCY, {
    variables: {
      currencyType: "All",
    },
    onCompleted: ({ getCashLabelByCurrency }) => {
      setCashLabelKHR(
        getCashLabelByCurrency
          .filter((e) => e?.currencyType === "KHR")
          .map((e) => ({
            cashLabel: e?.cashLabel,
            caseLabelId: e?._id,
            sheetQty: 0,
          }))
      );
      setCashLabelUSD(
        getCashLabelByCurrency
          .filter((e) => e?.currencyType === "USD")
          .map((e) => ({
            cashLabel: e?.cashLabel,
            caseLabelId: e?._id,
            sheetQty: 0,
          }))
      );
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  //======================== Create User =========================
  const [cashOutShift] = useMutation(CASH_OUT_SHIFT, {
    onCompleted: ({ cashOutShift }) => {
      setLoading(false);
      if (cashOutShift?.isSuccess === true) {
        setAlert(true, "success", cashOutShift?.message);
        handleClose();
        setTypeCash("");
        resetForm();
      } else {
        setAlert(true, "error", cashOutShift?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //======================== Create User =========================
  const [cashInShift] = useMutation(CASH_IN_SHIFT, {
    onCompleted: ({ cashInShift }) => {
      setLoading(false);
      if (cashInShift?.isSuccess === true) {
        setAlert(true, "success", cashInShift?.message);
        handleClose();
        setTypeCash("");
        resetForm();
      } else {
        setAlert(true, "error", cashInShift?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    usd: Yup.number().required(t("require")),
    khr: Yup.number().required(t("require")),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      usd: 0,
      khr: 0,
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => {
      setLoading(true);
      if (typeCash === "OUT") {
        cashOutShift({
          variables: {
            shiftId: shiftData?.shift?._id,
            cashOut: {
              usd: submitValues?.usd,
              khr: submitValues?.khr,
              remark: submitValues?.remark,
              cashLabelUsd: cashLabelUSD.map((e) => ({
                sheetQty: e?.sheetQty,
                caseLabelId: e?.caseLabelId,
              })),
              cashLabelKhr: cashLabelKHR.map((e) => ({
                sheetQty: e?.sheetQty,
                caseLabelId: e?.caseLabelId,
              })),
            },
          },
        });
      }
      if (typeCash === "IN") {
        cashInShift({
          variables: {
            shiftId: shiftData?.shift?._id,
            cashIn: {
              usd: submitValues?.usd,
              khr: submitValues?.khr,
              remark: submitValues?.remark,
              cashLabelUsd: cashLabelUSD.map((e) => ({
                sheetQty: e?.sheetQty,
                caseLabelId: e?.caseLabelId,
              })),
              cashLabelKhr: cashLabelKHR.map((e) => ({
                sheetQty: e?.sheetQty,
                caseLabelId: e?.caseLabelId,
              })),
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const handleChangeQtyCashLabelKHR = (caseLabelId, qty) => {
    const newData = cashLabelKHR?.map((e) => {
      if (e?.caseLabelId === caseLabelId) e.sheetQty = parseFloat(qty || 0);
      return e;
    });
    setCashLabelKHR(newData);
  };
  const handleChangeQtyCashLabelUSD = (caseLabelId, qty) => {
    const newData = cashLabelUSD?.map((e) => {
      if (e?.caseLabelId === caseLabelId) e.sheetQty = parseFloat(qty || 0);
      return e;
    });
    setCashLabelUSD(newData);
  };

  useEffect(() => {
    if (!values?.khr) setFieldValue("khr", 0);
  }, [values?.khr]);

  useEffect(() => {
    if (!values?.usd) setFieldValue("usd", 0);
  }, [values?.usd]);

  useEffect(() => {
    resetForm();
    setTypeCash("IN");
  }, [open]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("thead_cash")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    onClick={() => setTypeCash("IN")}
                    className={
                      typeCash === "IN" ? "cash_in_out_active" : "cash_in_out"
                    }
                  >
                    {t("cash_in")}
                  </Button>
                  <Button
                    onClick={() => setTypeCash("OUT")}
                    className={
                      typeCash === "OUT" ? "cash_in_out_active" : "cash_in_out"
                    }
                  >
                    {t("cash_out")}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack width="100%" spacing={1}>
                  <Stack direction="row" width="100%" spacing={1}>
                    <TextField
                      className="text-field"
                      fullWidth
                      size="small"
                      type="number"
                      inputProps={{ min: 0 }}
                      onFocus={(e) => e?.target?.select()}
                      InputProps={{
                        endAdornment: <Typography> $ </Typography>,
                        startAdornment: (
                          <Typography
                            pr={0.8}
                            whiteSpace="pre"
                            fontWeight="bold"
                            color="#0f81c2"
                            fontSize="15px"
                          >
                            {t("usd")} :
                          </Typography>
                        ),
                      }}
                      {...getFieldProps("usd")}
                      error={Boolean(touched.usd && errors.usd)}
                      helperText={touched.usd && errors.usd}
                    />
                    <IconButton onClick={handleOpenCashUSD}>
                      <GiMoneyStack style={{ color: "green" }} />
                    </IconButton>
                  </Stack>
                  <Stack direction="row" width="100%" spacing={1}>
                    <TextField
                      className="text-field"
                      fullWidth
                      size="small"
                      type="number"
                      inputProps={{ min: 0 }}
                      onFocus={(e) => e?.target?.select()}
                      InputProps={{
                        endAdornment: <Typography> ៛ </Typography>,
                        startAdornment: (
                          <Typography
                            pr={0.8}
                            whiteSpace="pre"
                            fontWeight="bold"
                            color="#0f81c2"
                            fontSize="15px"
                          >
                            {t("khr")} : 
                          </Typography>
                        ),
                      }}
                      {...getFieldProps("khr")}
                      error={Boolean(touched.khr && errors.khr)}
                      helperText={touched.khr && errors.khr}
                    />
                    <IconButton onClick={handleOpenCashKHR}>
                      <GiMoneyStack style={{ color: "green" }} />
                    </IconButton>
                  </Stack>
                </Stack>
                <CashKHR
                  open={openCashKHR}
                  cashLabelKHR={cashLabelKHR}
                  handleClose={handleCloseCashKHR}
                  handleChangeQtyCashLabelKHR={handleChangeQtyCashLabelKHR}
                />
                <CashUSD
                  open={openCashUSD}
                  cashLabelUSD={cashLabelUSD}
                  handleClose={handleCloseCashUSD}
                  handleChangeQtyCashLabelUSD={handleChangeQtyCashLabelUSD}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  placeholder={t('thead_reason')}
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                className={language === "kh" ? "btn-suspend-kh" : "btn-suspend"}
                style={{ width: "100%" }}
                fullWidth
                onClick={handleClose}
              >
                {t("btn-cancel")}
              </Button>
              {loading ? (
                <Button
                  className={language === "kh" ? "btn-create-kh" : "btn-create"}
                  fullWidth
                >
                  {t("loading")}
                </Button>
              ) : (
                <Button
                  disabled={typeCash === ""}
                  className={language === "kh" ? "btn-create-kh" : "btn-create"}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {t("btn-confirm")}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
