import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Stack,
  Select,
  Button,
  TableRow,
  MenuItem,
  TextField,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import "../../../Style/pageStyle.scss";
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectCategory } from "../../../Include/Function/DynamicSelect";
import { GET_MAIN_PRODUCT_WITH_PAGINATION } from "../../../Schema/Product";
import { ProductForm } from "../../../Components/Setting/Product/ProductForm";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import ProductAccordion from "../../../Components/Setting/Product/ProductAccordion";

export const Product = () => {
  // ===================== Change Language ==========================

  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("All");
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState();
  const [selectedCategoryVal, setSelectedCategoryVal] = useState({
    id: "",
    title: "",
  });

  const { refetch } = useQuery(GET_MAIN_PRODUCT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      isUse: status === "All" ? null : status,
      categoryId: selectedCategoryVal?.id || null,
    },
    onCompleted: ({ getMainProductPagination }) => {
      setLoading(false);
      setTableData(getMainProductPagination?.data);
      setPaginationData(getMainProductPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  //================ Handle Refetch Data Category =================
  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      //======== Check Category ============
      const checkValueCategory = tableData?.filter(
        (e) =>
          e?.categoryIds?.length > 0 &&
          e.categoryIds.some(
            (cat) =>
              cat?.categoryNameEn === null || cat?.categoryNameKh === null
          )
      );
      if (checkValueCategory?.length > 0) {
        refetch();
      }

      //======== Check Unit ============
      const checkValueUnit = tableData?.filter(
        (e) => e?.minimumUnitId === null
      );
      if (checkValueUnit?.length > 0) {
        refetch();
      }
    }
  }, [tableData]);

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography
              className={language === "kh" ? "page-title-kh" : "page-title"}
            >
              {t(`page-setting`)}
            </Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`product`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              placeholder={t(`label-search`)}
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_category")}
            </Typography>
            <SelectCategory
              className={"search-field"}
              selectedVal={selectedCategoryVal}
              setSelectedVal={setSelectedCategoryVal}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`thead_status`)}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>In-active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={12} md={12} lg={3} xl={5.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
            <ProductForm
              dialogTitle="Create"
              open={openCreate}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="5%"
                >
                  {t("thead_no")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="35%"
                  sx={{ paddingLeft: "27px !important" }}
                >
                  {t("product_name")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="14%"
                  sx={{ paddingLeft: "0px !important" }}
                >
                  {t("minimum_unit")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="15%"
                  sx={{ paddingLeft: "0px !important" }}
                >
                  {t("thead_category")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="25%"
                  sx={{ paddingLeft: "0px !important" }}
                >
                  {t("remark")}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="6%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <></>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        <ProductAccordion tableData={tableData} setRefetch={refetch} />

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </div>
  );
};
