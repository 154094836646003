import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_INVOICE_BY_ID } from "../../Schema/Invoice";

const useGetInvoiceById = (invoiceId) => {
    const [invoiceById, setInvoiceById] = useState([]);
    const { refetch: refetchInvoiceById } = useQuery(GET_INVOICE_BY_ID, {
        variables: {
            id: invoiceId
        },
        onCompleted: ({ getInvoiceById }) => {
            if (getInvoiceById) setInvoiceById(getInvoiceById);
        },
    });
    // total amount to BePaid
    const amountToBePaid = invoiceById?.reduce(
        (a, b) => a + (b?.total - b?.paidAmount) || 0,
        0
    );
    // Refetch data whenever `invoiceId`,  changes
    useEffect(() => {
        refetchInvoiceById();
    }, [invoiceId]);

    return {
        invoiceById,
        refetchInvoiceById,
        totalAmountToBePaid: amountToBePaid > 0 ? parseFloat(amountToBePaid?.toFixed(2)) : 0,
    };
};

export default useGetInvoiceById;
