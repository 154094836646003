import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  Button,
  Select,
  TableRow,
  MenuItem,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  FormControl,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import AdjustOutAction from "./AdjustOutAction";
import CreateIcon from "../../../Assets/add.png";
import { AdjustProductOut } from "./AdjustProductOut";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import { GET_ADJUST_OUT_PAGINATION } from "../../../Schema/InventoryInWarehouse";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
import {
  SelectMainProduct,
  SelectWarehouse,
} from "../../../Include/Function/DynamicSelect";

export const AdjustOut = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [reason, setReason] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [paginationData, setPaginationData] = useState();
  const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({
    id: "",
    title: "",
  });
  const [selectedMainProductVal, setSelectedMainProductVal] = useState({
    id: "",
    title: "",
  });

  const { refetch } = useQuery(GET_ADJUST_OUT_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      reason: reason,
      warehouseId: selectedWarehouseVal?.id || null,
      endDate: moment(endDate).format("YYYY-MM-DD"),
      mainProductId: selectedMainProductVal?.id || null,
      startDate: moment(startDate).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getAdjustOutPagination }) => {
      setLoading(false);
      setTableData(getAdjustOutPagination?.data);
      setPaginationData(getAdjustOutPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_warehouse")}
          </Typography>

          <SelectWarehouse
            className={"search-field"}
            selectedVal={selectedWarehouseVal}
            setSelectedVal={setSelectedWarehouseVal}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_product")}
          </Typography>

          <SelectMainProduct
            className={"search-field"}
            selectedVal={selectedMainProductVal}
            setSelectedVal={setSelectedMainProductVal}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_reason")}
          </Typography>
          <FormControl fullWidth size="small" className="search-field">
            <Select
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            >
              <MenuItem value={"All"}> {t("thead_all")}</MenuItem>
              <MenuItem value={"Lose"}> {t("thead_lose")} </MenuItem>
              <MenuItem value={"ForUse"}> {t("thead_for_use")} </MenuItem>
              <MenuItem value={"ReCount"}> {t("thead_recount")} </MenuItem>
              <MenuItem value={"Expired"}> {t("thead_expired")} </MenuItem>
              <MenuItem value={"Broken"}> {t("thead_broken")} </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_start_date")}
          </Typography>
          <SelectDatePicker
            date={startDate}
            className={"search-field"}
            setDate={(e) => setStartDate(e)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_end_date")}
          </Typography>
          <SelectDatePicker
            date={endDate}
            className={"search-field"}
            setDate={(e) => setEndDate(e)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={9} xl={2}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              className="btn-create-style"
              startIcon={
                <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
              }
            >
              <Typography
                className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
              >
                {t("thead_adjust_out")}
              </Typography>
            </Button>
            <AdjustProductOut
              open={open}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Stack>
        </Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="5%"
                >
                  {" "}
                  {t("thead_no")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="12%"
                >
                  {" "}
                  {t("thead_warehouse")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="12%"
                >
                  {" "}
                  {t("thead_product")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="12%"
                >
                  {" "}
                  {t("thead_requester")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="10%"
                >
                  {" "}
                  {t("thead_reason")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="9%"
                >
                  {" "}
                  {t("remark")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={9} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={9} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                            >
                              {index + paginationData?.slNo}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.warehouseId?.warehouseEnName
                                : row?.warehouseId?.warehouseKhName}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.adjustOutStockIds[0]?.mainStockId
                                    ?.mainProductId?.mainProductEnName
                                : row?.adjustOutStockIds[0]?.mainStockId
                                    ?.mainProductId?.mainProductKhName}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.createdBy?.latinName
                                : row?.createdBy?.khmerName}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.reason === "ForUse" ? "Use" : row?.reason}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.remark}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <AdjustOutAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.status === "Received"}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </>
  );
};
