import React, { useState, useContext } from "react";
import "../../../Style/actionStyle.scss";
import { ViewReceive } from "./ViewReceive/ViewReceive";
import { AuthContext } from "../../../Context/AuthContext";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import { ReceiveProduct } from "./ReceiveProduct/ReceiveProduct";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { translateLauguage } from "../../../Include/Function/Translate";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function RequestAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title={t("thead_view")} placement="top">
          <IconButton onClick={handleOpenView}>
            <Box className="view-container" >
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("tooltip-update")} placement="top">
          <IconButton onClick={handleOpenEdit} disabled={disabled}>
            <Box className="update-container" sx={disabled && { bgcolor: "grey !important" }}>
              <ArrowDownwardIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>

      </Stack>

      <ViewReceive
        open={openView}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseView}
      />
      <ReceiveProduct
        open={openEdit}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />

    </div>
  );
}
