import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  TextField,
  IconButton,
  colors,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";

import "./login.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import email from "../Assets/email.png";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
// Src
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Include/Function/Translate";
import logo from "../Assets/it-logo.png";
import CamFlag from "../Assets/Cambodia.png";
import EngFlag from "../Assets/United_Kingdom.png";
import { FORGOT_PASSWORD } from "../Schema/Users";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  // ===================== Change Language ==========================
  const { language, changeLanguage, setAlert, dispatch } =
    useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [iconReload, setIconReload] = useState(false);
  const navigate = useNavigate();

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  //============ Function Change Password ==============
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onError: (error) => {
      setIconReload(false);
      setAlert(true, "error", error?.message);
      console.log(error);
    },
  });

  const CheckValidate = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: CheckValidate,
    onSubmit: async (values) => {
      // console.log("values=====", values);
      setIconReload(true);
      forgotPassword({
        variables: {
          ...values,
        },
        onCompleted: ({ forgotPassword }) => {
          setIconReload(false);
          if (forgotPassword?.isSuccess) {
            setAlert(true, "success", forgotPassword?.message);
            navigate(`/verifyOtp?email=${values?.email}`);
          } else {
            setAlert(true, "error", forgotPassword?.message);
          }
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="login-container-page"
    >
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container className="box-container">
              <Grid item xs={7} alignSelf="center">
                <Stack direction="row" justifyContent="center" width="100%">
                  <Stack direction="column" spacing={2}>
                    <img width="100%" src={email} alt="email" />
                    <Stack
                      direction={`column`}
                      justifyContent={`center`}
                      alignItems={`center`}
                      spacing={0.5}
                    >
                      <Typography
                        className={
                          language === "en" ? "noted-txt" : "noted-txt-kh"
                        }
                      >
                        {t("input_verify_email")}
                      </Typography>
                      <Typography
                        className={
                          language === "en" ? "noted-txt" : "noted-txt-kh"
                        }
                      >
                        {t("in_order_to_change")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={5} sx={{ padding: "40px 10px 30px 20px" }}>
                <Stack direction="column" spacing={3}>
                  <Stack direction={`row`} justifyContent={`center`}>
                    <img src={logo} alt="logo" className="img" style={{borderRadius: "50%"}}/>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    width="100%"
                    className="box-login"
                    spacing={2}
                  >
                    <Stack direction="column">
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("email")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        className="input-login"
                        placeholder="example@company.com"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                    <Link to="/login">
                      <Typography
                        className={
                          language === "en" ? "forgot-pwd" : "forgot-pwd-kh"
                        }
                      >
                        {t("back_to_login")}
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                      <Button
                        onClick={handleSubmit}
                        className={
                          language === "en" ? "btn-sign-in" : "btn-sign-in-kh"
                        }
                        type="submit"
                        endIcon={
                          iconReload ? (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size="20px"
                            />
                          ) : null
                        }
                      >
                        {t("btn-submit")}
                      </Button>
                    </Stack>
                  </Stack>
                  <Typography className="footer-copy-right">
                    Copyright © {moment().format("YYYY")} Go Global IT. All
                    rights reserved.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
      <Stack
        sx={{
          right: "20px",
          top: "10px",
          position: "absolute",
        }}
      >
        <Stack direction="column" justifyContent="center">
          {language === "en" ? (
            <Tooltip title="Change to Khmer" placement="left">
              <IconButton onClick={() => changeLanguage("kh")}>
                <Avatar src={EngFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change to English" placement="left">
              <IconButton onClick={() => changeLanguage("en")}>
                <Avatar src={CamFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
