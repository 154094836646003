import React, { useContext, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  Avatar,
  Tooltip,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";

import "./login.scss";
import * as Yup from "yup";
import moment from "moment";
import logo from "../Assets/it-logo.png";
import CamFlag from "../Assets/Cambodia.png";
import { useMutation } from "@apollo/client";
import verify from "../Assets/verifyOTP.png";
import { VERIFY_OTP } from "../Schema/Users";
import EngFlag from "../Assets/United_Kingdom.png";
import { AuthContext } from "../Context/AuthContext";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { translateLauguage } from "../Include/Function/Translate";

const VerifyOtp = () => {
  // ===================== Change Language ==========================
  const { language, changeLanguage, setAlert, dispatch } =
    useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [iconReload, setIconReload] = useState(false);
  const navigate = useNavigate();

  //Get email by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  // const [token, setToken] = useState(
  //   JSON.parse(window.localStorage.getItem("token"))
  // );

  const [verifyOTP] = useMutation(VERIFY_OTP, {
    onCompleted: ({ verifyOTP }) => {
      // console.log("verifyOTP", verifyOTP);
      setIconReload(false);
      if (verifyOTP?.isSuccess) {
        setAlert(true, "success", verifyOTP?.message);
        setTimeout(() => {
          // localStorage.setItem("token", JSON.stringify(verifyOTP?.data?.token));
          // localStorage.setItem("userLogin",JSON.stringify(verifyOTP?.data?.user));
          localStorage.setItem(
            "verifyToken",
            JSON.stringify(verifyOTP?.data?.token)
          );
          localStorage.setItem(
            "verifyUser",
            JSON.stringify(verifyOTP?.data?.user)
          );
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              token: verifyOTP?.data?.token,
            },
          });
          navigate(`/reset-password?email=${email}`);
          setIconReload(false);
        }, 500);
      } else {
        setAlert(true, "error", verifyOTP?.message);
      }
    },
    onError: (error) => {
      setIconReload(false);
      console.log("error::::", error);
      setAlert(true, "error", error?.message);
    },
  });

  const VerifySchema = Yup.object().shape({
    email: Yup.string(),
    otp: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      otp: "",
    },

    validationSchema: VerifySchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      setIconReload(true);
      verifyOTP({
        variables: {
          ...values,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="login-container-page"
    >
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container className="box-container">
              <Grid item xs={6} alignSelf="center">
                <Stack direction="row" justifyContent="center" width="100%">
                  <Stack direction="column" spacing={2}>
                    <img width="100%" src={verify} alt="verify" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ padding: "40px 10px 30px 20px" }}>
                <Stack direction="column" spacing={3}>
                  <Stack direction={`row`} justifyContent={`center`}>
                    <img src={logo} alt="logo" className="img" />
                  </Stack>
                  <Stack
                    direction={`column`}
                    justifyContent={`center`}
                    // alignItems={`center`}
                    spacing={0.5}
                  >
                    <Typography
                      className={
                        language === "en" ? "noted-txt" : "noted-txt-kh"
                      }
                    >
                      {t("email_verify")}
                    </Typography>
                    <Typography className="email-txt">
                      {/* {t("in_order_to_change")} */}
                      {email}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    width="100%"
                    className="box-login"
                    spacing={2}
                  >
                    <Stack direction="column">
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("digit_code")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        // type="number"
                        className="input-login"
                        placeholder="0000"
                        {...getFieldProps("otp")}
                        error={Boolean(touched.otp && errors.otp)}
                        helperText={touched.otp && errors.otp}
                      />
                    </Stack>
                    <Link to="/forgotpassword">
                      <Typography
                        className={
                          language === "en" ? "forgot-pwd" : "forgot-pwd-kh"
                        }
                      >
                        {t("back_to_email")}
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                      <Button
                        onClick={handleSubmit}
                        className={
                          language === "en" ? "btn-sign-in" : "btn-sign-in-kh"
                        }
                        type="submit"
                        endIcon={
                          iconReload ? (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size="20px"
                            />
                          ) : null
                        }
                      >
                        {t("btn-submit")}
                      </Button>
                    </Stack>
                    {/* <Stack direction="column" justifyContent="center">
                      <Typography className="label-login" sx={{ textAlign: "center" }}>{t("or")}</Typography>
                    </Stack>
                    <Stack direction="column">
                      <Button
                        sx={{ backgroundColor: "#f1f9fc" }}
                        onClick={() => {
                          navigate('/logintelegram')
                        }}
                        endIcon={
                          <FaTelegram size="28px" sx={{ color: "#006eba" }} />
                        }
                      >
                        {t("login_with")}
                      </Button>
                    </Stack> */}
                  </Stack>
                  <Typography className="footer-copy-right">
                    Copyright © {moment().format("YYYY")} Go Global IT. All
                    rights reserved.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
      <Stack
        sx={{
          right: "20px",
          top: "10px",
          position: "absolute",
        }}
      >
        <Stack direction="column" justifyContent="center">
          {language === "en" ? (
            <Tooltip title="Change to Khmer" placement="left">
              <IconButton onClick={() => changeLanguage("kh")}>
                <Avatar src={EngFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change to English" placement="left">
              <IconButton onClick={() => changeLanguage("en")}>
                <Avatar src={CamFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VerifyOtp;
