import React, { useContext, useState } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { SelectBank } from "../../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { IconButton, Stack, TextField, Typography } from "@mui/material";

export default function SelectBankAccount({ bankAccountData, setBankAccountData, required }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newBankAccount = {
    key: Math.random() + Date.now(),
    bankId: null,
    accountName: null,
    accountNumber: null,
  }
  const handleAddItem = () => setBankAccountData([...bankAccountData, newBankAccount]);
  const handleRemoveItem = (key) => {
    const newData = bankAccountData.filter((e) => e.key !== key);
    setBankAccountData([...newData]);
  }
  const handleUpdateItem = (key, value, fieldName) => {
    const newData = bankAccountData.map((e) => {
      if (e.key === key && fieldName === "bankId") e.bankId = value;
      if (e.key === key && fieldName === "accountName") e.accountName = value;
      if (e.key === key && fieldName === "accountNumber") e.accountNumber = value;
      return e;
    })
    setBankAccountData([...newData]);
  }


  return (
    <Stack direction="column" width="100%" spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
          {t("bank")}
        </Typography>

        <IconButton onClick={handleAddItem} >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" width="100%" spacing={2}>
        {
          bankAccountData?.map((e) =>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack sx={{ width: "25%" }}>
                <SelectBank
                  require={required}
                  selectedVal={e?.bankId}
                  setSelectedVal={(value) => handleUpdateItem(e.key, value, "bankId")}
                />
              </Stack>
              <TextField
                sx={{ width: "35%" }}
                fullWidth
                size="small"
                className="text-field"
                value={e?.accountName}
                error={required && !e?.accountName}
                placeholder={t('thead_bank_name')}
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "accountName")}
              />
              <TextField
                fullWidth
                size="small"
                sx={{ width: "40%" }}
                className="text-field"
                value={e?.accountNumber}
                error={required && !e?.accountNumber}
                placeholder={t('thead_account_number')}
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "accountNumber")}
              />
              <IconButton onClick={() => handleRemoveItem(e?.key)}>
                <CloseOutlinedIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>
          )
        }

      </Stack>
    </Stack>


  );
}
