import React, { useEffect, useState, useContext } from "react";
import "./layout.scss";
import { styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { Box, Drawer, AppBar } from "@mui/material";
//icons
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import MenuNavbar from "../../Menu/MenuNavbar";
import TopNavbar from "../../Menu/TopNavbar";
import TopNavbarMobile from "../../Menu/TopNavbarMobile";
import { GET_USER_LOGIN } from "../../Schema/Users";
import { AuthContext } from "../../Context/AuthContext";
import { GET_OUT_OF_SHOP } from "../../Schema/Shop";
import { useMutation } from "@apollo/client";
// import logo from "../Assets/logoLogin.svg";

const drawerWidth = 270;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

// const outShop = [
//   "You are not authorized to access this shop!",
//   "This shop is still in the process of closing!",
//   "Shift not found!",
// ];

export default function Layout() {
  //============== Hook Open Menu Mobile ==============
  const [openMobile, setOpenMobile] = React.useState(false);
  const handleDrawerOpenMobile = () => setOpenMobile(true);
  const handleDrawerCloseMobile = () => setOpenMobile(false);
  const location = useLocation();

  const navigate = useNavigate();

  // =================  Resize width ===================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const [getOutOfShop] = useMutation(GET_OUT_OF_SHOP, {
    onError: (error) => {
      console.log("error:::", error.messagae);
    },
  });

  const { dispatch, setAlert } = useContext(AuthContext);

  //================ Check User Login ===================
  const { refetch } = useQuery(GET_USER_LOGIN, {
    pollInterval: 5000,
    onCompleted: ({ getUserLogin }) => {
      if (!getUserLogin?.isSuccess) {
        setAlert(true, "error", getUserLogin?.message);

        window.localStorage.removeItem("shopId");
        window.localStorage.removeItem("shiftId");
        window.localStorage.removeItem("shopStatus");

        setTimeout(() => {
          navigate("/shop", { replace: true });
        }, 1000);

      } else if (getUserLogin?.data) {

        window.localStorage.setItem("userLogin", JSON.stringify(getUserLogin?.data));
      }
    },
    onError: ({ message }) => {
      setAlert(true, "error", { messageKh: message, messageEn: message });
      setTimeout(() => {
        navigate("/login");
        window.localStorage.clear();
        window.location.reload("/login");
        dispatch({ type: "LOGGED_IN_USER", payload: null, });
      }, 1000);
    },
  });

  useEffect(() => {
    refetch();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (["/shop"].includes(location?.pathname)) {
      const outOfShop = async () => {
        try {
          await getOutOfShop();
        } catch (err) { }
      };
      outOfShop();
      window.localStorage.removeItem("shopId");
      window.localStorage.removeItem("shiftId");
      window.localStorage.removeItem("shopStatus");
    }
  }, [location?.pathname]);

  return (
    <Box className="main">
      {/* {width > 1000 ? (
        <Box className="inner" sx={{ display: "flex" }}>
          <AppBar
            sx={{ boxShadow: "none", backgroundColor: "red", height: "30px" }}
            position="fixed"
            open={true}
          >
            <TopNavbar />
          </AppBar>
          <Main open={true} sx={{ marginTop: "60px" }}>
            <Outlet />
          </Main>
        </Box>
      ) : (
        <Box className="inner" sx={{ display: "flex" }}>
          <AppBar sx={{ boxShadow: "none" }} position="fixed" open={true}>
            <TopNavbarMobile handleDrawerOpen={handleDrawerOpenMobile} />
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={openMobile}
          >
            <MenuNavbar
              open={openMobile}
              handleDrawerClose={handleDrawerCloseMobile}
            />
          </Drawer>

          <Main open={openMobile} sx={{ marginTop: "60px" }}>
            <Outlet />
          </Main>
        </Box>
      )} */}

      <Box className="inner" sx={{ display: "flex" }}>
        <AppBar
          sx={{ boxShadow: "none" , height: '30px'}}
          position="fixed" 
          open={true}
        >
          <TopNavbar />
        </AppBar>
        <Main open={true} sx={{ marginTop: "40px" }}>
          <Outlet />
        </Main>
      </Box>
    </Box>
  );
}
