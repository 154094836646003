import { gql } from "@apollo/client";

export const CREATE_REQUEST = gql`
  mutation CreateRequest($input: RequestInput) {
    createRequest(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation UpdateRequest($id: ID, $input: RequestInput) {
    updateRequest(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const VOID_REQUEST = gql`
  mutation VoidRequest($id: ID, $voidBy: ActionByInput) {
    voidRequest(_id: $id, voidBy: $voidBy) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CANCEL_REQUEST = gql`
  mutation CancelRequest($id: ID, $checkBy: ActionByInput) {
    cancelRequest(_id: $id, checkBy: $checkBy) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_COUNT_TRANSFER_IN_BY_SHOP = gql`
  query Query {
    getCountTransferInByShopBadge
  }
`;

export const GET_REMAIN_BY_PRODUCT_IN_SHOP = gql`
  query Query($mainProductId: ID) {
    getRemainByProductInShop(mainProductId: $mainProductId)
  }
`;

export const CREATE_TRANSFER_BACK = gql`
  mutation CreateTransferBack($input: TransferBackInput) {
    createTransferBack(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const RECEIVE_TRANSFER_FROM_WAREHOUSE = gql`
  mutation ReceiveTransferFromWarehouse(
    $id: ID
    $requestId: ID
    $input: ReceiveTransferFromWarehouseInput
  ) {
    receiveTransferFromWarehouse(
      _id: $id
      requestId: $requestId
      input: $input
    ) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CREATE_POS_ADJUST_OUT = gql`
  mutation CreatePosAdjustOut($input: AdjustOutInput) {
    createPosAdjustOut(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_POS_STOCK_PRODUCT_FOR_ADJUST_OUT = gql`
  query GetPosStockProductForAdjustOut($mainProductId: ID) {
    getPosStockProductForAdjustOut(mainProductId: $mainProductId) {
      _id
      unitPrice
      qty
      remain
      expireDate
      createdAt
      action
      mainProductId {
        _id
        mainProductKhName
        mainProductEnName
        minimumUnitId {
          unitEnName
          unitKhName
        }
      }
    }
  }
`;

export const GET_POS_ADJUST_OUT_PAGINATION = gql`
  query GetPosAdjustOutPagination(
    $page: Int
    $limit: Int
    $endDate: Date
    $startDate: Date
    $reason: AdjustOutReasonEnum
  ) {
    getPosAdjustOutPagination(
      page: $page
      limit: $limit
      endDate: $endDate
      startDate: $startDate
      reason: $reason
    ) {
      data {
        _id
        warehouseId {
          warehouseKhName
          warehouseEnName
          _id
        }
        shopId {
          shopNameEn
          shopNameKh
        }
        adjustOutStockIds {
          mainStockId {
            mainProductId {
              mainProductEnName
              mainProductKhName
              minimumUnitId {
                unitEnName
                unitKhName
              }
            }
          }
          posStockId {
            action
          }
          adjustFrom
          adjustTo
        }
        reason
        remark
        createdBy {
          latinName
          khmerName
          _id
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_TRANSFER_PAGINATION = gql`
  query Data(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $shopId: ID
    $transferNo: Int
    $fromDate: Date
    $toDate: Date
    $status: TransferStatusEnum
    $type: TransferTypeEnum
  ) {
    getTransferPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      shopId: $shopId
      transferNo: $transferNo
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      type: $type
    ) {
      data {
        _id
        transferNo
        createdAt
        status
        receiveBy {
          userId {
            latinName
            khmerName
          }
          createdAt
          remark
        }
        transferBy {
          userId {
            latinName
            khmerName
            _id
          }
        }
        transferProducts {
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
          deliverQty
          warehouseIds {
            warehouseId {
              warehouseKhName
              warehouseEnName
              _id
            }
            qty
          }
          takeQty
        }
        requestId {
          _id
          requestNo
          needDate
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_POS_STOCK_PAGINATION = gql`
  query GetPosStockPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $mainProductId: ID
    $categoryId: ID
    $isHaveStock: Boolean
  ) {
    getPosStockPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      mainProductId: $mainProductId
      categoryId: $categoryId
      isHaveStock: $isHaveStock
    ) {
      data {
        _id
        mainProductKhName
        mainProductEnName
        categoryIds {
          _id
          categoryNameEn
          categoryNameKh
        }
        minimumUnitId {
          _id
          unitEnName
          unitKhName
        }
        isUse
        remark
        remain
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_REQUEST_PAGINATION = gql`
  query GetRequestPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $requestNo: Int
    $priority: PurchasePriorityEnum
    $status: RequestStatusEnum
    $isVoid: Boolean
  ) {
    getRequestPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      requestNo: $requestNo
      priority: $priority
      status: $status
      isVoid: $isVoid
    ) {
      data {
        _id
        requestNo
        needDate
        requestMainProducts {
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
          qty
        }
        approveMainProducts {
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              remark
              unitKhName
              unitEnName
            }
          }
          qty
        }
        priority
        status
        isVoid
        requestBy {
          userId {
            _id
            latinName
            khmerName
          }
          remark
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_POS_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION = gql`
  query GetPosStockDetailByMainProductPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $mainProductId: ID
    $action: PosStockActionEnum
  ) {
    getPosStockDetailByMainProductPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      mainProductId: $mainProductId
      action: $action
    ) {
      data {
        _id
        action
        unitPrice
        qty
        remain
        expireDate
        createdAt
        mainProductId {
          remain
          mainProductEnName
          mainProductKhName
          minimumUnitId {
            remark
            unitKhName
            unitEnName
          }
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      } 
    }
  }
`;
