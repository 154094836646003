import React, { useContext } from "react";
import {
  Grid,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import EmptyData from "../../../Include/EmptyData/EmptyData";

export const ViewAdjustIn = ({ open, editData, handleClose, }) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("dialog_title_correction_history")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>



          {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_date")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {moment(editData?.adjustInDate).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_reason")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {editData?.reason}
              </Typography>
            </Stack>
          </Grid>
 */}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack width="100%" direction="column" spacing={2} mt={2}  >

              <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
                  {t("thead_product")}
                </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
                  {t("thead_qty")}
                </Typography>

                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
                  {t("thead_price")}
                </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
                  {t("thead_total_amount")}
                </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%" whiteSpace="pre">
                  {t("thead_expire_date")}
                </Typography>
              </Stack>


              <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
                <Divider />
                {
                  editData?.adjustInProducts?.length === 0 ? <EmptyData /> :
                    editData?.adjustInProducts?.map((e, key) => (
                      <Stack width="100%" direction="column" spacing={1.7} key={key}>
                        <Stack width="100%" direction="row" spacing={2} sx={{ paddingX: 2 }}>
                          <Typography width="20%" > {language === "en" ? e?.productId?.mainProductId?.mainProductEnName : e?.productId?.mainProductId?.mainProductKhName} </Typography>
                          <Typography width="20%" > {e?.qty} {language === "en" ? e?.productId?.unitId?.unitEnName : e?.productId?.unitId?.unitKhName}</Typography>
                          <Typography width="20%" > $ {e?.unitPrice} </Typography>
                          <Typography width="20%" > $ {e?.unitPrice * e?.qty} </Typography>
                          <Typography width="20%" > {e?.expireDate && moment(e?.expireDate).format("DD.MM.YYYY")} </Typography>
                        </Stack>
                        <Divider />
                      </Stack>
                    ))
                }
              </Stack>
            </Stack>

          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            fullWidth
            onClick={handleClose}
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog >
  );
};
