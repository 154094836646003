import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  Button,
  Select,
  Switch,
  TableRow,
  MenuItem,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Typography,
  FormControl,
  TableContainer,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
//Icon
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../../../Style/pageStyle.scss";
import { useQuery, useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import { translateLauguage } from "../../../Include/Function/Translate";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { CustomerForm } from "../../../Components/Setting/Customer/CustomerForm";
import CustomerAction from "../../../Components/Setting/Customer/CustomerAction";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { GET_CUSTOMER_PAGINATION, UPDATE_CUSTOMER, } from "../../../Schema/Customer";

export const Customer = () => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);


  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_CUSTOMER_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      pagination: true,
      keyword: keyword,
      isUse: status === "All" ? null : status,
    },
    onCompleted: ({ getCustomerPagination }) => {
      console.log(" onCompleted === > ", getCustomerPagination)
      setLoading(false);
      setTableData(getCustomerPagination?.data);
      setPaginationData(getCustomerPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: ({ updateCustomer }) => {
      console.log("updateCustomer", updateCustomer);
      if (updateCustomer?.isSuccess) {
        setAlert(true, "success", updateCustomer?.message);
        refetch();
      } else {
        setAlert(true, "error", updateCustomer?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const handleUpdateStatus = (id, status) => {
    console.log("iseuse", id);
    updateCustomer({
      variables: {
        id: id,
        input: {
          isUse: !status,
        },
      },
    });
  };




  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography
              className={language === "kh" ? "page-title-kh" : "page-title"}
            >
              {t(`page-setting`)}
            </Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-customer`)}
          </Typography>
        </Stack>
      </Stack>


      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              placeholder="ស្វែងរក"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`thead_status`)}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>In-active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={6} xl={7.2}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography> 
              </Button>
            </Stack>
            <CustomerForm
              dialogTitle="Create"
              open={openCreate}
              setRefetch={refetch}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>

                <TableCell className={language === "en" ? "header-title-start" : "header-title-start-kh"} width="5%"    >
                  {t("thead_no")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="15%">
                  {t("cus_name")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="12%">
                  {t("company")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="12%">
                  {t("thead_tel")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="12%">
                  {t("email")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="12%">
                  {t("address")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="17%">
                  {t("remark")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="10%" align="center" >
                  {t("thead_status")}
                </TableCell>

                <TableCell className={language === "en" ? "header-title-end" : "header-title-end-kh"} width="5%"></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={12} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={12} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        // console.log("row::", row);
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell className={language === "en" ? "body-cell-start" : "body-cell-start-kh"}>
                              {index + paginationData?.slNo}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.name}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.company}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.phone}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.email}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.address}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                              {row?.remark}
                            </TableCell>

                            <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"} align="center"  >
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={row?.isUse}
                                    onChange={() => handleUpdateStatus(row?._id, row?.isUse)}
                                  />
                                }
                              />
                            </TableCell>


                            <TableCell className={language === "en" ? "body-cell-end" : "body-cell-end-kh"} align="right"  >
                              <CustomerAction
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </div>
  );
};
