import React, { useContext, useState, useEffect } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// icon
//Srcs
import "../../Style/pageStyle.scss";

import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

import bank from "../../Assets/setting-icons/bank.png";
import user from "../../Assets/setting-icons/login.png";
import cash from "../../Assets/setting-icons/money.png";
import table from "../../Assets/setting-icons/table.png";
import Supply from "../../Assets/setting-icons/supply.png";
import unit from "../../Assets/setting-icons/3d-printer.png";
import boxcard from "../../Assets/setting-icons/boxcard.png";
import Tickets from "../../Assets/setting-icons/tickets.png";
import Voucher from "../../Assets/setting-icons/gift-card.png";
import Customer from "../../Assets/setting-icons/customer.png";
import Warehouse from "../../Assets/setting-icons/warehouse.png";
import Category from "../../Assets/setting-icons/categories.png";
import productivity from "../../Assets/setting-icons/productivity.png";

export default function Setting() {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const location = useLocation();

  const SettingData = [
    {
      RouteTo: "/setting/user",
      Title: t("user"),
      Description: t("des_user"),
      Icon: <img alt="user" className="image" src={user} />,
    },
    {
      Title: t("page-customer"),
      Description: t("des_customer"),
      RouteTo: "/setting/customer",
      Icon: <img alt="user" className="image" src={Customer} />,
    },
    {
      Title: t("page-supplier"),
      Description: t("des_supplier"),
      RouteTo: "/setting/supplier",
      Icon: <img alt="user" className="image" src={Supply} />,
    },
    {
      Title: t("page-warehouse"),
      Description: t("des_warehouse"),
      RouteTo: "/setting/warehouse",
      Icon: <img alt="user" className="image" src={Warehouse} />,
    },
    {
      RouteTo: "/setting/cash-label",
      Title: t("cash_lable"),
      Description: t("des_cash"),
      Icon: <img alt="cash" className="image" src={cash} />,
    },
    {
      RouteTo: "/setting/unit",
      Title: t("unit"),
      Description: t("des_unit"),
      Icon: <img alt="unit" className="image" src={unit} />,
    },
    {
      RouteTo: "/setting/bank",
      Title: t("bank"),
      Description: t("des_bank"),
      Icon: <img alt="bank" className="image" src={bank} />,
    },
    {
      RouteTo: "/setting/table",
      Title: t("table"),
      Description: t("des_table"),
      Icon: <img alt="Loading.." className="image" src={table} />,
    },
    {
      RouteTo: "/setting/category",
      Title: t("category"),
      Description: t("des_category"),
      Icon: <img alt="category" className="image" src={Category} />,
    },
    {
      RouteTo: "/setting/product",
      Title: t("product"),
      Description: t("des_product"),
      Icon: <img alt="category" className="image" src={productivity} />,
    },
    {
      RouteTo: "/setting/card-number",
      Title: t("page-card-number"),
      Description: t("des_card-number"),
      Icon: <img alt="cardnumber" className="image" src={boxcard} />,
    },
    {
      RouteTo: "/setting/voucher",
      Title: t("page-voucher"),
      Description: t("des_voucher"),
      Icon: <img alt="cardnumber" className="image" src={Voucher} />,
    },
    {
      RouteTo: "/setting/ticket-group",
      Title: t("page-ticket"),
      Description: t("des_ticket"),
      Icon: <img alt="cardnumber" className="image" src={Tickets} />,
    },
  ];



  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography className={language === "kh" ? "page-title-kh" : "page-title"} >
            {t(`page-setting`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ mt: "30px" }}>
        <Grid container spacing={4}>
          {SettingData?.map((e, key) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={key}>
              <Link to={e?.RouteTo} style={{ textDecoration: "none" }}>
                <Stack direction="row" spacing={1.8} className="setting-page-container"   >
                  <Stack direction="column" justifyContent="center" className="page-image"   >
                    {e.Icon}
                  </Stack>
                  <Stack direction="column" justifyContent="center">
                    <Stack direction="column">
                      <Typography className={language === "kh" ? "page-title-kh" : "page-title"}  >
                        {e?.Title}
                      </Typography>
                      <Typography className={language === "kh" ? "page-description-kh" : "page-description"} >
                        {e?.Description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
