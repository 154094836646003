import { gql } from "@apollo/client";

export const CREATE_CASH_LABEL = gql`
  mutation CreateCashLabel($input: CashLabelInput) {
    createCashLabel(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CASH_LABEL_WITH_PAGINATION = gql`
  query GetCashLabelWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $currencyType: String
    $pagination: Boolean
  ) {
    getCashLabelWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      currencyType: $currencyType
      pagination: $pagination
    ) {
      data {
        _id
        cashLabel
        currencyType
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_CASH_LABEL = gql`
  mutation UpdateCashLabel($id: ID!, $input: CashLabelInput) {
    updateCashLabel(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_CASH_LABEL = gql`
  mutation DeleteCashLabel($id: ID!) {
    deleteCashLabel(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CASH_LABEL_BY_CURRENCY = gql`
  query GetCashLabelByCurrency($currencyType: CurrencyTypeEnum) {
    getCashLabelByCurrency(currencyType: $currencyType) {
      _id
      cashLabel
      currencyType
      isUse
      remark
    }
  }
`;
export const OPEN_SHIFT = gql`
  mutation OpenShift($input: OpenShiftInput) {
    openShift(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      data
    }
  }
`;
