import React, { useContext } from "react";
import {
  Grid,
  Card,
  Stack,
  TextField,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import "./productinvoice.scss";
import { BiSolidEdit } from "react-icons/bi";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import EmptyImage from "../../Assets/emptyImage.png";
import { AuthContext } from "../../Context/AuthContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AccordionSummary from "@mui/material/AccordionSummary";
import { currencyFormat } from "../../Include/Function/DynamicFn";
import { translateLauguage } from "../../Include/Function/Translate";

export default function ItemProductSelected({
  item,
  selectedItem,
  setSelectedItem,
  handleOpenAdjust,
  setAdjustProductData,
  handleDeleteItemInCard,
  handleUpdateInputQtyItem,
  handleMinusQtyItemFromCard,
  handleIncreaseQtyItemInCard,
}) {
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const LongTextComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        className="item-name"
      >
        {text}
      </div>
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Grid container>
        <Grid item sm={8} md={7} lg={6.5} xl={7.5}>
          <Stack direction="row" spacing={2} alignItems="center">
            <AccordionSummary
              id="panel1-header"
              aria-controls="panel1-content"
              sx={{
                padding: "0px !important",
                margin: "0px 0px 0px 10px !important",
                height: 20,
              }}
            >
              <Card sx={{ width: 30, height: 30, boxShadow: "none" }}>
                <CardMedia
                  sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                  component="img"
                  alt="loading.."
                  image={item?.imageSrc ? item?.imageSrc : EmptyImage}
                />
              </Card>
            </AccordionSummary>

            <Stack direction="column" width="90%">
              <Stack direction="row" alignItems="center" width={"85%"}>
                <LongTextComponent text={item?.productName} />
                <IconButton
                  onClick={() => {
                    setAdjustProductData(item);
                    handleOpenAdjust();
                  }}
                >
                  <BiSolidEdit style={{ color: "orange", fontSize: 18 }} />
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                marginTop={-1}
              >
                <Typography sx={{ fontSize: "13px", color: "gray" }}>
                  {t("thead_price")} :
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {currencyFormat(item?.price)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item sm={4} md={5} lg={5.5} xl={4.5}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item sm={2} md={1} lg={1} xl={2}>
              <Stack
                width="100%"
                height="100%"
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  className="btn-minus"
                  onClick={() => handleMinusQtyItemFromCard(item?.productId)}
                >
                  <RemoveIcon sx={{ fontSize: "17px", color: "#fff" }} />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item sm={2} md={2} lg={2} xl={2}>
              <Stack
                width="100%"
                height="100%"
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {!(selectedItem === item?.productId) ? (
                  <Typography
                    className="item-qty"
                    // onClick={() => setSelectedItem(item?.productId)}
                  >
                    {item?.qty}
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    autoFocus
                    size="small"
                    type="number"
                    value={item?.qty}
                    sx={{ width: "60px" }}
                    onFocus={(e) => e.target.select()}
                    onMouseLeave={() => setSelectedItem("")}
                    inputProps={{
                      style: { textAlign: "center", padding: "1px" },
                    }}
                    onChange={(e) =>
                      handleUpdateInputQtyItem(item?.productId, e.target.value)
                    }
                  />
                )}
              </Stack>
            </Grid>

            <Grid item sm={2} md={1} lg={1} xl={2}>
              <Stack
                width="100%"
                height="100%"
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  className="btn-plus"
                  onClick={() => handleIncreaseQtyItemInCard(item?.productId)}
                >
                  <AddOutlinedIcon sx={{ fontSize: "17px", color: "#fff" }} />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item sm={3} md={5} lg={5} xl={3}>
              <Stack
                direction="column"
                justifyContent="center"
                height="100%"
                width="100%"
              >
                <Stack
                  direction="row"
                  justifyContent="right"
                  spacing={1}
                  width="100%"
                >
                  <Typography className="item-price">
                    {currencyFormat(item?.price * item?.qty)}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={3}>
              <Stack
                width="100%"
                height="100%"
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  className="btn-icon"
                  onClick={() => handleDeleteItemInCard(item?.productId)}
                >
                  <CloseIcon className="delete-icon" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
