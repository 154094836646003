import { gql } from "@apollo/client";

export const GET_CATEGORY_FOR_SELECT = gql`
  query SelectCategory($selfId: ID) {
    selectCategory(selfId: $selfId) {
      _id
      categoryNameKh
      categoryNameEn
      isUse
      remark
      parentCategoryId {
        _id
        categoryNameEn
        categoryNameKh
      }
      subCategories {
        _id
        categoryNameEn
        categoryNameKh
        subCategories {
          _id
          categoryNameEn
          categoryNameKh
          subCategories {
            _id
            categoryNameEn
            categoryNameKh
            subCategories {
              _id
              categoryNameEn
              categoryNameKh
              subCategories {
                _id
                categoryNameEn
                categoryNameKh
                subCategories {
                  _id
                  categoryNameEn
                  categoryNameKh
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_FOR_SELECT_VALUE = gql`
  query SelectCategory($selfId: ID) {
    selectCategory(selfId: $selfId) {
      _id
      categoryNameKh
      categoryNameEn
      isUse
      remark
    }
  }
`;

export const GET_SELECT_UNIT = gql`
  query SelectUnit($isUse: Boolean) {
    selectUnit(isUse: $isUse) {
      _id
      unitKhName
      unitEnName
      isUse
      remark
    }
  }
`;

export const GET_CATEGORY_BY_SHOP = gql`
  query GetCategoryByShop($isUse: Boolean) {
    getCategoryByShop(isUse: $isUse) {
      categoryNameEn
      categoryNameKh
      _id
    }
  }
`;

export const GET_SELECT_CATEGORY_FOR_MAIN_PRODUCT = gql`
  query SelectCategoryForMainProduct {
    selectCategoryForMainProduct {
      _id
      categoryNameEn
      categoryNameKh
      subCategories {
        _id
        categoryNameEn
        categoryNameKh
        subCategories {
          _id
          categoryNameEn
          categoryNameKh
          subCategories {
            _id
            categoryNameEn
            categoryNameKh
            subCategories {
              _id
              categoryNameEn
              categoryNameKh
              subCategories {
                _id
                categoryNameEn
                categoryNameKh
                subCategories {
                  _id
                  categoryNameEn
                  categoryNameKh
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_SELECT_MAIN_PRODUCT = gql`
  query SelectMainProduct($isUse: Boolean) {
    selectMainProduct(isUse: $isUse) {
      mainProductEnName
      mainProductKhName
      _id
    }
  }
`;
export const SELECT_SHOP = gql`
  query SelectShop($isUse: Boolean, $selfId: ID) {
    selectShop(isUse: $isUse, selfId: $selfId) {
      shopNameKh
      shopNameEn
      _id
    }
  }
`;

export const SELECT_CUSTOMER = gql`
  query SelectCustomer {
    selectCustomer {
      _id
      name
    }
  }
`;

export const SELECT_CHECK_IN_MEMBER = gql`
query SelectCheckInMember {
  selectCheckInMember {
    _id
    cardNo
    imageSrc
    remark
    isUse
    checkInMemberId {
      customerId {
        name
        _id
      }
    }
  }
} 
`;

export const SELECT_USER_FOR_CASHIER = gql`
  query SelectUserForCashier($shopId: ID) {
    selectUserForCashier(shopId: $shopId) {
      _id
      khmerName
      latinName
      userProfile
    }
  }
`;

export const SELECT_BANK = gql`
  query SelectBank($isUse: Boolean) {
    selectBank(isUse: $isUse) {
      _id
      bankName
      remark
      isUse
    }
  }
`;

export const SELECT_CARD_FOR_MEMBER = gql`
  query SelectCardForMemeber {
    selectCardForMemeber {
      cardNo
      _id
      remark
    }
  }
`;

export const SELECT_WAREHOUSE = gql`
  query SelectWarehouse($isUse: Boolean) {
    selectWarehouse(isUse: $isUse) {
      warehouseKhName
      warehouseEnName
      _id
    }
  }
`;

export const SELECT_SUPPLIER = gql`
  query SelectSupplier($isUse: Boolean) {
    selectSupplier(isUse: $isUse) {
      name
      _id
    }
  }
`;

export const SELECT_MAIN_PRODUCT = gql`
  query SelectMainProduct($isUse: Boolean) {
    selectMainProduct(isUse: $isUse) {
      mainProductKhName
      mainProductEnName
      _id
      minimumUnitId {
        _id
        unitEnName
        unitKhName
      }
    }
  }
`;

export const SELECT_MAIN_PRODUCT_FOR_INGREDIENT = gql`
  query SelectMainProductForIngredient($ownId: ID, $isUse: Boolean) {
    selectMainProductForIngredient(ownId: $ownId, isUse: $isUse) {
      _id
      mainProductEnName
      mainProductKhName
      minimumUnitId {
        unitEnName
        unitKhName
        _id
      }
    }
  }
`;

export const SELECT_MAIN_PRODUCT_BY_SHOP = gql`
  query SelectMainProductByShop($shopId: ID, $isUse: Boolean) {
    selectMainProductByShop(shopId: $shopId, isUse: $isUse) {
      mainProductEnName
      mainProductKhName
      _id
      minimumUnitId {
        unitEnName
        unitKhName
        _id
      }
    }
  }
`;

export const SELECT_REFUND_INVOICES = gql`
  query SelectRefundInvoices {
    selectRefundInvoices {
      _id
      invoiceNo
      shopId {
        _id
        shopNameEn
        shopNameKh
        billingHead
        vattinNo
      }
    }
  }
`;

export const GET_REFUND_ITEMS_BY_IN_VOICE = gql`
  query GetRefundItemsByInvoice($invoiceId: ID) {
    getRefundItemsByInvoice(invoiceId: $invoiceId) {
      _id
      productId {
      _id
        qtyInMinimumUnit
        mainProductId {
          mainProductEnName
          mainProductKhName
          _id
          minimumUnitId {
            unitEnName
            unitKhName
            _id
          }
        }
        unitId {
          unitEnName
          unitKhName
          _id
        }
      }
      price
      qty
      discount {
        type
        amount
        remark
      }
      isRefundable
      remainRefundQty
      previousRefundQty
      total
    }
  }
`;

export const SELECT_PRODUCT_BY_MAIN_PRODUCT = gql`
  query SelectProductByMainProduct($mainProductId: ID) {
    selectProductByMainProduct(mainProductId: $mainProductId) {
      _id
      mainProductId {
        _id
        mainProductKhName
        mainProductEnName
      }
      unitId {
        _id
        unitKhName
        unitEnName
      }
      qtyInMinimumUnit
    }
  }
`;

export const CREATE_REFUND = gql`
  mutation CreateRefund($input: RefundInput) {
    createRefund(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const SELECT_SHIFT = gql`
query SelectShift($date: Date) {
  selectShift(date: $date) {
    createdAt
    closeBy {
      createdAt
    }
    _id
  }
} 
`;