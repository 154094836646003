import React, { useState, useContext } from "react";
import "../../../Style/actionStyle.scss";
import ReplyIcon from "@mui/icons-material/Reply";
import { AuthContext } from "../../../Context/AuthContext";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import { translateLauguage } from "../../../Include/Function/Translate";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { TransferProduct } from "./TransferProduct/TransferProduct";
import { ViewRequest } from "./ViewRequest/ViewRequest";
import { ApprovedAndRejectedRequest } from "./ApprovedAndRejectedRequest/ApprovedAndRejectedRequest";
import { RejectedRequest } from "./RejectedRequest/RejectedRequest";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
export default function RequestAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  const [openPending, setOpenPending] = useState(false);
  const handleOpenPending = () => setOpenPending(true);
  const handleClosePending = () => setOpenPending(false);

  const [openRejected, setOpenRejected] = useState(false);
  const handleOpenRejected = () => setOpenRejected(true);
  const handleCloseRejected = () => setOpenRejected(false);
 
  
  return (
    <div>
      <Stack direction="row" justifyContent="right">  
        <Tooltip title={t("thead_view")} placement="top">
          <IconButton onClick={handleOpenView} disabled={disabled}>
            <Box
              className="view-container"
              sx={disabled && { bgcolor: "grey !important" }}
            >
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        {editData?.transitStatus === "Pending" && (
          <Tooltip title={t("thead_pending")} placement="top">
            <IconButton onClick={handleOpenPending} disabled={disabled}>
              <Box
                className="pending-container"
                sx={disabled && { bgcolor: "grey !important" }}
              > 
                <PriorityHighIcon className="pending-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {["Approved", "InTransit"].includes(editData?.transitStatus) && (
          <Tooltip title={t("thead_transfer")} placement="top">
            <IconButton onClick={handleOpenEdit} disabled={disabled}>
              <Box
                className="update-container"
                sx={disabled && { bgcolor: "grey !important" }}
              >
                <ReplyIcon className="update-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {editData?.transitStatus === "Rejected" && (
          <Tooltip title={t("thead_rejected")} placement="top">
            <IconButton onClick={handleOpenRejected} disabled={disabled}>
              <Box
                className="rejected-container"
                sx={disabled && { bgcolor: "grey !important" }}
              >
                <DoneIcon className="rejected-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {editData?.transitStatus === "Canceled" && (
          <Tooltip title={t("thead_canceled")} placement="top">
            <IconButton onClick={handleOpenEdit} disabled={disabled}>
              <Box
                className="rejected-container"
                sx={disabled && { bgcolor: "grey !important" }}
              > 
                <CloseIcon className="rejected-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {editData?.transitStatus === "Completed" && (
          <Tooltip title={t("thead_completed")} placement="top"> 
            <IconButton onClick={handleOpenEdit} disabled={disabled}>
              <Box
                className="completed-container"
                sx={disabled && { bgcolor: "grey !important" }}
              >
                <DoneIcon className="completed-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <ViewRequest
        open={openView}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseView}
      />

      <RejectedRequest
        open={openRejected}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseRejected}
      />

      <TransferProduct
        open={openEdit}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />

      <ApprovedAndRejectedRequest
        open={openPending}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleClosePending}
      />
    </div>
  );
}
