import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  Button,
  Select,
  TableRow,
  MenuItem,
  TableCell,
  TextField,
  TableHead,
  TableBody,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import PurchaseAction from "./PurchaseAction";
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
import { PurchaseProduct } from "./PurchaseProduct";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { GET_PURCHASE_WITH_PAGINATION } from "../../../Schema/InventoryInWarehouse";

export const Purchase = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState();
  const [isVoid, setIsVoid] = useState("All");
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_PURCHASE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      status: status,
      priority: "All",
      purchaseNo: parseFloat(keyword),
      pagination: true,
      isVoid: isVoid === "All" ? null : isVoid === "Yes" ? true : false,
    },
    onCompleted: ({ getPurchasePagination }) => {
      setTableData(getPurchasePagination?.data);
      setPaginationData(getPurchasePagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const translateStatus = (status) => {
    if (status === "Pending") return t("thead_pending");
    if (status === "Canceled") return t("thead_canceled");
    if (status === "Approved") return t("thead_approved");
    if (status === "Rejected") return t("thead_rejected");
    if (status === "Purchase") return t("thead_purchase");
    if (status === "Completed") return t("thead_completed");
    if (status === "InTransit") return t("thead_not_completed");
    if (status === "PartiallyReceived") return t("thead_partially_received");
    else return status;
  };

  const statusIcon = (status) => {
    if (status === "Purchase")
      return <ShoppingCartOutlinedIcon className="icon" />;
    if (status === "Completed")
      return <CheckCircleOutlineIcon className="icon" />;
    if (status === "PartiallyReceived")
      return <DownloadingOutlinedIcon className="icon" />;
    else return status;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`label-search`)}
          </Typography>
          <TextField
            placeholder={t(`label-search`)}
            size="small"
            type="number"
            className="search-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1, color: "black" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`thead_status`)}
          </Typography>
          <FormControl fullWidth size="small" className="search-field">
            <Select
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {/* <MenuItem value={"Purchase"}>Purchase</MenuItem> */}
              {/* <MenuItem value={"Pending"}>Pending</MenuItem> */}
              {/* <MenuItem value={"Canceled"}>Canceled</MenuItem> */}
              {/* <MenuItem value={"Approved"}>Approved</MenuItem> */}
              {/* <MenuItem value={"Rejected"}>Rejected</MenuItem> */}
              <MenuItem value={"PartiallyReceived"}>
                {t("thead_partially_received")}
              </MenuItem>
              <MenuItem value={"Completed"}>{t("thead_completed")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            Is Void
          </Typography>
          <FormControl size="small" fullWidth className="search-field">
            <Select value={isVoid} onChange={(e) => setIsVoid(e.target.value)}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={12} lg={3} xl={6}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              className="btn-create-style"
              startIcon={
                <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
              }
            >
              <Typography
                className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
              >
                {t("btn-purchase")}
              </Typography>
            </Button>
            <PurchaseProduct
              open={open}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="10%"
                >
                  {t("thead_no")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {t("thead_date")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {t("thead_requester")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {t("thead_supplier")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="10%"
                  align="center"
                >
                  {t("thead_transaction")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="22%"
                >
                  {t("remark")}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                            >
                              {row?.purchaseNo}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "MM-DD-YYYY hh:mm a"
                              )}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.requestBy?.userId?.latinName
                                : row?.requestBy?.userId?.khmerName}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.supplierId?.name}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box className={`status-${row?.status}`}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.7}
                                    justifyContent="center"
                                  >
                                    {statusIcon(row?.status)}
                                    <Typography className="status">
                                      {translateStatus(row?.status)}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Stack>
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.remark}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <PurchaseAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.status === "Completed"}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </>
  );
};
