import { gql } from "@apollo/client";

export const CREATE_SHOP = gql`
  mutation CreateShop($input: ShopInput) {
    createShop(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation UpdateShop($id: ID, $input: ShopInput) {
    updateShop(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CANCEL_CLOSE_SHIFT = gql`
  mutation CancelCloseShift($shopId: ID) {
    cancelCloseShift(shopId: $shopId) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CREATE_CASHIER = gql`
  mutation CreateCashier($input: CashierInput) {
    createCashier(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const KICK_ALL_ACTIVE_CASHIRE = gql`
  mutation KickAllActiveCashier($shopId: ID!) {
    kickAllActiveCashier(shopId: $shopId) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const DELETE_CASHIER = gql`
  mutation DeleteCashier($id: ID!) {
    deleteCashier(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CASHIER_BY_SHOT = gql`
  query GetCashierByShop($shopId: ID) {
    getCashierByShop(shopId: $shopId) {
      _id
      userId {
        _id
        latinName
        khmerName
        userProfile
      }
      status
    }
  }
`;
export const GET_CASHIER_BY_USER = gql`
  query GetCashierByUser($userId: ID) {
    getCashierByUser(userId: $userId) {
      shopId {
        shopNameEn
        shopNameKh
      }
      status
    }
  }
`;

export const GET_OUT_OF_SHOP = gql`
  mutation GetOutOfShop {
    getOutOfShop {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_ACTIVE_CASHIER = gql`
  query GetActiveCashier($shopId: ID) {
    getActiveCashier(shopId: $shopId) {
      _id
      userId {
        _id
        userProfile
        latinName
        khmerName
      }
      status
    }
  }
`;

export const GET_SHOP_WITH_PAGINATION = gql`
  query Data($page: Int, $limit: Int, $pagination: Boolean, $keyword: String) {
    getShopPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
    ) {
      data {
        _id
        shopType
        shopNameKh
        shopNameEn
        billingHead
        logo
        longtitude
        latitude
        address
        returnPolicyKh
        returnPolicyEn
        email
        phone
        vattinNo
        isShowVattin
        isUse
        remark
        wifiName
        wifiPassword
        isShowWifi
        isShowQueueNo
        isInClose
        inCloseBy {
          _id
          latinName
          khmerName
          userProfile
        }
        copyProductShopId {
          _id
          shopNameEn
          shopNameKh
        }
        socailContact {
          name
          link
          platform
          isShow
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const DELETE_SHOP = gql`
  mutation DeleteShop($id: ID) {
    deleteShop(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_SHIFT_STATUS_BY_SHOP = gql`
  query GetShiftStatusByShop($shopId: ID!) {
    getShiftStatusByShop(shopId: $shopId) {
      shift {
        _id
        openBy {
          _id
          khmerName
          latinName
        }
      }
      previousShift {
        _id
      }
      status
    }
  }
`;

export const GO_IN_TO_SHOP = gql`
  mutation GoIntoShop($shopId: ID) {
    goIntoShop(shopId: $shopId) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GO_IN_CLOSE_SHIFT = gql`
  mutation GotoCloseShift($shopId: ID) {
    gotoCloseShift(shopId: $shopId) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CASH_IN_SHIFT = gql`
  mutation CashInShift($shiftId: ID, $cashIn: CashInOutInput) {
    cashInShift(shiftId: $shiftId, cashIn: $cashIn) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CASH_OUT_SHIFT = gql`
  mutation CashOutShift($shiftId: ID, $cashOut: CashInOutInput) {
    cashOutShift(shiftId: $shiftId, cashOut: $cashOut) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CLOSE_SHIFT = gql`
  mutation CloseShift($id: ID!, $input: CloseShiftInput) {
    closeShift(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_SHIFT_REPORT = gql`
query GetShiftReport(
  $viewType: ViewTypeEnum
  $shiftId: ID
  $startDate: Date
  $endDate: Date
) {
  getShiftReport(
    viewType: $viewType
    shiftId: $shiftId
    startDate: $startDate
    endDate: $endDate
  ) {
    totalCashFloat {
      khr
      usd
    }
    cashFloatIn {
      khr
      usd
    }
    cashFloatOut {
      khr
      usd
    }
    cashChange {
      amount
      khr
      usd
    }

    totalInvoices

    cashInBank {
      amount
      khr
      usd
    }
    cashInVoucher {
      amount
      khr
      usd
    }
    externalPayments {
      bankName
      amount
      khr
      usd
    }

    crARCash {
      amount
      khr
      usd
    }
    crARBank {
      amount
      khr
      usd
    }

    openDateTime
    closedDateTime
    openBy
    closedBy
    cashInCash {
      amount
      khr
      usd
    }
    complimentary {
      amount
      khr
      usd
    }
    refundCash {
      amount
      khr
      usd
    }
    refundBank {
      amount
      khr
      usd
    }
    guestDepositAPCash {
      amount
      khr
      usd
    }
    guestDepositAPBank {
      amount
      khr
      usd
    }
    guestDepositExternalPayments {
      bankName
      amount
      khr
      usd
    }
    drAr
    invoicesAmount
    cashChangeInvoices {
      invoiceNo
      createdAt
      amount
      khr
      usd
      isDeposit
    }
  }
}

`;
