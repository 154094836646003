import moment from "moment";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { GET_REFUND_PAGINATION } from "../../Schema/Report";
import { translateLauguage } from "../../Include/Function/Translate";

const useGetProductSellReport = ({
    selectedShift,
    startDate,
    viewType,
    endDate,
}) => {
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);


    const [tableData, setTableData] = useState([]);
    const { refetch, loading } = useQuery(GET_REFUND_PAGINATION, {
        variables: {
            viewType: viewType,
            shiftId: selectedShift?.id || null,
            endDate: viewType === "Shift" ? "" : moment(endDate).format("YYYY-MM-DD"),
            startDate: viewType === "Shift" ? "" : moment(startDate).format("YYYY-MM-DD"),
        },
        skip: true, // Prevent automatic query execution
        onCompleted: ({ getProductSellReport }) => {
            setTableData(getProductSellReport || []);
        },
        onError: ({ message }) => {
            console.error("GetProductSellReport Error fetching data:", message);
        },
    });

    // Refetch data whenever `startDate`, `endDate`, or `viewType` changes
    useEffect(() => {
        refetch();
    }, [selectedShift, viewType, startDate, endDate,]);

    return {
        t,
        loading,
        tableData,
    };
};

export default useGetProductSellReport;
