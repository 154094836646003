import { gql } from "@apollo/client";

export const CREATE_WAREHOUSE = gql`
  mutation CreateWarehouse($input: WarehouseInput) {
    createWarehouse(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_WAREHOUSE = gql`
  mutation UpdateWarehouse($id: ID, $input: WarehouseInput) {
    updateWarehouse(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_WAREHOUSE = gql`
  mutation DeleteWarehouse($id: ID) {
    deleteWarehouse(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_WAREHOUSE_WITH_PAGINATION = gql`
  query GetWarehousePagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
    $isUse: Boolean
  ) {
    getWarehousePagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
      isUse: $isUse
    ) {
      data {
        _id
        warehouseEnName
        warehouseKhName
        location
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
