import React, { useContext } from "react";
import "../../../../Style/actionStyle.scss";
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from "../../../../Context/AuthContext.js";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { translateLauguage } from "../../../../Include/Function/Translate.js";
import { Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { SelectWarehouse } from "../../../../Include/Function/DynamicSelect.js";
import SelectDatePicker from "../../../../Include/SelectDatePicker/SelectDatePicker.jsx";

export default function SelectTransferProducts({ transferProductsData, setTransferProductsData, required, }) {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newSubItem = { key: Math.random() + Date.now(), warehouseId: null, qty: null, expireDate: null }

  const handleAddSubItem = (mainKey) => {
    const transferData = transferProductsData?.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = [...e?.warehouseIds, newSubItem]
      return e
    })
    setTransferProductsData([...transferData]);
  }

  const handleRemoveSubItem = (mainKey, subItem) => {
    const transferData = transferProductsData?.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = e?.warehouseIds.length > 1 ? e?.warehouseIds.filter(e => e.key !== subItem) : e?.warehouseIds
      return e
    })
    setTransferProductsData([...transferData]);
  }


  const handleUpdateSubItem = (mainKey, subKey, value, fieldName) => {

    const newData = transferProductsData?.map((e) => {
      if (e?.key === mainKey) {
        e?.warehouseIds?.map((sub) => {

          if (sub?.key === subKey && fieldName === "qty") {
            const total = e?.warehouseIds.filter((e) => e?.key !== subKey)?.reduce((a, b) => a + (b?.qty || 0), 0) + value - parseFloat(e?.remain || 0);
            const remainTotal = total > 0 ? value - total : value
            sub.qty = remainTotal
          }

          if (sub?.key === subKey && fieldName === "expireDate") sub.expireDate = value;
          if (sub?.key === subKey && fieldName === "warehouseId") sub.warehouseId = value;
          if (!sub.warehouseId?.id) sub.qty = null;
          return sub;
        });
      }
      return e;
    });
    setTransferProductsData([...newData]);
  }

  return (
    <Stack direction="column" width="100%" spacing={1}>
      <Stack direction="column" width="100%" spacing={2} minHeight={300}>
        {
          transferProductsData?.map((e) => (
            <Stack direction="column" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>

                <Grid container>
                  <Grid item xs={6} sm={5} md={4} lg={3} xl={3.5}>
                    <Stack direction="column" sx={{ width: "100%" }} spacing={1} mt={"81.5px !important"}>

                      <Grid container rowSpacing={1}>
                        <Grid item sm={6} md={5} lg={4} xl={4}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                            {t('thead_product')}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} md={7} lg={8} xl={8}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                            : {e?.mainProductId?.title}
                          </Typography>
                        </Grid>

                        <Grid item sm={6} md={5} lg={4} xl={4}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                            {t('thead_received')}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} md={7} lg={8} xl={8}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                            :  {e?.qty - e?.remain} {e?.mainProductId?.unit}
                          </Typography>
                        </Grid>
  
                        <Grid item sm={6} md={5} lg={4} xl={4}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                            {t('thead_remaining')}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} md={7} lg={8} xl={8}>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                            : {e?.remain}  {e?.mainProductId?.unit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
 
                  <Grid item xs={6} sm={7} md={8} lg={9} xl={8.5}>
                    <Stack direction="column" spacing={1} sx={{ width: "100%" }} >
                      <Stack direction="column" sx={{ width: "100%" }} spacing={1} >
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }} justifyContent='space-between' >
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="100%">
                            {t("dialog_title_transfer_product")}
                          </Typography>
                          <IconButton onClick={() => handleAddSubItem(e?.key)}>
                            <AddIcon sx={{ color: "green" }} />
                          </IconButton>
                        </Stack>
                        <Divider sx={{ borderBottomWidth: "2px", borderColor: "#00A77C" }} />
                        <Stack direction="row" alignItems="center" width="90%" >
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="40%">
                            {t("thead_warehouse")}
                          </Typography>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="30%">
                            {t("thead_transfer")}
                          </Typography>
                          <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="30%">
                            {t("thead_expire_date")}
                          </Typography>
                        </Stack>
                      </Stack>


                      {
                        e?.warehouseIds?.map((sub) => (
                          <Stack direction="row" spacing={1.5} sx={{ width: "95%" }}>

                            <Stack sx={{ width: "40%" }}>
                              <SelectWarehouse
                                required={required}
                                // dataList={e?.warehouseIds}
                                selectedVal={sub?.warehouseId}
                                setSelectedVal={(value) => handleUpdateSubItem(e.key, sub.key, value, "warehouseId")}
                              />
                            </Stack>
                            <Stack sx={{ width: "30%" }}>
                              <TextField
                                fullWidth
                                size="small"
                                type="number"
                                className="text-field"
                                value={parseFloat(sub?.qty||0)}
                                placeholder={t('thead_qty')}
                                error={!sub?.qty && required}
                                InputProps={{ endAdornment: e?.mainProductId?.unit }}
                                onChange={({ target }) => handleUpdateSubItem(e?.key, sub?.key, parseFloat(target?.value ||0), "qty")}
                              />

                            </Stack>
                            <Stack sx={{ width: "30%" }}>
                              <SelectDatePicker
                                date={e?.expireDate}
                                setDate={(value) => handleUpdateSubItem(e.key, sub.key, value, "expireDate")}
                              />
                            </Stack>
                            <IconButton onClick={() => handleRemoveSubItem(e?.key, sub?.key)}>
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            </IconButton>
                          </Stack>
                        ))
                      }
                    </Stack>
                  </Grid>


                </Grid>



              </Stack>

              <Stack sx={{ width: "100%" }}   >
                <Divider sx={{ borderBottomWidth: "2px", borderColor: "#1d4592" }} />
              </Stack>


            </Stack>
          )
          )
        }
      </Stack>
    </Stack>


  );
}
