import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import SelectPlatforms from "./SelectPlatforms";
import SelectBankAccount from "./SelectBankAccount";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_SUPPLIER, UPDATE_SUPPLIER } from "../../../Schema/Supplier";
export const SupplierForm = ({ open, handleClose, dialogTitle, editData, setRefetch, hideBackdrop, }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [required, setRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  //======================== Create User =========================
  const [createSupplier] = useMutation(CREATE_SUPPLIER, {
    onCompleted: ({ createSupplier }) => {
      setLoading(false);
      if (createSupplier?.isSuccess === true) {
        setAlert(true, "success", createSupplier?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createSupplier?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //======================== Create User =========================
  const [updateSupplier] = useMutation(UPDATE_SUPPLIER, {
    onCompleted: ({ updateSupplier }) => {
      setLoading(false);
      if (updateSupplier?.isSuccess === true) {
        setAlert(true, "success", updateSupplier?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateSupplier?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    name: Yup.string().required(t("require")),
    phone: Yup.string().required(t("require")),
    email: Yup.string(),
    remark: Yup.string(),
    address: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      remark: "",
      address: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {

      const requiredBank = bankAccountData?.map((e) => e?.accountName && e?.accountNumber && e?.bankId?.id ? true : false)?.filter((e) => !e);

      if (requiredBank.length > 0) {
        setRequired(true);
        return;
      }


      if (dialogTitle === "Create") {
        const bankAccount = bankAccountData?.map((e) => ({
          bankId: e?.bankId?.id,
          accountName: e?.accountName,
          accountNumber: e?.accountNumber,
        }))
        const platforms = platformsData?.map((e) => ({
          url: e?.url,
          username: e?.username,
          platformName: e?.platformName?.title,
        }))
        setLoading(true);
        createSupplier({
          variables: {
            input: {
              ...value,
              isUse: true,
              platforms: platforms,
              bankAccount: bankAccount,
            },
          },
        });
      } else {

        setLoading(true);
        const bankAccount = bankAccountData?.map((e) => ({
          bankId: e?.bankId?.id,
          accountName: e?.accountName,
          accountNumber: e?.accountNumber,
        }));

        const platforms = platformsData?.map((e) => ({
          url: e?.url,
          username: e?.username,
          platformName: e?.platformName?.title,
        }));

        updateSupplier({
          variables: {
            id: editData?._id,
            input: {
              ...value,
              isUse: true,
              platforms: platforms,
              bankAccount: bankAccount,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);

  useEffect(() => {

    if (editData) {

      const bankAccount = editData?.bankAccount?.map((e) => ({
        key: Math.random() + Date.now(),
        accountName: e?.accountName,
        accountNumber: e?.accountNumber,
        bankId: { id: e?.bankId?._id, title: e?.bankId?.bankName, },
      }));

      const platforms = editData?.platforms?.map((e) => ({
        url: e?.url,
        username: e?.username,
        key: Math.random() + Date.now(),
        platformName: { id: Math.random() + Date.now(), title: e?.platformName, },
      }))


      setPlatformsData(platforms);
      setBankAccountData(bankAccount);
      setFieldValue("name", editData?.name || "");
      setFieldValue("phone", editData?.phone || "");
      setFieldValue("email", editData?.email || "");
      setFieldValue("remark", editData?.remark || "");
      setFieldValue("address", editData?.address || "");
    }
  }, [open, editData]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md" hideBackdrop={hideBackdrop}> 
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {dialogTitle === "Create" ? t("dialog_title_create_supplier") : t("dialog_title_update_supplier")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("cus_name")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_tel")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("email")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("address")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("address")}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBankAccount
                  required={required}
                  bankAccountData={bankAccountData}
                  setBankAccountData={setBankAccountData}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectPlatforms
                  platformsData={platformsData}
                  setPlatformsData={setPlatformsData}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={2}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
