import { gql } from "@apollo/client";

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($input: InvoiceInput) {
    createInvoice(input: $input) {
      message {
        messageKh
        messageEn
      }
      isSuccess
      invoiceId
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($input: InvoiceInput, $id: ID!) {
    updateInvoice(input: $input, _id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      invoiceId
    }
  }
`;

export const VOID_INVOICE = gql`
  mutation VoidInvoice($id: ID!, $shiftId: ID!, $remark: String!) {
    voidInvoice(_id: $id, shiftId: $shiftId, remark: $remark) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_SUSPEND_INVOICE = gql`
  query GetSuspendInvoice($shiftId: ID) {
    getSuspendInvoice(shiftId: $shiftId) {
      invoiceNo
      tableId {
        tableName
      }
      customerId {
        name
      }
      _id
      createdAt
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: [PaymentInput]) {
    createPayment(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CREATE_FIRST_PAYMENT = gql`
  mutation CreateFirstPayment($input: PaymentInput) {
    createFirstPayment(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const VOID_PAYMENT = gql`
  mutation VoidPayment($id: ID!, $remark: String, $shiftId: ID!) {
    voidPayment(_id: $id, remark: $remark, shiftId: $shiftId) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_VOUCHER_BY_CODE = gql`
  query GetVoucherByCode($voucherCode: String) {
    getVoucherByCode(voucherCode: $voucherCode) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      data {
        _id
        voucherCode
        voucherName
        validFrom
        expireDate
        discountType
        value
      }
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query GetInvoiceById($id: [ID]) {
    getInvoiceById(_id: $id) {
      _id
      remainAmount
      customerId {
        name
        _id
      }
      tableId {
        _id
        tableName
        capacity
        location
        status
        isUse
        remark
      }
      invoiceNo
      products {
        _id
        price
        qty
        discount {
          type
          amount
          remark
        }
        total
        productId {
          _id
          imageSrc
          mainProductId {
            mainProductKhName
            mainProductEnName
            _id
            minimumUnitId {
              unitEnName
              unitKhName
              _id
            }
          }
          unitId {
            unitEnName
            unitKhName
            _id
          }
        }
      }
      paymentStatus
      voidBy {
        createdAt
        userId {
          khmerName
          latinName
        }
      }
      subTotal
      allDiscount {
        type
        amount
        remark
      }
      total
      remark
      createdBy {
        latinName
        khmerName
      }
      changeAmount
      createdAt
      isVoid
      paidAmount
      queueNo
      shopId {
        shopNameEn 
        shopNameKh
        isShowVattin
        isShowQueueNo
        isShowWifi
        wifiName
        wifiPassword
        vattinNo
        billingHead
        logo
        shopType
        phone
        email
        returnPolicyEn
        returnPolicyKh
        address
        latitude
        longtitude
      }
    }
  }
`;

export const GET_INVOICE_WITH_PAGINATION = gql`
  query GetInvoicePagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $shopIds: [ID]
    $invoiceNo: Int
    $startDate: Date
    $endDate: Date
    $paymentStatus: PaymentStatusEnum
    $isVoid: Boolean
  ) {
    getInvoicePagination(
      page: $page
      limit: $limit
      pagination: $pagination
      shopIds: $shopIds
      invoiceNo: $invoiceNo
      startDate: $startDate
      endDate: $endDate
      paymentStatus: $paymentStatus
      isVoid: $isVoid
    ) {
      data {
        _id
        invoiceNo
        shiftId {
          shopId {
            shopType
            billingHead
            shopNameKh
            shopNameEn
          }
        }
        customerId {
          name
        }
        paymentStatus
        total
        remark
        createdBy {
          khmerName
          latinName
        }
        isVoid
        voidBy {
          userId {
            khmerName
            latinName
          }
          remark
          createdAt
        }
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
export const GET_PAYMENT_BY_INVOICES = gql`
  query GetPaymentByInvoices($invoiceIds: [ID]) {
    getPaymentByInvoices(invoiceIds: $invoiceIds) {
      _id
      invoiceId {
        _id
        customerId {
          name
          _id
        }
        tableId {
          tableName
        }
        invoiceNo
        paymentStatus
        subTotal
        allDiscount {
          type
          amount
          remark
        }
        total
        remark
        products {
          _id
          productId {
            _id
            imageSrc
            mainProductId {
              mainProductEnName
              mainProductKhName
              _id
            }
          }
          price
          qty
          discount {
            type
            amount
            remark
          }
          total
        }
        createdAt
      }
      exchangeRate
      paymentMethod
      change {
        amount
        khr
        usd
      }
      createdAt
      remark
      bankId {
        bankName
      }
      paid {
        amount
        khr
        usd
      }
      createdBy {
        khmerName
        _id
      }
      isVoid
      voidBy {
        remark
        createdAt
      }
    }
  }
`;

export const GET_UNFINISH_INVOICE = gql`
  query GetUnfinishInvoice(
    $shopIds: [ID]
    $customerId: ID
    $invoiceNo: Int
    $startDate: Date
    $endDate: Date
  ) {
    getUnfinishInvoice(
      shopIds: $shopIds
      customerId: $customerId
      invoiceNo: $invoiceNo
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      total
      subTotal
      remainAmount
      paidAmount
      createdAt
      customerId {
        name
      }
      invoiceNo
    }
  }
`;
