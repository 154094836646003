import React, { useState, useContext } from "react";
import Menu from "@mui/material/Menu";
import { IconButton, MenuItem, Stack, Typography } from "@mui/material";

import CreateIcon from "@mui/icons-material/Create";
import { BiDotsVertical } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";

import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

import { ShopForm } from "./ShopForm";
import { DeleteShop } from "./DeleteShop";
import PersonIcon from '@mui/icons-material/Person';
import { Cashier } from "./Cashier/Cashier";
export default function ShopAction({ setRefetch, editData }) {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openCashier, setOpenCashier] = useState(false);
  const handleOpenCashier = () => setOpenCashier(true);
  const handleCloseCashier = () => setOpenCashier(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  return (
    <div>
      <IconButton onClick={handleClick}>
        <BiDotsVertical className="edit-icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={handleOpenCashier}>
          <Stack direction="row" spacing={1} alignItems="center">
            <PersonIcon
              sx={{ height: "22px", width: "22px", color: "#5939B6" }}
            />
            <Typography
              className={
                language === "en" ? "edit-txt-detail" : "edit-txt-detail-kh"
              }
              sx={{ color: "#5939B6" }}
            >
              {/* {t("btn-update")} */}Cashier
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={handleOpenEdit}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CreateIcon
              sx={{ height: "22px", width: "22px", color: "#0f81c2" }}
            />
            <Typography
              className={
                language === "en" ? "edit-txt-detail" : "edit-txt-detail-kh"
              }
              sx={{ color: "#0f81c2" }}
            >
              {t("btn-update")}
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={handleOpenDel}>
          <Stack direction="row" spacing={1} alignItems="center">
            <DeleteIcon sx={{ height: "22px", width: "22px", color: "red" }} />
            <Typography
              className={
                language === "en" ? "del-txt-detail" : "del-txt-detail-kh"
              }
              sx={{ color: "red" }}
            >
              {t("btn-delete")}
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Cashier
        handleClose={handleCloseCashier}
        open={openCashier}
        setRefetch={setRefetch}
        editData={editData}
        dialogTitle="Update"
      />
      <ShopForm
        handleClose={handleCloseEdit}
        open={openEdit}
        setRefetch={setRefetch}
        editData={editData}
        dialogTitle="Update"
      />
      <DeleteShop
        handleClose={handleCloseDel}
        open={openDel}
        setRefetch={setRefetch}
        editData={editData?._id}
      />
    </div>
  );
}
