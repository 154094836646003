import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
  FormHelperText,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import moment from "moment";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../Context/AuthContext";
import SelectPurchaseProducts from "./SelectPurchaseProducts";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectSupplier } from "../../../Include/Function/DynamicSelect";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
import { CREATE_PURCHASE_WITH_OUT_CHECK } from "../../../Schema/InventoryInWarehouse";

export const PurchaseProduct = ({ open, handleClose, setRefetch, hideBackdrop, }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [purchaseProductsData, setPurchaseProductsData] = useState([]);
  const [selectedSupplierVal, setSelectedSupplierVal] = useState({ id: "", title: "" });

  const newItem = {
    qty: null,
    unitPrice: null,
    subProductId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }

  //======================== Create User =========================
  const [createPurchaseWithOutCheck] = useMutation(CREATE_PURCHASE_WITH_OUT_CHECK, {
    onCompleted: ({ createPurchaseWithOutCheck }) => {
      setLoading(false);
      if (createPurchaseWithOutCheck?.isSuccess === true) {
        setAlert(true, "success", createPurchaseWithOutCheck?.message);
        handleClose();
        setRefetch();
        resetForm();
        setPurchaseProductsData([]);
        setSelectedSupplierVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createPurchaseWithOutCheck?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    priority: Yup.string().required(t("require")),
    needDate: Yup.string().required(t("require")),
    supplierId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      supplierId: "",
      priority: "Medium",
      needDate: moment(),
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (purchaseProductsData?.length > 0) {
        const requiredProduct = purchaseProductsData?.map((e) => e?.subProductId?.id && e?.mainProductId?.id && e?.unitPrice && e?.qty ? true : false)?.filter((e) => !e);
        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }

        const purchaseProducts = purchaseProductsData?.map((e) => ({
          qty: parseFloat(e?.qty),
          unitPrice: parseFloat(e?.unitPrice),
          productId: e?.subProductId?.id,
        }));


        setLoading(true);
        createPurchaseWithOutCheck({
          variables: {
            input: {
              priority: value?.priority,
              supplierId: value?.supplierId,
              approveProducts: purchaseProducts,
              requestBy: { remark: value?.remark },
              needDate: moment(value?.needDate).format("YYYY-MM-DD"),
            }
          },
        });
      } else {
        setPurchaseProductsData([newItem]);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);


  useEffect(() => {
    setFieldValue("supplierId", selectedSupplierVal?.id);
  }, [selectedSupplierVal]);

  useEffect(() => {
    resetForm();
    setPurchaseProductsData([newItem]);
    setSelectedSupplierVal({ id: "", title: "" });
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_purchase_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_supplier")}
                </Typography>

                <SelectSupplier
                  selectedVal={selectedSupplierVal}
                  setSelectedVal={setSelectedSupplierVal}
                />

                {!!errors.supplierId && touched.supplierId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.supplierId}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_date")}
                </Typography>

                <SelectDatePicker date={date} setDate={(e) => setDate(e)} />

                {!!errors.needDate && touched.needDate && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.needDate}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_priority")}
                </Typography>

                <FormControl fullWidth size="small" className="search-field">
                  <Select value={values?.priority} onChange={(event) => setFieldValue("priority", event.target.value)}>
                    <MenuItem value={"Urgent"}> Urgent </MenuItem>
                    <MenuItem value={"Important"}> Important </MenuItem>
                    <MenuItem value={"Medium"}> Medium </MenuItem>
                    <MenuItem value={"Low"}> Low </MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={12}>
                <SelectPurchaseProducts
                  required={required}
                  purchaseProductsData={purchaseProductsData}
                  setPurchaseProductsData={setPurchaseProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-purchase")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
