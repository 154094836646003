import { gql } from "@apollo/client";

export const CREATE_MAIN_PRODUCT = gql`
  mutation CreateMainProduct($input: MainProductInput) {
    createMainProduct(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: ProductInput) {
    createProduct(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_SPECAIL_FIELD = gql`
  mutation UpdateProductSpecailField($id: ID!, $input: ProductInput) {
    updateProductSpecailField(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: ProductInput) {
    updateProduct(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_MAIN_PRODUCT = gql`
  mutation UpdateMainProduct($id: ID!, $input: MainProductInput) {
    updateMainProduct(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_MAIN_PRODUCT = gql`
  mutation DeleteMainProduct($id: ID!) {
    deleteMainProduct(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;


export const UPDATE_SPACIAL_FIELD_MAINT_PRODUCT = gql`
  mutation UpdateMainProductSpecialField($id: ID!, $input: MainProductInput) {
    updateMainProductSpecialField(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`


export const GET_MAIN_PRODUCT_WITH_PAGINATION = gql`
  query GetMainProductPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $isUse: Boolean
    $categoryId: ID
    $pagination: Boolean
  ) {
    getMainProductPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      isUse: $isUse
      categoryId: $categoryId
      pagination: $pagination
    ) {
      data {
        _id
        mainProductKhName
        mainProductEnName
        categoryIds {
          _id
          categoryNameKh
          categoryNameEn
          subCategories {
            _id
            categoryNameEn
            categoryNameKh
            subCategories {
              _id
              categoryNameEn
              categoryNameKh
              subCategories {
                _id
                categoryNameEn
                categoryNameKh
                subCategories {
                  _id
                  categoryNameEn
                  categoryNameKh
                  subCategories {
                    _id
                    categoryNameEn
                    categoryNameKh
                    subCategories {
                      _id
                      categoryNameEn
                      categoryNameKh
                    }
                  }
                }
              }
            }
          }
        }
        isUse
        remark
        remain
        minimumUnitId {
          _id
          unitEnName
          unitKhName
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;


export const GET_PRODUCT_BY_MAIN_PRODUCT_ID = gql`
  query GetProductByMainProductId($mainProductId: ID!) {
    getProductByMainProductId(mainProductId: $mainProductId) {
      _id
      unitId {
        unitEnName
        unitKhName
        _id
      }
      qtyInMinimumUnit
      barcode
      tagsDesc
      productDesc
      imageSrc
      expiredDate
      cost
      salePrice
      taxRate
      price
      isUse
      remark
      accessShops {
        _id
        shopNameEn
        shopNameKh
      }
      accessFutureShop
      productFor
      isCheckStock
      ingredients {
        _id
        mainProductId {
          _id
          mainProductEnName
          mainProductKhName
          minimumUnitId {
            unitEnName
            unitKhName
          }
        }
        percentage
        qty
        actualQty
      }
      mainProductId {
        _id
        mainProductEnName
        mainProductKhName
      }
    }
  }
`;
