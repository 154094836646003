import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { useMutation } from "@apollo/client";
import { CREATE_TABLE, UPDATE_TABLE } from "../../../Schema/TablePage";
export const TablePageForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Available");
 
  //======================== Create Bank =========================
  const [createTable] = useMutation(CREATE_TABLE, {
    onCompleted: ({ createTable }) => {
      console.log("createTable::", createTable);
      setLoading(false);
      if (createTable?.isSuccess === true) {
        setAlert(true, "success", createTable?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createTable?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== update Bank =========================
  const [updateTable] = useMutation(UPDATE_TABLE, {
    onCompleted: ({ updateTable }) => {
      console.log("updateTable::", updateTable);
      setLoading(false);
      if (updateTable?.isSuccess === true) {
        setAlert(true, "success", updateTable?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateTable?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    location: Yup.string(),
    capacity: Yup.number().required(t("require")),
    tableName: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      capacity: "",
      location: "",
      tableName: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      console.log("value:::", value);
      setLoading(true);
      if (dialogTitle === "Create") {
        createTable({
          variables: {
            input: {
              ...value,
              status: status,
              capacity: parseFloat(value.capacity),
            },
          },
        });
      } else {
        updateTable({
          variables: {
            id: editData?._id,
            input: {
              ...value,
              status: status,
              capacity: parseFloat(value.capacity),
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setStatus("Available");
    }

    if (dialogTitle === "Update" && open) {
      setStatus(editData?.status);
      setFieldValue("remark", editData?.remark);
      setFieldValue("capacity", editData?.capacity);
      setFieldValue("location", editData?.location);
      setFieldValue("tableName", editData?.tableName);
    }
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_table")
                : t("dialog_title_update_table")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_table_name")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("label_table_name")}
                  {...getFieldProps("tableName")}
                  error={Boolean(touched.tableName && errors.tableName)}
                  helperText={touched.tableName && errors.tableName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_capacity")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  className="text-field"
                  placeholder={t("label_capacity")}
                  {...getFieldProps("capacity")}
                  error={Boolean(touched.capacity && errors.capacity)}
                  helperText={touched.capacity && errors.capacity}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_location")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("thead_location")}
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_type")}
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <MenuItem value={"Available"}>Available</MenuItem>
                    <MenuItem value={"Occupied"}>Occupied</MenuItem>
                    <MenuItem value={"Reserved"}>Reserved</MenuItem>
                    <MenuItem value={"Dirty"}>Dirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  className="text-field"
                  placeholder={t("remark")}
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
