import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  FormControl,
  DialogActions,
  FormHelperText,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import ProductForSelect from "./ProductForSelect";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectMainProduct, SelectMainProductByShop } from "../../../Include/Function/DynamicSelect";
import { CREATE_POS_ADJUST_OUT, GET_POS_STOCK_PRODUCT_FOR_ADJUST_OUT } from "../../../Schema/InventoryInShop";

export const AdjustProductOut = ({ open, handleClose, setRefetch, hideBackdrop, }) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const [checkedProductId, setCheckedProductId] = useState([]);
  const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({ id: "", title: "" });
  const [selectedMainProductVal, setSelectedMainProductVal] = useState({ id: "", title: "" });


  const { refetch } = useQuery(GET_POS_STOCK_PRODUCT_FOR_ADJUST_OUT, {
    variables: {
      mainProductId: selectedMainProductVal?.id,
    },
    onCompleted: ({ getPosStockProductForAdjustOut }) => {
      setLoading(false);

      const newData = getPosStockProductForAdjustOut.map((e) => ({

        id: e?._id,
        qty: e?.qty,
        adjustTo: e?.remain - 1,
        adjustFrom: e?.remain,
        createdAt: e?.createdAt,
        unitPrice: e?.unitPrice,
        product: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,

      }))

      setTableData(newData);
    },
    onError: (err) => {
      setTableData([]);
      console.log("Error::", err?.message);
    },
  });

  //======================== Create User =========================
  const [createPosAdjustOut] = useMutation(CREATE_POS_ADJUST_OUT, {
    onCompleted: ({ createPosAdjustOut }) => {
      setLoading(false);
      if (createPosAdjustOut?.isSuccess === true) {
        setAlert(true, "success", createPosAdjustOut?.message);
        handleClose();
        setRefetch();
        resetForm();
        setSelectedWarehouseVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createPosAdjustOut?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    reason: Yup.string().required(t("require")),
    mainProductId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      reason: "ForUse",
      mainProductId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);






      const newAdjustOutStockIds = tableData.filter((d) => checkedProductId.map(e => e?.id).includes(d?.id)).map(e => ({
        mainStockId: e?.id,
        adjustTo: e?.adjustTo,
        adjustFrom: e?.adjustFrom,
      }))

      if (newAdjustOutStockIds.length === 0) {
        setAlert(true, "error", { messageKh: 'ទាមទារបរិមាណសម្រាប់កែតម្រូវ', messageEn: 'Quantity required for adjust out!' });
        return
      }

      console.log("createPosAdjustOut ===>", {
        reason: value?.reason,
        remark: value?.remark,
        adjustOutStockIds: newAdjustOutStockIds
      });


      createPosAdjustOut({
        variables: {
          input: {
            reason: value?.reason,
            remark: value?.remark,
            adjustOutStockIds: newAdjustOutStockIds
          }
        }
      });
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;


  useEffect(() => {
    setCheckedProductId([]);
  }, [selectedWarehouseVal, selectedMainProductVal]);

  useEffect(() => {
    setFieldValue("mainProductId", selectedMainProductVal?.id);
  }, [selectedMainProductVal]);

  useEffect(() => {
    resetForm();
    setSelectedWarehouseVal({ id: "", title: "" });
    setSelectedMainProductVal({ id: "", title: "" });
  }, [open]);

  useEffect(() => { refetch(); }, []);
  const shopId = window.localStorage.getItem("shopId")
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("thead_adjust_out")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>



              <Grid item xs={3}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_product")}
                </Typography>

                <SelectMainProductByShop
                  shopId={shopId}
                  selectedVal={selectedMainProductVal}
                  setSelectedVal={setSelectedMainProductVal}
                /> 
                {!!errors.mainProductId && touched.mainProductId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.mainProductId}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_reason")}
                </Typography>


                <FormControl fullWidth size="small" className="search-field">
                  <Select value={values?.reason} onChange={(event) => setFieldValue("reason", event.target.value)}>

                    <MenuItem value={"ForUse"}> {t("thead_for_use")} </MenuItem>
                    <MenuItem value={"Lose"}> {t("thead_lose")} </MenuItem>
                    <MenuItem value={"ReCount"}> {t("thead_recount")} </MenuItem>
                    <MenuItem value={"Expired"}> {t("thead_expired")} </MenuItem>
                    <MenuItem value={"Broken"}> {t("thead_broken")} </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <ProductForSelect 
                  tableData={tableData}
                  setTableData={setTableData}
                  checkedProductId={checkedProductId}
                  setCheckedProductId={setCheckedProductId}
                />
              </Grid>


              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  rows={3}
                  multiline
                  fullWidth
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit} disabled={!checkedProductId}>
                {t("btn-confirm")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
