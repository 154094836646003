import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogActions,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { useFormik, Form, FormikProvider } from "formik";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_CASH_LABEL, UPDATE_CASH_LABEL } from "../../../Schema/CashLabel";

export const CashLabelForm = ({ open, handleClose, dialogTitle, editData }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);

  //======================== Create User =========================
  const [createCashLabel] = useMutation(CREATE_CASH_LABEL, {
    onCompleted: ({ createCashLabel }) => {
      // console.log("createCashLabel::", createCashLabel);
      setLoading(false);
      if (createCashLabel?.isSuccess === true) {
        setAlert(true, "success", createCashLabel?.message);
        handleClose();
        resetForm();
        // setRefetch();
      } else {
        setAlert(true, "error", createCashLabel?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
      setAlert(true, "error", error?.message);
    },
  });

  //======================== Create User =========================
  const [updateCashLabel] = useMutation(UPDATE_CASH_LABEL, {
    onCompleted: ({ updateCashLabel }) => {
      // console.log("updateCashLabel::", updateCashLabel);
      setLoading(false);
      if (updateCashLabel?.isSuccess === true) {
        setAlert(true, "success", updateCashLabel?.message);
        handleClose();
        resetForm();
        // setRefetch();
      } else {
        setAlert(true, "error", updateCashLabel?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    cashLabel: Yup.string().required(t("require")),
    currencyType: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      cashLabel: "",
      currencyType: "KHR",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => { 
      setLoading(true);
      if (dialogTitle === "Create") {
        createCashLabel({
          variables: {
            input: {
              cashLabel: parseInt(submitValues?.cashLabel),
              currencyType: submitValues?.currencyType,
            },
          },
        });
      } else {
        updateCashLabel({
          variables: {
            id: editData?._id,
            input: {
              cashLabel: parseInt(submitValues?.cashLabel),
              currencyType: submitValues?.currencyType,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    if (editData) {
      setFieldValue("cashLabel", editData?.cashLabel);
      setFieldValue("currencyType", editData?.currencyType);
    }
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_cash")
                : t("dialog_title_update_cash")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("cash_label_name")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  onFocus={(e) => e?.target?.select()}
                  {...getFieldProps("cashLabel")}
                  error={Boolean(touched.cashLabel && errors.cashLabel)}
                  helperText={touched.cashLabel && errors.cashLabel}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_currency")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={values?.currencyType}
                    onChange={(e) =>
                      setFieldValue("currencyType", e.target.value)
                    }
                  >
                    <MenuItem value="KHR">Currency KHR</MenuItem>
                    <MenuItem value="USD">Currency USD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
