import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import { useQuery } from "@apollo/client";
import CreateIcon from "../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData/EmptyData";
import LoadingPage from "../../Include/LoadingPage/LoadingPage";
import RegisterCard from "../../Components/Register/RegisterCard";
import { translateLauguage } from "../../Include/Function/Translate";
import { RegisterForm } from "../../Components/Register/RegisterForm";
import { GET_CHECK_IN_WITH_PAGINATION } from "../../Schema/CardNumber";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";

export const Register = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(moment());
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { refetch } = useQuery(GET_CHECK_IN_WITH_PAGINATION, {
    variables: {
      page: 1,
      limit: 12,
      pagination: true,
      date: date ? moment(date).format("YYYY-MM-DD") : "",
      keyword: keyword,
      status: status,
    },
    onCompleted: ({ getCheckInPagination }) => {
      setLoading(false);
      setTableData(getCheckInPagination.data);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  return (
    <div className="report-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-register-card`)}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3} md={3} lg={2.4} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              size="small"
              className="search-field"
              placeholder={t("label-search")}
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={3} lg={2.4} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_status")}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="All">{t("thead_all")}</MenuItem>
                <MenuItem value="Waiting">{t("thead_waiting")}</MenuItem>
                <MenuItem value="In"> {t("thead_check_in")}</MenuItem>
                <MenuItem value="Out">{t("thead_check_out")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3} md={3} lg={2.4} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_date")}
            </Typography>
            <SelectDatePicker
              date={date}
              setDate={(e) => setDate(e)}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={4.8} xl={6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
            <RegisterForm
              open={open}
              dialogTitle="Create"
              setRefetch={refetch}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 6 }}>
        {loading ? (
          <LoadingPage />
        ) : tableData?.length === 0 ? (
          <EmptyData />
        ) : (
          <Grid container spacing={3} columnSpacing={3}>
            {tableData?.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                  <RegisterCard item={item} setRefetch={refetch} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </div>
  );
};
