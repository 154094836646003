import {
  Stack,
  Pagination,
  Select,
  MenuItem,
  PaginationItem,
} from "@mui/material";

export default function FooterPagination({
  totalPages,
  totalDocs,
  limit,
  page,
  setPage,
  handleLimit,
}) {
  const handlePageNum = (event, pageNum) => {
    setPage(parseInt(pageNum));
  };

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="right"
      sx={{ marginTop: "20px" }}
    >
      <Stack direction="column" justifyContent="center">
        <Pagination
          page={page}
          color="primary"
          shape="rounded"
          count={totalPages}
          hideNextButton={false}
          hidePrevButton={false}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              variant={item.page === page ? "text" : "outlined"}
            />
          )}
          onChange={(event, pageNum) => handlePageNum(event, pageNum)}
        />
      </Stack>
      <Select
        size="small"
        value={limit}
        onChange={handleLimit}
        sx={{
          fontSize: 14,
          ".css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            {
              paddingY: "6px !important",
            },
        }}
      >
        <MenuItem value={6}>6/ទំព័រ</MenuItem>
        <MenuItem value={9}>9/ទំព័រ</MenuItem>
        <MenuItem value={12}>12/ទំព័រ</MenuItem>
        <MenuItem value={totalDocs}>ទាំងអស់/ទំព័រ</MenuItem>
      </Select>
    </Stack>
  );
}
