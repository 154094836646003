import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput) {
    createCategory(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
 

export const GET_CATEGORY_BY_SHOP = gql`
  query GetCategoryByShop($isUse: Boolean) {
    getCategoryByShop(isUse: $isUse) {
      _id
      categoryNameKh
      categoryNameEn
      isUse
      subCategories {
        _id
        categoryNameKh
        categoryNameEn
        isUse
        subCategories {
          _id
          categoryNameEn
          categoryNameKh
          subCategories {
            _id
            categoryNameEn
            categoryNameKh
            isUse
            subCategories {
              _id
              categoryNameEn
              categoryNameKh
              isUse
              subCategories {
                _id
                categoryNameEn
                categoryNameKh
                subCategories {
                  _id
                  categoryNameEn
                  categoryNameKh
                  isUse
                }
              }
            }
          }  
        }
      }
    }
  }
`;

export const GET_CATEGORY_WITH_PAGINATION = gql`
  query Data($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
    getCategoryWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        categoryNameKh
        categoryNameEn
        isUse
        remark
        subCategories {
          _id
          categoryNameEn
          categoryNameKh
          isUse
          remark
          parentCategoryId {
            _id
            categoryNameEn
            categoryNameKh
          }
          subCategories {
            _id
            categoryNameEn
            categoryNameKh
            isUse
            remark
            parentCategoryId {
              _id
              categoryNameEn
              categoryNameKh
            }
            subCategories {
              _id
              categoryNameEn
              categoryNameKh
              isUse
              remark
              parentCategoryId {
                _id
                categoryNameEn
                categoryNameKh
              }
              subCategories {
                _id
                categoryNameEn
                categoryNameKh
                isUse
                remark
                parentCategoryId {
                  _id
                  categoryNameEn
                  categoryNameKh
                }
                subCategories {
                  _id
                  categoryNameEn
                  categoryNameKh
                  isUse
                  remark
                  parentCategoryId {
                    _id
                    categoryNameEn
                    categoryNameKh
                  }
                  subCategories {
                    _id
                    categoryNameEn
                    categoryNameKh
                    isUse
                    remark
                    parentCategoryId {
                      _id
                      categoryNameEn
                      categoryNameKh
                    }
                  }
                }
              }
            }
          }
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $input: CategoryInput) {
    updateCategory(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
