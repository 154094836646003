import React, { useContext, useEffect, useState } from "react";
import "./exchangerate.scss";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_EXCHANGE_RATE,
  UPDATE_EXCHANGE_RATE,
} from "../../Schema/ExchangeRate";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

export default function ExchangeRate() {
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { refetch, data } = useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangeRate }) => {
      window.localStorage.setItem(
        "exChangeRate",
        getExchangeRate?.exchangeRate
      );
      setExchangeRate(getExchangeRate?.exchangeRate);
    },
  });
  const [updateExchangeRate] = useMutation(UPDATE_EXCHANGE_RATE, {
    onCompleted: ({ updateExchangeRate }) => {
      setLoading(false);
      if (updateExchangeRate?.isSuccess === true) {
        handleClose();
        setAlert(true, "success", updateExchangeRate?.message);
      } else {
        setAlert(true, "error", updateExchangeRate?.message);
      }
    },
    onError: ({ message }) => {
      console.log("Error updateExchangeRate :", message);
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  const handleUpdate = () => {
    setLoading(true);
    updateExchangeRate({
      variables: {
        id: data?.getExchangeRate?._id,
        exchangeRate: exchangeRate,
      },
    });
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
        sx={{
          fontWeight: "bold",
          fontSize: 18,
          color: "#006A4C",
          letterSpacing: 0.8,
        }}
      >
        1$ = {data?.getExchangeRate?.exchangeRate} ៛
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack spacing={1.5} direction="column" sx={{ padding: 2 }}>
          <Typography
            className={
              language === "kh" ? "sub-title-text-kh" : "sub-title-text"
            }
          >
            {t("thead_exchange_rate")}
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            value={exchangeRate}
            className="text-field"
            sx={{ width: "250px" }}
            onChange={({ target }) =>
              setExchangeRate(parseFloat(target?.value))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {t("khr")} / {t("usd")}
                </InputAdornment>
              ),
            }}
          />
          <Button
            size="small"
            fullWidth
            className={!exchangeRate || loading ? "btn-disabled" : "btn-change"}
            onClick={handleUpdate}
            disabled={!exchangeRate || loading}
          >
            {loading ? t("loading") : t("btn-update")}
          </Button>
        </Stack>
      </Menu>
    </div>
  );
}
