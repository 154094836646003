import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import "./openselling.scss";
import logo from "../../Assets/it-logo.png";
import { GiMoneyStack } from "react-icons/gi";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OpenCashUSD } from "../../Components/OpenCash/OpenCashUSD";
import { OpenCashKHR } from "../../Components/OpenCash/OpenCashKHR";
import { translateLauguage } from "../../Include/Function/Translate";
import { GET_CASH_LABEL_BY_CURRENCY, OPEN_SHIFT } from "../../Schema/CashLabel";

const OpenSelling = () => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shopId = params.get("id");
  const [loading, setLoading] = useState(false);

  const [openCashUSD, setOpenCashUSD] = useState(false);
  const handleOpenCashUSD = () => setOpenCashUSD(true);
  const handleCloseCashUSD = () => setOpenCashUSD(false);

  const [openCashKHR, setOpenCashKHR] = useState(false);
  const handleOpenCashKHR = () => setOpenCashKHR(true);
  const handleCloseCashKHR = () => setOpenCashKHR(false);

  const [cashLabelKHR, setCashLabelKHR] = useState([]);
  const [cashLabelUSD, setCashLabelUSD] = useState([]);

  const [openAmountUsd, setOpenAmountUsd] = useState(0);
  const [openAmountKhr, setOpenAmountKhr] = useState(0);

  const [openShift] = useMutation(OPEN_SHIFT, {
    onCompleted: ({ openShift }) => {
      if (openShift?.isSuccess === true) {
        window.localStorage?.setItem("shiftId", openShift?.data);
        window.localStorage?.setItem("shopId", shopId);

        setTimeout(() => {
          setLoading(false);
          navigate(`/shop`);
          // navigate(`/pos`);
          // navigate(`/pos?id=${shopId}`);
        }, 1000);

        setAlert(true, "success", openShift?.message);
      } else {
        setLoading(false);
        setAlert(true, "error", openShift?.message);
      }
    },
    onError: ({ message }) => {
      setLoading(false);
      console.log("error:::", message);
    },
  });

  const { refetch } = useQuery(GET_CASH_LABEL_BY_CURRENCY, {
    variables: {
      currencyType: "All",
    },
    onCompleted: ({ getCashLabelByCurrency }) => {
      setCashLabelKHR(
        getCashLabelByCurrency
          .filter((e) => e?.currencyType === "KHR")
          .map((e) => ({
            cashLabel: e?.cashLabel,
            caseLabelId: e?._id,
            sheetQty: 0,
          }))
      );
      setCashLabelUSD(
        getCashLabelByCurrency
          .filter((e) => e?.currencyType === "USD")
          .map((e) => ({
            cashLabel: e?.cashLabel,
            caseLabelId: e?._id,
            sheetQty: 0,
          }))
      );
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  const handleChangeQtyCashLabelKHR = (caseLabelId, qty) => {
    const newData = cashLabelKHR?.map((e) => {
      if (e?.caseLabelId === caseLabelId) e.sheetQty = parseFloat(qty || 0);
      return e;
    });
    setCashLabelKHR(newData);
  };
  const handleChangeQtyCashLabelUSD = (caseLabelId, qty) => {
    const newData = cashLabelUSD?.map((e) => {
      if (e?.caseLabelId === caseLabelId) e.sheetQty = parseFloat(qty || 0);
      return e;
    });
    setCashLabelUSD(newData);
  };
  const totalCashLabelKHR = cashLabelKHR.reduce(
    (a, b) => a + b?.sheetQty * b?.cashLabel,
    0
  );
  const totalCashLabelUSD = cashLabelUSD.reduce(
    (a, b) => a + b?.sheetQty * b?.cashLabel,
    0
  );
  const handleOpenShift = () => {
    setLoading(true);

    openShift({
      variables: {
        input: {
          openAmountKhr: parseFloat(openAmountKhr),
          openAmountUsd: parseFloat(openAmountUsd),

          openLabelKhr: cashLabelKHR.map((e) => ({
            caseLabelId: e?._id,
            sheetQty: e?.sheetQty,
          })),
          openLabelUsd: cashLabelUSD.map((e) => ({
            caseLabelId: e?._id,
            sheetQty: e?.sheetQty,
          })),
          shopId: shopId,
        },
      },
    });
  };
  return (
    <>
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/shop">
            <Typography
              className={language === "kh" ? "page-title-kh" : "page-title"}
            >
              {t(`page-shop`)}
            </Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-opensale`)}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        className="login-container"
      >
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <Stack direction={`column`} className="box-container">
            <Stack
              direction={`row`}
              spacing={10}
              justifyContent={`center`}
              alignItems={`center`}
            >
              <img alt="logo" src={logo} className="logo" />
              <Typography
                className={language === "kh" ? "tittle-kh" : "tittle"}
              >
                {t("open_cash_control")}
              </Typography>
            </Stack>
            <Stack direction={`row`} justifyContent={`center`} mt={5}>
              <Typography
                className={language === "kh" ? "sub-tittle-kh" : "sub-tittle"}
              >
                {t("select_start_up_cash_to_open_shop")}
              </Typography>
            </Stack>
            <Stack direction={`column`} spacing={1} mt={5}>
              <Stack
                direction={`row`}
                justifyContent={`center`}
                alignItems={`center`}
                spacing={1}
                width={`100%`}
              >
                <Typography
                  className={language === "en" ? "detail" : "detail-kh"}
                  width={`28%`}
                >
                  {t("opening_cash_in_riel")}
                </Typography>
                <Typography
                  className={language === "en" ? "detail" : "detail-kh"}
                  width={`3%`}
                >
                  :
                </Typography>
                <TextField
                  value={openAmountKhr}
                  onChange={(e) =>
                    setOpenAmountKhr(e?.target?.value ? e?.target?.value : 0)
                  }
                  sx={{ width: "30%" }}
                  size="small"
                  InputProps={{ endAdornment: "៛" }}
                />
                <IconButton onClick={handleOpenCashKHR}>
                  <GiMoneyStack className="money-icon" />
                </IconButton>
              </Stack>
              <Stack
                direction={`row`}
                justifyContent={`center`}
                alignItems={`center`}
                spacing={1}
                width={`100%`}
              >
                <Typography
                  className={language === "en" ? "detail" : "detail-kh"}
                  width={`28%`}
                >
                  {t("opening_cash_in_dollar")}
                </Typography>
                <Typography
                  className={language === "en" ? "detail" : "detail-kh"}
                  width={`3%`}
                >
                  :
                </Typography>
                <TextField
                  value={openAmountUsd}
                  onChange={(e) =>
                    setOpenAmountUsd(e?.target?.value ? e?.target?.value : 0)
                  }
                  sx={{ width: "30%" }}
                  size="small"
                  InputProps={{ endAdornment: "$" }}
                />
                <IconButton onClick={handleOpenCashUSD}>
                  <GiMoneyStack className="money-icon" />
                </IconButton>
              </Stack>
              <OpenCashKHR
                open={openCashKHR}
                cashLabelKHR={cashLabelKHR}
                handleClose={handleCloseCashKHR}
                handleChangeQtyCashLabelKHR={handleChangeQtyCashLabelKHR}
              />
              <OpenCashUSD
                open={openCashUSD}
                cashLabelUSD={cashLabelUSD}
                handleClose={handleCloseCashUSD}
                handleChangeQtyCashLabelUSD={handleChangeQtyCashLabelUSD}
              />
            </Stack>
            <Stack direction={`row`} justifyContent={`center`} mt={5}>
              <Button
                className="btn-open-shop"
                onClick={handleOpenShift}
                disabled={loading}
              >
                <Typography
                // className={Language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {loading ? t("loading") : t("btn-open-shop")}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OpenSelling;
