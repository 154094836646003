import React, { useContext, useEffect } from "react";
import "../../../../Style/actionStyle.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import {
  SelectCategoryForMainProduct,
  SelectSubCategoryForMainProduct,
} from "../../../../Include/Function/DynamicSelect";
import { Stack } from "@mui/material";
import { useState } from "react";


export default function SelectMainCategory({
  setSelectedVal,
  editData,
}) {
 
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectedSubCategoryVal0, setSelectedSubCategoryVal0] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
  const [selectedSubCategoryVal1, setSelectedSubCategoryVal1] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
  const [selectedSubCategoryVal2, setSelectedSubCategoryVal2] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
  const [selectedSubCategoryVal3, setSelectedSubCategoryVal3] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
  const [selectedSubCategoryVal4, setSelectedSubCategoryVal4] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
  const [selectedSubCategoryVal5, setSelectedSubCategoryVal5] = useState({
    id: "",
    title: "",
    subCategories: [],
  });
 
  useEffect(() => {
    if (!selectedSubCategoryVal0?.id) {
      setSelectedSubCategoryVal1();
      setSelectedSubCategoryVal2();
      setSelectedSubCategoryVal3();
      setSelectedSubCategoryVal4();
      setSelectedSubCategoryVal5();
      setSelectedVal([]);
    } else if (selectedSubCategoryVal0?.id) {
      setSelectedVal([selectedSubCategoryVal0?.id]);
    }

    if (!selectedSubCategoryVal1?.id) {
      setSelectedSubCategoryVal1();
      setSelectedSubCategoryVal2();
      setSelectedSubCategoryVal3();
      setSelectedSubCategoryVal4();
      setSelectedSubCategoryVal5();
      setSelectedVal([selectedSubCategoryVal0?.id]);
    } else if (selectedSubCategoryVal1?.id) {
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
      ]);
    }
    
    if (!selectedSubCategoryVal2?.id) {
      setSelectedSubCategoryVal2();
      setSelectedSubCategoryVal3();
      setSelectedSubCategoryVal4();
      setSelectedSubCategoryVal5();
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
      ]);
    } else if (selectedSubCategoryVal2?.id) {
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
      ]);
    }

    if (!selectedSubCategoryVal3?.id) {
      setSelectedSubCategoryVal3();
      setSelectedSubCategoryVal4();
      setSelectedSubCategoryVal5();
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
      ]);
    } else if (selectedSubCategoryVal3?.id) {
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
        selectedSubCategoryVal3?.id,
      ]);
    }
    if (!selectedSubCategoryVal4?.id) {
      setSelectedSubCategoryVal4();
      setSelectedSubCategoryVal5();
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
        selectedSubCategoryVal3?.id,
      ]);
    } else if (selectedSubCategoryVal4?.id) {
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
        selectedSubCategoryVal3?.id,
        selectedSubCategoryVal4?.id,
      ]);
    }
    if (!selectedSubCategoryVal5?.id) {
      setSelectedSubCategoryVal5();
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
        selectedSubCategoryVal3?.id,
        selectedSubCategoryVal4?.id,
      ]);
    } else if (selectedSubCategoryVal5?.id) {
      setSelectedVal([
        selectedSubCategoryVal0?.id,
        selectedSubCategoryVal1?.id,
        selectedSubCategoryVal2?.id,
        selectedSubCategoryVal3?.id,
        selectedSubCategoryVal4?.id,
        selectedSubCategoryVal5?.id,
      ]);
    }
  }, [
    selectedSubCategoryVal0,
    selectedSubCategoryVal1,
    selectedSubCategoryVal2,
    selectedSubCategoryVal3,
    selectedSubCategoryVal4,
    selectedSubCategoryVal5,
  ]);


  useEffect(() => {
    editData?.categoryIds?.map((e, index) => {
      let subOptionCat = [];
      if(e?.subCategories && e?.subCategories?.length > 0) {

        subOptionCat = e?.subCategories?.map((sub0) => ({
          id: sub0?._id,
          title: language === "en" ? sub0?.categoryNameEn : sub0?.categoryNameKh,
          subCategories: sub0?.subCategories && sub0?.subCategories.map((sub1) => ({
              id: sub1?._id,
              title:
                language === "en" ? sub1?.categoryNameEn : sub1?.categoryNameKh,
              subCategories:
                sub1?.subCategories &&
                sub1?.subCategories.map((sub2) => ({
                  id: sub2?._id,
                  title:
                    language === "en"
                      ? sub2?.categoryNameEn
                      : sub2?.categoryNameKh,
                  subCategories:
                    sub2?.subCategories &&
                    sub2?.subCategories.map((sub3) => ({
                      id: sub3?._id,
                      title:
                        language === "en"
                          ? sub3?.categoryNameEn
                          : sub3?.categoryNameKh,
                      subCategories:
                        sub3?.subCategories &&
                        sub3?.subCategories.map((sub4) => ({
                          id: sub4?._id,
                          title:
                            language === "en"
                              ? sub4?.categoryNameEn
                              : sub4?.categoryNameKh,
                          subCategories:
                            sub4?.subCategories &&
                            sub4?.subCategories.map((sub5) => ({
                              id: sub5?._id,
                              title:
                                language === "en"
                                  ? sub5?.categoryNameEn
                                  : sub5?.categoryNameKh,
                            })),
                        })),
                    })),
                })),
            })),
        }));

      }

      if (index === 0) { 
        setSelectedSubCategoryVal0({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
      if (index === 1) {
        setSelectedSubCategoryVal1({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
      if (index === 2) {
        setSelectedSubCategoryVal2({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
      if (index === 3) {
        setSelectedSubCategoryVal3({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
      if (index === 4) {
        setSelectedSubCategoryVal4({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
      if (index === 5) {
        setSelectedSubCategoryVal5({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories: subOptionCat,
        });
      }
    });
  }, [editData]);

  // console.log("selectedSubCategoryVal0:::",selectedSubCategoryVal0)
 
  return (
    <>
      <Stack direction="column" spacing={1}>
        <SelectCategoryForMainProduct
          selectedVal={selectedSubCategoryVal0}
          setSelectedVal={setSelectedSubCategoryVal0}
        />
        {selectedSubCategoryVal0?.subCategories?.length > 0 && (
          <SelectSubCategoryForMainProduct
            selectedVal={selectedSubCategoryVal1}
            setSelectedVal={setSelectedSubCategoryVal1}
            selectOptions={selectedSubCategoryVal0?.subCategories}
          />
        )}
        {selectedSubCategoryVal1?.subCategories?.length > 0 && (
          <SelectSubCategoryForMainProduct
            selectedVal={selectedSubCategoryVal2}
            setSelectedVal={setSelectedSubCategoryVal2}
            selectOptions={selectedSubCategoryVal1?.subCategories}
          />
        )}
        {selectedSubCategoryVal2?.subCategories?.length > 0 && (
          <SelectSubCategoryForMainProduct
            selectedVal={selectedSubCategoryVal3}
            setSelectedVal={setSelectedSubCategoryVal3}
            selectOptions={selectedSubCategoryVal2?.subCategories}
          />
        )}
        {selectedSubCategoryVal3?.subCategories?.length > 0 && (
          <SelectSubCategoryForMainProduct
            selectedVal={selectedSubCategoryVal4}
            setSelectedVal={setSelectedSubCategoryVal4}
            selectOptions={selectedSubCategoryVal3?.subCategories}
          />
        )}
        {selectedSubCategoryVal4?.subCategories?.length > 0 && (
          <SelectSubCategoryForMainProduct
            selectedVal={selectedSubCategoryVal5}
            setSelectedVal={setSelectedSubCategoryVal5}
            selectOptions={selectedSubCategoryVal4?.subCategories}
          />
        )}
      </Stack>
    </>
  );
}
