import React, { useState, useContext } from "react";
import "../../../Style/actionStyle.scss";
import { ViewRequest } from "./ViewRequest";
import { CancelRequest } from "./CancelRequest";
import CloseIcon from '@mui/icons-material/Close';
import { RequestProduct } from "./RequestProduct";
import CreateIcon from "@mui/icons-material/Create";
import { AuthContext } from "../../../Context/AuthContext";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import { translateLauguage } from "../../../Include/Function/Translate";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function RequestAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal update 
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);
 

  return (
    <div>
      <Stack direction="row" justifyContent="right">

        <Tooltip title={t("thead_view")} placement="top">
          <IconButton onClick={handleOpenView}>
            <Box className="view-container" >
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("tooltip-update")} placement="top">
          <IconButton onClick={handleOpenEdit} disabled={disabled}>
            <Box className="update-container" sx={disabled && { bgcolor: "grey !important" }}>
              <CreateIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("tooltip-calcel")} placement="top">
          <IconButton onClick={handleOpenDel} disabled={(editData?.isVoid || disabled)}>
            <Box className="delete-container" sx={(editData?.isVoid || disabled) && { bgcolor: "grey !important" }}>
              <CloseIcon className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ViewRequest
        open={openView}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseView}
      />
      <RequestProduct
        open={openEdit}
        editData={editData}
        dailogTitle={"Update"}
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />

      <CancelRequest
        open={openDel}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseDel}
      />
    </div>
  );
}
