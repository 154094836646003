import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  FormControl,
  DialogActions,
  FormHelperText,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "../../../Style/dialogStyle.scss"; 
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import { AuthContext } from "../../../Context/AuthContext";
import SelectPurchaseProducts from "./SelectPurchaseProducts";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectWarehouse } from "../../../Include/Function/DynamicSelect";
import { CREATE_POS_ADJUST_IN } from "../../../Schema/InventoryInWarehouse";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
export const AdjustProductIn = ({ open, handleClose, setRefetch, hideBackdrop, }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [purchaseProductsData, setPurchaseProductsData] = useState([]);
  // const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({ id: "", title: "" });
  const newItem = {
    qty: null,
    unitPrice: null,
    expireDate: null,
    subProductId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }
  //======================== Create User =========================
  const [createPosAdjustIn] = useMutation(CREATE_POS_ADJUST_IN, {
    onCompleted: ({ createPosAdjustIn }) => {
      setLoading(false);
      if (createPosAdjustIn?.isSuccess === true) {
        setAlert(true, "success", createPosAdjustIn?.message);
        handleClose();
        setRefetch();
        resetForm();
        setPurchaseProductsData([]);
        // setSelectedWarehouseVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createPosAdjustIn?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    reason: Yup.string().required(t("require")),
    // warehouseId: Yup.string().required(t("require")),
    // adjustInDate: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      reason: "Free",
      // warehouseId: "",
      // adjustInDate: moment(),
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (purchaseProductsData?.length > 0) {

        const requiredProduct = purchaseProductsData?.map((e) => e?.subProductId?.id && e?.mainProductId?.id && e?.unitPrice && e?.qty ? true : false)?.filter((e) => !e);
        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }

        const adjustInProducts = purchaseProductsData?.map((e) => ({
          qty: parseFloat(e?.qty),
          productId: e?.subProductId?.id,
          unitPrice: parseFloat(e?.unitPrice),
          mainProductId: e?.mainProductId?.id,
          qtyInMinimumUnit: e?.subProductId?.qtyInMinimumUnit,
          expireDate: e?.expireDate ? moment(e?.expireDate).format("YYYY-MM-DD") : null,
        }));

        setLoading(true);
        createPosAdjustIn({
          variables: {
            input: {
              ...value,
              adjustInProducts: adjustInProducts,
              // adjustInDate: moment(value?.purchaseDate).format("YYYY-MM-DD"),
            },
          },
        });
      } else {
        setPurchaseProductsData([newItem]);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);



  // useEffect(() => {
  //   setFieldValue("warehouseId", selectedWarehouseVal?.id);
  // }, [selectedWarehouseVal]);

  useEffect(() => {
    resetForm();
    setPurchaseProductsData([newItem]);
    // setSelectedWarehouseVal({ id: "", title: "" });
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("thead_adjust_in")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
 
              <Grid item xs={4}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("thead_reason")}
                </Typography>


                <FormControl fullWidth size="small" className="search-field">
                  <Select value={values?.reason} onChange={(event) => setFieldValue("reason", event.target.value)}>
                    <MenuItem value={"Free"}>Free</MenuItem>
                    <MenuItem value={"ReCount"}>Recount</MenuItem>
                    <MenuItem value={"FromUse"}>From Use</MenuItem> 
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>

                <SelectPurchaseProducts
                  required={required}
                  purchaseProductsData={purchaseProductsData}
                  setPurchaseProductsData={setPurchaseProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-confirm")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
