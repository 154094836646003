import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Select,
  TableRow,
  TableCell,
  MenuItem,
  TextField,
  TableHead,
  TableBody,
  Typography,
  InputAdornment,
  TableContainer,
  FormControl,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import RequestAction from "./RequestAction";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { SelectShop } from "../../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../../Include/Function/Translate";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { GET_INVENTORY_REQUEST_PAGINATION } from "../../../Schema/InventoryInWarehouse";

export const Request = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState();
  const [isVoid, setIsVoid] = useState("All");
  const [transitStatus, setTransitStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [priority, setPriority] = useState("All");
  const [paginationData, setPaginationData] = useState();
  const [selectedShopVal, setSelectedShopVal] = useState({ id: "", title: "" });

  const { refetch } = useQuery(GET_INVENTORY_REQUEST_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      transitStatus,
      pagination: true,
      priority: priority,
      shopId: selectedShopVal?.id,
      requestNo: parseFloat(keyword),
      isVoid: isVoid === "All" ? null : isVoid === "Yes" ? true : false,
    },
    onCompleted: ({ getInventoryRequestPagination }) => {
      setTableData(getInventoryRequestPagination?.data);
      setPaginationData(getInventoryRequestPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`label-search`)}
          </Typography>
          <TextField
            size="small"
            type="number"
            className="search-field"
            placeholder={t(`label-search`)}
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1, color: "black" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_shop")}
          </Typography>

          <SelectShop
            className={"search-field"}
            selectedVal={selectedShopVal}
            setSelectedVal={setSelectedShopVal}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`thead_priority`)}
          </Typography>
          <FormControl fullWidth size="small" className="search-field">
            <Select
              value={priority}
              onChange={(event) => setPriority(event.target.value)}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Important"}>Important</MenuItem>
              <MenuItem value={"Urgent"}>Urgent</MenuItem>
              <MenuItem value={"Medium"}>Medium</MenuItem>
              <MenuItem value={"Low"}>Low</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`thead_status`)}
          </Typography>
          <FormControl fullWidth size="small" className="search-field">
            <Select
              value={transitStatus}
              onChange={(event) => setTransitStatus(event.target.value)}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Approved"}>Approved</MenuItem>
              <MenuItem value={"Rejected"}>Rejected</MenuItem>
              <MenuItem value={"InTransit"}>In Transit</MenuItem>
              <MenuItem value={"Completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            Is Void
          </Typography>
          <FormControl size="small" fullWidth className="search-field">
            <Select value={isVoid} onChange={(e) => setIsVoid(e.target.value)}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="10%"
                >
                  {" "}
                  {t("thead_no")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_date")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_shop")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_priority")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_status")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("remark")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                            >
                              {" "}
                              {row?.requestNo}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "MM-DD-YYYY hh:mm a"
                              )}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.shopId?.shopNameEn
                                : row?.shopId?.shopNameKh}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.priority}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              <Typography className={row?.transitStatus}>
                                {row?.transitStatus}
                              </Typography>
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.transferBy?.remark}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <RequestAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.transitStatus === "Received"}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </>
  );
};
