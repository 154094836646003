import React, { useState, useContext, Fragment } from "react";
import moment from "moment";
import { Stack, Typography, Box } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LogoCompany from "../../../Assets/it-logo-invoice.png";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";

const MainRecord = ({ title, totalAmount }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bold-main">{title}</td>
      <td className="report-content-cell-left-bold-main"></td>
      <td className="report-content-cell-right-bg-main">
        {totalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

const SubRecord = ({ title, qauntity, totalAmount }) => {
  return (
    <tr>
      <td
        className="report-content-cell-left-main"
        style={{ paddingLeft: "30px" }}
      >
        {title}
      </td>
      <td className="report-content-cell-center-main">{qauntity}</td>
      <td className="report-content-cell-right-main">
        {totalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

const FinalRecord = ({ title, totalAmount }) => {
  return (
    <tr style={{ height: "40px" }}>
      <td
        colSpan={2}
        className="tr-close-shift-sticky report-content-cell-right-bg-main"
        style={{ paddingLeft: "50px", paddingRight: "30px" }}
      >
        {title}
      </td>
      <td className="tr-close-shift-sticky report-content-cell-right-bg-main">
        {totalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

export default function CostOfGoodSoldReports({
  componentRef,
  outletShop,
  fromDate,
  toDate,
}) {
  //===================  Change Language ======================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);

  //=================== Get Payment Reports ===================
  const [costOfGoodSoldReport, setCostOfGoodSoldReport] = useState([
    1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
  ]);

  // const { refetch } = useQuery(GET_COST_OF_GOODS_SOLD_REPORT, {
  //   variables: {
  //     outlet: outletShop !== "select" ? outletShop : role,
  //     shiftId: shiftID,
  //     from: fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : "",
  //     to: toDate ? moment(toDate)?.format("YYYY-MM-DD") : "",
  //     type: filterType,
  //   },
  //   onCompleted: ({ getCostGoodsSoldReport }) => {
  //     console.log("getCostGoodsSoldReport", getCostGoodsSoldReport);
  //     setTimeout(() => {
  //       setLoading(false);
  //       setCostOfGoodSoldReport(getCostGoodsSoldReport);
  //     }, 300);
  //   },
  //   onError: (err) => {
  //     console.log("Error::", err?.message);
  //   },
  // });

  // useEffect(() => {
  //   setLoading(true);
  //   refetch();
  // }, [outletShop, shiftID, filterType, fromDate, toDate]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {costOfGoodSoldReport === undefined ||
          costOfGoodSoldReport === null ? (
            <Box className="page-container-report-main">
              <Stack
                direction="row"
                justifyContent="center"
                mb={1}
                spacing={4}
                height="100%"
              >
                <Stack direction="column" justifyContent="center" height="100%">
                  <Box height="100%" marginTop="15px">
                    <img src={LogoCompany} className="page-report-logo-size" />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Stack
                    className="page-header-report-title-main"
                    direction="row"
                    justifyContent="center"
                  >
                    Cost of Goods Sold Reports {outletShop}
                  </Stack>
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Typography className="date-time">DD-MMM-YYYY</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <EmptyData />
            </Box>
          ) : (
            <>
              <Box ref={componentRef} className="page-container-report-main">
                <Stack
                  direction="row"
                  justifyContent="center"
                  mb={1}
                  spacing={4}
                  height="100%"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Box height="100%" marginTop="15px">
                      <img
                        src={LogoCompany}
                        className="page-report-logo-size"
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      className="page-header-report-title-main"
                      direction="row"
                      justifyContent="center"
                    >
                      Cost of Goods Sold {outletShop}
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography className="date-time">
                        {moment(fromDate).format("DD-MMM-YYYY") ===
                        moment(toDate).format("DD-MMM-YYYY")
                          ? moment(fromDate).format("DD-MMM-YYYY")
                          : moment(fromDate).format("DD-MMM-YYYY") +
                            " - " +
                            moment(toDate).format("DD-MMM-YYYY")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <table className="report-container-main">
                  <thead className="report-header-main">
                    <tr>
                      <td className="header-title-report-main">Name</td>
                      <td className="header-title-report-main">Qauntity</td>
                      <td className="header-title-report-main">COSTING</td>
                    </tr>
                  </thead>

                  <tfoot className="report-footer-main">
                    <tr>
                      <td className="report-footer-cell" colSpan={3}>
                        <div className="footer-info-main">
                          <div className={"page-footer-main"}>
                            <div className="page-footer-line-main" />
                            <div className={"page-footer-text-main"}>
                              Boeungdonpa Village, Slokram Commune Siem Reap /
                              Telephone: 078 881 919
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                  <tbody className="report-content-main">
                    {[1, 2, 3, 4]?.map((row, index) => (
                      <Fragment key={index}>
                        <MainRecord
                          title={"Category Name"}
                          totalAmount={row?.totalCoat}
                        />

                        {[1, 2, 3, 4]?.map((rows, indexs) => (
                          <SubRecord
                            key={indexs}
                            title={"Product Name"}
                            totalAmount={rows?.cost || indexs}
                            qauntity={indexs + " " + "case"}
                          />
                        ))}
                      </Fragment>
                    ))}

                    {/* ========================== Final ====================== */}
                    <FinalRecord
                      title={"Total"}
                      totalAmount={costOfGoodSoldReport?.total}
                    />
                  </tbody>
                </table>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
}
