import React, { useState, useContext, useEffect, Fragment } from "react";
import moment from "moment";
import {
  Box,
  Table,
  Stack,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import ItemRow from "./ItemRow";
import PrintPaymentReports from "./PrintPaymentReports";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LogoCompany from "../../../Assets/it-logo-invoice.png";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import { currencyFormat, padNumber } from "../../../Include/Function/DynamicFn";

export default function PaymentReport({
  loading,
  endDate,
  startDate,
  tableData,
}) {
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { language, role } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openItem, setOpenItem] = useState(false);
  const [rowActive, setRowActive] = useState("");
  const checkDate = () => {
    if (
      moment(startDate).format("DD-MMM-YYYY") ===
      moment(endDate).format("DD-MMM-YYYY")
    ) {
      return moment(startDate).format("DD.MMM.YYYY");
    } else {
      return (
        moment(startDate).format("DD.MMM.YYYY") +
        " - " +
        moment(endDate).format("DD.MMM.YYYY")
      );
    }
  };

  const tottalPaid = tableData?.reduce(
    (a, b) => a + parseFloat(b?.paid || 0),
    0
  );

  const tottalPreviousPaid = tableData?.reduce(
    (a, b) => a + parseFloat(b?.previousPaid || 0),
    0
  );

  const tottalComplimentary = tableData?.reduce(
    (a, b) => a + parseFloat(b?.complimentary || 0),
    0
  );

  return (
    <>
      {/* <PrintPaymentReports
        t={t}
        role={role}
        toDate={toDate}
        loading={loading}
        fromDate={fromDate}
        outletShop={outletShop}
        componentRef={componentRef}
        tableData={tableData}
      /> */}

      <Box className="page-container-report-main">
        <Stack
          direction="row"
          justifyContent="center"
          mb={1}
          spacing={4}
          height="100%"
        >
          <Stack direction="column" justifyContent="center" height="100%">
            <Box height="100%" marginTop="15px">
              <img src={LogoCompany} className="page-report-logo-size" />
            </Box>
          </Stack>
          <Stack direction="column" justifyContent="center" height="100%">
            <Stack
              className="page-header-report-title-main"
              direction="row"
              justifyContent="center"
            >
              Payment Report
            </Stack>

            <Stack direction="row" justifyContent="center" spacing={1}>
              <Typography color="#1d4592" fontSize={14}>
                {checkDate()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table-booking" aria-label="simple table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "bold",
                    color: "#1d4592",
                  }}
                  width="10%"
                >
                  {t("thead_inv")}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "bold",
                    color: "#1d4592",
                  }}
                  width="30%"
                >
                  {t("thead_customer_name")}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "bold",
                    color: "#1d4592",
                  }}
                  width="20%"
                >
                  {t("thead_receive_amount")}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "bold",
                    color: "#1d4592",
                  }}
                  width="20%"
                >
                  {t("thead_comp_amount")}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "bold",
                    color: "#1d4592",
                  }}
                  width="20%"
                >
                  {t("thead_awe_amount")}
                </TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow className="field-row">
                  <TableCell colSpan={6}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData?.invoices?.length === 0 ||
                tableData === undefined ||
                tableData?.length === 0 ? (
                  <TableRow className="field-row">
                    <TableCell colSpan={6}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TableRow
                            scope="row"
                            className="field-row"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenItem(!openItem);
                              setRowActive(index);
                            }}
                          >
                            <TableCell
                              className="field-column-start"
                              sx={{
                                textAlign: "left !important",
                                color: "#1d4592",
                              }}
                            >
                              {padNumber(
                                row?.invoice?.invoiceNo,
                                5,
                                moment(row?.invoice?.createdAt).format("YYYY")
                              )}
                            </TableCell>
                            <TableCell>
                              {row?.invoice?.customerId?.name}
                            </TableCell>
                            <TableCell>{currencyFormat(row?.paid)}</TableCell>
                            <TableCell>
                              {row?.complimentary &&
                                currencyFormat(row?.complimentary)}
                            </TableCell>
                            <TableCell>
                              {currencyFormat(row?.previousPaid)}
                            </TableCell>
                          </TableRow>

                          {rowActive !== undefined &&
                          rowActive !== "" &&
                          rowActive === index ? (
                            <ItemRow
                              t={t}
                              open={openItem}
                              data={row?.invoice?.products}
                            />
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </>
                )}

                <TableRow className="header-row">
                  <TableCell></TableCell>

                  <TableCell
                    sx={{ textAlign: "left !important", color: "#1d4592" }}
                  >
                    Total
                  </TableCell>

                  <TableCell
                    sx={{ textAlign: "left !important", color: "#1d4592" }}
                  >
                    {currencyFormat(tottalPaid)}
                  </TableCell>

                  <TableCell
                    sx={{ textAlign: "left !important", color: "#1d4592" }}
                  >
                    {currencyFormat(tottalComplimentary)}
                  </TableCell>

                  <TableCell
                    sx={{ textAlign: "left !important", color: "#1d4592" }}
                  >
                    {currencyFormat(tottalPreviousPaid)}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
