import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import {
  currencyFormat,
  currencyFormatKh,
} from "../../../../Include/Function/DynamicFn";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AuthContext } from "../../../../Context/AuthContext";
import CancelIcon from "../../../../Assets/payment-icon/cancel.png";
import PresentIcon from "../../../../Assets/payment-icon/present.png";
import PaymentIcon from "../../../../Assets/payment-icon/payment.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { SelectBank } from "../../../../Include/Function/DynamicSelect";
import useGetInvoiceById from "../../../../Hooks/POS/useGetInvoiceById";
import useGetExchangeRate from "../../../../Hooks/POS/useGetExchangeRate";
import { translateLauguage } from "../../../../Include/Function/Translate";
import CreditCardIcon from "../../../../Assets/payment-icon/credit-card.png";
import DialogKeyboardNumber from "./DialogKeyboardNumber/DialogKeyboardNumber";
import {
  GET_VOUCHER_BY_CODE,
  CREATE_FIRST_PAYMENT,
} from "../../../../Schema/Invoice";

export default function Payment() {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  let textInputUSD = useRef("");
  let textInputKHR = useRef("");
  let textInputChangeUSD = useRef("");
  let textInputChangeKHR = useRef("");
  const shiftId = window.localStorage?.getItem("shiftId");

  const [code, setCode] = useState("");
  const [display, setDisplay] = useState(0);
  const [require, setRequire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voucherData, setVoucherData] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [keyboardFocus, setKeyboardFocus] = useState("");
  const [changeAmountUSD, setChangeAmountUSD] = useState(0);
  const [changeAmountKHR, setChangeAmountKHR] = useState(0);
  const [receiveAmountUSD, setReceiveAmountUSD] = useState(0);
  const [receiveAmountKHR, setReceiveAmountKHR] = useState(0);
  const [remainChangeAmount, setRemainChangeAmount] = useState(0);
  const [showTotalChangeAmount, setShowTotalChangeAmount] = useState(0);
  const [selectedBankVal, setSelectedBankVal] = useState({ id: "", title: "" });
  const [showRemainingAmountToBePaid, setShowRemainingAmountToBePaid] =
    useState(0);

  // fecht exchange rate
  const { exchangeRate } = useGetExchangeRate();
  // convert amount USD to  KH
  const convertAmountUSDToKH = (amount) => {
    return parseFloat(Number(amount * exchangeRate)?.toFixed(2));
  };

  // convert amount KH to USD
  const convertAmountKHToUSD = (amount) => {
    return parseFloat((amount / exchangeRate).toFixed(2));
  };

  const handleSetValue = () => {
    if (keyboardFocus === "ReceiveUSD") {
      setReceiveAmountUSD(parseFloat(display || 0));
    }
    if (keyboardFocus === "ReceiveKHR") {
      setReceiveAmountKHR(parseFloat(display || 0));
    }

    if (keyboardFocus === "ChangeUSD") {
      setChangeAmountUSD(parseFloat(display || 0));
    }

    if (keyboardFocus === "ChangeKHR") {
      setChangeAmountKHR(parseFloat(display || 0));
    }
  };

  useEffect(() => {
    if (display !== 0) {
      setDisplay(0); // Only reset if display is not already 0
    }
  }, [keyboardFocus]);

  //=================== Received Amount USD ===============
  const handleGetReceivedAmountUSD = (valueAmountUSD) => {
    setReceiveAmountUSD(valueAmountUSD);
  };

  const handleClear = () => setDisplay(0);
  const handleClick = (value) => setDisplay((prev) => prev + value);
  const handleRemoveLastValue = () => {
    setDisplay((prev) => prev.toString().substring(0, prev.length - 1));
  };

  // fetch data invoice as array from hook
  const invoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));
  const { totalAmountToBePaid } = useGetInvoiceById([invoiceId]);

  // calculate amount
  const calculateAmount = (type, value, amount) => {
    if (type === "Amount") return value;
    if (paymentType !== "Voucher") return 0;
    if (type === "Percentage") return (amount * value) / 100;
  };
  // suggest change amount
  const handleGetSuggestChangeAmount = () => {
    let totalReceiveAmount =
      parseFloat(receiveAmountUSD.toFixed(2)) +
      convertAmountKHToUSD(receiveAmountKHR);
    const remainingAmount = totalReceiveAmount - totalAmountToBePaid;

    setShowTotalChangeAmount(
      remainingAmount > 0 ? parseFloat(remainingAmount?.toFixed(2)) : 0
    );

    if (showTotalChangeAmount === 0) {
      setChangeAmountUSD(0);
      setChangeAmountKHR(0);
    }
  };

  // calculate value remain pay amount value for show
  const handleGetshowRemainingAmountToBePaid = () => {
    let receiveUSD = parseFloat(receiveAmountUSD || 0);
    let receiveKHR = parseFloat(convertAmountKHToUSD(receiveAmountKHR || 0));

    const totalShowRemainingAmountToBePaid =
      totalAmountToBePaid - receiveUSD - receiveKHR;

    setShowRemainingAmountToBePaid(
      totalShowRemainingAmountToBePaid < 0
        ? 0
        : totalShowRemainingAmountToBePaid
    );
  };

  useEffect(() => {
    // Set change amount to local storage
    const currentReceiveAmount = {
      usdAmount: receiveAmountUSD ? parseFloat(receiveAmountUSD) : 0,
      khrAmount: receiveAmountKHR ? parseFloat(receiveAmountKHR) : 0,
    };
    const currentChangeAmount = {
      usdAmount: changeAmountUSD ? parseFloat(changeAmountUSD) : 0,
      khrAmount: changeAmountKHR ? parseFloat(changeAmountKHR) : 0,
    };

    window.localStorage.setItem(
      "CurrentReceiveAmount",
      JSON.stringify(currentReceiveAmount)
    );

    window.localStorage.setItem(
      "CurrentChangeAmount",
      JSON.stringify(currentChangeAmount)
    );
    handleGetSuggestChangeAmount();
    handleGetshowRemainingAmountToBePaid();
  }, [
    paymentType,
    changeAmountUSD,
    changeAmountKHR,
    receiveAmountUSD,
    receiveAmountKHR,
  ]);

  //=================== Remain Change Amount ===============
  const handleGetRemainChangeAmount = () => {
    let receiveChangeUSD = parseFloat(changeAmountUSD);
    let receiveChangeKHR = convertAmountKHToUSD(changeAmountKHR);
    const totalRemainChangeAmount =
      showTotalChangeAmount - receiveChangeUSD - receiveChangeKHR;
    setRemainChangeAmount(
      totalRemainChangeAmount < 0 ? 0 : totalRemainChangeAmount
    );
  };

  useEffect(() => {
    handleGetRemainChangeAmount();
  }, [changeAmountUSD, changeAmountKHR]);

  // create first payment
  const [createFirstPayment] = useMutation(CREATE_FIRST_PAYMENT, {
    onCompleted: ({ createFirstPayment }) => {
      setLoading(false);
      if (createFirstPayment?.isSuccess === true) {
        setAlert(true, "success", createFirstPayment?.message);
        setChangeAmountUSD(0);
        setChangeAmountKHR(0);
        setReceiveAmountUSD(0);
        setReceiveAmountKHR(0);
        setPaymentType("");
      } else {
        setAlert(true, "error", createFirstPayment?.message);
      }
    },

    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  // function handle create payment
  const handleSubmit = () => {
    const amountFinalPaid =
      convertAmountKHToUSD(receiveAmountKHR) +
      parseFloat(receiveAmountUSD || 0);

    if (paymentType === "Bank" && !selectedBankVal?.id) {
      setRequire(true);
      return;
    }

    setLoading(true);
    createFirstPayment({
      variables: {
        input: {
          paid: {
            usd:
              parseFloat(receiveAmountUSD || 0) +
              calculateAmount(
                voucherData?.data?.discountType,
                voucherData?.data?.value,
                amountFinalPaid
              ),
            khr: parseFloat(receiveAmountKHR || 0),
            amount:
              amountFinalPaid +
              calculateAmount(
                voucherData?.data?.discountType,
                voucherData?.data?.value,
                amountFinalPaid
              ),
          },
          change: {
            usd: parseFloat(changeAmountUSD || 0),
            khr: parseFloat(changeAmountKHR || 0),
            amount:
              convertAmountKHToUSD(changeAmountKHR) +
              parseFloat(changeAmountUSD || 0),
          },
          remark: "",
          shiftId: shiftId,
          paymentMethod: paymentType,
          invoiceId: invoiceId,
          exchangeRate: parseFloat(exchangeRate),
          bankId: selectedBankVal?.id || null,
          voucherId: paymentType === "Voucher" ? voucherData?.data?._id : null,
        },
      },
    });
  };

  useEffect(() => {
    if (paymentType !== "Bank") {
      setRequire(false);
      setSelectedBankVal({ id: "", title: "" });
    }

    if (paymentType !== "Voucher") setVoucherData();
  }, [paymentType]);

  const [getVoucherByCode] = useLazyQuery(GET_VOUCHER_BY_CODE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setVoucherData(data?.getVoucherByCode);
    },
    onError: (error) => {
      setVoucherData();
      console.log(error.message);
    },
  });

  useEffect(() => {
    setReceiveAmountUSD(0);
    setReceiveAmountKHR(0);
    setChangeAmountUSD(0);
    setChangeAmountKHR(0);
  }, [paymentType]);

  const paymentTypeData = [
    {
      Type: "NotPaid",
      Color: "#686A6E",
      BgColor: "#F0F0F0",
      HoverBgColor: "#D8D8D8",
      Title: t("thead_not_paid"),
      Icon: <img src={CancelIcon} alt="" style={{ width: 50, height: 50 }} />,
    },
    {
      Type: "Cash",
      Color: "#007654",
      BgColor: "#E6F1EE",
      HoverBgColor: "#D9EAE5",
      Title: t("thead_cash"),
      Icon: <img src={PaymentIcon} alt="" style={{ width: 60, height: 60 }} />,
    },
    {
      Type: "Bank",
      Title: t("bank"),
      Color: "#147BC9",
      BgColor: "#E8F5FF",
      HoverBgColor: "#DDF0FE",
      Icon: (
        <img src={CreditCardIcon} alt="" style={{ width: 60, height: 60 }} />
      ),
    },
    {
      Color: "#CC9110",
      BgColor: "#FFF8E8",
      Type: "Complimentary",
      HoverBgColor: "#FFF4DC",
      Title: t("thead_complimentary"),
      Icon: <img src={PresentIcon} alt="" style={{ width: 60, height: 60 }} />,
    },
  ];

  const translatePaymentType = (type) => {
    if (type === "Bank") return t("bank");
    if (type === "Cash") return t("thead_cash");
    if (type === "NotPaid") return t("thead_not_paid");
    if (type === "Complimentary") return t("thead_complimentary");
    return type;
  };

  const checkConditionDisable = (condition) => {
    return !condition.includes(true);
  };

  const checkChangeAmount = () => {
    if (totalAmountToBePaid <= 0) return true;
    if (showTotalChangeAmount > 0) {
      return !(
        parseFloat(changeAmountUSD?.toFixed(2) || 0) +
          convertAmountKHToUSD(changeAmountKHR) ===
        parseFloat(showTotalChangeAmount?.toFixed(2))
      );
    }
  };

  // console.log("showTotalChangeAmount ==>", showTotalChangeAmount);

  if (paymentType === "") {
    return (
      <Stack direction="column" spacing={1.5}>
        <Typography className="subtitle-payment">
          {t("thead_payment_type")}
        </Typography>

        <Stack direction="column">
          <Grid container spacing={4}>
            {paymentTypeData.map((e, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index}>
                <Stack
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => setPaymentType(e?.Type)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{
                      paddingY: 2,
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      borderRadius: 1.5,
                      border: `1px solid #1d4592`,
                      ":hover": {
                        bgcolor: "#E8ECF4",
                        transition: "all 0.02s",
                      },
                    }}
                  >
                    {e?.Icon}
                  </Stack>

                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#1d4592 !important",
                      }}
                    >
                      {e?.Title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={4}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => setPaymentType("")}
          sx={{ borderRadius: "6px", paddingX: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={1.6}>
            <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
            <Typography sx={{ color: "gray", fontWeight: "bold" }}>
              {t("thead_back")}
            </Typography>
          </Stack>
        </IconButton>
        <Typography className="subtitle-payment">
          {translatePaymentType(paymentType)}
        </Typography>
        <Stack direction="column" alignItems="left" spacing={1}>
          <Typography>$ 1 = {currencyFormatKh(exchangeRate)}</Typography>
        </Stack>
      </Stack>
      <Box>
        <Grid item container spacing={4.1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="subtitle-payment">
              {t("thead_amount_to_be_paid")}
            </Typography>
            <Button className="btn-total">
              {currencyFormat(totalAmountToBePaid || 0)} ={" "}
              {currencyFormatKh(convertAmountUSDToKH(totalAmountToBePaid))}
            </Button>
          </Grid>

          {paymentType !== "NotPaid" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="subtitle-payment">
                {t("thead_received_amount")}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  inputRef={textInputUSD}
                  value={receiveAmountUSD}
                  disabled={totalAmountToBePaid <= 0}
                  onFocus={(e) => e?.target?.select()}
                  onChange={({ target }) => {
                    handleGetReceivedAmountUSD(parseFloat(target?.value || 0));
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    style: { textAlign: "center" },
                    endAdornment: (
                      <DialogKeyboardNumber
                        display={display}
                        handleClear={handleClear}
                        handleClick={handleClick}
                        keyboardFocus={keyboardFocus}
                        handleSetValue={handleSetValue}
                        disabled={totalAmountToBePaid <= 0}
                        handleRemoveLastValue={handleRemoveLastValue}
                        onClick={() => {
                          setDisplay(receiveAmountUSD);
                          textInputUSD.current.focus();
                          setKeyboardFocus("ReceiveUSD");
                        }}
                      />
                    ),
                    startAdornment: (
                      <IconButton
                        className="btn-pay-receive-pos"
                        onClick={() => {
                          textInputUSD.current.focus();

                          // ====== Set Receive When Click ==========

                          if (receiveAmountKHR === 0) {
                            handleGetReceivedAmountUSD(
                              parseFloat(totalAmountToBePaid.toFixed(2))
                            );
                          } else {
                            handleGetReceivedAmountUSD(
                              parseFloat(
                                (showRemainingAmountToBePaid || 0).toFixed(2)
                              )
                            );
                          }
                        }}
                      >
                        USD
                      </IconButton>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  disabled={totalAmountToBePaid <= 0}
                  value={receiveAmountKHR}
                  onFocus={(e) => e?.target?.select()}
                  onChange={({ target }) => {
                    setReceiveAmountKHR(parseFloat(target?.value || 0));
                  }}
                  inputRef={textInputKHR}
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: (
                      <DialogKeyboardNumber
                        display={display}
                        handleClick={handleClick}
                        handleClear={handleClear}
                        keyboardFocus={keyboardFocus}
                        handleSetValue={handleSetValue}
                        disabled={totalAmountToBePaid <= 0}
                        handleRemoveLastValue={handleRemoveLastValue}
                        onClick={() => {
                          setDisplay(receiveAmountKHR);
                          textInputKHR.current.focus();
                          setKeyboardFocus("ReceiveKHR");
                        }}
                      />
                    ),
                    startAdornment: (
                      <IconButton
                        className="btn-pay-receive-pos"
                        onClick={() => {
                          textInputKHR.current.focus();
                          // set total receive amount to paid when click
                          if (receiveAmountUSD === 0) {
                            setReceiveAmountKHR(
                              convertAmountUSDToKH(totalAmountToBePaid)
                            );
                          } else {
                            setReceiveAmountKHR(
                              convertAmountUSDToKH(showRemainingAmountToBePaid)
                            );
                          }
                        }}
                      >
                        KHR
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
            </Grid>
          )}
          {/* show remain amount */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: paymentType === "NotPaid" ? "none" : "block",
            }}
          >
            <Typography className="subtitle-payment">
              {t("thead_remaining_amount")}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button className="btn-total-remain">
                {currencyFormat(showRemainingAmountToBePaid || 0)}
              </Button>
              <Button className="btn-total-remain">
                {currencyFormatKh(
                  convertAmountUSDToKH(showRemainingAmountToBePaid)
                )}
              </Button>
            </Stack>
          </Grid>

          {/* show change amount */}
          {showTotalChangeAmount > 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="subtitle-payment">
                {t("thead_change_amount")}
              </Typography>
              <Button className="btn-total">
                {currencyFormat(showTotalChangeAmount || 0)} ={" "}
                {currencyFormatKh(convertAmountUSDToKH(showTotalChangeAmount))}
              </Button>

              <Stack
                spacing={2}
                marginTop={2}
                direction="row"
                alignItems="center"
              >
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  value={changeAmountUSD}
                  inputRef={textInputChangeUSD}
                  disabled={!showTotalChangeAmount}
                  onFocus={(e) => e?.target?.select()}
                  onChange={({ target }) =>
                    setChangeAmountUSD(parseFloat(target?.value) || 0)
                  }
                  InputProps={{
                    inputProps: { min: 1 },
                    style: { textAlign: "center" },
                    endAdornment: (
                      <DialogKeyboardNumber
                        display={display}
                        handleClear={handleClear}
                        handleClick={handleClick}
                        keyboardFocus={keyboardFocus}
                        handleSetValue={handleSetValue}
                        handleRemoveLastValue={handleRemoveLastValue}
                        onClick={() => {
                          setDisplay(changeAmountUSD);
                          setKeyboardFocus("ChangeUSD");
                          textInputChangeUSD.current.focus();
                        }}
                      />
                    ),
                    startAdornment: (
                      <IconButton
                        className="btn-pay-receive-pos"
                        onClick={() => {
                          textInputChangeUSD.current.focus();
                          // ====== Set Receive When Click ==========
                          if (!changeAmountKHR) {
                            setChangeAmountUSD(
                              parseFloat(showTotalChangeAmount?.toFixed(2))
                            );
                          } else {
                            setChangeAmountUSD(
                              parseFloat(remainChangeAmount?.toFixed(2))
                            );
                          }
                        }}
                      >
                        USD
                      </IconButton>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  value={changeAmountKHR}
                  onFocus={(e) => e?.target?.select()}
                  onChange={({ target }) =>
                    setChangeAmountKHR(parseFloat(target?.value) || 0)
                  }
                  disabled={!(showTotalChangeAmount * exchangeRate)}
                  inputRef={textInputChangeKHR}
                  error={
                    (receiveAmountUSD - totalAmountToBePaid) * exchangeRate < 0
                      ? false
                      : (receiveAmountUSD - totalAmountToBePaid) *
                          exchangeRate <
                        changeAmountKHR
                  }
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: (
                      <DialogKeyboardNumber
                        display={display}
                        handleClear={handleClear}
                        handleClick={handleClick}
                        keyboardFocus={keyboardFocus}
                        handleSetValue={handleSetValue}
                        handleRemoveLastValue={handleRemoveLastValue}
                        onClick={() => {
                          setDisplay(changeAmountKHR);
                          setKeyboardFocus("ChangeKHR");
                          textInputChangeKHR.current.focus();
                        }}
                      />
                    ),
                    startAdornment: (
                      <IconButton
                        className="btn-pay-receive-pos"
                        onClick={() => {
                          textInputChangeKHR.current.focus();
                          // ====== Set Receive When Click ==========
                          if (changeAmountUSD === 0 || changeAmountUSD === "") {
                            setChangeAmountKHR(
                              convertAmountUSDToKH(
                                parseFloat(showTotalChangeAmount?.toFixed(2))
                              )
                            );
                          } else {
                            setChangeAmountKHR(
                              convertAmountUSDToKH(
                                parseFloat(remainChangeAmount?.toFixed(2))
                              )
                            );
                          }
                        }}
                      >
                        KHR
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
            </Grid>
          )}

          {paymentType === "Bank" && (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className="subtitle-payment">{t("bank")}</Typography>
              <SelectBank
                require={require}
                selectedVal={selectedBankVal}
                setSelectedVal={setSelectedBankVal}
              />
            </Grid>
          )}

          {paymentType === "Voucher" && (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Stack direction="column" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <Typography className="subtitle-payment">
                    {t("thead_payment_card_number")}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    className="create-container"
                    spacing={1}
                    sx={{ paddingX: "0px !important" }}
                  >
                    <Stack width="80%">
                      <TextField
                        fullWidth
                        size="small"
                        onFocus={(e) => e?.target?.select()}
                        onChange={({ target }) => setCode(target?.value)}
                        placeholder="x x x x x x x x x"
                      />
                    </Stack>
                    <Stack width="20%">
                      <Button
                        fullWidth
                        className={
                          language === "kh" ? "btn-create-kh" : "btn-create"
                        }
                        onClick={() =>
                          getVoucherByCode({
                            variables: {
                              voucherCode: code,
                            },
                          })
                        }
                      >
                        {t("label-search")}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
                {voucherData && voucherData?.isSuccess === false && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      paddingX: 2,
                      paddingY: 1.5,
                      width: "100%",
                      height: "100%",
                      bgcolor: "#FFE9E9",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>
                      {/* {!voucherData?.isSuccess ? t('thead_this_card_has_already_been_paid') : t('thead_could_not_find_the_this_voucher')} */}

                      {language === "en"
                        ? voucherData?.message?.messageKh
                        : voucherData?.message?.messageKh}
                    </Typography>
                  </Stack>
                )}

                {voucherData?.data && (
                  <Box
                    sx={{
                      paddingX: 2,
                      paddingY: 1.5,
                      width: "100%",
                      height: "100%",
                      bgcolor: "#E8F5FF",
                      borderRadius: "8px",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      height="100%"
                      alignItems="center"
                    >
                      <Stack direction="column" spacing={0.5} width="75%">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            className="subtitle-payment"
                            whiteSpace="pre"
                            sx={{ width: "30%" }}
                          >
                            {t("thead_payment_card_number")}
                          </Typography>
                          <Typography>
                            : {voucherData?.data?.voucherCode}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            className="subtitle-payment"
                            whiteSpace="pre"
                            sx={{ width: "30%" }}
                          >
                            {t("thead_voucher")}
                          </Typography>
                          <Typography>
                            : {voucherData?.data?.voucherName}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            className="subtitle-payment"
                            whiteSpace="pre"
                            sx={{ width: "30%" }}
                          >
                            {t("thead_valid")}
                          </Typography>
                          <Typography>
                            :
                            {moment(voucherData?.data?.validFrom).format(
                              "DD.MM.YYYY"
                            )}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            className="subtitle-payment"
                            whiteSpace="pre"
                            sx={{ width: "30%" }}
                          >
                            {t("thead_expired")}
                          </Typography>
                          <Typography>
                            :
                            {moment(voucherData?.data?.expireDate).format(
                              "DD.MM.YYYY"
                            )}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack
                        direction="column"
                        spacing={1}
                        width="25%"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          className="subtitle-payment"
                          whiteSpace="pre"
                        >
                          {t("thead_value")}
                        </Typography>
                        <Typography>
                          {voucherData?.data?.value}
                          {voucherData?.data?.discountType === "Amount"
                            ? "$"
                            : "%"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>

          {/* {totalAmountToBePaid > 0 && ( */}
          <Grid
            className="create-container"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ padding: "0px 0px 0px 32.8px !important" }}
          >
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                fullWidth
                onClick={handleSubmit}
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                disabled={
                  checkChangeAmount() ||
                  checkConditionDisable([
                    paymentType === "NotPaid",
                    receiveAmountUSD + receiveAmountKHR > 0,
                    paymentType === "Voucher" && voucherData?.isSuccess,
                  ])
                }
                sx={{
                  bgcolor:
                    checkChangeAmount() ||
                    checkConditionDisable([
                      paymentType === "NotPaid",
                      receiveAmountUSD + receiveAmountKHR > 0,
                      paymentType === "Voucher" && voucherData?.isSuccess,
                    ])
                      ? "gray !important"
                      : "",
                }}
              >
                {t("btn-payment")}
              </Button>
            )}
          </Grid>
          {/* )} */}
        </Grid>
      </Box>
    </Stack>
  );
}
