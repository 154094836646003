import React, { useState, useContext, useEffect } from "react";
import "../../Style/actionStyle.scss";
import ViewInvoice from "./ViewInvoice";
import { VoidInvoice } from "./VoidInvoice";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
export default function TransactionAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    // if (newOpen) window.localStorage.setItem("invoiceId", editData?._id);
    // if (!newOpen) window.localStorage.setItem("invoiceId", "");
    setOpen(newOpen);
  };
  useEffect(() => {
    setRefetch();
  }, [editData]);
  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title={t("tooltip-view")} placement="top">
          <IconButton onClick={toggleDrawer(true)}>
            <Box className="view-container" >
              <RemoveRedEyeIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("tooltip-delete")} placement="top">
          <IconButton onClick={handleOpenDel} disabled={disabled}>
            <Box className="delete-container" sx={disabled && { bgcolor: "grey !important" }} >
              <RemoveCircleOutlineSharpIcon className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ViewInvoice
        open={open}
        toggleDrawer={toggleDrawer}
        invoiceId={editData?._id}
      />
      <VoidInvoice
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
