import React, { useRef } from "react";
import {
  Box,
  Stack,
  Dialog,
  Button,
  Divider,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import BarCode from "./Barcode";
import moment from "moment/moment";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";



export default function ViewBarcode({ open, handleClose, editData }) {

  const exChangeRate = JSON.parse(window.localStorage.getItem("exChangeRate"));

  const handleDownloadCard = async () => {
    const element = document.getElementById("card"),
      canvas = await html2canvas(element, {
        logging: true,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
      }),
      data = canvas.toDataURL("image/tif"),
      link = document.createElement("a");
    link.href = data;
    link.download = `card-${editData?.barcode}.tif`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //PRINT
  const compnentRef = useRef();
  const handleToPrint = useReactToPrint({
    content: () => compnentRef.current,
  });


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="view-qrcode"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography className="title">
            {/* Preview Qrcode {editData?.product_id?.name} */}
            Preview Barcode {editData?.mainProductId?.mainProductKhName}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" sx={{ color: "red" }} />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" justifyContent="center" spacing={3} className="body-container">
          <Box id="card" paddingTop="0px" paddingLeft="5px" paddingRight="5px" paddingBottom="5px" ref={compnentRef}>
            <Stack direction="row" justifyContent="center" sx={{ color: "#1d4592 !important", fontWeight: "bold", fontSize: "16px" }}>
              <NumericFormat
                displayType={'text'}
                thousandSeparator={true}
                value={(editData?.price * exChangeRate)?.toFixed(0)}
                renderText={(value, props) =>
                  <div {...props}>
                    {editData?.mainProductId?.mainProductKhName} {" "} $ {editData?.price}{" "}-{" "} ៛ {value}
                  </div>}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="center" sx={{ color: "#000", fontWeight: "bold", fontSize: "15px" }}>
              <span>
                Pack: {moment()?.format("DD-MMM-YY")}
              </span>
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="center" sx={{ color: "#000", fontWeight: "bold", fontSize: "15px" }}>
              {
                editData?.expiredDate ?
                  <span>
                    Expire: {moment(editData?.expiredDate)?.format("DD-MMM-YY")}
                  </span>
                  : null
              }
            </Stack>
            <Stack
              mt={0}
              direction="row"
              justifyContent="center"
              className="qrcode-container"
            >
              <BarCode productId={editData?.barcode} />
            </Stack>

          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" justifyContent="center" spacing={4}>
            <Button
              startIcon={<DownloadOutlinedIcon />}
              onClick={handleDownloadCard}
            >
              Download
            </Button>

            <Button
              startIcon={<DownloadOutlinedIcon />}
              onClick={handleToPrint}
            >
              Print
            </Button>
          </Stack>

        </Stack>
      </DialogContent>
    </Dialog>
  );
}
