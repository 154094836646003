import React, { useContext } from "react";
import "../../../../Style/actionStyle.scss";
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from "../../../../Context/AuthContext.js";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { translateLauguage } from "../../../../Include/Function/Translate.js";
import { SelectWarehouse } from "../../../../Include/Function/DynamicSelect.js";
import GetRemainMainProductByIdAndByWarehouse from "./GetRemainMainProductByIdAndByWarehouse.jsx";

export default function SelectTransferProducts({ transferProductsData, setTransferProductsData, required, }) {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newSubItem = { key: Math.random() + Date.now(), warehouseId: null, qty: null, remainInStock: null }

  const handleAddSubItem = (mainKey) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = [...e?.warehouseIds, newSubItem]
      return e
    })
    setTransferProductsData([...transferData]);
  }

  const handleRemoveSubItem = (mainKey, subItem) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = e?.warehouseIds.length > 1 ? e?.warehouseIds.filter(e => e.key !== subItem) : e?.warehouseIds
      return e
    })
    setTransferProductsData([...transferData]);
  }


  const handleUpdateSubItem = (mainKey, subKey, value, fieldName) => {

    const newData = transferProductsData.map((e) => { 
      if (e?.key === mainKey) {
        e?.warehouseIds.map((sub) => {

          if (sub?.key === subKey && fieldName === "qty") {

            const total = e?.warehouseIds.filter((e) => e?.key !== subKey)?.reduce((a, b) => a + (b?.qty || 0), 0) + value - e?.remainQty;
            if ((e?.remainQty > sub?.remainInStock)) sub.qty = total > 0 ? value - total : parseFloat(value || 0);

            const totalExcess = e?.warehouseIds.filter((e) => e?.key !== subKey)?.reduce((a, b) => a + (b?.qty || 0), 0) + value;
            if ((e?.remainQty < sub?.remainInStock)) sub.qty = totalExcess > e?.remainQty ? value - (totalExcess - e?.remainQty) : value;

          }

          if (sub?.key === subKey && fieldName === "warehouseId") sub.warehouseId = value;
          if (sub?.key === subKey && fieldName === "remainInStock") sub.remainInStock = value;
          if (!sub.warehouseId?.id) sub.qty = 0;

        });
      }
      return e;
    });
    setTransferProductsData([...newData]);
  }


  return (
    <Stack direction="column" width="100%" spacing={1}>

      <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="22%" >
          {t("thead_product")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="10%" >
          {t("thead_request")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="10%" >
          {t("thead_received")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="8.5%" >
          {t("thead_remaining")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="14.5%" >
          {t("thead_warehouse")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="10.5%" >
          {t("thead_qty_in_warehouse")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="17.5%" >
          {t("thead_transfer")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="7%" >
          {t("thead_total")}
        </Typography>

      </Stack>

      <Stack direction="column" width="100%" spacing={2} minHeight={300}>
        {
          transferProductsData?.map((e) => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack sx={{ width: "20%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="100%">
                    {e?.mainProductId?.title}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ width: "11%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }} spacing={0.5} >
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                    {e?.approveQty}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"}    >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ width: "11%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }} spacing={0.5} >
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                    {e?.deliveryQty}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"}    >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ width: "11%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }} spacing={0.5} >
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                    {e?.remainQty}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"}    >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>


              <Stack direction="column" alignItems="center" spacing={1} sx={{ width: "45%" }} >
                <Stack direction="row" alignItems="center" justifyContent='flex-end' spacing={1} sx={{ width: "100%" }}>
                  <IconButton onClick={() => handleAddSubItem(e?.key)}>
                    <AddIcon sx={{ color: "green" }} />
                  </IconButton>
                </Stack>
                {
                  e?.warehouseIds?.map((sub) => (
                    <Stack direction="row" alignItems="center" spacing={1.5} sx={{ width: "100%" }}>
                      <Stack sx={{ width: "40%" }}>
                        <SelectWarehouse
                          required={required}
                          dataList={e?.warehouseIds}
                          selectedVal={sub?.warehouseId}
                          setSelectedVal={(value) => handleUpdateSubItem(e.key, sub.key, value, "warehouseId")}
                        />
                      </Stack>
                      <Stack sx={{ width: "60%" }}>
                        <GetRemainMainProductByIdAndByWarehouse
                          mainData={e}
                          subData={sub}
                          required={required}
                          handleUpdateSubItem={handleUpdateSubItem}
                        />
                      </Stack>
                      <IconButton onClick={() => handleRemoveSubItem(e?.key, sub?.key)}>
                        <CloseOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Stack>
                  ))
                }

              </Stack>

              <Stack sx={{ width: "12%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }} spacing={0.5}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold"  >
                    {e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty || 0), 0)}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"}    >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
                {/* <TextField
                  fullWidth
                  size="small"
                  type="number"
                  className="text-field"
                  placeholder={t('thead_qty')}
                  value={e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty || 0), 0)}
                  InputProps={{ endAdornment: e?.mainProductId?.unit, readOnly: true }}
                /> */}
              </Stack>

            </Stack>
          )
          )
        }
      </Stack>
    </Stack>


  );
}
