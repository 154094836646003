import { useCallback, useContext, useState } from "react";
import "./backdropcloseshift.scss";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/it-logo.png";
import { useLazyQuery, useMutation } from "@apollo/client";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { AuthContext } from "../../../Context/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import {
  CLOSE_SHIFT,
  GET_ACTIVE_CASHIER,
  GET_SHIFT_REPORT,
  GO_IN_CLOSE_SHIFT,
} from "../../../Schema/Shop";
import { Box, Stack, Button, Dialog, Typography } from "@mui/material";
import { IconButtonCancelCloseShift } from "./IconButtonCancelCloseShift";
import { translateLauguage } from "../../../Include/Function/Translate";
import { LogOutCashier } from "../LogOutCashier/LogOutCashier";
import { memo } from "react";

const MainRecord = ({ title, amountUSD, amountKHR }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bold-main">{title}</td>
      <td className="report-content-cell-right-main">
        {amountUSD?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-main">
        {amountKHR?.toFixed(2)} ៛
      </td>
    </tr>
  );
};

const MainTitleRecord = ({ title }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bold-main">{title}</td>
      <td className="report-content-cell-right-main"></td>
      <td className="report-content-cell-right-main"></td>
    </tr>
  );
};

const ExpectedRecord = ({ title, amountUSD, amountKHR }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bg-main">{title}</td>
      <td className="report-content-cell-right-bg-main">
        {amountUSD?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-bg-main">
        {amountKHR?.toFixed(2)} ៛
      </td>
    </tr>
  );
};

const FinalRecord = ({ title, amountUSD, amountKHR }) => {
  return (
    <tr style={{ height: "40px" }}>
      <td className="tr-close-shift-sticky title-close-cash-remain">{title}</td>
      <td className="tr-close-shift-sticky title-close-cash-amount-remain">
        {amountUSD?.toFixed(2)} $
      </td>
      <td className="tr-close-shift-sticky title-close-cash-amount-remain">
        {amountKHR?.toFixed(2)} ៛
      </td>
    </tr>
  );
};

const SubRecord = ({ title, amountUSD, amountKHR }) => {
  return (
    <tr>
      <td className="report-content-cell-left-main">{title}</td>
      <td className="report-content-cell-right-main">
        {amountUSD?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-main">
        {amountKHR?.toFixed(2)} ៛
      </td>
    </tr>
  );
};

function BackdropCloseShift({ item, shopId, shiftID, setRefetch, outletShop }) {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [shiftPayment, setShiftPayment] = useState(null);
  const [activeCashier, setActiveCashier] = useState([]);
  const [loadingDataReport, setLoadingDataReport] = useState(false);

  const [openStopSell, setOpenStopSell] = useState(false);
  const handleOpenStopSell = () => setOpenStopSell(true);
  const handleCloseStopSell = () => setOpenStopSell(false);

  //================ Backdrop Close Shift ==================

  const [open, setOpen] = useState(false);
  const hanldeOpen = () => setOpen(true);
  const hanldeclose = () => setOpen(false);

  const [gotoCloseShift] = useMutation(GO_IN_CLOSE_SHIFT, {
    onCompleted: ({ gotoCloseShift }) => {
      if (gotoCloseShift?.isSuccess) hanldeOpen(true);
      else setRefetch();
    },
    onError: ({ message }) => {
      console.log("error:::", message);
    },
  });

  //======================= GET SHIFT PAYMENT BY ID ==========================
  const [getShiftReport] = useLazyQuery(GET_SHIFT_REPORT, {
    onCompleted: ({ getShiftReport }) => {
      console.log("getShiftReport ===>", getShiftReport);

      if (getShiftReport) setShiftPayment(getShiftReport);
    },
    onError: (error) => {
      console.log(error?.message);
      setLoadingDataReport(false);
    },
  });

  const shiftReport = useCallback(() => {
    getShiftReport({
      variables: {
        endDate: "",
        startDate: "",
        viewType: "Shift",
        shiftId: shiftID,
      },
    });
  }, []);

  const [getActiveCashier] = useLazyQuery(GET_ACTIVE_CASHIER, {
    onCompleted: ({ getActiveCashier }) => {
      if (getActiveCashier?.length === 0) {
        shiftReport();
        gotoCloseShift({ variables: { shopId: item?._id } });
      } else if (getActiveCashier?.length > 0) {
        hanldeclose();
        setActiveCashier(getActiveCashier);
        handleOpenStopSell();
      }
    },
    notifyOnNetworkStatusChange: true,
    onError: ({ message }) => {
      console.log("error  ===>", message);
      console.log(message);
    },
  });

  //================================== CLOSE FUNCTION ============================
  const [closeShift] = useMutation(CLOSE_SHIFT, {
    onCompleted: ({ closeShift }) => {
      setRefetch();
      setLoading(false);
      if (closeShift?.isSuccess) {
        setRefetch();
        setAlert(true, "success", closeShift?.message);
        hanldeclose();
        navigate(`/shop`);
        window.location.reload("/shop");
      } else {
        setAlert(true, "error", closeShift?.message);
      }
    },
    onError: ({ message }) => {
      setLoading(false);
      console.log("message ====>", message);
    },
  });

  const handleCreateCloseShift = () => {
    closeShift({
      variables: {
        id: shiftID,
        input: {
          closeRevenue: {
            usd: shiftPayment?.totalRevenue?.usd,
            khr: shiftPayment?.totalRevenue?.khr,
            amount: shiftPayment?.totalRevenue?.amount,
          },
        },
      },
    });
  };

  return (
    <>
      <Button
        className="btn-close-sell-style"
        onClick={() => {
          getActiveCashier({ variables: { shopId: item?._id } });
        }}
      >
        <Typography className={language === "kh" ? "btn-txt-kh" : "btn-txt"}>
          {t("btn-stop-selling")}
        </Typography>
      </Button>
      {activeCashier?.length > 0 && (
        <LogOutCashier
          shopId={item?._id}
          open={openStopSell}
          editData={activeCashier}
          handleClose={handleCloseStopSell}
          setOpenBackdropCloseShift={hanldeclose}
        />
      )}
      <Dialog fullScreen open={open} className="backdrop-style">
        <Stack direction="column" justifyContent="center">
          {/* =================================== Header ==================================== */}
          <Stack
            spacing={2}
            width="100%"
            direction="row"
            padding="24px 24px 0px 0px"
          >
            <Box sx={{ flexGrow: 1 }} />
            <IconButtonCancelCloseShift
              hanldeclose={hanldeclose}
              setRefetch={setRefetch}
              shopId={shopId}
            />
          </Stack>

          <Stack
            spacing={2}
            width="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Stack direction="column" alignItems="center">
              <img src={logo} alt="logo" width="10%" />
              <Typography className="system-title">
                <span style={{ textTransform: "uppercase" }}>{outletShop}</span>{" "}
                CLOSING CASH CONTROL
              </Typography>
            </Stack>

            <Stack
              width="100%"
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="detail-close-payment"
            >
              {loadingDataReport ? (
                <LoadingPage />
              ) : (
                <table className="table-close-cash">
                  <thead>
                    <tr>
                      <th
                        align="center"
                        className="tr-close-shift-top-sticky"
                        style={{ width: 600 }}
                      >
                        Method
                      </th>
                      <th
                        align="center"
                        className="tr-close-shift-top-sticky header-title-close-cash"
                      >
                        USD
                      </th>
                      <th
                        align="center"
                        className="tr-close-shift-top-sticky header-title-close-cash"
                      >
                        KHR
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <MainRecord
                      title={"Total Cash Float"}
                      amountUSD={shiftPayment?.totalCashFloat?.usd}
                      amountKHR={shiftPayment?.totalCashFloat?.khr}
                    />
                    <MainRecord
                      title={"Cash Float In"}
                      amountUSD={shiftPayment?.cashFloatIn?.usd}
                      amountKHR={shiftPayment?.cashFloatIn?.khr}
                    />

                    <MainRecord
                      title={"Cash Float Out"}
                      amountUSD={shiftPayment?.cashFloatOut?.usd}
                      amountKHR={shiftPayment?.cashFloatOut?.khr}
                    />

                    <MainRecord
                      title={"Cash Change"}
                      amountUSD={shiftPayment?.cashChange?.usd}
                      amountKHR={shiftPayment?.cashChange?.khr}
                    />

                    {shiftPayment?.guestDepositExternalPayments?.map(
                      (row, index) => (
                        <SubRecord
                          key={index}
                          amountUSD={row?.usd}
                          amountKHR={row?.khr}
                          title={row?.bankName ? row?.bankName : "other"}
                        />
                      )
                    )}

                    {/* ====================== Remain ===================== */}
                    <ExpectedRecord
                      title={"Remain Cash Float"}
                      amountUSD={
                        shiftPayment?.totalCashFloat?.usd +
                        shiftPayment?.cashFloatIn?.usd -
                        shiftPayment?.cashFloatOut?.usd -
                        shiftPayment?.cashChange?.usd
                      }
                      amountKHR={
                        shiftPayment?.totalCashFloat?.khr +
                        shiftPayment?.cashFloatIn?.khr -
                        shiftPayment?.cashFloatOut?.khr -
                        shiftPayment?.cashChange?.khr
                      }
                    />

                    {/* ================ Summary amount invoice ============ */}
                    <MainTitleRecord
                      title={`Total invoices : ${shiftPayment?.totalInvoices} invoices`}
                      amountUSD={0}
                      amountKHR={0}
                    />

                    <SubRecord
                      title={`In Cash`}
                      amountUSD={shiftPayment?.cashInCash?.usd}
                      amountKHR={shiftPayment?.cashInCash?.khr}
                    />

                    {shiftPayment?.externalPayments?.map((row, index) => (
                      <SubRecord
                        key={index}
                        title={`In Bank (${row?.bankName})`}
                        amountUSD={row?.usd}
                        amountKHR={row?.khr}
                      />
                    ))}

                    {/* ========================  Summary =================== */}
                    <ExpectedRecord
                      title={"Cash In Bank"}
                      amountUSD={shiftPayment?.cashInBank?.usd}
                      amountKHR={shiftPayment?.cashInBank?.khr}
                    />

                    <ExpectedRecord
                      title={"Cash In Safe"}
                      amountUSD={
                        shiftPayment?.totalCashFloat?.usd +
                        shiftPayment?.cashFloatIn?.usd +
                        shiftPayment?.cashInCash?.usd -
                        shiftPayment?.cashChange?.usd -
                        shiftPayment?.cashFloatOut?.usd
                      }
                      amountKHR={
                        shiftPayment?.totalCashFloat?.khr +
                        shiftPayment?.cashFloatIn?.khr +
                        shiftPayment?.cashInCash?.khr -
                        shiftPayment?.cashChange?.khr -
                        shiftPayment?.cashFloatOut?.khr
                      }
                    />

                    <ExpectedRecord
                      title={"Dr. A/R."}
                      amountUSD={
                        shiftPayment?.totalCashFloat?.usd +
                        shiftPayment?.cashInCash?.usd -
                        shiftPayment?.cashChange?.usd -
                        shiftPayment?.cashFloatOut?.usd
                      }
                      amountKHR={
                        shiftPayment?.totalCashFloat?.khr +
                        shiftPayment?.cashInCash?.khr -
                        shiftPayment?.cashChange?.khr -
                        shiftPayment?.cashFloatOut?.khr
                      }
                    />

                    <ExpectedRecord
                      title={"Cr. A/R Cash"}
                      amountUSD={shiftPayment?.crARCash?.usd}
                      amountKHR={shiftPayment?.crARCash?.khr}
                    />
                    <ExpectedRecord
                      title={"Cr. A/R Bank"}
                      amountUSD={shiftPayment?.crARBank?.usd}
                      amountKHR={shiftPayment?.crARBank?.khr}
                    />

                    <ExpectedRecord
                      title={"Guests Deposit A/P. Cash"}
                      amountUSD={shiftPayment?.guestDepositAPCash?.usd}
                      amountKHR={shiftPayment?.guestDepositAPCash?.khr}
                    />
                    <ExpectedRecord
                      title={"Guests Deposit A/P. Bank"}
                      amountUSD={shiftPayment?.guestDepositAPBank?.usd}
                      amountKHR={shiftPayment?.guestDepositAPBank?.khr}
                    />

                    {/* ========================== Final ====================== */}
                    <FinalRecord
                      amountUSD={shiftPayment?.totalRevenue?.usd}
                      amountKHR={shiftPayment?.totalRevenue?.khr}
                      title={`Total Revenue (${shiftPayment?.totalInvoices} invoices)`}
                    />
                  </tbody>
                </table>
              )}
            </Stack>
            <Stack
              mt={3}
              spacing={3}
              width="100%"
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {loading ? (
                <Button
                  className="btn-create-title"
                  endIcon={
                    <CircularProgress sx={{ color: "white" }} size="25px" />
                  }
                >
                  Close shift .......
                </Button>
              ) : (
                <Button
                  className="btn-create-title"
                  endIcon={<VscGitPullRequestCreate />}
                  disabled={
                    shiftPayment === null || loadingDataReport ? true : false
                  }
                  onClick={() => {
                    handleCreateCloseShift();
                  }}
                >
                  Close shift
                </Button>
              )}
              <Typography sx={{ color: "#1d4592" }}>
                Power By: GO GLOBAL IT
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
export default memo(BackdropCloseShift);
