import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  Button,
  Select,
  TableRow,
  TableCell,
  MenuItem,
  TextField,
  TableHead,
  TableBody,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import TransferAction from "./TransferAction";
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
import { TransferProduct } from "./TransferProduct";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
import { GET_TRANSFER_BACK_PAGINATION } from "../../../Schema/InventoryInWarehouse";

export const Transfer = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState();
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_TRANSFER_BACK_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      status: status,
      pagination: true,
      transferNo: parseFloat(keyword),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getTransferBackPagination }) => {
      setLoading(false);
      setTableData(getTransferBackPagination?.data);
      setPaginationData(getTransferBackPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const translateStatus = (status) => {
    if (status === "Transfer") return t("thead_transfer");
    if (status === "Received") return t("thead_incoming");
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`label-search`)}
          </Typography>
          <TextField
            size="small"
            type="number"
            className="search-field"
            placeholder={t(`label-search`)}
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1, color: "black" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t(`thead_transaction`)}
          </Typography>
          <FormControl fullWidth size="small" className="search-field">
            <Select
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            >
              <MenuItem value={"All"}>{t(`thead_all`)}</MenuItem>
              <MenuItem value={"Transfer"}> {t(`thead_transfer`)} </MenuItem>
              <MenuItem value={"Received"}> {t(`thead_received`)} </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_start_date")}
          </Typography>
          <SelectDatePicker
            date={startDate}
            className={"search-field"}
            setDate={(e) => setStartDate(e)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <Typography
            className={language === "kh" ? "header-text-kh" : "header-text"}
          >
            {t("thead_end_date")}
          </Typography>
          <SelectDatePicker
            date={endDate}
            className={"search-field"}
            setDate={(e) => setEndDate(e)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={8} lg={12} xl={4}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              className="btn-create-style"
              startIcon={
                <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
              }
            >
              <Typography
                className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
              >
                {t("btn-transfer")}
              </Typography>
            </Button>
            <TransferProduct open={open} handleClose={handleClose} />
          </Stack>
        </Grid>
      </Grid>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="10%"
                >
                  {" "}
                  {t("thead_no")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_date")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_shop")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("thead_transaction")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="16%"
                >
                  {" "}
                  {t("remark")}{" "}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                            >
                              {" "}
                              {row?.transferNo}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "MM-DD-YYYY hh:mm a"
                              )}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {language === "en"
                                ? row?.shopId?.shopNameEn
                                : row?.shopId?.shopNameKh}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {translateStatus(row?.status)}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.transferBy?.remark}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <TransferAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.status === "Received"}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </>
  );
};
