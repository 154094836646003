import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";

//Icons
import CreateIcon from "@mui/icons-material/Create";
import { RiDeleteBin5Fill } from "react-icons/ri";

//Srcs
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { UnitForm } from "./UnitForm";
import { DeleteUnit } from "./DeleteUnit";

export default function UnitAction({ editData, setRefetch }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal update
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title={t("tooltip-update")} placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <CreateIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip-delete")} placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <RiDeleteBin5Fill className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <UnitForm
        open={openEdit}
        editData={editData}
        dialogTitle="Update"
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />
      <DeleteUnit
        open={openDel}
        setRefetch={setRefetch}
        editData={editData?._id}
        handleClose={handleCloseDel}
      />
    </div>
  );
}
