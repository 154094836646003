import React, { useContext, useState, useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import "./dialogpayment.scss";
import Payment from "./Payment/Payment";
import Invoice from "./Invoice/Invoice";
import ReactToPrint from "react-to-print";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import { PiPrinterFill } from "react-icons/pi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InvoiceForPrint from "./Invoice/InvoiceForPrint";
import RecentPayment from "./RecentPayment/RecentPayment";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import useGetPaymentByInvoices from "../../../Hooks/POS/useGetPaymentByInvoices";
import MuiltiplePayment from "./MuiltiplePayment/MuiltiplePayment";

export default function DialogMuiltiplePayment({
  open,
  handleClose,
  invoiceIDSelected,
}) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  // =========================== print invoice ================================
  const componentRef = useRef(null);
  const reactToPrintContent = () => {
    return componentRef.current;
  };

  //===================== Get Invoice ID ====================
  const invoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));

  const { paymentByInvoiceData, refetchPayment } = useGetPaymentByInvoices([
    ...invoiceIDSelected,
  ]);

  //============== Remain Pay Amount ================

  const reactToPrintTrigger = () => {
    return (
      <Button
        className={language === "kh" ? "btn-create-kh" : "btn-create"}
        fullWidth
        startIcon={<PiPrinterFill />}
      >
        Print
      </Button>
    );
  };

  // =========================== end print invoice ================================

  return (
    <Dialog open={open} className="dialog-payment-invoice" fullWidth fullScreen>
      <DialogTitle sx={{ padding: "2.5px 15px 2.5px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_payment")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6.5} xl={7}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <MuiltiplePayment
                  muiltipleInvoiceId={invoiceIDSelected}
                  paymentByInvoiceData={paymentByInvoiceData}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <RecentPayment
                  refetchPayment={refetchPayment}
                  paymentByInvoiceData={paymentByInvoiceData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5.5} xl={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  width="100%"
                  direction="column"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  className="create-container"
                  sx={{ padding: "0px !important" }}
                >
                  <Box width={120}>
                    <ReactToPrint
                      removeAfterPrint
                      documentTitle={"invoice"}
                      content={reactToPrintContent}
                      trigger={reactToPrintTrigger}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Invoice
                  componentRef={componentRef}
                  muiltipleInvoiceId={invoiceIDSelected}
                  paymentByInvoiceData={paymentByInvoiceData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
