import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { SelectMainProduct, SelectSubProduct } from "../../../Include/Function/DynamicSelect";
export default function SelectPurchaseProducts({ purchaseProductsData, setPurchaseProductsData, required, }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newItem = {
    qty: null,
    unitPrice: null,
    subProductId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }

  const handleAddItem = () => setPurchaseProductsData([...purchaseProductsData, newItem]);

  const handleRemoveItem = (key) => {
    const newData = purchaseProductsData.filter((e) => e.key !== key);
    setPurchaseProductsData([...newData]);
  }

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = purchaseProductsData.map((e) => {
      if (e.key === key && fieldName === "qty") e.qty = value;
      if (e.key === key && fieldName === "unitPrice") e.unitPrice = value;
      if (e.key === key && fieldName === "subProductId") e.subProductId = value;
      if (e.key === key && fieldName === "mainProductId") {
        e.mainProductId = value;
        e.subProductId = { id: "", title: "" };
      }
      return e;
    })
    setPurchaseProductsData([...newData]);
  }


  return (
    <Stack direction="column" width="100%" spacing={1}>

      <Stack direction="row" alignItems="center" width="100%" spacing={2}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_product")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_qty")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_unit")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_price")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_total_amount")}
        </Typography>

        <IconButton onClick={handleAddItem}  >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" width="100%" spacing={2} minHeight={300}>
        {
          purchaseProductsData?.map((e) =>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack sx={{ width: "25%" }}>
                <SelectMainProduct
                  required={required}
                  selectedVal={e?.mainProductId}
                  dataList={purchaseProductsData}
                  setSelectedVal={(value) => handleUpdateItem(e.key, value, "mainProductId")}
                />
              </Stack>

              <TextField
                sx={{ width: "25%" }}
                fullWidth
                size="small"
                type="number"
                value={e?.qty}
                className="text-field"
                error={!e?.qty && required}
                placeholder={t('thead_qty')}
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "qty")}
              />

              <Stack sx={{ width: "25%" }}>
                <SelectSubProduct
                  required={required}
                  selectedVal={e?.subProductId || { id: "", title: "" }}
                  mainProductId={e?.mainProductId?.id}
                  setSelectedVal={(value) => handleUpdateItem(e.key, value, "subProductId")}
                />
              </Stack>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={e?.unitPrice}
                sx={{ width: "25%" }}
                className="text-field"
                placeholder={t('thead_price')}
                error={!e?.unitPrice && required}
                InputProps={{ endAdornment: "$" }}
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "unitPrice")}
              />

              <TextField
                fullWidth
                size="small"
                type="number"
                sx={{ width: "25%" }}
                className="text-field"
                value={e?.unitPrice * e?.qty || ""}
                placeholder={t('thead_total_amount')}
                InputProps={{ endAdornment: "$", readOnly: true }}
              />
              <IconButton onClick={() => handleRemoveItem(e?.key)}>
                <CloseOutlinedIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>
          )
        }

      </Stack>
    </Stack>


  );
}
