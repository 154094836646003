import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
//Icon
import CreateIcon from "../../../Assets/add.png";
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../../../Style/pageStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { GET_CATEGORY_WITH_PAGINATION } from "../../../Schema/Category";
import CategoryData from "../../../Components/Setting/Category/CategoryData";
import { CategoryForm } from "../../../Components/Setting/Category/CategoryForm";
import { useQuery } from "@apollo/client";

export const Category = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();

  //
  const { refetch } = useQuery(GET_CATEGORY_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      pagination: true,
      keyword: keyword,
    },
    onCompleted: ({ getCategoryWithPagination }) => {
      setLoading(false);
      setTableData(getCategoryWithPagination?.data);
      setPaginationData(getCategoryWithPagination?.paginator); 
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [page, limit, keyword]);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography
              className={language === "kh" ? "page-title-kh" : "page-title"}
            >
              {t(`page-setting`)}
            </Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`category`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              size="small"
              className="search-field"
              placeholder={t(`label-search`)}
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
            <CategoryForm
              open={openCreate}
              dialogTitle="Create"
              setRefetch={refetch}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </Box>
      <CategoryData
        handleLimit={handleLimit}
        page={page}
        loading={loading}
        limit={limit}
        keyword={keyword}
        setPage={setPage}
        setLimit={setLimit}
        setRefetch={refetch}
        tableData={tableData}
        paginationData={paginationData}
      />
    </div>
  );
};
