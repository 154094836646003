import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Badge,
  Stack,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

// src
import "./ticketpos.scss";

import TicketPosFront from "./TicketPosFront";

export default function TicketSelect({handleBackTicket}) {
  const navigate = useNavigate();

  return (
    <div className="ticket-pos-container">
      <Box sx={{ marginTop: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={2} sx={{ cursor: "pointer" }}>
            <Stack direction="row" justifyContent="center">
              <Button className="ticket-button" onClick={handleBackTicket}>
                <IoChevronBackOutline size={30} sx={{ color: "#1d4592" }} />
              </Button>
            </Stack>
          </Grid>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]?.map((row, index) => {
            return (
              <Grid item xs={2} key={index} sx={{ cursor: "pointer" }}>
                <Stack direction="row" justifyContent="center">
                  <Button className="ticket-button"></Button>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
