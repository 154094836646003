import React, { useContext } from "react";
import "../../../../Style/actionStyle.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import EmptyData from "../../../../Include/EmptyData/EmptyData";
import { Stack, TextField, Typography, Divider } from "@mui/material";
import { translateLauguage } from "../../../../Include/Function/Translate";
export default function SelectReceiveProduct({ purchaseProductsData, setPurchaseProductsData, required, }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = purchaseProductsData.map((e) => {
      if (e.key === key && fieldName === "takeQty") e.takeQty = value > e?.deliverQty ? e?.deliverQty : value;
      return e;
    })
    setPurchaseProductsData([...newData]);
  } 

  return (

    <Stack width="100%" direction="column" spacing={2} mt={2} >

      <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
          {t("thead_product")}
        </Typography>


        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="30%">
          {t("thead_delivery")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="30%">
          {t("thead_receive")}
        </Typography>
      </Stack>

      <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
        <Divider />
        {
          purchaseProductsData?.length === 0 ? <EmptyData /> :
            purchaseProductsData?.map((e, key) => (
              <Stack width="100%" direction="column" spacing={1.7} key={key}>
                <Stack width="100%" direction="row" alignItems="center" sx={{ paddingX: 2 }}>
                  <Typography width="40%" > {e?.mainProductId?.title} </Typography>
                  <Typography width="30%" > {e?.deliverQty} {e?.mainProductId?.unit}  </Typography>
                  <TextField
                    sx={{ width: "30%" }}
                    fullWidth
                    size="small"
                    type="number"
                    value={e?.takeQty}
                    className="text-field"
                    error={!e?.takeQty && required}
                    placeholder={t('thead_qty')}
                    InputProps={{ endAdornment: e?.mainProductId?.unit }}
                    onChange={({ target }) => handleUpdateItem(e.key, target.value < 0 ? 0 : target.value, "takeQty")}
                  />
                </Stack>
                <Divider />
              </Stack>
            ))
        }
      </Stack>
    </Stack>


  );
}
