import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { GET_PRODUCT_WITH_PAGINATION } from '../../../../Schema/POS';

const useGetProductForScan = (shopId, handleSelectProductItem) => {

    const [productBarcode, setProductBarcode] = useState("");
    const [productForScan, setProductForScan] = useState([]);


    // ==================== Get All Product For Scan ================
    const { refetch: refetchProduct } = useQuery(GET_PRODUCT_WITH_PAGINATION, {
        variables: {
            page: 1,
            keyword: "",
            limit: 10,
            shopId: shopId,
            categoryId: null,
            pagination: false,
        },
        onCompleted: ({ getProductPagination }) => {
            setProductForScan(getProductPagination?.data || []);
        },
    });



    //=================== Function  Scan Product ===========

    const handleScanProduct = () => {
        if (
            productForScan?.filter((e) => e?.barcode === productBarcode)?.length > 0
        ) {
            let getItemScan = productForScan?.filter(
                (e) => e?.barcode === productBarcode
            );
            handleSelectProductItem(...getItemScan);
            setProductBarcode("");
        }
    };



    useEffect(() => {
        refetchProduct();
    }, []);


    useEffect(() => {
        if (productBarcode) handleScanProduct();
    }, [productBarcode]);

    return {
        productBarcode, setProductBarcode
    }
}

export default useGetProductForScan
