import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
export default function SelectDatePicker({
  date,
  views,
  setDate,
  disabled,
  className,
}) {
  return (
    <Stack className={"page-container"}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          slotProps={{
            textField: {
              sx: {
                "& .MuiInputBase-input": {
                  padding: "8.5px 14px",
                },
              },
            },
          }}
          views={views}
          onChange={setDate}
          disabled={disabled}
          className={className}
          value={date ? new Date(date) : null}
        />
      </LocalizationProvider>
    </Stack>
  );
}
