import React, { useContext } from "react";
import "./itemproductforsale.scss";
import EmptyImage from "../../../../Assets/emptyImage.png";
import { AuthContext } from "../../../../Context/AuthContext";
import { currencyFormat } from "../../../../Include/Function/DynamicFn";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { Card, Stack, Button, CardMedia, Typography } from "@mui/material";

export default function ItemProductPOS({ handleSelectProductItem, row }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const LongTextComponent = ({ text }) => {
    return (
      <div
        style={{
          width: "100%",
          height: "3em", // Adjust height based on line-height
          overflow: "hidden",
          WebkitLineClamp: 2, // Limits to 2 lines
          lineHeight: "1.5em", // Set line height for proper spacing
          whiteSpace: "normal", // Ensures the text wraps
          display: "-webkit-box",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </div>
    );
  };

  const LongTextCodeComponent = ({ text }) => {
    return (
      <div
        style={{
          width: "100%",
          // height: '3em', // Adjust height based on line-height
          overflow: "hidden",
          WebkitLineClamp: 2, // Limits to 2 lines
          lineHeight: "1.5em", // Set line height for proper spacing
          whiteSpace: "pre", // Ensures the text wraps
          display: "-webkit-box",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </div>
    );
  };

  return (
    <Button
      className="card-product-for_sale"
      onClick={() => handleSelectProductItem(row)}
    >
      <Stack
        spacing={1}
        direction="column"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Card sx={{ boxShadow: "none", width: "70%" }}>
          <CardMedia
            component="img"
            sx={{ height: "65%" }}
            image={row?.imageSrc ? row?.imageSrc : EmptyImage}
            title={
              language === "en"
                ? row?.mainProductId?.mainProductEnName
                : row?.mainProductId?.mainProductKhName
            }
          />
        </Card>

        <Stack spacing={0.5} direction="column" sx={{ width: "90%" }}>
          <Typography className="product-name">
            <LongTextComponent
              text={
                language === "en"
                  ? row?.mainProductId?.mainProductEnName
                  : row?.mainProductId?.mainProductKhName
              }
            />
          </Typography>
          <Typography className="product-code-number">
            <LongTextCodeComponent text={`Code: ${row?.barcode}`} />
          </Typography>
          <Typography className="product-price">
            {currencyFormat(row?.salePrice)}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
}
