import { gql } from "@apollo/client";

export const CREATE_PURCHASE_WITH_OUT_CHECK = gql`
  mutation CreatePurchaseWithOutCheck($input: PurchaseInput) {
    createPurchaseWithOutCheck(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const RECEIVE_TRANSFER_FROM_POS = gql`
  mutation ReceiveTransferFromPos($id: ID, $input: TransferBackInput) {
    receiveTransferFromPos(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const RECEIVE_PURCHASE = gql`
  mutation ReceivePurchase(
    $id: ID!
    $remark: String
    $warehouseId: ID
    $receiveProducts: [ReceiveProductInput]
  ) {
    receivePurchase(
      _id: $id
      remark: $remark
      warehouseId: $warehouseId
      receiveProducts: $receiveProducts
    ) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const VOID_PURCHASE = gql`
  mutation VoidPurchase($id: ID!, $remark: String) {
    voidPurchase(_id: $id, remark: $remark) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CREATE_MORE_ADJUST_IN = gql`
  mutation CreateMoreAdjustIn($input: AdjustInInput) {
    createMoreAdjustIn(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_REMAIN_MAIN_PRODUCT_BY_ID_AND_BY_WAREHOUSE = gql`
  query Query($warehouseId: ID, $mainProductId: ID) {
    getRemainMainProductByIdAndByWarehouse(
      warehouseId: $warehouseId
      mainProductId: $mainProductId
    )
  }
`;

export const CREATE_TRANSFER = gql`
  mutation CreateTransfer($input: TransferInput) {
    createTransfer(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CREATE_RECEIVE_ORDER = gql`
  mutation CreateReceiveOrder($input: ReceiveOrderInput) {
    createReceiveOrder(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_COUNT_REQUEST_STATUS_BADGE = gql`
  query GetCountRequestStatusBadge($shopId: ID) {
    getCountRequestStatusBadge(shopId: $shopId) {
      pending
      approved
      inTransit
    }
  }
`;

export const GET_RECEIVE_ORDER_HISTORY_BY_PURCHASE = gql`
  query GetReceiveOrderHistoryByPurchase($purchaseId: ID) {
    getReceiveOrderHistoryByPurchase(purchaseId: $purchaseId) {
      _id
      receiveProducts {
        productId {
          mainProductId {
            mainProductEnName
            mainProductKhName
            _id
          }
          unitId {
            _id
            unitKhName
            unitEnName
          }
        }
        qty
        remain
        unitPrice
        warehouseIds {
          expireDate
          qty
          warehouseId {
            warehouseKhName
            warehouseEnName
            _id
          }
        }
      }
      receiveBy {
        latinName
        khmerName
        _id
      }
      remark
      createdAt
    }
  }
`;

export const GET_REMAIN_PRODUCT_FOR_RECEIVE = gql`
  query GetRemainProductForReceive($purchaseId: ID) {
    getRemainProductForReceive(purchaseId: $purchaseId) {
      productId {
        _id
        qtyInMinimumUnit
        unitId {
          unitEnName
          unitKhName
          _id
        }
        mainProductId {
          _id
          mainProductKhName
          mainProductEnName
          minimumUnitId {
            _id
            unitKhName
            unitEnName
          }
        }
      }
      qty
      remain
      unitPrice
    }
  }
`;

export const GET_COUNT_TRANSFER_BACK_BADGE = gql`
  query Query {
    getCountTransferBackBadge
  }
`;
export const CHECK_REQUEST = gql`
  mutation CheckRequest($id: ID, $input: CheckRequestInput) {
    checkRequest(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_INVENTORY_REQUEST_PAGINATION = gql`
  query GetInventoryRequestPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $shopId: ID
    $requestNo: Int
    $priority: PurchasePriorityEnum
    $transitStatus: RequestStatusEnum
    $isVoid: Boolean
  ) {
    getInventoryRequestPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      shopId: $shopId
      requestNo: $requestNo
      priority: $priority
      transitStatus: $transitStatus
      isVoid: $isVoid
    ) {
      data {
        _id
        shopId {
          _id
          shopNameEn
          shopNameKh
        }
        requestNo
        needDate
        requestMainProducts {
          qty
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
        }
        approveMainProducts {
          deliveryQty
          receiveQty
          qty
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
        }
        priority
        transitStatus
        isVoid
        requestBy {
          userId {
            _id
            latinName
            khmerName
          }
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_TRANSFER_BACK_PAGINATION = gql`
  query GetTransferBackPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $shopId: ID
    $transferNo: Int
    $toDate: Date
    $fromDate: Date
    $status: TransferStatusEnum
  ) {
    getTransferBackPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      shopId: $shopId
      transferNo: $transferNo
      toDate: $toDate
      fromDate: $fromDate
      status: $status
    ) {
      data {
        _id
        transferNo
        shopId {
          shopNameKh
          shopNameEn
        }
        transferProducts {
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
          transferQty
          warehouseIds {
            warehouseId {
              warehouseKhName
              warehouseEnName
              _id
            }
            qty
          }
          receiveQty
        }
        status
        transferBy {
          userId {
            latinName
            khmerName
            _id
          }
          remark
        }
        createdAt
        receiveBy {
          userId {
            latinName
            khmerName
            _id
          }
          remark
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  } 
`;

export const GET_TRANSFER_PAGINATION = gql`
  query GetTransferPagination(
    $toDate: Date
    $fromDate: Date
    $status: TransferStatusEnum
    $transferNo: Int
    $pagination: Boolean
    $limit: Int
    $page: Int
    $type: TransferTypeEnum
  ) {
    getTransferPagination(
      toDate: $toDate
      fromDate: $fromDate
      status: $status
      transferNo: $transferNo
      pagination: $pagination
      limit: $limit
      page: $page
      type: $type
    ) {
      data {
        _id
        transferNo
        shopId {
          shopNameEn
          shopNameKh
        }
        transitStatus
        transferProducts {
          mainProductId {
            _id
            mainProductEnName
            mainProductKhName
            minimumUnitId {
              _id
              unitEnName
              unitKhName
            }
          }
          warehouseIds {
            warehouseId {
              warehouseKhName
              warehouseEnName
            }
            qty
          }
        }
        status
        createdAt
        transferBy {
          remark
          createdAt
          userId {
            _id
            khmerName
            latinName
          }
        }
        receiveBy {
          remark
          userId {
            _id
            khmerName
            latinName
          }
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
export const CREATE_ADJUST_OUT = gql`
  mutation CreateAdjustOut($input: AdjustOutInput) {
    createAdjustOut(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_MAIN_STOCK_PRODUCT_FOR_ADJUST_OUT = gql`
  query GetMainStockProductForAdjustOut($mainProductId: ID, $warehouseId: ID) {
    getMainStockProductForAdjustOut(
      mainProductId: $mainProductId
      warehouseId: $warehouseId
    ) {
      _id
      unitPrice
      qty
      remain
      expireDate
      createdAt
      action
      mainProductId {
        _id
        mainProductKhName
        mainProductEnName
        minimumUnitId {
          unitEnName
          unitKhName
        }
      }
    }
  }
`;

export const GET_ADJUST_IN_PAGINATION = gql`
  query GetAdjustInPagination(
    $page: Int
    $limit: Int
    $reason: AdjustInReasonEnum
    $warehouseId: ID
    $startDate: Date
    $endDate: Date
    $pagination: Boolean
    $adjustInNo: Int
  ) {
    getAdjustInPagination(
      page: $page
      limit: $limit
      reason: $reason
      warehouseId: $warehouseId
      startDate: $startDate
      endDate: $endDate
      pagination: $pagination
      adjustInNo: $adjustInNo
    ) {
      data {
        _id
        warehouseId {
          warehouseKhName
          warehouseEnName
          _id
        }
        adjustInProducts {
          productId {
            _id
            imageSrc
            mainProductId {
              _id
              mainProductKhName
              mainProductEnName
            }
            price
            qtyInMinimumUnit
            salePrice
            unitId {
              _id
              unitKhName
              unitEnName
            }
          }
          unitPrice
          qty
          expireDate
        }
        reason
        remark
        createdBy {
          khmerName
          latinName
          _id
        }
        createdAt
        adjustInNo
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
export const GET_PURCHASE_WITH_PAGINATION = gql`
  query GetPurchasePagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $purchaseNo: Int
    $priority: PurchasePriorityEnum
    $status: PurchaseStatusEnum
    $isVoid: Boolean
  ) {
    getPurchasePagination(
      page: $page
      limit: $limit
      pagination: $pagination
      purchaseNo: $purchaseNo
      priority: $priority
      status: $status
      isVoid: $isVoid
    ) {
      data {
        _id
        isVoid
        needDate
        priority
        # purchaseDate
        purchaseNo
        status
        purchaseProducts {
          productId {
            _id
            imageSrc
            mainProductId {
              _id
              mainProductKhName
              mainProductEnName
            }
            price
            qtyInMinimumUnit
            salePrice
            unitId {
              _id
              unitKhName
              unitEnName
            }
          }
          unitPrice
          qty
        }
        requestBy {
          userId {
            latinName
            khmerName
            _id
          }
        }
        supplierId {
          _id
          name
        }
        purchaseBy {
          userId {
            _id
            khmerName
            latinName
          }
        }
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_ADJUST_OUT_PAGINATION = gql`
  query GetAdjustOutPagination(
    $page: Int
    $limit: Int
    $warehouseId: ID
    $reason: AdjustOutReasonEnum
    $startDate: Date
    $endDate: Date
  ) {
    getAdjustOutPagination(
      page: $page
      limit: $limit
      warehouseId: $warehouseId
      reason: $reason
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        _id
        warehouseId {
          _id
          warehouseEnName
          warehouseKhName
        }
        reason
        adjustOutStockIds {
          adjustFrom
          adjustTo
          mainStockId {
            mainProductId {
              mainProductEnName
              mainProductKhName
              minimumUnitId {
                unitEnName
                unitKhName
              }
            }
            expireDate
            unitPrice
          }
        }
        remark
        createdBy {
          _id
          khmerName
          latinName
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_MAIN_STOCK_PAFINATION = gql`
  query GetMainStockPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $warehouseId: ID
    $mainProductId: ID
    $categoryId: ID
    $isHaveStock: Boolean
  ) {
    getMainStockPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      warehouseId: $warehouseId
      mainProductId: $mainProductId
      categoryId: $categoryId
      isHaveStock: $isHaveStock
    ) {
      data {
        _id
        mainProductKhName
        mainProductEnName
        categoryIds {
          _id
          categoryNameEn
          categoryNameKh
        }

        minimumUnitId {
          _id
          unitEnName
          unitKhName
        }
        isUse
        remark
        remain
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_MAIN_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION = gql`
  query GetMainStockDetailByMainProductPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $warehouseId: ID
    $mainProductId: ID
    $action: MainStockActionEnum
  ) {
    getMainStockDetailByMainProductPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      warehouseId: $warehouseId
      mainProductId: $mainProductId
      action: $action
    ) {
      data {
        _id
        action
        mainProductId {
          _id
          mainProductKhName
          mainProductEnName
          minimumUnitId {
            _id
            unitKhName
            unitEnName
          }
        }
        unitPrice
        qty
        remain
        expireDate
        createdAt
        warehouseId {
          _id
          warehouseKhName
          warehouseEnName
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_POS_ADJUST_IN_PAGINATION = gql`
  query GetPosAdjustInPagination(
    $endDate: Date
    $startDate: Date
    $warehouseId: ID
    $reason: AdjustInReasonEnum
    $adjustInNo: Int
    $pagination: Boolean
    $limit: Int
    $page: Int
  ) {
    getPosAdjustInPagination(
      endDate: $endDate
      startDate: $startDate
      warehouseId: $warehouseId
      reason: $reason
      adjustInNo: $adjustInNo
      pagination: $pagination
      limit: $limit
      page: $page
    ) {
      data {
        _id
        warehouseId {
          _id
          warehouseEnName
          warehouseKhName
        }
        adjustInNo
        adjustInProducts {
          productId {
            _id
            remark
            mainProductId {
              _id
              mainProductKhName
              mainProductEnName
            }
            unitId {
              unitEnName
              unitKhName
            }
          }
          unitPrice
          qty
          expireDate
        }
        reason
        remark
        createdBy {
          _id
          khmerName
          latinName
        }
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
export const CREATE_POS_ADJUST_IN = gql`
  mutation CreatePosAdjustIn($input: AdjustInInput) {
    createPosAdjustIn(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
