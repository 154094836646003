import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormHelperText,
} from "@mui/material";  
//Srcs 
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import { SelectUnit } from "../../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../../Include/Function/Translate"; 
import { UPDATE_SPACIAL_FIELD_MAINT_PRODUCT } from "../../../Schema/Product";
import { SiTicktick } from "react-icons/si";


export const FormEditFieldUnitMainProduct = ({editData , values ,setRefetch, setFieldSpecialUpdate}) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //
  const [selectedValue,setSelectedValue] = useState({ id: "" , title: ""});
  
  useEffect( () => {
    setSelectedValue(values);
  },[values])

  //================ Mutation Update =================
  const [updateMainProductSpecialField] = useMutation(UPDATE_SPACIAL_FIELD_MAINT_PRODUCT,{
    onCompleted: ({updateMainProductSpecialField}) => {  
      if(updateMainProductSpecialField?.isSuccess) {
        setRefetch();
        setFieldSpecialUpdate("");
        setAlert(true, "success" , updateMainProductSpecialField?.message)
      } else {
        setAlert(true, "error" ,  updateMainProductSpecialField?.message)
        setFieldSpecialUpdate("");
      } 
    },
    onError: (error) => { 
      setAlert(true, "error" , error.message)
      setFieldSpecialUpdate("");
    }
  })

  const handleSubmitUpdate = () => {
    updateMainProductSpecialField({
      variables: {
        id: editData?._id,
        input: {
          minimumUnitId: selectedValue?.id,
        }
      }
    })
  }

  return(
    <>
      <Stack direction="row" spacing={2}>
        <Typography
          className={
            language === "en" ? "field-title" : "field-title-kh"
          }
        >
          {t("unit")}
        </Typography> 
         
        <IconButton 
          disabled={selectedValue?.id ? false : true}
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            '&:hover': {
              backgroundColor: "transparent",
            }, 
          }}
          onClick={() => {  
            handleSubmitUpdate();
          }}
        >
          <SiTicktick style={{ color: "green", fontSize: 18 }} />
        </IconButton> 
      </Stack>

      <SelectUnit 
        selectedVal={selectedValue} 
        setSelectedVal={setSelectedValue} 
      /> 
    </>
  )
}
 
