import React, { useContext } from "react";
import "../../../../Style/actionStyle.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import { Divider, Stack, TextField, Typography } from "@mui/material";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { SelectMainProduct } from "../../../../Include/Function/DynamicSelect";
import EmptyData from "../../../../Include/EmptyData/EmptyData";

export default function SelectRequestProducts({ purchaseProductsData, setPurchaseProductsData, required, }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = purchaseProductsData.map((e) => {
      if (e.key === key && fieldName === "qtyApprove") e.qtyApprove = value;
      return e;
    })
    setPurchaseProductsData([...newData]);
  }


  return (

    <Stack width="100%" direction="column" spacing={2} mt={2}  >
      <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33%" fontWeight="bold" >  {t('thead_product')} </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33%" fontWeight="bold" >  {t('thead_request')} </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33%" fontWeight="bold" >  {t('thead_send')} </Typography>
      </Stack>
      <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
        <Divider />
        {
          purchaseProductsData === 0 ? <EmptyData /> :
            purchaseProductsData?.map((e) => (
              <Stack width="100%" direction="column" spacing={1.7}>
                <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ paddingX: 2 }}>
                  <Typography width="33%" > {e?.mainProductId?.title} </Typography>
                  <Typography width="33%" > {e?.qty} {e?.mainProductId?.unit} </Typography>
                  <TextField
                    sx={{ width: "33%" }}
                    fullWidth
                    size="small"
                    type="number"
                    value={e?.qtyApprove}
                    className="text-field"
                    error={!e?.qtyApprove && required}
                    placeholder={t('thead_qty')}
                    InputProps={{ endAdornment: e?.mainProductId?.unit }}
                    onChange={({ target }) => handleUpdateItem(e.key, target.value > e?.qty ? e?.qty : target.value, "qtyApprove")}
                  />
                  {/* <Typography width="33%" >  {e?.qty} {language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName}  </Typography> */}
                </Stack>
                <Divider />
              </Stack>
            ))
        }
      </Stack>
    </Stack>
    // <Stack direction="column" width="100%" spacing={1}>

    //   <Stack direction="row" alignItems="center" width="100%" spacing={2}>
    //     <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="33%">
    //       {t("thead_product")}
    //     </Typography>
    //     <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="33%">
    //       {t("thead_request")}
    //     </Typography>
    //     <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="33%">
    //       {t("thead_send")}
    //     </Typography>
    //   </Stack>
    //   <Stack direction="column" width="100%" spacing={2} minHeight={300}>
    //     {
    //       purchaseProductsData?.map((e) =>
    //         <Stack direction="row" alignItems="center" spacing={1}>
    //           <Stack sx={{ width: "33%" }}>
    //             <SelectMainProduct
    //               disabled={true}
    //               required={required}
    //               selectedVal={e?.mainProductId}
    //               dataList={purchaseProductsData}
    //               setSelectedVal={(value) => handleUpdateItem(e.key, value, "mainProductId")}
    //             />
    //           </Stack>

    //           <TextField
    //             sx={{ width: "33%" }}
    //             fullWidth
    //             size="small"
    //             type="number"
    //             value={e?.qty}
    //             className="text-field"
    //             error={!e?.qty && required}
    //             placeholder={t('thead_qty')}
    //             InputProps={{ endAdornment: e?.mainProductId?.unit, readOnly: true }}
    //           />

    //           <TextField
    //             sx={{ width: "33%" }}
    //             fullWidth
    //             size="small"
    //             type="number"
    //             value={e?.qtyApprove}
    //             className="text-field"
    //             error={!e?.qtyApprove && required}
    //             placeholder={t('thead_qty')}
    //             InputProps={{ endAdornment: e?.mainProductId?.unit }}
    //             onChange={({ target }) => handleUpdateItem(e.key, target.value > e?.qty ? e?.qty : target.value, "qtyApprove")}
    //           />
    //         </Stack>
    //       )
    //     }

    //   </Stack>
    // </Stack>


  );
}
