import React, { useContext, useState, useEffect } from "react";
import {
  Tab,
  Box,
  Menu,
  Tabs,
  Stack,
  MenuItem,
  Typography,
} from "@mui/material";
import "./allcategory.scss";
import { useQuery } from "@apollo/client";
import { NestedMenuItem } from "mui-nested-menu";
import { tabsClasses } from "@mui/material/Tabs";
import { AuthContext } from "../../../Context/AuthContext";
import { GET_CATEGORY_BY_SHOP } from "../../../Schema/Category";
import { translateLauguage } from "../../../Include/Function/Translate";

const StyleMenuSubCagetory = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    "& .MuiAvatar-root": {
      width: 40,
      height: 32,
      ml: 1,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export default function AllCategory({ categoryId, setCategoryId }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [categoryData, setCategoryData] = useState([]);
  const [categoryActive, setCategoryActive] = useState("All");

  const handleChange = (event, newValue) => {
    setCategoryId(newValue);
    setCategoryActive(newValue);
  };

  //=================== open sub category ==================
  const [subCategory, setSubCategory] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickSubCategory = (event, subValue) => {
    setSubCategory(subValue);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSubCategory = () => {
    setAnchorEl(null);
  };

  //===================Get all category by outlet===================
  const { refetch } = useQuery(GET_CATEGORY_BY_SHOP, {
    variables: {
      isUse: true,
    },
    onCompleted: ({ getCategoryByShop }) => { 
      if (getCategoryByShop) {
        const Data = getCategoryByShop?.map((e) => ({
          id: e?._id,
          title: language === "en" ? e?.categoryNameEn : e?.categoryNameKh,
          subCategories:
            e?.subCategories &&
            e?.subCategories.map((sub1) => ({
              id: sub1?._id,
              title:
                language === "en" ? sub1?.categoryNameEn : sub1?.categoryNameKh,
              subCategories:
                sub1?.subCategories &&
                sub1?.subCategories.map((sub2) => ({
                  id: sub2?._id,
                  title:
                    language === "en"
                      ? sub2?.categoryNameEn
                      : sub2?.categoryNameKh,
                  subCategories:
                    sub2?.subCategories &&
                    sub2?.subCategories.map((sub3) => ({
                      id: sub3?._id,
                      title:
                        language === "en"
                          ? sub3?.categoryNameEn
                          : sub3?.categoryNameKh,
                      subCategories:
                        sub3?.subCategories &&
                        sub3?.subCategories.map((sub4) => ({
                          id: sub4?._id,
                          title:
                            language === "en"
                              ? sub4?.categoryNameEn
                              : sub4?.categoryNameKh,
                          subCategories:
                            sub4?.subCategories &&
                            sub4?.subCategories.map(() => ({})),
                        })),
                    })),
                })),
            })),
        }));

        setCategoryData([
          {
            id: "All",
            title: language === "en" ? "All" : "ទាំងអស់",
            subCategories: [],
          },
          ...Data,
        ]);
      }
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (categoryId === "All") {
      setCategoryActive("All");
    }
  }, [categoryId]);

  return (
    <div className="tab-style">
      <Box className="tabs-container">
        <Tabs
          value={categoryActive}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          className="tabs"
          aria-label="visible arrows tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            width: "100%",
            [`& .${tabsClasses.flexContainer}`]: {
              gap: "1px", //space tab each item
            },
          }}
        >
          {categoryData?.map((catItem, index) => {
            return (
              <Tab
                key={index}
                value={catItem?.id}
                onClick={(event) => {
                  setCategoryId(catItem?.id);
                  handleClickSubCategory(event, catItem?.subCategories);
                  setCategoryActive(catItem?.id);
                }}
                className="tab-name-container"
                label={
                  <Stack
                    className={
                      categoryActive === catItem?.id
                        ? "stack-label-active"
                        : "stack-label"
                    }
                    direction="column" 
                    justifyContent="center"
                  >
                    <Stack
                      spacing={0.8}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography className="tab-name">
                        {catItem?.title || ""}
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />
            );
          })}
        </Tabs>

        {/* ======================= Popup Sub Category ==================== */}
        {subCategory?.length !== 0 && (
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleCloseSubCategory}
            onClick={handleCloseSubCategory}
            PaperProps={StyleMenuSubCagetory}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* ====================Sub category1============================== */}
            {subCategory?.map((rowSub, indexSub) =>
              rowSub?.subCategories?.length !== 0 ? (
                <NestedMenuItem
                  rightIcon={""}
                  onClick={() => setCategoryId(rowSub?.id)}
                  label={rowSub?.title || ""}
                  parentMenuOpen={openMenu}
                  key={indexSub}
                >
                  {/* ====================Sub category2============================== */}
                  {rowSub?.subCategories?.length !== 0 &&
                    rowSub?.subCategories?.map((rowSub2, indexSub2) =>
                      rowSub2?.subCategories?.length !== 0 ? (
                        <NestedMenuItem
                          rightIcon={""}
                          onClick={() => setCategoryId(rowSub2?.id)}
                          label={rowSub2?.title || ""}
                          parentMenuOpen={openMenu}
                          key={indexSub2}
                        >
                          {/* ====================Sub category3============================== */}
                          {rowSub2?.subCategories?.length !== 0 &&
                            rowSub2?.subCategories?.map((rowSub3, indexSub3) =>
                              rowSub3?.subCategories?.length !== 0 ? (
                                <NestedMenuItem
                                  onClick={() => setCategoryId(rowSub3?.id)}
                                  rightIcon={""}
                                  label={rowSub3?.title || ""}
                                  parentMenuOpen={openMenu}
                                  key={indexSub3}
                                >
                                  {/* ====================Sub category4============================== */}
                                  {rowSub3?.subCategories?.length !== 0 &&
                                    rowSub3?.subCategories?.map(
                                      (rowSub4, indexSub4) => (
                                        <MenuItem
                                          onClick={() =>
                                            setCategoryId(rowSub4?.id)
                                          }
                                          key={indexSub4}
                                        >
                                          {rowSub4?.title || ""}
                                        </MenuItem>
                                      )
                                    )}
                                  {/* =============================End sub category4==================================== */}
                                </NestedMenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() => setCategoryId(rowSub3?.id)}
                                  key={indexSub3}
                                >
                                  {rowSub3?.title || ""}
                                </MenuItem>
                              )
                            )}
                          {/* ==================================End sub category3======================================= */}
                        </NestedMenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => setCategoryId(rowSub2?.id)}
                          key={indexSub2}
                        >
                          {rowSub2?.title}
                        </MenuItem>
                      )
                    )}
                  {/* ===========================End sub category2======================================== */}
                </NestedMenuItem>
              ) : (
                <MenuItem
                  onClick={() => setCategoryId(rowSub?.id)}
                  key={indexSub}
                >
                  {rowSub?.title || ""}
                </MenuItem>
              )
            )}
          </Menu>
        )}
        {/* ===============================End sub category1==================================== */}
      </Box>
    </div>
  );
}
