import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import moment from "moment";
import MemberList from "./MemberList";
import "../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "../../Context/AuthContext";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { translateLauguage } from "../../Include/Function/Translate";
import { CREATE_CHECK_IN, UPDATE_CHECK_IN } from "../../Schema/CardNumber";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";
import { SelectCustomerWithAddNew } from "../../Include/Function/DynamicSelect";

export const RegisterForm = ({ open, editData, handleClose, dialogTitle }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const newItem = {
    customerId: null,
    cardId: null,
    key: Math.random() + Date.now(),
  };
  const [memberDataList, setMemberDataList] = useState([newItem]);
  const [selectedCustomerVal, setSelectedCustomerVal] = useState({
    id: "",
    title: "",
  });

  //======================== Create Bank =========================
  const [createCheckIn] = useMutation(CREATE_CHECK_IN, {
    onCompleted: ({ createCheckIn }) => {
      setLoading(false);
      if (createCheckIn?.isSuccess === true) {
        setAlert(true, "success", createCheckIn?.message);
        handleClose();
        resetForm();
        setRequired(false);
        setMemberDataList([newItem]);
        setSelectedCustomerVal({ id: "", title: "" });
        // setRefetch();
      } else {
        setAlert(true, "error", createCheckIn?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== update Bank =========================
  const [updateCheckIn] = useMutation(UPDATE_CHECK_IN, {
    onCompleted: ({ updateCheckIn }) => {
      setLoading(false);
      if (updateCheckIn?.isSuccess === true) {
        setAlert(true, "success", updateCheckIn?.message);
        handleClose();
        resetForm();
        setRequired(false);
        // setRefetch();
      } else {
        setAlert(true, "error", updateCheckIn?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    date: Yup.string().required(t("require")),
    leaderId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      date: moment(),
      remark: "",
      leaderId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);
      if (dialogTitle === "Create") {
        const submit = memberDataList
          .map((e, index) =>
            e?.customerId?.id && e?.cardId?.id ? true : false
          )
          .filter((e) => !e);
        if (submit.length > 0) {
          setLoading(false);
          setRequired(true);
        } else {
          createCheckIn({
            variables: {
              input: {
                ...value,
                date: moment(value?.date).format("YYYY-MM-DD"),
                member: memberDataList.map((e, index) => ({
                  isLead: index === 0,
                  customerId: e?.customerId?.id,
                  cardId: e?.cardId?.id,
                })),
              },
            },
          });
        }
      } else {
        const submit = memberDataList
          .map((e, index) =>
            e?.customerId?.id && e?.cardId?.id ? true : false
          )
          .filter((e) => !e);
        if (submit.length > 0) {
          setLoading(false);
          setRequired(true);
        } else {
          updateCheckIn({
            variables: {
              id: editData?._id,
              input: {
                ...value,
                member: memberDataList.map((e, index) => ({
                  isLead: index === 0,
                  customerId: e?.customerId?.id,
                  cardId: e?.cardId?.id,
                })),
              },
            },
          });
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("leaderId", selectedCustomerVal?.id || "");
  }, [selectedCustomerVal]);
  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setRequired(false);
      setMemberDataList([newItem]);
      setSelectedCustomerVal({ id: "", title: "" });
    }

    if (dialogTitle === "Update" && open) {
      setFieldValue("remark", editData?.remark);
    }
  }, [open, editData]);

  const handleAddItem = () => setMemberDataList([...memberDataList, newItem]);

  useEffect(() => {
    const data = memberDataList?.map((e, index) => {
      if (index === 0) e.customerId = selectedCustomerVal;
      return e;
    });
    setMemberDataList([...data]);
  }, [selectedCustomerVal]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_register")
                : t("dialog_title_update_register")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={3.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  height="100%"
                >
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                  >
                    {t("thead_leader")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8.5}>
                <Stack direction="column" spacing={1} height="100%">
                  <SelectCustomerWithAddNew
                    selectedVal={selectedCustomerVal}
                    setSelectedVal={setSelectedCustomerVal}
                  />
                  {!!errors.leaderId && touched.leaderId && (
                    <FormHelperText error id="outlined-adornment-password">
                      {errors.leaderId}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  height="100%"
                >
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                  >
                    {t("thead_date")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8.5}>
                <Stack direction="column" spacing={1} height="100%">
                  <SelectDatePicker
                    date={values.date}
                    setDate={(e) => setFieldValue("date", e)}
                  />
                  {!!errors.date && touched.date && (
                    <FormHelperText error id="outlined-adornment-password">
                      {errors.date}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("total_member")}
                  </Typography>
                  <IconButton onClick={handleAddItem}>
                    <AddBoxOutlinedIcon sx={{ color: "green" }} />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_card_number")}
                </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_customer")}
                </Typography>
              </Grid>

              <MemberList
                memberDataList={memberDataList}
                setMemberDataList={setMemberDataList}
                required={required}
              />

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  className="text-field"
                  placeholder={t("remark")}
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
