import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import VoidPayment from "../../VoidPayment";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";

export default function RecentPayment({
  refetchPayment,
  paymentByInvoiceData,
}) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <Stack direction="column" spacing={1}>
      <Typography className="subtitle-payment">
        {t("thead_recent_payments")}
      </Typography>

      {paymentByInvoiceData?.map((e, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            sx={{
              paddingY: 1,
              paddingX: 3,
              borderRadius: 1,
              bgcolor: "#DDF0FE",
            }}
          >
            <Stack width="20%" direction="row" alignItems="center">
              <Typography sx={{ fontWeight: "bold", color: "#178BE2" }}>
                {e?.paymentMethod !== "NotPaid"
                  ? t(`${e?.paymentMethod} Paid`)
                  : "Unpaid"}
              </Typography>
            </Stack>

            <Stack
              width="20%"
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography sx={{ whiteSpace: "pre" }}>
                {(e?.paid?.amount - e?.change?.amount).toFixed(2)} $
              </Typography>
            </Stack>

            <Stack
              width="20%"
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography>{(e?.paid?.usd).toFixed(2)} $</Typography>
            </Stack>

            <Stack
              width="20%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography sx={{ whiteSpace: "pre" }}>
                {(e?.paid?.khr).toFixed(2)} ៛
              </Typography>
            </Stack>

            <Stack
              width="20%"
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <VoidPayment editData={e} refetchPayment={refetchPayment} />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
