import { gql } from "@apollo/client";

export const GET_REFUND_PAGINATION = gql`
 query GetProductSellReport(
  $viewType: ViewTypeEnum
  $shiftId: ID
  $startDate: Date
  $endDate: Date
) {
  getProductSellReport(
    viewType: $viewType
    shiftId: $shiftId
    startDate: $startDate
    endDate: $endDate
  ) {
    final
    discount
    amount
    categories {
      category {
        _id
        categoryNameKh
        categoryNameEn
        parentCategoryId {
          categoryNameEn
          categoryNameKh
        }
        isUse
        remark
      }
      mainProducts {
        mainProductKhName
        mainProductEnName
        unitKhName
        unitEnName
        qty
        amount
        discount
        final
      }
      amount
      discount
      final
    }
  }
} 
`;


export const GET_INVENTORY_REPORT = gql` 
query GetInventoryReport(
  $viewType: ViewTypeEnum
  $shiftId: ID
  $startDate: Date
  $endDate: Date
  $isHaveStock: Boolean
) {
  getInventoryReport(
    viewType: $viewType
    shiftId: $shiftId
    startDate: $startDate
    endDate: $endDate
    isHaveStock: $isHaveStock
  ) {
    category {
      categoryNameEn
      categoryNameKh
    }
    mainProducts {
      mainProduct {
        mainProductEnName
        mainProductKhName
        minimumUnitId {
          unitEnName
          unitKhName
        }
      }
      opens {
        qty
        price
      }
      ins {
        qty
        price
        action
      }
      outs {
        qty
        price
        action
      }
      ends {
        qty
        price
      }
    }
  }
} 
`;
export const GET_PAYMENT_REPORT = gql`
query GetPaymentReport(
  $viewType: ViewTypeEnum
  $shiftId: ID
  $startDate: Date
  $endDate: Date
) {
  getPaymentReport(
    viewType: $viewType
    shiftId: $shiftId
    startDate: $startDate
    endDate: $endDate
  ) {
    paid
    complimentary
    previousPaid
    invoice {
      _id
      createdAt
      customerId {
        name
      }
      products {
        productId {
          mainProductId {
            mainProductEnName
            mainProductKhName
          }
          unitId {
            unitEnName
            unitKhName
          }
        }
        qty
        price
        total
      }
      invoiceNo
      queueNo
    }
  }
}

 `;
