import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Badge,
  Stack,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Src
import logo from "../../Assets/it-logo.png";
import "./screenview.scss";
import ACQR from "../../Assets/ACLEDA_BANK.jpg";
// import ScreenMode from "./ScreenMode";
import ThanksDialog from "../../Components/ViewScreen/ThanksDialog";

import { GET_INVOICE_BY_ID_FOR_SCREEN_VIEW } from "../../Schema/ScreenView";
import { useQuery } from "@apollo/client";
import {
  currencyFormat,
  currencyFormatKh,
} from "../../Include/Function/DynamicFn";
import { GET_EXCHANGE_RATE } from "../../Schema/ExchangeRate";

// icon
import { FaShop } from "react-icons/fa6";
import { PiHandsPraying } from "react-icons/pi";

export default function ScreenView() {
  //
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1204px)");
  const [loading, setLoading] = useState(true);

  const [openThank, setOpenThank] = useState(false);
  const handleOpenThank = () => setOpenThank(true);
  const handleCloseThank = () => setOpenThank(false);

  const [viewData, setViewData] = useState();
  const [receiveAmount, setReceiveAmount] = useState(
    JSON.parse(window.localStorage.getItem("CurrentReceiveAmount"))
  );
  const [changeAmount, setChangeAmount] = useState(
    JSON.parse(window.localStorage.getItem("CurrentChangeAmount"))
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "CurrentReceiveAmount") {
        setReceiveAmount(JSON.parse(event.newValue));
      }
      if (event.key === "CurrentChangeAmount") {
        setChangeAmount(JSON.parse(event.newValue));
      }
    };
    // Set the event listener
    window.addEventListener("storage", handleStorageChange);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  //============ Get Exchange Rate =================
  const [exchangeRate, setExchangeRate] = useState(0);
  const { refetch: refetchExchangeRate } = useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangeRate }) => {
      setExchangeRate(getExchangeRate?.exchangeRate);
    },
  });

  //============= Get Invoice By ID ================
  const CurrentInvoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));

  const { refetch } = useQuery(GET_INVOICE_BY_ID_FOR_SCREEN_VIEW, {
    pollInterval: 1000,
    variables: {
      id: [CurrentInvoiceId],
    },
    onCompleted: ({ getInvoiceById }) => {
      setLoading(false);
      setViewData(getInvoiceById);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [CurrentInvoiceId]);
 
  return (
    <>
      <div
        style={{
          // height: "100vh",
          position: "relative",
          // objectFit: "cover",
          width: "100vw",
          height: "100vh",
          backgroundImage:
            "url('https://img.freepik.com/premium-vector/hand-typing-laptop-with-plant-cup-coffee-top-view-desk_22052-2190.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack
          sx={{
            right: "20px",
            top: "10px",
            position: "absolute",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent="center">
            <IconButton onClick={() => handleOpenThank()}>
              <PiHandsPraying />
            </IconButton>

            <IconButton onClick={() => navigate("/shop")}>
              <FaShop />
            </IconButton>
          </Stack>
        </Stack>

        <Grid container sx={{ width: "100%", height: "100%" }}>
          <Grid item xs={1}></Grid>

          <Grid item xs={10}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%", height: "100%" }}
              className="screen-view-container"
            >
              <Box className="box-payment-container">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <img src={logo} style={{ width: "110px" }} />
                      <Typography className="header-kh">
                        ការទូរទាត់សាច់ប្រាក់
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Stack direction="column" spacing={2}>
                      {viewData?.map((e, index) => {
                        return (
                          <Stack
                            direction="column"
                            spacing={2}
                            sx={{ padding: "15px" }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography className="payment-text-normal">
                                សរុបរង
                              </Typography>
                              <Stack
                                direction={`row`}
                                justifyContent="right"
                                alignItems="center"
                              >
                                <Typography>
                                  {currencyFormat(e?.subTotal)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography className="payment-text-normal">
                                បញ្ចុះតម្លៃ
                              </Typography>
                              <Stack
                                direction={`row`}
                                justifyContent="right"
                                alignItems="center"
                              >
                                <Typography>
                                  {currencyFormat(e?.allDiscount ?? 0)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography className="payment-text-normal">
                                ចំនួនសរុប
                              </Typography>
                              <Stack
                                direction={`row`}
                                justifyContent="right"
                                alignItems="center"
                              >
                                <Typography>
                                  {currencyFormat(e?.total ?? 0)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider />
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography className="payment-text-big">
                                  សរុប
                                </Typography>
                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="payment-text-normal">
                                    (USD)
                                  </Typography>
                                  <Typography className="payment-text-normal">
                                    (Riel)
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack
                                direction="column"
                                spacing={0.5}
                                justifyContent="right"
                                textAlign="right"
                              >
                                <Typography>
                                  {currencyFormat(e?.total ?? 0)}
                                </Typography>
                                <Typography>
                                  {currencyFormatKh(e?.total * exchangeRate)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider />
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography className="payment-text-big">
                                  ទទួល
                                </Typography>
                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="payment-text-normal">
                                    (USD)
                                  </Typography>
                                  <Typography className="payment-text-normal">
                                    (Riel)
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack
                                direction="column"
                                spacing={0.5}
                                justifyContent="right"
                                textAlign="right"
                              >
                                <Typography>
                                  {currencyFormat(receiveAmount?.usdAmount)}
                                </Typography>
                                <Typography>
                                  {currencyFormatKh(receiveAmount?.khrAmount)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider />
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography className="payment-text-big">
                                  អាប់
                                </Typography>
                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="payment-text-normal">
                                    (USD)
                                  </Typography>
                                  <Typography className="payment-text-normal">
                                    (Riel)
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack
                                direction="column"
                                spacing={0.5}
                                justifyContent="right"
                                textAlign="right"
                              >
                                <Typography>
                                  {currencyFormat(changeAmount?.usdAmount ?? 0)}
                                </Typography>
                                <Typography>
                                  {currencyFormatKh(changeAmount?.khrAmount)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* <ScreenMode /> */}
                  </Grid>

                  {/* {!isSmallScreen && (
                    <Grid
                      item
                      xs={5}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack
                        direction="column"
                        spacing={1}
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <img src={ACQR} style={{ width: "100%" }} />
                      </Stack>
                    </Grid>
                  )} */}

                </Grid>
              </Box>
            </Stack>
          </Grid>
          
          <Grid item xs={1}></Grid>
        </Grid>
      </div>

      <ThanksDialog open={openThank} handleClose={handleCloseThank} />
    </>
  );
}
