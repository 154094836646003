import * as React from "react";
import { Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { translateLauguage } from "../../../Include/Function/Translate";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ProductAction from "./ProductAction";
import { AuthContext } from "../../../Context/AuthContext";
import SubProductData from "./SubProduct/SubProductData";  

export default function ProductAccordion({ tableData, setRefetch }) {
  //
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Stack direction="column" spacing={1}>
        {tableData?.map((row, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              boxShadow: "none",
              borderRadius: 1,
              paddingLeft: "10px",
              ":before": {
                border: "none",
                content: "none",
                display: "none", 
                backgroundColor: "none",
              },
            }}
          >
            <AccordionSummary
              id="panel1bh-header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Stack
                spacing={1}
                width="100%"
                direction="row"
                alignItems="center"
                height={10}
              >
                <Typography width="5%"> 
                  {index + 1} 
                </Typography>
                <Typography width="35%"> 
                  {language === "en" ? row?.mainProductEnName : row?.mainProductKhName} 
                </Typography>
                <Typography width="15%"> 
                  {language === "en" ? row?.minimumUnitId?.unitEnName : row?.minimumUnitId?.unitKhName} 
                </Typography>
                <Typography width="15%"> 
                  {row?.categoryIds?.length > 0 ? language === "en" ? row?.categoryIds[row?.categoryIds.length - 1]?.categoryNameEn : row?.categoryIds[row?.categoryIds.length - 1]?.categoryNameKh : "unit"} 
                </Typography>
                <Typography width="25%">
                  {row?.remark}
                </Typography>
                <Stack width="5%">  
                  <ProductAction editData={row} setRefetch={setRefetch} />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <SubProductData mainProduct={row} /> 
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </div>
  );
}
