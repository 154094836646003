import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  Stack,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import "../../../../Style/actionStyle.scss";
import { SubProductForm } from "./SubProductForm";
import SubProductAction from "./SubProductAction";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EmptyImage from "../../../../Assets/photo.png";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { GET_PRODUCT_BY_MAIN_PRODUCT_ID } from "../../../../Schema/Product";


export default function SubProductData({ mainProduct }) {

  // =============== Change Language ==============
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [tableData, setTableData] = useState();
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const { refetch } = useQuery(GET_PRODUCT_BY_MAIN_PRODUCT_ID, {
    variables: {
      mainProductId: mainProduct?._id,
    },
    onCompleted: ({ getProductByMainProductId }) => {
      setTableData(getProductByMainProductId);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, []);


  return (
    <Stack direction="column" alignItems="center" spacing={1.5}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ width: "100%", borderRadius: 1, paddingX: 3, bgcolor: "#1d4592", }}
      >
        <Typography sx={{ width: "11%", color: "#fff", textAlign: "center", }}>
          {t("product")}
        </Typography>
        <Typography sx={{ width: "20.70%", color: "#fff", textAlign: "center", }}>
          {t("thead_unit")}
        </Typography>
        <Typography sx={{ width: "15%", color: "#fff", }}>
          {t("thead_cost")}
        </Typography>
        <Typography sx={{ width: "17.85%", color: "#fff", paddingLeft: 3, }}>
          {t("thead_sale_price")}
        </Typography>
        <Typography sx={{ width: "17.85%", color: "#fff", }}>
          {t("thead_tax_rate")}
        </Typography>
        <Typography sx={{ width: "12.92%", color: "#fff", }}>
          {t("thead_price_include_tax")}
        </Typography>

        <Stack
          sx={{ fontWeight: "bold", width: "240px" }}
          direction="row"
          justifyContent="flex-end"
        >
          <Box
            sx={{
              margin: "4px",
              backgroundColor: "#ffffff14",
              borderRadius: "50%"
            }}
          >
            <IconButton onClick={handleOpen}>
              <AddBoxIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
        </Stack>

        <SubProductForm
          open={openCreate}
          dialogTitle="Create"
          setRefetch={refetch}
          handleClose={handleClose}
          mainProduct={mainProduct}
        />
      </Stack>
      <Stack direction="column" width="100%">
        {tableData?.map((e, key) => (
          <Stack
            key={key}
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{
              width: "100%",
              paddingY: 1,
              paddingX: 3,
              borderRadius: 1.5,
              ":hover": {
                bgcolor: "#F4F4F4",
                cursor: "pointer",
                transition: "all 0.2s",
              },
            }}
          >
            <Stack
              sx={{ width: "11%" }}
              direction="row"
              alignContent="center"
              justifyContent="center"
            >
              {e?.imageSrc ? (
                <CardMedia
                  component="img"
                  height="70"
                  image={e?.imageSrc}
                  alt="Loading..."
                />
              ) : (
                <img src={EmptyImage} alt="Empty Image" height="70" />
              )}
            </Stack>

            <Typography sx={{ fontWeight: "bold", width: "20.70%", textAlign: "center" }}  >
              {language === "en" ? e?.unitId?.unitEnName : e?.unitId?.unitKhName}
            </Typography>

            <Typography sx={{ fontWeight: "bold", width: "15%" }}>
              $ {e?.cost}
            </Typography>

            <Typography sx={{ fontWeight: "bold", width: "17.85%", paddingLeft: 3, }}>

              $ {e?.salePrice}
            </Typography>

            <Typography sx={{ fontWeight: "bold", width: "17.85%" }}>
              % {e?.taxRate}
            </Typography>

            <Typography sx={{ fontWeight: "bold", width: "12.92%" }}>
              $ {e?.price}
            </Typography>

            <Stack sx={{ fontWeight: "bold", width: "240px" }}>
              <SubProductAction
                editData={e}
                setRefetch={refetch}
                mainProduct={mainProduct}
              />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
