import React, { useContext } from "react";
import { IconButton, Stack, TextField, Typography, InputAdornment } from "@mui/material";
import "../../../../Style/actionStyle.scss";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { SelectMainProductForIngredient } from "../../../../Include/Function/DynamicSelect";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import EmptyData from "../../../../Include/EmptyData/EmptyData";

export default function ProductIngredients({
  ownId,
  required,
  ingredientData,
  setIngredientData,
}) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //===================== Hook ===============
  const newIngredient = { mainProductId: null, percentage: null, qty: null, key: Math.random() + Date.now(), };

  //============  Handle Add itemlist ===============
  const handleAddIngredient = () => {
    setIngredientData([...ingredientData, newIngredient]);
  };
  //=============  handle Remove itemlist ==================
  const handleRemoveIngredient = (key) => {
    const data = ingredientData?.filter((e) => e?.key !== key);
    setIngredientData([...data]);
  };

  //============== handle Update ItemList ==================
  const handleChangeIngredient = (key, value, fieldName) => {
    const data = ingredientData?.map((e) => {
      if (key === e.key && fieldName === "mainProductId") {
        e.mainProductId = value;
      }
      if (key === e.key && fieldName === "percentage") {
        e.percentage = value;
      }
      if (key === e.key && fieldName === "qty") {
        e.qty = value;
      }
      return e;
    });
    setIngredientData([...data]);
  };


  return (
    <Stack direction="column" alignItems="center" spacing={1.5}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        bgcolor={"#F0F0F0"}

        sx={{
          width: "100%",
          borderRadius: 1,
          paddingLeft: 1.5,
          bgcolor: "#F0F0F0",
        }}
      >
        <Typography sx={{ fontWeight: "bold", width: "36%" }}>
          {t("thead_ingredients")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "32%" }}>
          {t("thead_qty")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "32%" }}>
          {t("thead_percentage")}
        </Typography>
        <IconButton onClick={handleAddIngredient}>
          <AddBoxIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" alignItems="center" spacing={1.5} minHeight={120} width="100%">
        {
          ingredientData?.length === 0 ? <EmptyData /> :
            ingredientData?.map((e) => (
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack sx={{ width: "36%" }}>
                  <SelectMainProductForIngredient
                    ownId={ownId}
                    required={required}
                    dataList={ingredientData}
                    selectedVal={e?.mainProductId}
                    setSelectedVal={(value) => handleChangeIngredient(e.key, value, "mainProductId")}
                  />
                </Stack>
                <Stack sx={{ width: "32%" }}>
                  <TextField
                    type="number"
                    fullWidth
                    onChange={({ target }) =>
                      handleChangeIngredient(e.key, target?.value, "qty")
                    }
                    size="small"
                    value={e?.qty}
                    disabled={e?.percentage > 0}
                    // error={required && e?.qty <= 0}
                    placeholder={t("label_qty")}
                    className="text-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {e?.mainProductId?.unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <Stack sx={{ width: "32%" }}>
                  <TextField
                    fullWidth
                    type="number"
                    disabled={e?.qty > 0}
                    value={e?.percentage}
                    size="small"
                    onChange={({ target }) =>
                      handleChangeIngredient(e.key, target?.value, "percentage")
                    }
                    placeholder={t("label_percentage")}
                    className="text-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          %
                          {/* /{e?.mainProductId?.unit} */}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <IconButton onClick={() => handleRemoveIngredient(e.key)}>
                  <IndeterminateCheckBoxIcon sx={{ color: "red" }} />
                </IconButton>

              </Stack>
            ))}
      </Stack>
    </Stack>
  );
}
