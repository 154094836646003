import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Stack, TextField } from "@mui/material";
import { VOID_PAYMENT } from "../../Schema/Invoice";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

export default function VoidPayment({ editData, refetchPayment }) {
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = useState(null);
  const [remark, setRemark] = useState(null);
  const [loading, setLoading] = useState(null);

  // =============== Menu ===============
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRemark(null);
    setAnchorEl(null);
  };

  const shiftId = window.localStorage?.getItem("shiftId");
  //======================== Create User =========================
  const [voidPayment] = useMutation(VOID_PAYMENT, {
    onCompleted: ({ voidPayment }) => {
      setLoading(false);
      if (voidPayment?.isSuccess === true) {
        setAlert(true, "success", voidPayment?.message);
        handleClose();
        refetchPayment();
      } else {
        setAlert(true, "error", voidPayment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });
  const handleSubmit = () => {
    voidPayment({
      variables: {
        shiftId: shiftId,
        id: editData?._id,
        remark: remark,
      },
    });
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "basic-menu" : undefined}
        sx={
          editData?.voidBy
            ? { bgcolor: "#F3262B !important", color: "white !important" }
            : {}
        }
        disabled={editData?.voidBy}
      >
        void
      </Button>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack
          width={240}
          spacing={1.5}
          paddingX={1.5}
          paddingY={0.5}
          direction="column"
        >
          <Stack direction="row" width="100%" spacing={1.5}>
            <Button
              fullWidth
              sx={{ bgcolor: "red !important", color: "white" }}
              onClick={handleClose}
            >
              NO
            </Button>
            <Button
              fullWidth
              sx={{ bgcolor: "green !important", color: "white" }}
              onClick={handleSubmit}
            >
              YES
            </Button>
          </Stack>
          <Stack direction="row" width="100%">
            <TextField
              value={remark}
              onChange={({ target }) => setRemark(target?.value)}
              fullWidth
              multiline
              size="small"
              placeholder={t("remark")}
            />
          </Stack>
        </Stack>
      </Menu>
    </div>
  );
}
