import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  Box,
  Select,
  Tooltip,
  Checkbox,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  FormControl,
  DialogActions,
  FormHelperText,
  Tabs,
  Tab
} from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import "../../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import ProductIngredients from "./ProductIngredients";
import EmptyImage from "../../../../Assets/emptyImage.png";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import { deleteFileAPI } from "../../../../Include/api/api";
import CropImageFile from "../../../CropImage/CropImageFile";
import { AuthContext } from "../../../../Context/AuthContext";
import { useMutation as mutationDeleteImage } from "react-query";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "../../../../Schema/Product";
import { MultipleSelectShop, SelectUnit, } from "../../../../Include/Function/DynamicSelect";
import { FormEditFieldUnit, FormEditFieldQuantityInUnit } from "./SubProductFormSpecailField"
import { BiSolidEdit } from "react-icons/bi";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const SubProductForm = ({
  ownId,
  open,
  editData,
  setRefetch,
  dialogTitle,
  handleClose,
  mainProduct,
}) => {


  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [productFor, setProductFor] = useState("Sell");
  const [isCheckStock, setIsCheckStock] = useState(false);
  const [unitVal, setUnitVal] = useState({ id: "", title: "" });
  const [multipleSelectShopVal, setMultipleSelectShopVal] = useState([]);

  const [fieldSpecialUpdate, setFieldSpecialUpdate] = useState("");
  //=========== Tab ===========
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue)
  }
  // ========================= upload Image =========================
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [profileHook, setProfileHook] = useState("");
  const [ingredientData, setIngredientData] = useState([
    // {
    //   key: Math.random() + Date.now(),
    //   mainProductId: null,
    //   percentage: null,
    //   qty: null,
    // },
  ]);
  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
  // ===============  Mutations delete image  =======================
  const mutationDelete = mutationDeleteImage(deleteFileAPI, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        console.log("delete sucess::", data?.data?.message);
      } else {
        console.log("error::", data?.data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
      // console.log("deleteFileAPI", deleteFileAPI);
    },
  });

  const handleCloseAction = () => {
    setProfileHook("");
    handleClose();
    if (profileHook) {
      let splitSrc = profileHook?.split(":")[4];
      mutationDelete.mutate({
        storage: "pos_go_globalit",
        folder: "it_pos_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  //======================== Create =========================
  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: ({ createProduct }) => {
      setLoading(false);
      if (createProduct?.isSuccess === true) {
        setAlert(true, "success", createProduct?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createProduct?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });





  //======================== update Bank =========================
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({ updateProduct }) => {
      setLoading(false);
      if (updateProduct?.isSuccess === true) {
        setAlert(true, "success", updateProduct?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateProduct?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });






  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    tagsDesc: Yup.string(),
    imageSrc: Yup.string(),
    productDesc: Yup.string(),
    cost: Yup.number().required(t("require")),
    unitId: Yup.string().required(t("require")),
    taxRate: Yup.number().required(t("require")),
    salePrice: Yup.number().required(t("require")),
    qtyInMinimumUnit: Yup.number().required(t("require")),
    barcode: dialogTitle === "Create" ? Yup.string().min(6).max(6) : Yup.string().min(6).max(6).required(t("require")), 
  });

  const formik = useFormik({
    initialValues: {
      cost: 0,
      remark: "",
      unitId: "",
      taxRate: 0,
      barcode: "",
      imageSrc: "",
      tagsDesc: "",
      salePrice: 0,
      productDesc: "",
      qtyInMinimumUnit: 0,
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {


      const requireIngre = ingredientData
        .map((e) => { if (!e?.mainProductId?.id) return true; })
        .filter((e) => e);

      if (requireIngre.length === 0) {
        setLoading(true);
        const data = ingredientData.map((e) => ({
          qty: parseFloat(e?.qty || 0),
          mainProductId: e?.mainProductId?.id,
          percentage: parseFloat(e?.percentage || 0),
          actualQty: parseFloat(e?.qty || 0) > 0 ? parseFloat(e?.qty || 0) : parseFloat(e?.percentage || 0) / 100
        }))
          .filter((e) => e?.actualQty > 0);



        if (dialogTitle === "Create") {
          createProduct({
            variables: {
              input: {
                ...value,
                ingredients: data,
                imageSrc: profileHook,
                productFor: productFor,
                isCheckStock: isCheckStock,
                cost: parseFloat(value?.cost),
                mainProductId: mainProduct?._id,
                barcode: value?.barcode.toString(),
                taxRate: parseFloat(value?.taxRate),
                salePrice: parseFloat(value?.salePrice),
                accessShops: multipleSelectShopVal?.map((e) => e.id),
                qtyInMinimumUnit: parseFloat(value?.qtyInMinimumUnit),
                price: parseFloat(((parseFloat(values?.salePrice || 0) * parseFloat(values?.taxRate || 0)) / 100 + parseFloat(values?.salePrice || 0))?.toFixed(2)),
              },
            },
          });
        } else {
          updateProduct({
            variables: {
              id: editData?._id,
              input: {
                ...value,
                ingredients: data,
                productFor: productFor,
                isCheckStock: isCheckStock,
                cost: parseFloat(value?.cost),
                mainProductId: mainProduct?._id,
                barcode: value?.barcode.toString(),
                taxRate: parseFloat(value?.taxRate),
                salePrice: parseFloat(value?.salePrice),
                accessShops: multipleSelectShopVal?.map((e) => e.id),
                qtyInMinimumUnit: parseFloat(value?.qtyInMinimumUnit),
                imageSrc: profileHook ? profileHook : editData?.imageSrc,
                price: parseFloat(((parseFloat(values?.salePrice || 0) * parseFloat(values?.taxRate || 0)) / 100 + parseFloat(values?.salePrice || 0))?.toFixed(2)),
              },
            },
          });
        }
      } else setRequired(true);
    },
  });






  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);





  // ===================== End progress =============================

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };




  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;



  useEffect(() => {
    setFieldValue("unitId", unitVal?.id || "");
  }, [unitVal]);



  useEffect(() => {

    setRequired(false);

    if (dialogTitle === "Create" && open) {
      resetForm();
      setProfileHook("");
      setImageFile(null);
      setPhotoURL(null);
      setProductFor("Sell");
      setIsCheckStock(false);
      setMultipleSelectShopVal([]);
      setUnitVal({ id: "", title: "" });
    }

    if (dialogTitle === "Update" && open) {
      setProductFor(editData?.productFor);
      setIsCheckStock(editData?.isCheckStock);
      setMultipleSelectShopVal(editData?.accessShops?.map((e) => ({ id: e?._id, title: language === "en" ? e?.shopNameEn : e?.shopNameKh, })));
      setFieldValue("cost", editData?.cost);
      setFieldValue("price", editData?.price);
      setFieldValue("remark", editData?.remark);
      setFieldValue("barcode", editData?.barcode);
      setFieldValue("taxRate", editData?.taxRate);
      setFieldValue("imageSrc", editData?.imageSrc);
      setFieldValue("tagsDesc", editData?.tagsDesc);
      setFieldValue("productDesc", editData?.productDesc);
      setFieldValue("salePrice", editData?.salePrice);
      setFieldValue("qtyInMinimumUnit", editData?.qtyInMinimumUnit);
      setUnitVal({ id: editData?.unitId?._id, title: language === "en" ? editData?.unitId?.unitEnName : editData?.unitId?.unitKhName, });

      if (editData?.ingredients) {

        const ingredientsData = editData?.ingredients?.map((e) => ({
          key: Math.random() + Date.now(),
          qty: e?.qty,
          percentage: e?.percentage,
          mainProductId: {
            id: e?.mainProductId?._id,
            title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
            unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,

          },
        }));


        setIngredientData([...ingredientsData]);
      } else setIngredientData([])

    }
  }, [open, editData]);





  useEffect(() => {
    setFieldSpecialUpdate("");
  }, [open])

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_product")
                : t("dialog_title_update_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleCloseAction}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

            <Box sx={{ width: '100%' }}>
              <Tabs
                value={valueTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Information"
                  id="tab-0"
                  sx={{
                    backgroundColor: valueTab === 0 ? '#1976d21c' : 'transparent',
                    color: "#1d4592 !important",
                    '&:hover': {
                      backgroundColor: valueTab === 0 ? '#1976d21c' : 'rgba(0, 0, 0, 0.04)',
                    },
                    fontSize: "14px !important",
                    fontWeight: "bold",
                    textTransform: "none",
                    paddingTop: "10px",
                  }}
                />
                <Tab
                  label="Price"
                  id="tab-1"
                  sx={{
                    backgroundColor: valueTab === 1 ? '#1976d21c' : 'transparent',
                    color: "#1d4592 !important",
                    '&:hover': {
                      backgroundColor: valueTab === 1 ? '#1976d21c' : 'rgba(0, 0, 0, 0.04)',
                    },
                    fontSize: "14px !important",
                    fontWeight: "bold",
                    textTransform: "none",
                    paddingTop: "10px",
                  }}
                />
                <Tab
                  label="Ingredient"
                  id="tab-2"
                  sx={{
                    backgroundColor: valueTab === 2 ? '#1976d21c' : 'transparent',
                    color: "#1d4592 !important",
                    '&:hover': {
                      backgroundColor: valueTab === 2 ? '#1976d21c' : 'rgba(0, 0, 0, 0.04)',
                    },
                    fontSize: "14px !important",
                    fontWeight: "bold",
                    textTransform: "none",
                    paddingTop: "10px",
                  }}
                />

              </Tabs>

              <TabPanel value={valueTab} index={0}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={8}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item xs={6}>

                        {
                          dialogTitle === "Create" ?
                            <>
                              <Stack direction="row" spacing={2}>
                                <Typography
                                  className={
                                    language === "en" ? "field-title" : "field-title-kh"
                                  }
                                >
                                  {t("unit")}
                                </Typography>
                              </Stack>

                              <SelectUnit
                                selectedVal={unitVal}
                                setSelectedVal={setUnitVal}
                              />
                              {!!errors.unitId && touched.unitId && (
                                <FormHelperText error id="outlined-adornment-password">
                                  {errors.unitId}
                                </FormHelperText>
                              )}
                            </>
                            : null
                        }

                        {
                          fieldSpecialUpdate !== "unitProduct" && dialogTitle !== "Create" ?
                            <>
                              <Stack direction="row" spacing={2}>
                                <Typography
                                  className={
                                    language === "en" ? "field-title" : "field-title-kh"
                                  }
                                >
                                  {t("unit")}
                                </Typography>
                                <IconButton
                                  sx={{
                                    padding: 0,
                                    backgroundColor: "transparent",
                                    '&:hover': {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  onClick={() => {
                                    setFieldSpecialUpdate("unitProduct")
                                  }}
                                >
                                  <BiSolidEdit style={{ color: "orange", fontSize: 18 }} />
                                </IconButton>
                              </Stack>

                              <SelectUnit
                                selectedVal={unitVal}
                                setSelectedVal={setUnitVal}
                                disabled={true}
                              />
                              {!!errors.unitId && touched.unitId && (
                                <FormHelperText error id="outlined-adornment-password">
                                  {errors.unitId}
                                </FormHelperText>
                              )}
                            </>
                            : null
                        }

                        {
                          fieldSpecialUpdate === "unitProduct" && dialogTitle !== "Create" ?
                            <>
                              <FormEditFieldUnit
                                values={unitVal}
                                setRefetch={setRefetch}
                                setFieldSpecialUpdate={setFieldSpecialUpdate}
                                editData={editData}
                              />
                            </>
                            : null
                        }

                      </Grid>

                      <Grid item xs={6}>

                        {
                          dialogTitle === "Create" ?
                            <>
                              <Stack direction="row" spacing={2}>
                                <Typography
                                  className={
                                    language === "en" ? "field-title" : "field-title-kh"
                                  }
                                >
                                  {t("thead_qty_in_unit")}
                                </Typography>
                              </Stack>

                              <TextField
                                disabled={dialogTitle === "Create" ? false : true}
                                fullWidth
                                size="small"
                                type="number"
                                className="text-field"
                                placeholder={t("label_qty_in_unit")}
                                InputProps={{
                                  endAdornment: (
                                    <Typography whiteSpace="pre" fontSize={15}>
                                      {language === "en"
                                        ? mainProduct?.minimumUnitId?.unitEnName
                                        : mainProduct?.minimumUnitId?.unitKhName}/
                                      {unitVal?.title}
                                    </Typography>
                                  ),
                                }}
                                {...getFieldProps("qtyInMinimumUnit")}
                                error={Boolean(
                                  touched.qtyInMinimumUnit && errors.qtyInMinimumUnit
                                )}
                                helperText={
                                  touched.qtyInMinimumUnit && errors.qtyInMinimumUnit
                                }
                              />
                            </>
                            : null
                        }

                        {
                          fieldSpecialUpdate !== "QuantityInUnit" && dialogTitle !== "Create" ?
                            <>
                              <Stack direction="row" spacing={2}>
                                <Typography
                                  className={
                                    language === "en" ? "field-title" : "field-title-kh"
                                  }
                                >
                                  {t("thead_qty_in_unit")}
                                </Typography>
                                {
                                  dialogTitle === "Create" ? null :
                                    <IconButton
                                      sx={{
                                        padding: 0,
                                        backgroundColor: "transparent",
                                        '&:hover': {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                      onClick={() => {
                                        setFieldSpecialUpdate("QuantityInUnit")
                                      }}
                                    >
                                      <BiSolidEdit style={{ color: "orange", fontSize: 18 }} />
                                    </IconButton>
                                }
                              </Stack>

                              <TextField
                                disabled={dialogTitle === "Create" ? false : true}
                                fullWidth
                                size="small"
                                type="number"
                                className="text-field"
                                placeholder={t("label_qty_in_unit")}
                                InputProps={{
                                  endAdornment: (
                                    <Typography whiteSpace="pre" fontSize={15}>
                                      {language === "en"
                                        ? mainProduct?.minimumUnitId?.unitEnName
                                        : mainProduct?.minimumUnitId?.unitKhName}/
                                      {unitVal?.title}
                                    </Typography>
                                  ),
                                }}
                                {...getFieldProps("qtyInMinimumUnit")}
                                error={Boolean(
                                  touched.qtyInMinimumUnit && errors.qtyInMinimumUnit
                                )}
                                helperText={
                                  touched.qtyInMinimumUnit && errors.qtyInMinimumUnit
                                }
                              />
                            </>
                            : null
                        }

                        {
                          fieldSpecialUpdate === "QuantityInUnit" && dialogTitle !== "Create" ?
                            <>
                              <FormEditFieldQuantityInUnit
                                values={values?.qtyInMinimumUnit}
                                setRefetch={setRefetch}
                                setFieldSpecialUpdate={setFieldSpecialUpdate}
                                editData={editData}
                                unitVal={unitVal}
                                mainProduct={mainProduct}
                              />
                            </>
                            : null
                        }


                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          className={language === "en" ? "field-title" : "field-title-kh"}
                        >
                          {t("thead_barcode")}
                        </Typography>
                        <TextField
                          className="text-field"
                          fullWidth
                          size="small"
                          type="number"
                          {...getFieldProps("barcode")}
                          error={Boolean(touched.barcode && errors.barcode)}
                          helperText={touched.barcode && errors.barcode}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_for")}
                        </Typography>
                        <FormControl fullWidth size="small">
                          <Select
                            value={productFor}
                            onChange={(event) => setProductFor(event.target.value)}
                          >
                            <MenuItem value={"Sell"}>Sell</MenuItem>
                            <MenuItem value={"Use"}>Use</MenuItem>
                            <MenuItem value={"Both"}>Both</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          Access Shops
                        </Typography>
                        <MultipleSelectShop
                          selectedVal={multipleSelectShopVal}
                          setSelectedVal={setMultipleSelectShopVal}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography
                              className={
                                language === "en" ? "field-title" : "field-title-kh"
                              }
                              fontWeight="bold"
                            >
                              Check Stock When Selling
                            </Typography>
                            <Checkbox
                              checked={isCheckStock}
                              onChange={(e) => setIsCheckStock(!isCheckStock)}
                            />
                          </Stack>

                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_prodoct_desc")}
                        </Typography>
                        <TextField
                          className="text-field"
                          fullWidth
                          placeholder={t("label_description")}
                          size="small"
                          multiline
                          rows={2}
                          {...getFieldProps("productDesc")}
                          error={Boolean(touched.productDesc && errors.productDesc)}
                          helperText={touched.productDesc && errors.productDesc}
                        />
                      </Grid>

                    </Grid>
                  </Grid>


                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ textAlign: "center" }}
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                          fontWeight="bold"
                        >
                          Name: {language === "en" ? mainProduct?.mainProductEnName : mainProduct?.mainProductKhName}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {!openCrop ? (
                          <Box height="100%">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              <Tooltip title="click to upload">
                                <Button component="label" className="btn-upload">
                                  <TextField
                                    type="file"
                                    id="image"
                                    sx={{ display: "none" }}
                                    onChange={handleUploadImage}
                                  />
                                  <img
                                    src={
                                      imageFile
                                        ? URL.createObjectURL(imageFile)
                                        : editData?.imageSrc
                                          ? editData?.imageSrc
                                          : EmptyImage
                                    }
                                    alt=""
                                    style={{ width: "140px" }}
                                  />

                                </Button>
                              </Tooltip>
                            </Stack>
                          </Box>
                        ) : (
                          <CropImageFile
                            setProgress={setProgress}
                            setStatusProgress={setStatusProgress}
                            openCrop={openCrop}
                            setOpenCrop={setOpenCrop}
                            photoURL={photoURL}
                            setPhotoURL={setPhotoURL}
                            setImageFile={setImageFile}
                            setProfileHook={setProfileHook}
                          />
                        )}

                        {statusProgress && progress < 100 && (
                          <Box sx={{ width: "100%", marginLeft: "20px" }}>
                            <LinearProgressWithLabel value={progress} />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={2}>

                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_cost")}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          className="text-field"
                          placeholder={t("label_cost")}
                          {...getFieldProps("cost")}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_sale_price")}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          className="text-field"
                          placeholder={t("label_sale_price")}
                          {...getFieldProps("salePrice")}
                          error={Boolean(touched.salePrice && errors.salePrice)}
                          helperText={touched.salePrice && errors.salePrice}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_tax_rate")}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          className="text-field"
                          InputProps={{ endAdornment: "%" }}
                          placeholder={t("label_tax_rate")}
                          {...getFieldProps("taxRate")}
                          error={Boolean(touched.taxRate && errors.taxRate)}
                          helperText={touched.taxRate && errors.taxRate}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_price_include_tax")}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          className="text-field"
                          placeholder={t("label_price")}
                          value={(
                            (parseFloat(values?.salePrice || 0) *
                              parseFloat(values?.taxRate || 0)) /
                            100 +
                            parseFloat(values?.salePrice || 0)
                          )?.toFixed(2)}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("thead_tags_price_desc")}
                        </Typography>
                        <TextField
                          className="text-field"
                          fullWidth
                          placeholder={t("label_description")}
                          size="small"
                          multiline
                          rows={2}
                          {...getFieldProps("tagsDesc")}
                          error={Boolean(touched.tagsDesc && errors.tagsDesc)}
                          helperText={touched.tagsDesc && errors.tagsDesc}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ textAlign: "center" }}
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                          fontWeight="bold"
                        >
                          {language === "en" ? mainProduct?.mainProductEnName : mainProduct?.mainProductKhName}
                          {" "}/{" "}{unitVal?.title}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {!openCrop ? (
                          <Box height="100%">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              <Tooltip title="click to upload">
                                <Button component="label" className="btn-upload">
                                  <TextField
                                    type="file"
                                    id="image"
                                    sx={{ display: "none" }}
                                    onChange={handleUploadImage}
                                  />
                                  <img
                                    src={
                                      imageFile
                                        ? URL.createObjectURL(imageFile)
                                        : editData?.imageSrc
                                          ? editData?.imageSrc
                                          : EmptyImage
                                    }
                                    alt=""
                                    style={{ width: "140px" }}
                                  />

                                </Button>
                              </Tooltip>
                            </Stack>
                          </Box>
                        ) : (
                          <CropImageFile
                            setProgress={setProgress}
                            setStatusProgress={setStatusProgress}
                            openCrop={openCrop}
                            setOpenCrop={setOpenCrop}
                            photoURL={photoURL}
                            setPhotoURL={setPhotoURL}
                            setImageFile={setImageFile}
                            setProfileHook={setProfileHook}
                          />
                        )}

                        {statusProgress && progress < 100 && (
                          <Box sx={{ width: "100%", marginLeft: "20px" }}>
                            <LinearProgressWithLabel value={progress} />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={valueTab} index={2}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12}>
                    <ProductIngredients
                      ownId={ownId}
                      required={required}
                      ingredientData={ingredientData}
                      setIngredientData={setIngredientData}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className={
                        language === "en" ? "field-title" : "field-title-kh"
                      }
                    >
                      {t("remark")}
                    </Typography>
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      placeholder={t("label_remark")}
                      size="small"
                      className="text-field"
                      {...getFieldProps("remark")}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>

          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
