import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Select,
  MenuItem,
  Typography,
  FormControl,
} from "@mui/material";
import "./report.scss";
import "../../Style/reportstyle.css";
import CreateIcon from "../../Assets/add.png";
import { AuthContext } from "../../Context/AuthContext";
import { SelectShift } from "../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../Include/Function/Translate";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";
import StockInReport from "../../Components/Report/StockInReport/StockInReport";
import PaymentReport from "../../Components/Report/PaymentReport/PaymentReport";
import useGetProductSellReport from "../../Hooks/Report/useGetProductSellReport";
import InventoryReport from "../../Components/Report/InventoryReport/InventoryReport";
import ProductSaleReport from "../../Components/Report/ProductSaleReport/ProductSaleReport";
import CostOfGoodSoldReports from "../../Components/Report/CostOfGoodSoldReports/CostOfGoodSoldReports";
import useGetPaymentReport from "../../Hooks/Report/useGetPaymentReport";
import moment from "moment";
import useGetInventoryReport from "../../Hooks/Report/useGetInventoryReport";

const Report = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [reportType, setReportType] = useState("cost-of-goods-sold");

  const [date, setDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [viewType, setViewType] = useState("Range");
  const [startDate, setStartDate] = useState(moment());
  const [selectedShift, setSelectedShift] = useState({ id: "", title: "" });

  const { loading, tableData } = useGetProductSellReport({
    endDate,
    viewType,
    startDate,
    selectedShift,
  });

  const { tableDataPayment } = useGetPaymentReport({
    endDate,
    viewType,
    startDate,
    selectedShift,
  });

  const { tableDataInventory } = useGetInventoryReport({
    endDate,
    viewType,
    startDate,
    selectedShift,
  });

  useEffect(() => {
    if (viewType === "Range") setSelectedShift({ id: "", title: "" });
  }, [viewType]);

  const checkReportType = (type) => {
    if (type === "payment-report") {
      return (
        <PaymentReport
          loading={loading}
          endDate={endDate}
          startDate={startDate}
          tableData={tableDataPayment}
        />
      );
    }
    if (type === "stock-in-report") return <StockInReport />; 
    if (type === "inventory-report") {
      return <InventoryReport tableData={tableDataInventory} />;
    }
    if (type === "cost-of-goods-sold") return <CostOfGoodSoldReports />;
    if (type === "product-sale-report") {
      return (
        <ProductSaleReport
          loading={loading}
          endDate={endDate}
          startDate={startDate}
          tableData={tableData}
        />
      );
    }
  };

  return (
    <div className="report-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-report`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_report")}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <MenuItem value="cost-of-goods-sold">
                  Cost of Goods Sold
                </MenuItem>

                <MenuItem value="product-sale-report">
                  Product Sale Report
                </MenuItem>

                <MenuItem value="payment-report">Payment Report</MenuItem>
                <MenuItem value="inventory-report">Inventory Report</MenuItem>
                <MenuItem value="stock-in-report">Stock In Report</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_type")}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={viewType}
                onChange={(e) => setViewType(e.target.value)}
              >
                <MenuItem value="Range">Range</MenuItem>
                <MenuItem value="Shift">Shift</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {viewType === "Shift" && (
            <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
              <Typography
                className={language === "kh" ? "header-text-kh" : "header-text"}
              >
                {t("thead_date")}
              </Typography>
              <SelectDatePicker
                date={date}
                className={"search-field"}
                setDate={(e) => setDate(e)}
              />
            </Grid>
          )}

          {viewType === "Shift" && (
            <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
              <Typography
                className={language === "kh" ? "header-text-kh" : "header-text"}
              >
                {t("thead_shift")}
              </Typography>
              <SelectShift
                date={date}
                className={"search-field"}
                selectedVal={selectedShift}
                setSelectedVal={setSelectedShift}
              />
            </Grid>
          )}

          {viewType === "Range" && (
            <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
              <Typography
                className={language === "kh" ? "header-text-kh" : "header-text"}
              >
                {t("thead_start_date")}
              </Typography>
              <SelectDatePicker
                date={startDate}
                className={"search-field"}
                setDate={(e) => setStartDate(e)}
              />
            </Grid>
          )}
          {viewType === "Range" && (
            <Grid item xs={4} sm={4} md={3} lg={2.4} xl={2}>
              <Typography
                className={language === "kh" ? "header-text-kh" : "header-text"}
              >
                {t("thead_end_date")}
              </Typography>
              <SelectDatePicker
                date={endDate}
                className={"search-field"}
                setDate={(e) => setEndDate(e)}
              />
            </Grid>
          )}

          <Grid item xs={8} sm={8} md={12} lg={2.4} xl={4}>
            <Stack direction="row" justifyContent="right">
              <Button
                // onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {checkReportType(reportType)}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Report;
