import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { GET_PRODUCT_WITH_PAGINATION } from '../../../../Schema/POS';

const useGetProductPagination = () => {


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [keyword, setKeyword] = useState("");
    const [tableData, setTableData] = useState();
    const [categoryId, setCategoryId] = useState("All");
    const [paginationData, setPaginationData] = useState();
    const [loadingProductForSale, setLoadingProductForSale] = useState(true);

    const shopId = window.localStorage.getItem("shopId");
    const { refetch } = useQuery(GET_PRODUCT_WITH_PAGINATION, {
        variables: {
            page: page,
            limit: limit,
            shopId: shopId,
            pagination: true,
            keyword: keyword,
            categoryId: categoryId === "All" ? null : categoryId,
        },
        onCompleted: ({ getProductPagination }) => {
            setLoadingProductForSale(false);
            setTableData(getProductPagination?.data);
            setPaginationData(getProductPagination?.paginator);
        },
        onError: (err) => {
            console.log("Error getProductPagination::", err?.message);
        },
    });


    const handleLimit = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    useEffect(() => {
        refetch();
    }, [page, limit, shopId, keyword, categoryId]);


    const handleSearch = (e) => {
        setCategoryId("All");
        setKeyword(e);
    };

    return {
        page,
        limit,
        shopId,
        setPage,
        tableData,
        categoryId,
        handleLimit,
        handleSearch,
        setCategoryId,
        paginationData,
        loadingProductForSale,
    }
}

export default useGetProductPagination
