import { gql } from "@apollo/client";

export const CREATE_CARD = gql`
  mutation CreateCard($input: CardInput) {
    createCard(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_CARD = gql`
  mutation UpdateCard($id: ID!, $input: CardInput) {
    updateCard(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_CARD = gql`
  mutation DeleteCard($id: ID!) {
    deleteCard(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CARD_WITH_PAGINATION = gql`
  query GetCardWithPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $cardNo: String
    $isUse: Boolean
  ) {
    getCardWithPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      cardNo: $cardNo
      isUse: $isUse
    ) {
      data {
        _id
        cardNo
        imageSrc
        remark
        isUse
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const CREATE_CHECK_IN = gql`
  mutation CreateCheckIn($input: CheckInInput) {
    createCheckIn(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const UPDATE_CHECK_IN = gql`
  mutation UpdateCheckIn($id: ID!, $input: CheckInInput) {
    updateCheckIn(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const UPDATE_CHECK_IN_MEMBER = gql`
  mutation UpdateCheckInMember($id: ID!, $input: CheckInMemberInput) {
    updateCheckInMember(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const DELETE_CHECK_IN = gql`
  mutation DeleteCheckIn($id: ID!) {
    deleteCheckIn(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const CHECK_IN_OUT = gql`
  mutation CheckInOut($id: ID!, $status: CheckStatusEnum) {
    checkInOut(_id: $id, status: $status) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CHECK_IN_MEMBER_BY_CHECK_IN = gql`
  query GetCheckInMemberByCheckIn($checkInId: ID) {
    getCheckInMemberByCheckIn(checkInId: $checkInId) {
      _id
      checkInId {
        checkOut
        checkIn
      }
      isLead
      customerId {
        name
        _id
      }
      cardId {
        _id
        cardNo
      }
    }
  }
`;
export const GET_CHECK_IN_WITH_PAGINATION = gql`
  query GetCheckInPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $date: Date
    $status: CheckStatusEnum
    $keyword: String
  ) {
    getCheckInPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      date: $date
      status: $status
      keyword: $keyword
    ) {
      data {
        _id
        date
        status
        leaderId {
          _id
          name
        }
        checkIn
        checkOut
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
