import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup"; 
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi"; 
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_BANK, UPDATE_BANK } from "../../../Schema/Bank";
export const BankForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  //======================== Create Bank =========================
  const [createBank] = useMutation(CREATE_BANK, {
    onCompleted: ({ createBank }) => { 
      setLoading(false);
      if (createBank?.isSuccess === true) {
        setAlert(true, "success", createBank?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createBank?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //======================== update Bank =========================
  const [updateBank] = useMutation(UPDATE_BANK, {
    onCompleted: ({ updateBank }) => { 
      setLoading(false);
      if (updateBank?.isSuccess === true) {
        setAlert(true, "success", updateBank?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateBank?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    bankName: Yup.string().required(t("require")),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      bankName: "",
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => { 
      setLoading(true);
      if (dialogTitle === "Create") {
        createBank({
          variables: {
            input: {
              ...value,
            },
          },
        });
      } else {
        updateBank({
          variables: {
            id: editData?._id,
            input: {
              ...value,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
    }

    if (dialogTitle === "Update" && open) {
      setFieldValue("remark", editData?.remark);
      setFieldValue("bankName", editData?.bankName);
    }
  }, [open, editData]);

  useEffect(() => { }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_bank")
                : t("dialog_title_update_bank")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("bank_name")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("bankName")}
                  error={Boolean(touched.bankName && errors.bankName)}
                  helperText={touched.bankName && errors.bankName}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
