import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select, Box,
  MenuItem,
  TextField,
  DialogActions,
  Tooltip,
  LinearProgress,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from "../../../Schema/Customer";
import CropImageFile from "../../CropImage/CropImageFile";
import { useMutation as mutationDeleteImage } from "react-query";
import { deleteFileAPI } from "../../../Include/api/api";
import EmptyImage from "../../../Assets/emptyImage.png";
import PropTypes from "prop-types";
import { SelectCountries, SelectNationalitys } from "../../../Include/Function/DynamicSelect";
export const CustomerForm = ({ open, handleClose, dialogTitle, editData, setRefetch, hideBackdrop, setSelectedVal }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [selectedCountriesVal, setSelectedCountriesVal] = useState(null);
  const [selectedNationalitysVal, setSelectedNationalitysVal] = useState(null);
  // ========================= upload Image ========================= 
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [profileHook, setProfileHook] = useState("");

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
  // ===============  Mutations delete image  =======================
  const mutationDelete = mutationDeleteImage(deleteFileAPI, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        console.log("delete sucess::", data?.data?.message);
      } else {
        console.log("error::", data?.data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
      // console.log("deleteFileAPI", deleteFileAPI);
    },
  });

  const handleCloseAction = () => {
    setProfileHook("");
    handleClose();
    if (profileHook) {
      let splitSrc = profileHook?.split(":")[4];
      mutationDelete.mutate({
        storage: "pos_go_globalit",
        folder: "it_pos_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  //======================== Create User =========================
  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: ({ createCustomer }) => {

      console.log("createCustomer ===>", createCustomer)
      setLoading(false);
      if (createCustomer?.isSuccess === true) {
        handleClose();
        setAlert(true, "success", createCustomer?.message);
        resetForm();
        setRefetch();
        setSelectedVal({ id: createCustomer?.customer?._id, title: createCustomer?.customer?.name })
      } else {
        setAlert(true, "error", createCustomer?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //======================== Create User =========================
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: ({ updateCustomer }) => {
      setLoading(false);
      if (updateCustomer?.isSuccess === true) {
        setAlert(true, "success", updateCustomer?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateCustomer?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    isUse: Yup.string(),
    email: Yup.string(),
    remark: Yup.string(),
    address: Yup.string(),
    country: Yup.string(),
    passport: Yup.string(),
    nationality: Yup.string(),
    nationalCard: Yup.string(),
    name: Yup.string().required(t("require")),
    phone: Yup.string().required(t("require")),
    gender: Yup.string().required(t("require")),
    company: Yup.string().required(t("require")),


  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      isUse: "",
      phone: "",
      remark: "",
      address: "",
      company: "",
      country: "",
      passport: "",
      nationality: "",
      gender: "Female",
      nationalCard: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => {
      setLoading(true);
      if (dialogTitle === "Create") {
        createCustomer({
          variables: {
            input: {
              ...submitValues,
              isUse: true,
              imageSrc: profileHook,
            },
          },
        });
      } else {
        updateCustomer({
          variables: {
            id: editData?._id,
            input: {
              ...submitValues,
              isUse: true,
              imageSrc: profileHook,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    if (editData) {
      setSelectedCountriesVal({
        "num_code": editData?.country,
        "en_short_name": editData?.country,
        "label": editData?.country
      });
      setSelectedNationalitysVal({
        "num_code": editData?.nationality,
        "en_short_name": editData?.nationality,
        "nationality": editData?.nationality
      });
      setProfileHook(editData?.imageSrc || "");
      setFieldValue("name", editData?.name || "");
      setFieldValue("isUse", editData?.isUse || "");
      setFieldValue("email", editData?.email || "");
      setFieldValue("phone", editData?.phone || "");
      setFieldValue("remark", editData?.remark || "");
      setFieldValue("gender", editData?.gender || "");
      setFieldValue("country", editData?.country || "");
      setFieldValue("address", editData?.address || "");
      setFieldValue("company", editData?.company || "");
      setFieldValue("passport", editData?.passport || "");
      setFieldValue("nationality", editData?.nationality || "");
      setFieldValue("nationalCard", editData?.nationalCard || "");
    }
  }, [open, editData]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_customer")
                : t("dialog_title_update_customer")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleCloseAction}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box height="100%">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.imageSrc
                                  ? editData?.imageSrc
                                  : EmptyImage
                            }
                            alt=""
                            style={{ width: "140px" }}
                          />
                          {/* <Avatar 
                            className="avater-image" 
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : EmptyImage
                            }
                          /> */}
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setProgress={setProgress}
                    setStatusProgress={setStatusProgress}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}

                {statusProgress && progress < 100 && (
                  <Box sx={{ width: "100%", marginLeft: "20px" }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("cus_name")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("company")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("company")}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("label_gender")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={values?.gender}
                    onChange={(e) => setFieldValue("gender", e.target.value)}
                  >
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("label_tel")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_country")}
                </Typography>
                <SelectCountries
                  selectedVal={selectedCountriesVal}
                  setSelectedVal={(e, value) => {
                    setFieldValue("country", e?.label)
                    setSelectedCountriesVal(e);
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_nationality")}
                </Typography>

                <SelectNationalitys
                  selectedVal={selectedNationalitysVal}
                  setSelectedVal={(e, value) => {
                    setFieldValue("nationality", e?.nationality)
                    setSelectedNationalitysVal(e);
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_nationality_card")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("nationalCard")}
                  error={Boolean(touched.nationalCard && errors.nationalCard)}
                  helperText={touched.nationalCard && errors.nationalCard}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_passport")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("passport")}
                  error={Boolean(touched.passport && errors.passport)}
                  helperText={touched.passport && errors.passport}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("email")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("address")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("address")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("remark")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  rows={2}
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
