import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_EXCHANGE_RATE } from "../../Schema/ExchangeRate";

const useGetExchangeRate = (invoiceId) => {
    ;
    const [exchangeRate, setExchangeRate] = useState(0);
    //get exchange rate
    const { refetch: refetchExchangeRate } = useQuery(GET_EXCHANGE_RATE, {
        onCompleted: ({ getExchangeRate }) => {
            setExchangeRate(getExchangeRate?.exchangeRate);
        },
    });
    useEffect(() => {
        refetchExchangeRate();
    }, [invoiceId]);


    return {
        exchangeRate
    };
};

export default useGetExchangeRate;
