import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField, Tooltip, Box,
  IconButton,
  Typography,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup"; 
import PropTypes from "prop-types";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi"; 
import { deleteFileAPI } from "../../../Include/api/api";
import EmptyImage from "../../../Assets/emptyImage.png";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CropImageFile from "../../CropImage/CropImageFile";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { useMutation as mutationDeleteImage } from "react-query";
import { CREATE_CARD, UPDATE_CARD } from "../../../Schema/CardNumber";
export const CardNumberForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Available");
  // ========================= upload Image =========================
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [profileHook, setProfileHook] = useState("");

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
  // ===============  Mutations delete image  =======================
  const mutationDelete = mutationDeleteImage(deleteFileAPI, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        console.log("delete sucess::", data?.data?.message);
      } else {
        console.log("error::", data?.data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
      // console.log("deleteFileAPI", deleteFileAPI);
    },
  });

  const handleCloseAction = () => {
    setProfileHook("");
    handleClose();
    if (profileHook) {
      let splitSrc = profileHook?.split(":")[4];
      mutationDelete.mutate({
        storage: "pos_go_globalit",
        folder: "it_pos_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  //======================== Create Bank =========================
  const [createCard] = useMutation(CREATE_CARD, {
    onCompleted: ({ createCard }) => { 
      setLoading(false);
      if (createCard?.isSuccess === true) {
        setAlert(true, "success", createCard?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createCard?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== update Bank =========================
  const [updateCard] = useMutation(UPDATE_CARD, {
    onCompleted: ({ updateCard }) => { 
      setLoading(false);
      if (updateCard?.isSuccess === true) {
        setAlert(true, "success", updateCard?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateCard?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    cardNo: Yup.string().required(t("require")),
    // cardName: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      cardNo: "",
      // cardName: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => { 
      setLoading(true);
      if (dialogTitle === "Create") {
        createCard({
          variables: {
            input: {
              ...value,
              isUse: true,
              imageSrc: profileHook,
            },
          },
        });
      } else {
        updateCard({
          variables: {
            id: editData?._id,
            input: {
              ...value,
              imageSrc: profileHook,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
    }

    if (dialogTitle === "Update" && open) {
      setProfileHook(editData?.imageSrc);
      setFieldValue("remark", editData?.remark);
      setFieldValue("cardNo", editData?.cardNo);
    }
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_number_card")
                : t("dialog_title_update_number_card")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box height="100%">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.imageSrc
                                  ? editData?.imageSrc
                                  : EmptyImage
                            }
                            alt=""
                            style={{ width: "140px" }}
                          />
                          {/* <Avatar 
                            className="avater-image" 
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : EmptyImage
                            }
                          /> */}
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setProgress={setProgress}
                    setStatusProgress={setStatusProgress}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}

                {statusProgress && progress < 100 && (
                  <Box sx={{ width: "100%", marginLeft: "20px" }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_card_number")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("thead_card_number")}
                  {...getFieldProps("cardNo")}
                  error={Boolean(touched.cardNo && errors.cardNo)}
                  helperText={touched.cardNo && errors.cardNo}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  className="text-field"
                  placeholder={t("remark")}
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
