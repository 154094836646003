import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  FormControl,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import "../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../Context/AuthContext";
import { useFormik, Form, FormikProvider } from "formik";
import { translateLauguage } from "../../Include/Function/Translate";
import { MultipleSelectShop } from "../../Include/Function/DynamicSelect";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";
import { UPDATE_VOUCHER_GROUP, CREATE_VOUCHER_GROUP } from "../../Schema/Voucher";

export const VoucherGroupForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [multipleSelectShop, setMultipleSelectShop] = useState([]);


  //======================== Create Bank =========================
  const [createVoucherGroup] = useMutation(CREATE_VOUCHER_GROUP, {
    onCompleted: ({ createVoucherGroup }) => {
      console.log("createVoucherGroup::", createVoucherGroup);
      setLoading(false);
      if (createVoucherGroup?.isSuccess === true) {
        setAlert(true, "success", createVoucherGroup?.message);
        handleClose();
        resetForm();
        setRefetch();
        setMultipleSelectShop([]);
      } else {
        setAlert(true, "error", createVoucherGroup?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== update Bank =========================
  const [updateVoucherGroup] = useMutation(UPDATE_VOUCHER_GROUP, {
    onCompleted: ({ updateVoucherGroup }) => {
      console.log("updateVoucherGroup::", updateVoucherGroup);
      setLoading(false);
      if (updateVoucherGroup?.isSuccess === true) {
        setAlert(true, "success", updateVoucherGroup?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateVoucherGroup?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    validFrom: Yup.string(),
    expireDate: Yup.string(),
    mainCode: Yup.string().required(t("require")),
    value: Yup.string().required(t("require")),
    limit: dialogTitle === "Update" ? Yup.string() : Yup.string().required(t("require")),
    voucherName: Yup.string().required(t("require")),
    discountType: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      mainCode: "",
      limit: "",
      value: "",
      remark: "",
      validFrom: "",
      expireDate: "",
      voucherName: "",
      discountType: "Percentage",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      console.log("value:::", value);
      setLoading(true);
      if (dialogTitle === "Create") {
        createVoucherGroup({
          variables: {
            length: 6,
            paddingChar: value?.paddingChar,
            limit: parseFloat(value?.limit),
            input: {
              shopId: multipleSelectShop.map(e => e?.id),
              mainCode: value?.mainCode,
              value: parseFloat(value?.value),
              remark: value?.remark,
              validFrom: value?.validFrom,
              expireDate: value?.expireDate,
              voucherName: value?.voucherName,
              discountType: value?.discountType,
            }
          },
        });
      } else {
        updateVoucherGroup({
          variables: {
            id: editData?._id,
            input: {
              mainCode: value?.mainCode,
              value: parseFloat(value?.value),
              shopId: multipleSelectShop.map(e => e?.id),
              remark: value?.remark,
              validFrom: value?.validFrom,
              expireDate: value?.expireDate,
              voucherName: value?.voucherName,
              discountType: value?.discountType,
            }
          },
        });
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;


  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setMultipleSelectShop([]);
    }

    if (dialogTitle === "Update" && open) {

      const shopData = editData?.shopId?.map((e) => ({
        id: e?._id,
        title: language === "en" ? e?.shopNameEn : e?.shopNameKh,
      }))
      setMultipleSelectShop(shopData)
      setFieldValue("mainCode", editData?.mainCode);
      setFieldValue("limit", editData?.limit);
      setFieldValue("value", editData?.value);
      setFieldValue("remark", editData?.remark);
      setFieldValue("validFrom", editData?.validFrom);
      setFieldValue("expireDate", editData?.expireDate);
      setFieldValue("voucherName", editData?.voucherName);
      setFieldValue("discountType", editData?.discountType);

    }
  }, [open, editData]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_voucher")
                : t("dialog_title_update_voucher")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>



              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >

                  {t("thead_voucher")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("thead_voucher")}
                  {...getFieldProps("voucherName")}
                  error={Boolean(touched.voucherName && errors.voucherName)}
                  helperText={touched.voucherName && errors.voucherName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_code_header")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("thead_code_header")}
                  {...getFieldProps("mainCode")}
                  helperText={touched.mainCode && errors.mainCode}
                  error={Boolean(touched.mainCode && errors.mainCode)}
                />
              </Grid>

              {
                dialogTitle === "Create" && <Grid item xs={6}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {t("thead_number_of_cards")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    className="text-field"
                    placeholder="1-1000"
                    value={values.limit}
                    onChange={({ target }) => setFieldValue("limit", target?.value > 1000 ? 1000 : target?.value < 1 ? 1 : target?.value)}
                    error={Boolean(touched.limit && errors.limit)}
                    helperText={touched.limit && errors.limit}
                  />
                </Grid>
              }

              {
                dialogTitle === "Create" && <Grid item xs={6}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {t('thead_labels')}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={"######"}
                    className="text-field"
                    value={values?.paddingChar}
                    onChange={({ target }) => setFieldValue("paddingChar", target?.value.at(-1))}
                  />
                </Grid>
              }

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_valid")}
                </Typography>
                <SelectDatePicker
                  date={values?.validFrom}
                  setDate={(e) => setFieldValue("validFrom", e)}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_expiry")}
                </Typography>
 
                <SelectDatePicker
                  date={values?.expireDate}
                  setDate={(e) => setFieldValue("expireDate", e)}
                />

              </Grid>


              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_discount_type")}
                </Typography>

                <FormControl size="small" fullWidth className="search-field">
                  <Select
                    value={values?.discountType}
                    onChange={(e) => setFieldValue("discountType", e.target.value)}
                  >
                    <MenuItem value={"Amount"}> {t("thead_amount")} </MenuItem>
                    <MenuItem value={"Percentage"}> {t("thead_percentage")} </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_value")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  className="text-field"

                  placeholder={values?.discountType === "Amount" ? "10$" : "10%"}
                  InputProps={{
                    endAdornment: (<InputAdornment position="start">{values?.discountType === "Amount" ? "$" : "%"}</InputAdornment>),
                  }}
                  {...getFieldProps("value")}
                  error={Boolean(touched.value && errors.value)}
                  helperText={touched.value && errors.value}
                />
              </Grid>



              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_shop")}
                </Typography>
                <MultipleSelectShop selectedVal={multipleSelectShop} setSelectedVal={setMultipleSelectShop} />
              </Grid>


              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  className="text-field"
                  placeholder={t("remark")}
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
