import React, { useContext, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  IconButton,
  Typography,
  DialogActions,
  Divider,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import moment from "moment";
import "../../../Style/dialogStyle.scss";
import { useQuery } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { GET_POS_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION } from "../../../Schema/InventoryInShop";

export const ViewStockHistory = ({ open, editData, handleClose, }) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [action, setAction] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();


  const { refetch } = useQuery(GET_POS_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      action: action,
      pagination: true,
      mainProductId: editData?._id,
    },
    onCompleted: ({ getPosStockDetailByMainProductPagination }) => {
      setLoading(false);
      setTableData(getPosStockDetailByMainProductPagination?.data);
      setPaginationData(getPosStockDetailByMainProductPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  const translateAction = (action) => {
    // if (action === "Void") return t('thead_void');
    // if (action === "Sold") return t('thead_sold');
    // if (action === "Refund") return t('thead_return');
    // if (action === "ForUse") return t('thead_for_use');
    // if (action === "Expired") return t('thead_expired');
    // if (action === "Request") return t('thead_request');
    // if (action === "AdjustIn") return t('thead_adjust_in');
    // if (action === "AdjustOut") return t('thead_adjust_out');
    // if (action === "TransferIn") return t('thead_transfer_in');
    // if (action === "TransferOut") return t('thead_transfer_out');
    // else return action;
    const actionMap = {
      Void: 'thead_void',
      Sold: 'thead_sold',
      Free: 'thead_free',
      Refund: 'thead_return',
      ForUse: 'thead_for_use',
      Expired: 'thead_expired',
      Request: 'thead_request',
      AdjustIn: 'thead_adjust_in',
      AdjustOut: 'thead_adjust_out',
      TransferIn: 'thead_transfer_in',
      TransferOut: 'thead_transfer_out'
    };

    return actionMap[action] ? t(actionMap[action]) : action;
  }


  const colorAction = (action) => {
    if (['Broken', 'Void', 'Refund', 'ForUse', 'Sold', 'TransferOut', 'Expired'].includes(action)) return 'red'
    if (['Request', 'Free', 'TransferIn'].includes(action)) return 'green'
  }

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg" >
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("dialog_title_correction_history")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={1} columnSpacing={3}>


          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.6}>
            <FormControl fullWidth size="small" className="search-field">
              <Select value={action} onChange={(event) => setAction(event.target.value)}>
                <MenuItem value={"All"}>{t('thead_transaction')}</MenuItem>
                <MenuItem value={"AdjustIn"}> {t('thead_adjust_in')} </MenuItem>
                <MenuItem value={"AdjustOut"}> {t('thead_adjust_out')} </MenuItem>
                <MenuItem value={"TransferIn"}> {t('thead_transfer_in')} </MenuItem>
                <MenuItem value={"TransferOut"}> {t('thead_transfer_out')} </MenuItem>
                <MenuItem value={"Request"}> {t('thead_request')} </MenuItem>
                <MenuItem value={"Sold"}> {t('thead_sold')} </MenuItem>
                <MenuItem value={"Void"}> {t('thead_void')} </MenuItem>
                <MenuItem value={"Free"}> {t('thead_free')} </MenuItem>
                <MenuItem value={"Refund"}> {t('thead_return')} </MenuItem>


              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack width="100%" direction="column" spacing={2} mt={2}  >
              <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="25%"  >  {t('thead_date')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="25%" textAlign="center"> {t('thead_transaction')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="25%" textAlign="center"> {t('thead_qty')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="25%" textAlign="center"> {t('thead_remaining')} </Typography>
              </Stack>
              <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
                <Divider />
                {loading ? <LoadingPage /> :
                  tableData?.length === 0 ? <EmptyData /> :
                    tableData?.map((e) => (
                      <Stack width="100%" direction="column" spacing={1.7}>
                        <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ paddingX: 2 }}>
                          <Typography width="25%" > {moment(e?.createdAt).format("DD-MM-YYYY hh:mm a")} </Typography>
                          <Typography width="25%" textAlign="center"> {translateAction(e?.action)} </Typography>
                          <Typography width="25%" textAlign="center" color={colorAction(e?.action)}> {e?.action === "AdjustOut" && "-"} {e?.qty} {language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName}  </Typography>
                          <Typography width="25%" textAlign="center"> {e?.remain} {language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName}  </Typography>
                        </Stack>
                        <Divider />
                      </Stack>
                    ))
                }
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {/* ==================== Pagination  ============================= */}
            <FooterPagination
              page={page}
              limit={limit}
              setPage={setPage}
              handleLimit={handleLimit}
              totalDocs={paginationData?.totalDocs}
              totalPages={paginationData?.totalPages}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
            fullWidth
            onClick={handleClose}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
