import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";

//Icons
import CreateIcon from "@mui/icons-material/Create";
import { RiDeleteBin5Fill } from "react-icons/ri";

//Srcs
import "../../../../Style/actionStyle.scss";
import ViewBarcode from "../Barcode/ViewBarcode";
import { SubProductForm } from "./SubProductForm"; 
import QrCodeIcon from '@mui/icons-material/QrCode';
import { DeleteSubProduct } from "./DeleteSubProduct";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ViewSubProduct } from "./ViewSubProduct/ViewSubProduct";
export default function SubProductAction({
  editData,
  setRefetch,
  mainProduct,
}) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);


  //Modal update
  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);



  //Modal update
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal update
  const [openBar, setOpenBar] = useState(false);
  const handleOpenBar = () => setOpenBar(true);
  const handleCloseBar = () => setOpenBar(false);


  return (
    <div>
      <Stack direction="row" justifyContent="right">


        <Tooltip title={t("thead_barcode")} placement="top">
          <IconButton onClick={handleOpenBar}>
            <QrCodeIcon sx={{ color: "#080E4C", border: "1px solid #080E4C", borderRadius: "5px" }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("thead_view")} placement="top"  >
          <IconButton onClick={handleOpenView}  >
            <Box className="view-container" >
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>
 
        <Tooltip title={t("tooltip-update")} placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <CreateIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("tooltip-delete")} placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <RiDeleteBin5Fill className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <SubProductForm
        open={openEdit}
        editData={editData}
        ownId={mainProduct?._id}
        dialogTitle="Update"
        setRefetch={setRefetch}
        mainProduct={mainProduct}
        handleClose={handleCloseEdit}
      />

      <ViewSubProduct
        open={openView}
        editData={editData} 
        setRefetch={setRefetch}
        ownId={mainProduct?._id}
        mainProduct={mainProduct}
        handleClose={handleCloseView}
      />


      <DeleteSubProduct
        open={openDel}
        setRefetch={setRefetch}
        editData={editData?._id}
        handleClose={handleCloseDel}
      />


      <ViewBarcode
        open={openBar}
        editData={editData}
        handleClose={handleCloseBar}
      />

    </div>
  );
}
