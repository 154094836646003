import { Stack, TableCell, TableBody, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
export default function LoadingPage({ colSpan, height, size }) {
  return (
    <>
      {colSpan ? (
        <TableBody className="body">
          <TableRow className="body-row">
            <TableCell colSpan={colSpan} className="body-cell">
              <Stack
                width="100%"
                height="300px"
                bgcolor="white"
                direction="column"
                justifyContent="center"
              >
                <Stack direction="row" justifyContent="center">
                  <CircularProgress
                    color="primary"
                    size={size ? size : "30px"}
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <Stack
          width="100%"
          height={height ? height : "300px"}
          bgcolor="white"
          direction="column"
          justifyContent="center"
        >
          <Stack direction="row" justifyContent="center">
            <CircularProgress color="primary" size={size ? size : "30px"} />
          </Stack>
        </Stack>
      )}
    </>
  );
}
