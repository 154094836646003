import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { SelectMainProduct, SelectMainProductByShop } from "../../../Include/Function/DynamicSelect";
import EmptyData from "../../../Include/EmptyData/EmptyData";
export default function SelectRequestProducts({ purchaseProductsData, setPurchaseProductsData, required, }) {
  // ===================== Change Language ========================== 
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newItem = {
    qty: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }

  const handleAddItem = () => setPurchaseProductsData([...purchaseProductsData, newItem]);

  const handleRemoveItem = (key) => {
    const newData = purchaseProductsData.filter((e) => e.key !== key);
    setPurchaseProductsData([...newData]);
  }

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = purchaseProductsData.map((e) => {
      if (e.key === key && fieldName === "qty") e.qty = value;
      if (e.key === key && fieldName === "mainProductId") e.mainProductId = value;
      return e;
    })
    setPurchaseProductsData([...newData]);
  }
  const shopId = window.localStorage.getItem("shopId");

  return (
    <Stack direction="column" width="100%" spacing={1}>


      <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingX: 2 }}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="60%">
          {t("thead_product")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
          {t("thead_qty")}
        </Typography>
        <IconButton onClick={handleAddItem}  >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
 
      <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
        <Divider />
        {
          purchaseProductsData?.length === 0 ? <EmptyData /> :
            purchaseProductsData?.map((e, key) => (
              <Stack width="100%" direction="column" spacing={1.7} key={key}>
                <Stack width="100%" direction="row" spacing={2} justifyContent="space-between" sx={{ paddingX: 2 }}>
                  <Stack sx={{ width: "60%" }}>
                    <SelectMainProductByShop
                      shopId={shopId}
                      required={required}
                      selectedVal={e?.mainProductId}
                      dataList={purchaseProductsData}
                      setSelectedVal={(value) => handleUpdateItem(e.key, value, "mainProductId")}
                    />
                  </Stack>

                  <TextField
                    sx={{ width: "40%" }}
                    fullWidth
                    size="small"
                    type="number"
                    value={e?.qty}
                    className="text-field"
                    error={!e?.qty && required}
                    placeholder={t('thead_qty')}
                    InputProps={{ endAdornment: e?.mainProductId?.unit }}
                    onChange={({ target }) => handleUpdateItem(e.key, target.value < 0 ? 0 : target.value, "qty")}
                  />

                  <IconButton onClick={() => handleRemoveItem(e?.key)}>
                    <CloseOutlinedIcon sx={{ color: "red" }} />
                  </IconButton>
                </Stack>
                <Divider />
              </Stack>
            ))
        }
      </Stack> 
    </Stack>


  );
}
