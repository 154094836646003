import React, { useContext, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  IconButton,
  Typography,
  DialogActions,
  Divider,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import "../../../Style/dialogStyle.scss";
import { BiMinusCircle } from "react-icons/bi";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectWarehouse } from "../../../Include/Function/DynamicSelect";
import { GET_MAIN_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION } from "../../../Schema/InventoryInWarehouse";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import EmptyData from "../../../Include/EmptyData/EmptyData";

export const ViewStockHistory = ({
  open,
  editData,
  handleClose,
}) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [action, setAction] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [paginationData, setPaginationData] = useState();
  const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({ id: "", title: "" });


  const { refetch } = useQuery(GET_MAIN_STOCK_DETAIL_BY_MAIN_PRODUCT_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      action: action,
      pagination: true,
      mainProductId: editData?._id,
      warehouseId: selectedWarehouseVal?.id || null,
    },
    onCompleted: ({ getMainStockDetailByMainProductPagination }) => {
      setLoading(false);
      setTableData(getMainStockDetailByMainProductPagination?.data);
      setPaginationData(getMainStockDetailByMainProductPagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  const translateAction = (action) => {
    if (action === "Request") return t('thead_request');
    if (action === "TakeOut") return t('thead_take_out');
    if (action === "Purchase") return t('thead_purchase');
    if (action === "Transfer") return t('thead_transfer');
    if (action === "AdjustIn") return t('thead_adjust_in');
    if (action === "AdjustOut") return t('thead_adjust_out');
    if (action === "TransferOut") return t('thead_transfer_out');
    else return action;
  }

  const colorAction = (action) => {
    if (action === "AdjustOut") return "red";
    if (action === "AdjustIn") return "green";
    if (action === "TransferOut") return "red";
    if (action === "Request") return "#1d4592";
    if (action === "TakeOut") return "#CC9110";
    if (action === "Purchase") return "#1d4592";
    if (action === "Transfer") return "#147BC9";
  }
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("dialog_title_correction_history")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={1} columnSpacing={3}>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.6}>
            <SelectWarehouse
              className={"search-field"}
              selectedVal={selectedWarehouseVal}
              setSelectedVal={setSelectedWarehouseVal}
              placeholder={t("label_select_warehouse")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.6}>
            <FormControl fullWidth size="small" className="search-field">
              <Select value={action} onChange={(event) => setAction(event.target.value)}>
                <MenuItem value={"All"}>{t('thead_transaction')}</MenuItem>
                <MenuItem value={"Purchase"}> {t('thead_purchase')} </MenuItem>
                <MenuItem value={"AdjustIn"}> {t('thead_adjust_in')} </MenuItem>
                <MenuItem value={"AdjustOut"}> {t('thead_adjust_out')} </MenuItem>
                <MenuItem value={"Transfer"}> {t('thead_transfer')} </MenuItem>
                <MenuItem value={"Request"}> {t('thead_request')} </MenuItem>
                <MenuItem value={"TakeOut"}> {t('thead_take_out')} </MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack width="100%" direction="column" spacing={2} mt={2}  >
              <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%"  >  {t('thead_date')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%" textAlign="center"> {t('thead_warehouse')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%" textAlign="center"> {t('thead_transaction')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%" textAlign="center"> {t('thead_qty')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%" textAlign="center"> {t('thead_remaining')} </Typography>
              </Stack>
              <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
                <Divider />
                {loading ? <LoadingPage /> :
                  tableData?.length === 0 ? <EmptyData /> :
                    tableData?.map((e, index) => (
                      <Stack width="100%" direction="column" spacing={1.7} key={index}>
                        <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ paddingX: 2 }}>
                          <Typography width="20%"  > {moment(e?.createdAt).format("DD.MM.YYYY hh:mm a")} </Typography>
                          <Typography width="20%" textAlign="center"> {language === "en" ? e?.warehouseId?.warehouseEnName : e?.warehouseId?.warehouseKhName} </Typography>
                          <Typography width="20%" textAlign="center"> {translateAction(e?.action)} </Typography>
                          <Typography width="20%" textAlign="center" color={colorAction(e?.action)}> {(e?.action === "TransferOut") && "-"} {(e?.action === "AdjustOut") && "-"} {e?.qty} {language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName}  </Typography>
                          <Typography width="20%" textAlign="center"> {e?.remain} {language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName}  </Typography>
                        </Stack>
                        <Divider />
                      </Stack>
                    ))
                }
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {/* ==================== Pagination  ============================= */}
            <FooterPagination
              page={page}
              limit={limit}
              setPage={setPage}
              handleLimit={handleLimit}
              totalDocs={paginationData?.totalDocs}
              totalPages={paginationData?.totalPages}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
            fullWidth
            onClick={handleClose}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
