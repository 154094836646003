import React, { useContext } from "react";
import "./itemproductforsale.scss";
import { Stack, Button, Skeleton, Grid } from "@mui/material";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";

export default function LoadingSkeletonItemProductForSale() {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  return (
    <Grid container spacing={1}>
      {[1, 2, 3, 4, 5, 6].map((e, index) => (
        <Grid
          item
          xs={3}
          sm={2}
          md={4}
          lg={3}
          xl={2}
          key={index}
          sx={{ cursor: "pointer" }}
        >
          <Button className="card-product-for_sale">
            <Stack
              direction="column"
              sx={{ width: "100%", padding: "8px", paddingTop: "15px" }}
              alignItems="center"
              spacing={1}
            >
              <Skeleton variant="rounded" width="90%" height={145} />
              <Stack direction="column" sx={{ width: "90%" }}>
                <Skeleton variant="text" sx={{ fontSize: "0.7rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "0.7rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "0.7rem" }} />
              </Stack>
            </Stack>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
