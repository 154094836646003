import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import "../../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SelectReceiveProduct from "./SelectReceiveProduct";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { RECEIVE_TRANSFER_FROM_WAREHOUSE } from "../../../../Schema/InventoryInShop";

export const ReceiveProduct = ({ open, handleClose, hideBackdrop, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [purchaseProductsData, setPurchaseProductsData] = useState([]);

  //======================== Create User =========================
  const [receiveTransferFromWarehouse] = useMutation(RECEIVE_TRANSFER_FROM_WAREHOUSE, {
    onCompleted: ({ receiveTransferFromWarehouse }) => { 
      setLoading(false);
      if (receiveTransferFromWarehouse?.isSuccess === true) {
        setAlert(true, "success", receiveTransferFromWarehouse?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", receiveTransferFromWarehouse?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });



  const handleSubmit = () => {

    const requiredProduct = purchaseProductsData?.map((e) => e?.takeQty ? true : false)?.filter((e) => !e);

    if (requiredProduct?.length > 0) {
      setRequired(true);
      return;
    }

    const purchaseData = purchaseProductsData.map((e) => ({
      takeQty: parseFloat(e?.takeQty),
      mainProductId: e?.mainProductId?.id,
    }));

    setLoading(true);
 
    receiveTransferFromWarehouse({
      variables: {
        id: editData?._id,
        requestId: editData?.requestId?._id,
        input: {
          receiveBy: {
            remark: remark
          },
          transferProducts: purchaseData
        }
      }
    });
  }

  useEffect(() => {
    const purchaseData = editData?.transferProducts.map((e) => ({
      mainProductId: {
        id: e?.mainProductId?._id,
        title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,
      },
      takeQty: e?.takeQty,
      deliverQty: e?.deliverQty,
      warehouseIds: e?.warehouseIds?.map(e => ({ qty: e?.qty, avgPrice: e?.avgPrice, warehouseId: e?.warehouseId?._id, })),
      key: Math.random() + Date.now(),
    })).filter((e) => e?.deliverQty > 0);
    setPurchaseProductsData(purchaseData);
  }, [open, editData]);

  console.log('editData===>', editData);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_receive_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>
          {editData?.requestId &&
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                  {t("thead_request_no")}
                </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                  :  {editData?.requestId?.requestNo}
                </Typography>
              </Stack>
            </Grid>}


          <Grid item xs={12}>
            <SelectReceiveProduct
              required={required}
              purchaseProductsData={purchaseProductsData}
              setPurchaseProductsData={setPurchaseProductsData}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
              {t("remark")}
            </Typography>
            <TextField
              fullWidth
              rows={3}
              multiline
              size="small"
              className="text-field"
              onChange={({ target }) => setRemark(target?.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-receive")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
