import { gql } from "@apollo/client";

export const CREATE_UNIT = gql`
  mutation CreateUnit($input: UnitInput) {
    createUnit(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation UpdateUnit($id: ID, $input: UnitInput) {
    updateUnit(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_UNIT_WITH_PAGINATION = gql`
  query GetUnitWithPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
  ) {
    getUnitWithPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
    ) {
      data {
        _id
        unitKhName
        unitEnName
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation DeleteUnit($id: ID) {
    deleteUnit(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
