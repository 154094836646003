import React, { useReducer, createContext, useEffect, useState } from "react";

// reducer
const itGlobalReducer = (state, action) => {
  switch (action.type) {
    case "LOGGED_IN_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

// state
const initialState = {
  user: null,
};

// create context
const AuthContext = createContext();

// context provider
const AuthProvider = ({ children }) => {
  //================ Token User Login ===============
  const [state, dispatch] = useReducer(itGlobalReducer, initialState);

  // =================  change language ================
  const [language, setLanguage] = useState("en");
  const changeLanguage = (lang) => {
    setLanguage(lang);
    window.localStorage.setItem("language", JSON.stringify(lang));
  };

  useEffect(() => {
    async function getLanguage() {
      let newLang = await JSON.parse(window.localStorage.getItem("language"));
      if (newLang) {
        setLanguage(newLang);
      } else {
        setLanguage("kh");
      }
    }
    getLanguage();
  }, []);

  // ====================  Alert Message ==================================
  const [open, setOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [messageAlert, setMessageAlert] = useState({
    messageKh: "",
    messageEn: "",
  });
  const setAlert = (open, alert, message) => {
    setOpen(open);
    setAlertStatus(alert);
    setMessageAlert(message);
  };
  const alert = () => {
    return { open: open, status: alertStatus, message: messageAlert };
  };

  //================= Value Return ================
  const value = {
    state,
    alert,
    setAlert,
    dispatch,
    language,
    changeLanguage,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export
export { AuthContext, AuthProvider };
