import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CustomerInput) {
    createCustomer(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      customer {
        _id
        name
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: ID!, $input: CustomerInput) {
    updateCustomer(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_CUSTOMER_PAGINATION = gql`
  query GetCustomerPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $isUse: Boolean
    $pagination: Boolean
  ) {
    getCustomerPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      isUse: $isUse
      pagination: $pagination
    ) {
      data {
        _id
        name
        gender
        phone
        email
        address
        company
        isUse
        remark
        passport
        nationality
        nationalCard
        country
        imageSrc
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
