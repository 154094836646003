import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import "./invoice.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import { currencyFormat } from "../../../../Include/Function/DynamicFn";
import { translateLauguage } from "../../../../Include/Function/Translate";

export default function TableInvoice({ tableData }) {
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box className="inv-table-contaniner" sx={{ marginTop: "10px" }}>
      <TableContainer sx={{ maxWidth: `${width}px` }}>
        <Table className="table">
          <TableHead className="table-head">
            <TableRow className="table-row">
              <TableCell
                className="table-cell-header text-invoice"
                width={`10%`}
                align="center"
              ></TableCell>
              <TableCell
                className="table-cell-header text-invoice"
                width={`35%`}
              >
                បរិយាយ
              </TableCell>
              <TableCell
                className="table-cell-header text-invoice"
                width={`20%`}
                align="center"
              >
                បរិមាណ
              </TableCell>
              <TableCell
                className="table-cell-header text-invoice"
                width={`15%`}
                align="center"
              >
                តម្លៃ
              </TableCell>
              <TableCell
                className="table-cell-header text-invoice"
                align="right"
                width={`20%`}
              >
                សរុប
              </TableCell>
            </TableRow>
          </TableHead>

          {tableData?.length === 0 ? (
            <TableBody className="table-body">
              <TableRow className="body-row">
                <TableCell colSpan={6} className="body-cell">
                  <Typography>No Data</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody className="table-body">
                {tableData?.map((row, index) => {
                  return (
                    <TableRow className="body-row" key={index}>
                      <TableCell
                        className="body-cell-text text-invoice"
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="body-cell-text text-invoice">
                        {language === "en"
                          ? row?.productId?.mainProductId?.mainProductEnName
                          : row?.productId?.mainProductId?.mainProductKhName}
                      </TableCell>
                      <TableCell
                        className="body-cell-text text-invoice"
                        align="center"
                      >
                        {row?.qty}{" "}
                        {language === "en"
                          ? row?.productId?.unitId?.unitEnName
                          : row?.productId?.unitId?.unitKhName}
                      </TableCell>
                      <TableCell
                        className="body-cell-text text-invoice"
                        align="center"
                      >
                        {currencyFormat(row?.price)}
                      </TableCell>
                      <TableCell
                        className="body-cell-text text-invoice"
                        align="right"
                      >
                        {currencyFormat(row?.total)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
