import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../Context/AuthContext';
import { translateLauguage } from '../../../../Include/Function/Translate';

const useItemProductToPay = () => {
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);
    const [itemProductToPay, setItemProductToPay] = useState([]);
    const [loadingItemProductToPay, setLoadingItemProductToPay] = useState(true);
    const [selectedCardNumberVal, setSelectedCardNumberVal] = useState({
        id: "",
        title: "",
        customerId: "",
    });


    // ============================= Increase item qty in card ============================
    const handleIncreaseQtyItemInCard = (itemId) => {
        const items = itemProductToPay?.map((e) => {
            if (e?.productId === itemId) e.qty += 1;
            return e;
        });
        setItemProductToPay([...items]);
    };



    //=================================== add item to invoice =================================
    const handleSelectProductItem = (row) => {
        let newObject = {};
        newObject = {
            qty: 1,
            productId: row?._id,
            price: row?.salePrice,
            imageSrc: row?.imageSrc,
            key: Date.now() + Math.random(),
            mainProductId: row?.mainProductId?._id,
            discount: { type: "Cash", remark: "", amount: 0 },
            productName:
                language === "en"
                    ? row?.mainProductId?.mainProductEnName
                    : row?.mainProductId?.mainProductKhName,
        };

        const ifHaveProduct = itemProductToPay?.filter((e) => e?.productId === row?._id);

        if (ifHaveProduct?.length === 0)
            setItemProductToPay([...itemProductToPay, newObject]);
        if (ifHaveProduct?.length > 0) handleIncreaseQtyItemInCard(row?._id);
    };



    //================================== Minus items qty in card ===========================
    const handleMinusQtyItemFromCard = (itemId) => {
        const items = itemProductToPay
            ?.map((e) => {
                if (e?.productId === itemId) e.qty = e.qty - 1;
                return e;
            })
            .filter((e) => e?.qty > 0);
        setItemProductToPay([...items]);
    };



    // ============================= Delete item in card ===============================
    const handleDeleteItemInCard = (itemId) => {
        const filterItem = itemProductToPay?.filter((d) => d?.productId !== itemId);
        setItemProductToPay(filterItem);
    };



    // ============================= Delete all item from card ========================
    const handleDeleteAllItemsFromCard = () => setItemProductToPay([]);



    // =========================== Update input qty item ==========================
    const handleUpdateInputQtyItem = (itemId, newQty) => {
        const items = itemProductToPay?.map((i) => {
            if (i.productId === itemId)
                i.qty = parseFloat(newQty || 1) > 0 ? parseFloat(newQty || 1) : 1;
            return i;
        });
        setItemProductToPay([...items]);
    };



    // =========================== handle open extra item ========================== 
    const handleChangeDiscount = (itemId, fieldName, value) => {
        const items = itemProductToPay?.map((e) => {
            if (e?.productId === itemId && fieldName === "type")
                e.discount.type = value;
            if (e?.productId === itemId && fieldName === "remark")
                e.discount.remark = value;
            if (e?.productId === itemId && fieldName === "amount")
                e.discount.amount = parseFloat(value || 0);
            return e;
        });
        setItemProductToPay([...items]);
    };



    //================================= Find total amount  =======================================
    let subTotal = itemProductToPay?.reduce(
        (a, b) => a + (b?.price || 0) * (b?.qty || 0),
        0
    );



    let totalDiscount = itemProductToPay
        ?.map((e) =>
            e?.discount?.type === "Percentage"
                ? (e?.qty * e?.price * (e?.discount?.amount || 0)) / 100
                : e?.discount?.amount
        )
        ?.reduce((a, b) => a + b, 0);



    const totalAomunt = subTotal - totalDiscount; 
    return {
        subTotal,
        totalAomunt,
        totalDiscount,
        itemProductToPay,
        setItemProductToPay,
        handleChangeDiscount,
        selectedCardNumberVal,
        handleDeleteItemInCard,
        loadingItemProductToPay,
        handleSelectProductItem,
        setSelectedCardNumberVal,
        handleUpdateInputQtyItem,
        handleMinusQtyItemFromCard,
        setLoadingItemProductToPay,
        handleIncreaseQtyItemInCard,
        handleDeleteAllItemsFromCard,
    }
}

export default useItemProductToPay
