import React, { useState } from "react";
import { Typography, Stack, Box, Grid, Button } from "@mui/material";
import "./shop.scss";
import { useQuery } from "@apollo/client";
import CreateIcons from "../../Assets/add.png";
import CardShop from "../../Components/Shop/CardShop";
import { AuthContext } from "../../Context/AuthContext";
import { ShopForm } from "../../Components/Shop/ShopForm";
import EmptyData from "../../Include/EmptyData/EmptyData";
import { GET_SHOP_WITH_PAGINATION } from "../../Schema/Shop";
import LoadingPage from "../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../Include/Function/Translate";

export default function Shop() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpen = () => setOpenCreate(true);
  const handleClose = () => setOpenCreate(false);

  const [loading, setLoading] = useState(true); 
  const [tableData, setTableData] = useState([]);

  const { refetch, data } = useQuery(GET_SHOP_WITH_PAGINATION, {
    pollInterval: 1000, // Poll every 5 seconds
    fetchPolicy: "cache-and-network", // Allows polling to work
    variables: {
      page: 1,
      limit: 10,
      keyword: "",
      pagination: false,
    },
    onCompleted: ({ getShopPagination }) => {
      setTableData(getShopPagination?.data);
      setLoading(false);
    },
    onError: ({ message }) => {
      console.log("Error::", message);
    },
  });

  return (
    <div className="shop-page">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-shop`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container>
          <Grid item xs={12} justifyContent={`right`}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create-style"
                startIcon={
                  <img
                    src={CreateIcons}
                    alt="CreateIcons"
                    className="icon-add"
                  />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create-shop")}
                </Typography>
              </Button>
            </Stack>
            <ShopForm
              open={openCreate}
              dialogTitle="Create"
              setRefetch={refetch}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12}>
              {" "}
              <LoadingPage />{" "}
            </Grid>
          ) : tableData?.length === 0 ? (
            <Grid item xs={12}>
              {" "}
              <EmptyData />{" "}
            </Grid>
          ) : (
            tableData?.map((item, index) => (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
                <CardShop item={item} setRefetch={refetch} />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </div>
  );
}
