import React, { useState, useContext } from "react";
import moment from "moment";
import { Stack, Typography, Box } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LogoCompany from "../../../Assets/it-logo-invoice.png";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";

const MainRecord = ({ title, Amount }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bold-main"></td>
      <td className="report-content-cell-right-bg-main" colSpan={5}>
        Total {title}
      </td>
      <td className="report-content-cell-right-bg-main">
        {Amount?.toFixed(2)} $
      </td>
    </tr>
  );
};

const FinalRecord = ({ title, totalAmount }) => {
  return (
    <tr style={{ height: "40px" }}>
      <td
        colSpan={6}
        className="tr-close-shift-sticky report-content-cell-right-bg-main"
        style={{ paddingLeft: "50px", paddingRight: "30px" }}
      >
        {title}
      </td>
      <td className="tr-close-shift-sticky report-content-cell-right-bg-main">
        {totalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

export default function StockInReport({
  componentRef,
  outletShop,
  fromDate,
  toDate,
}) {
  //===================  Change Language ======================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);

  //=================== Get Payment Reports ===================
  const [stockInReport, setStockInReport] = useState(null);

  // const { refetch } = useQuery(GET_STOCK_IN_REPORT, {
  //   variables: {
  //     outlet: outletShop !== "select" ? outletShop : role,
  //     shiftId: shiftID,
  //     from: fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : "",
  //     to: toDate ? moment(toDate)?.format("YYYY-MM-DD") : "",
  //     type: filterType,
  //     productId: "",
  //     categoryId: "",
  //     typeOfIn: "All",
  //   },
  //   onCompleted: ({ getPOSStockInReport }) => {
  //     console.log("getPOSStockInReport", getPOSStockInReport);
  //     setTimeout(() => {
  //       setLoading(false);
  //       setStockInReport(getPOSStockInReport);
  //     }, 300);
  //   },
  //   onError: (err) => {
  //     console.log("Error::", err?.message);
  //   },
  // });

  // useEffect(() => {
  //   setLoading(true);
  //   refetch();
  // }, [outletShop, shiftID, filterType, fromDate, toDate]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {!(stockInReport === undefined || stockInReport === null) ? (
            <Box className="page-container-report-main">
              <Stack
                direction="row"
                justifyContent="center"
                mb={1}
                spacing={4}
                height="100%"
              >
                <Stack direction="column" justifyContent="center" height="100%">
                  <Box height="100%" marginTop="15px">
                    <img src={LogoCompany} className="page-report-logo-size" />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Stack
                    className="page-header-report-title-main"
                    direction="row"
                    justifyContent="center"
                  >
                    Stock In Reports {outletShop}
                  </Stack>
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Typography className="date-time">DD-MMM-YYYY</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <EmptyData />
            </Box>
          ) : (
            <>
              <Box ref={componentRef} className="page-container-report-main">
                <Stack
                  direction="row"
                  justifyContent="center"
                  mb={1}
                  spacing={4}
                  height="100%"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Box height="100%" marginTop="15px">
                      <img
                        src={LogoCompany}
                        className="page-report-logo-size"
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      className="page-header-report-title-main"
                      direction="row"
                      justifyContent="center"
                    >
                      Stock In {outletShop}
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography className="date-time">
                        {moment(fromDate).format("DD-MMM-YYYY") ===
                        moment(toDate).format("DD-MMM-YYYY")
                          ? moment(fromDate).format("DD-MMM-YYYY")
                          : moment(fromDate).format("DD-MMM-YYYY") +
                            " - " +
                            moment(toDate).format("DD-MMM-YYYY")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <table className="report-container-main">
                  <thead className="report-header-main">
                    <tr>
                      <td className="header-title-report-main" width="6%">
                        Type
                      </td>
                      <td className="header-title-report-main" width="18%">
                        Name
                      </td>
                      <td className="header-title-report-main">Date</td>
                      <td className="header-title-report-main">Time</td>
                      <td className="header-title-report-main">Quantity</td>
                      <td className="header-title-report-main" width="10%">
                        Unit price
                      </td>
                      <td
                        className="header-title-report-main"
                        style={{ textAlign: "right", paddingRight: "10px" }}
                        width="10%"
                      >
                        Total
                      </td>
                    </tr>
                  </thead>

                  <tfoot className="report-footer-main">
                    <tr>
                      <td className="report-footer-cell" colSpan={7}>
                        <div className="footer-info-main">
                          <div className={"page-footer-main"}>
                            <div className="page-footer-line-main" />
                            <div className={"page-footer-text-main"}>
                              Boeungdonpa Village, Slokram Commune Siem Reap /
                              Telephone: 078 881 919
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                  <tbody className="report-content-main">
                    {[1, 2, 3].map(() => (
                      <>
                        {[1, 2, 4, 5, 6].map((e, index) => (
                          <tr>
                            {index === 0 && (
                              <td
                                className="report-content-cell-center-main"
                                rowSpan={
                                  index === 0 ? [1, 2, 4, 5, 6].length : 1
                                }
                              >
                                Type
                              </td>
                            )}
                            <td
                              className="report-content-cell-center-main"
                              style={{ textAlign: "center !important" }}  
                              align="left"
                            >
                              Cocacola
                            </td>
                            <td className="report-content-cell-center-main">
                              {moment()?.format("DD-MM-YYYY")}
                            </td>
                            <td className="report-content-cell-center-main">
                              {moment()?.format("hh:mm A")}
                            </td>
                            <td className="report-content-cell-center-main">
                              1 can
                            </td>
                            <td className="report-content-cell-center-main">
                              0.50 $
                            </td>
                            <td className="report-content-cell-right-main">
                              13.00 $
                            </td>
                          </tr>
                        ))}

                        <MainRecord title={"total Stock in"} Amount={100} />
                      </>
                    ))}

                    {/* ========================== Final ====================== */}
                    <FinalRecord
                      title={"Total"}
                      totalAmount={stockInReport?.total}
                    />
                  </tbody>
                </table>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
}
