import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import { useMutation } from "@apollo/client";
import "../../../../src/Style/dialogStyle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CANCEL_CLOSE_SHIFT } from "../../../Schema/Shop";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";

export const IconButtonCancelCloseShift = ({
  shopId,
  setRefetch,
  hanldeclose,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [cancelCloseShift] = useMutation(CANCEL_CLOSE_SHIFT, {
    onCompleted: ({ cancelCloseShift }) => {
      if (cancelCloseShift?.isSuccess === true) {
        setRefetch();
        hanldeclose();
        window.location.reload("/shop");
      }
    },
  });

  const handleCancelCloseShift = () => {
    cancelCloseShift({
      variables: {
        shopId: shopId,
      },
    });
  };

  return (
    <IconButton onClick={() => handleCancelCloseShift()}>
      <CloseIcon sx={{ color: "red" }} fontSize="large" />
    </IconButton>
  );
};
