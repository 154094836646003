import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons


import CreateIcon from "@mui/icons-material/Create";

//Srcs
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { UpdateUser } from "./UpdateUser";

export default function UserAction({ editData, setRefetch, userLogin }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title={t("tooltip-update")} placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <CreateIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <UpdateUser
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
