import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
  FormHelperText,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import moment from "moment";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../Context/AuthContext";
import SelectTransferProducts from "./SelectTransferProducts";
import { SelectShop } from "../../../Include/Function/DynamicSelect";
import { CREATE_TRANSFER } from "../../../Schema/InventoryInWarehouse";
import { translateLauguage } from "../../../Include/Function/Translate";

export const TransferProduct = ({
  open,
  handleClose,
  setRefetch,
  hideBackdrop,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [transferProductsData, setTransferProductsData] = useState([]);
  const [selectedShopVal, setSelectedShopVal] = useState({ id: "", title: "" });
  const newItem = {
    key: Math.random() + Date.now(),
    warehouseIds: [
      { key: Math.random() + Date.now(), warehouseId: null, qty: null },
    ],
    mainProductId: null,
  };

  //======================== Create User =========================
  const [createTransfer] = useMutation(CREATE_TRANSFER, {
    onCompleted: ({ createTransfer }) => {
      setLoading(false);

      if (createTransfer?.isSuccess === true) {
        setAlert(true, "success", createTransfer?.message);
        handleClose();
        setRefetch();
        resetForm();
        setTransferProductsData([]);
        setSelectedShopVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createTransfer?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    shopId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      shopId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (transferProductsData?.length > 0) {
        const requiredProduct = transferProductsData
          ?.map((e) => {
            const warehouseIdsLength = e?.warehouseIds
              .map((i) => (i?.warehouseId?.id && i?.qty ? true : false))
              ?.filter((e) => !e);

            return e?.mainProductId?.id && warehouseIdsLength.length === 0
              ? true
              : false;
          })
          ?.filter((e) => !e);

        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }

        const transferProducts = transferProductsData.map((e) => ({
          deliverQty: e?.warehouseIds?.reduce(
            (a, b) => a + parseFloat(b?.qty || 0),
            0
          ),
          mainProductId: e?.mainProductId?.id,
          warehouseIds: e?.warehouseIds
            ?.map((i) => ({
              qty: parseFloat(i?.qty || 0),
              warehouseId: i?.warehouseId?.id,
              remainInStock: i?.remainInStock,
            }))
            .filter((e) => e?.qty > 0),
        }));

        setLoading(true);
        createTransfer({
          variables: {
            input: {
              shopId: value?.shopId,
              requestId: null,
              transferBy: {
                remark: value?.remark,
              },
              transferProducts: transferProducts,
            },
          },
        });
      } else {
        setTransferProductsData([newItem]);
      }
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);

  useEffect(() => {
    setFieldValue("shopId", selectedShopVal?.id);
  }, [selectedShopVal]);

  useEffect(() => {
    resetForm();
    setTransferProductsData([newItem]);
    setSelectedShopVal({ id: "", title: "" });
  }, [open]);

  return (
    <Dialog
      open={open}
      className="dialog-container"
      fullWidth
      maxWidth="xl"
      hideBackdrop={hideBackdrop}
    >
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_transfer_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_shop")}
                </Typography>

                <SelectShop
                  selectedVal={selectedShopVal}
                  setSelectedVal={setSelectedShopVal}
                />

                {!!errors.shopId && touched.shopId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.shopId}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <SelectTransferProducts
                  required={required}
                  selectedShopId={selectedShopVal?.id}
                  transferProductsData={transferProductsData}
                  setTransferProductsData={setTransferProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {t("btn-transfer")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
