import { gql } from "@apollo/client";

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($input: SupplierInput) {
    createSupplier(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier($id: ID, $input: SupplierInput) {
    updateSupplier(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      } 
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID) {
    deleteSupplier(_id: $id) {
      message {
        messageEn
        messageKh
      }
      isSuccess
    }
  }
`;

export const GET_SUPPLIER_PAGINATION = gql`
  query GetSupplierPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
    $isUse: Boolean
  ) {
    getSupplierPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
      isUse: $isUse
    ) {
      data {
        _id
        name
        phone
        email
        address
        platforms {
          platformName
          url
          username
        }
        bankAccount {
          bankId {
            _id
            bankName
            remark
            isUse
          }
          accountName
          accountNumber
        }
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
