import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext.js";
import { IconButton, Stack, Typography } from "@mui/material";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { SelectMainProduct, SelectMainProductByShop } from "../../../Include/Function/DynamicSelect";
import GetRemainByProductInShop from "./GetRemainByProductInShop.jsx";

export default function SelectTransferProducts({ transferProductsData, setTransferProductsData, required, }) {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newItem = {
    qty: null,
    remainInStock: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }

  const handleAddItem = () => setTransferProductsData([...transferProductsData, newItem]);

  const handleRemoveItem = (key) => {
    const newData = transferProductsData.filter((e) => e.key !== key);
    setTransferProductsData([...newData]);
  }

  const handleUpdateItem = (key, value, fieldName) => {

    const newData = transferProductsData.map((e) => {
      if (e.key === key && fieldName === "qty") e.qty = value;
      if (e.key === key && fieldName === "remainInStock") e.remainInStock = value;
      if (e.key === key && fieldName === "mainProductId") e.mainProductId = value;

      return e;
    });
    setTransferProductsData([...newData]);
  }

  const shopId = window.localStorage.getItem("shopId");

  return (
    <Stack direction="column" width="100%" spacing={1}>

      <Stack direction="row" alignItems="center" width="100%" spacing={1}>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="36%">
          {t("thead_product")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="32%">
          {t("thead_qty_in_warehouse")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="32%">
          {t("thead_transfer")}
        </Typography>
        <IconButton onClick={handleAddItem}  >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" width="100%" spacing={2}>
        {
          transferProductsData?.map((e) => (<Stack direction="row" alignItems="center" spacing={1}>
            <Stack sx={{ width: "35%" }}>
              <SelectMainProductByShop
                shopId={shopId}
                required={required}
                selectedVal={e?.mainProductId}
                dataList={transferProductsData}
                setSelectedVal={(value) => handleUpdateItem(e.key, value, "mainProductId")}
              />
            </Stack>
            <Stack sx={{ width: "65%" }}>
              <GetRemainByProductInShop
                e={e}
                required={required}
                handleUpdateItem={handleUpdateItem}
              />
            </Stack>
            <IconButton onClick={() => handleRemoveItem(e?.key)}>
              <CloseOutlinedIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>)
          )
        }
      </Stack>
    </Stack>


  );
}
