import React, { useState, useEffect, useContext } from "react";
import {
  Menu,
  Stack,
  Badge,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import moment from "moment";
import "./productinvoice.scss";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { GET_SUSPEND_INVOICE } from "../../Schema/Invoice";
import { padNumber } from "../../Include/Function/DynamicFn";
import { translateLauguage } from "../../Include/Function/Translate";
import UpdateSuspendInvoice from "./UpdateSuspendInvoice/UpdateSuspendInvoice";
import useItemProductToPay from "./UpdateSuspendInvoice/Hook/useItemProductToPay";

export default function SuspendInvoice({ shiftId, openPayment }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [suspendInvoice, setSuspendInvoice] = useState([]);
  const { setLoadingItemProductToPay } = useItemProductToPay();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [openUpdateSuspend, setOpenUpdateSuspend] = useState(false);
  const handleOpenUpdateSuspend = () => setOpenUpdateSuspend(true);
  const handleCloseUpdateSuspend = () => setOpenUpdateSuspend(false);

  //================= Get Invoice Suspend ============
  const { refetch } = useQuery(GET_SUSPEND_INVOICE, {
    variables: {
      shiftId: shiftId,
    },
    onCompleted: ({ getSuspendInvoice }) => {
      setSuspendInvoice(getSuspendInvoice);
    },
  });

  useEffect(() => {
    refetch();
  }, [openPayment, openUpdateSuspend, shiftId]);

  return (
    <>
      <Badge badgeContent={suspendInvoice.length || 0} color="error">
        <Button
          onClick={handleDropDown}
          className={
            language === "en" ? "btn-suspended-top" : "btn-suspended-top-kh"
          }
        >
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography>{t("suspended")}</Typography>
            <MdOutlineArrowDropDown sx={{ width: "50px", height: "50px" }} />
          </Stack>
        </Button>
      </Badge>

      <Menu
        open={open}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {suspendInvoice?.length > 0 ? (
          suspendInvoice?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  setLoadingItemProductToPay(true);
                  window.localStorage.setItem(
                    "CurrentInvoiceId",
                    JSON.stringify(item?._id)
                  );
                  handleOpenUpdateSuspend();
                  handleClose();
                }}
              >
                <Typography color="#0f81c2">
                  {padNumber(
                    item?.invoiceNo,
                    5,
                    moment(item?.createdAt).format("YYYY")
                  )}
                </Typography>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem onClick={() => handleClose()}>
            <Typography>{"No Invoice"}</Typography>
          </MenuItem>
        )}
      </Menu>

      <UpdateSuspendInvoice
        open={openUpdateSuspend}
        handleClose={handleCloseUpdateSuspend}
      />
    </>
  );
}
