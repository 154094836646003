import React, { useContext, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  Avatar,
  Tooltip,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import "./login.scss";
import * as Yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
import logo from "../Assets/it-logo.png";
import chasier from "../Assets/cashier.png";
import CamFlag from "../Assets/Cambodia.png";
import { USER_LOGIN } from "../Schema/Users";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import EngFlag from "../Assets/United_Kingdom.png";
import { AuthContext } from "../Context/AuthContext";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { translateLauguage } from "../Include/Function/Translate";



const Login = () => {
  // ===================== Change Language ==========================
  const { language, changeLanguage, setAlert, dispatch } =
    useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [iconReload, setIconReload] = useState(false);
  const navigate = useNavigate();

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [userLogin] = useMutation(USER_LOGIN, {
    onCompleted: ({ userLogin }) => {
      // console.log("userLogin:::", userLogin);
      if (userLogin?.isSuccess === true) {
        setAlert(true, "success", userLogin?.message);

        setTimeout(() => {
          localStorage.setItem("token", JSON.stringify(userLogin?.data?.token));
          localStorage.setItem(
            "userLogin",
            JSON.stringify(userLogin?.data?.user)
          );
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              token: userLogin?.data?.token,
            },
          });
          navigate("/shop");
          setIconReload(false);
        }, 500);
      } else {
        setIconReload(false);
        setAlert(true, "error", userLogin?.message);
      }
    },
    onError: ({ message }) => {
      setIconReload(false);
      console.log("errror ==>", message); 
      setAlert(true, "error", { messageKh: message, messageEn: message });
    },
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string()
      .required("Required")
      .min(10, "Password must be 10 characters!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setIconReload(true);
      userLogin({
        variables: {
          ...values,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="login-container-page"
    >
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container className="box-container">
              <Grid item xs={7} alignSelf="center">
                <Stack direction="row" justifyContent="center" width="100%">
                  <img width="60%" src={chasier} alt="chasier" />
                </Stack>
              </Grid>
              <Grid item xs={5} sx={{ padding: "40px 10px 30px 20px" }}>
                <Stack direction="column" spacing={3}>
                  <Stack direction={`row`} justifyContent={`center`}>
                    <img src={logo} alt="logo" className="img" style={{borderRadius: "50%"}}/>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    width="100%"
                    className="box-login"
                    spacing={2}
                  >
                    <Stack direction="column">
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("email")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        className="input-login"
                        placeholder="example@company.com"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                    <Stack>
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("password")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder={t("password")}
                        type={show ? "text" : "password"}
                        {...getFieldProps("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={handleClick}
                            >
                              {show ? (
                                <VisibilityIcon className="viewpw-icon" />
                              ) : (
                                <VisibilityOffIcon className="viewpw-icon" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Link to="/forgotpassword">
                      <Typography
                        className={
                          language === "en" ? "forgot-pwd" : "forgot-pwd-kh"
                        }
                      >
                        {t("forgot_password")}
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                      <Button
                        loading={iconReload}
                        onClick={handleSubmit}
                        className={
                          language === "en" ? "btn-sign-in" : "btn-sign-in-kh"
                        }
                        type="submit"
                        endIcon={
                          iconReload ? (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size="20px"
                            />
                          ) : null
                        }
                      >
                        {t("btn-sign-in")}
                      </Button>
                    </Stack>
                    {/* <Stack direction="column" justifyContent="center">
                      <Typography className="label-login" sx={{ textAlign: "center" }}>{t("or")}</Typography>
                    </Stack>
                    <Stack direction="column">
                      <Button
                        sx={{ backgroundColor: "#f1f9fc" }}
                        onClick={() => {
                          navigate('/logintelegram')
                        }}
                        endIcon={
                          <FaTelegram size="28px" sx={{ color: "#006eba" }} />
                        }
                      >
                        {t("login_with")}
                      </Button>
                    </Stack> */}
                  </Stack>
                  <Typography className="footer-copy-right">
                    Copyright © {moment().format("YYYY")} Go Global IT. All
                    rights reserved.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
      <Stack
        sx={{
          right: "20px",
          top: "10px",
          position: "absolute",
        }}
      >
        <Stack direction="column" justifyContent="center">
          {language === "en" ? (
            <Tooltip title="Change to Khmer" placement="left">
              <IconButton onClick={() => changeLanguage("kh")}>
                <Avatar src={EngFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change to English" placement="left">
              <IconButton onClick={() => changeLanguage("en")}>
                <Avatar src={CamFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Login;
