import React, { useContext, useState } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { SelectBank, SelectPlatform } from "../../../Include/Function/DynamicSelect";

export default function SelectPlatforms({ platformsData, setPlatformsData, }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newPlatforms = {
    key: Math.random() + Date.now(),
    platformName: null,
    username: null,
    url: null,
  }

  const handleAddItem = () => setPlatformsData([...platformsData, newPlatforms]);
  const handleRemoveItem = (key) => {
    const newData = platformsData.filter((e) => e.key !== key);
    setPlatformsData([...newData]);
  }
  const handleUpdateItem = (key, value, fieldName) => {
    const newData = platformsData.map((e) => {
      if (e.key === key && fieldName === "platformName") e.platformName = value;
      if (e.key === key && fieldName === "username") e.username = value;
      if (e.key === key && fieldName === "url") e.url = value;
      return e;
    })
    setPlatformsData([...newData]);
  }


  return (
    <Stack direction="column" width="100%" spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
          {/* {t("bank")} */}Platform
        </Typography>

        <IconButton onClick={handleAddItem} >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" width="100%" spacing={2}>
        {
          platformsData?.map((e) =>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack sx={{ width: "25%" }}>
                <SelectPlatform
                  selectedVal={e?.platformName}
                  setSelectedVal={(value) => handleUpdateItem(e.key, value, "platformName")}
                />
              </Stack>
              <TextField
                sx={{ width: "35%" }}
                fullWidth
                size="small"
                className="text-field"
                value={e?.username}
                placeholder={t('thead_username')}
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "username")}
              />
              <TextField
                fullWidth
                size="small"
                sx={{ width: "40%" }}
                className="text-field"
                value={e?.url}
                placeholder="url"
                onChange={({ target }) => handleUpdateItem(e.key, target.value, "url")}
              />
              <IconButton onClick={() => handleRemoveItem(e?.key)}>
                <CloseOutlinedIcon sx={{ color: "red" }} />
              </IconButton>
            </Stack>
          )
        }

      </Stack>
    </Stack>


  );
}
