import EN from "../../Include/Lang/En/translate.json";
import KH from "../../Include/Lang/Kh/translate.json";

export const translateLauguage = (lang) => {
  let language = JSON.parse(JSON.stringify(EN));
  if (lang === "kh") {
    language = JSON.parse(JSON.stringify(KH));
  }

  const t = (e) => {

    if(language[e]) {
      return language[e];
    } else {
      return e;
    }
    
  };

  return { t };
};
