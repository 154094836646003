import { gql } from "@apollo/client";

export const GET_EXCHANGE_RATE = gql`
  query GetExchangeRate {
    getExchangeRate {
      _id
      exchangeRate
    }
  }
`;


export const GET_CHECK_IN_MEMBER_BY_CARD = gql`
query Query($cardId: ID) {
  getCheckInMemberIsCheckInByCard(cardId: $cardId)
}
`;


export const UPDATE_EXCHANGE_RATE = gql`
  mutation UpdateExchangeRate($id: ID, $exchangeRate: Float) {
    updateExchangeRate(_id: $id, exchangeRate: $exchangeRate) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
