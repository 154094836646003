import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs
import "../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { SelectCardForMemeber, SelectCustomerWithAddNew } from "../../Include/Function/DynamicSelect";
import { UPDATE_CHECK_IN_MEMBER } from "../../Schema/CardNumber";
import moment from "moment";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";
export const UpdateCheckInMember = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [selectedCustomerVal, setSelectedCustomerVal] = useState({ id: "", title: "", });

  const [selectedCardVal, setSelectedCardVal] = useState({ id: "", title: "", });


  //======================== update Bank =========================
  const [updateCheckInMember] = useMutation(UPDATE_CHECK_IN_MEMBER, {
    onCompleted: ({ updateCheckInMember }) => {
      setLoading(false);
      if (updateCheckInMember?.isSuccess === true) {
        setAlert(true, "success", updateCheckInMember?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateCheckInMember?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    cardId: Yup.string().required(t("require")),
    customerId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      cardId: "",
      customerId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);
      updateCheckInMember({
        variables: {
          id: editData?._id,
          input: {
            ...value,
            isLead: editData?.isLead
          }
        },
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;
 
  useEffect(() => {
    setFieldValue("customerId", selectedCustomerVal?.id || "")
  }, [selectedCustomerVal]);

  useEffect(() => {
    setFieldValue("cardId", selectedCardVal?.id || "")
  }, [selectedCardVal]);


  useEffect(() => {
    setSelectedCardVal({ id: editData?.cardId?._id, title: editData?.cardId?.cardNo });
    setSelectedCustomerVal({ id: editData?.customerId?._id, title: editData?.customerId?.name });
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_update_member")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={3.5}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                  {t("thead_card_number")}
                </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                  {t("thead_customer")}
                </Typography>
              </Grid>

              <Grid item xs={3.5}>

                <SelectCardForMemeber
                  selectedVal={selectedCardVal}
                  setSelectedVal={setSelectedCardVal}
                />
                {!!errors.cardId && touched.cardId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.cardId}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={8.5}>
                <Stack direction="column" spacing={1} height="100%">
                  <SelectCustomerWithAddNew
                    selectedVal={selectedCustomerVal}
                    setSelectedVal={setSelectedCustomerVal}
                  />
                  {!!errors.customerId && touched.customerId && (
                    <FormHelperText error id="outlined-adornment-password">
                      {errors.customerId}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>


            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
