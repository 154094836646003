import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
  DialogActions,
  Checkbox,
  FormHelperText,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { useMutation } from "@apollo/client";
import { CREATE_CATEGORY, UPDATE_CATEGORY } from "../../../Schema/Category";
import { SelectListCategory } from "../../../Include/Function/DynamicSelect";

export const CategoryForm = ({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) => {

  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [cateVal, setCateVal] = useState({ id: "", title: "" });
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    onCompleted: ({ createCategory }) => { 
      setLoading(false);
      if (createCategory?.isSuccess === true) {
        setAlert(true, "success", createCategory?.message);
        handleClose();
        resetForm();
        setCateVal({ id: "", title: "" });
        setRefetch();
      } else {
        setAlert(true, "error", createCategory?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //======================== Create User =========================
  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onCompleted: ({ updateCategory }) => {
      setLoading(false);
      if (updateCategory?.isSuccess === true) {
        setAlert(true, "success", updateCategory?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateCategory?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    categoryNameKh: Yup.string().required(t("require")),
    categoryNameEn: Yup.string().required(t("require")),
    remark: Yup.string(),
    parentCategoryId: checked
      ? Yup.string().required(t("require"))
      : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      categoryNameKh: "",
      categoryNameEn: "",
      parentCategoryId: "",
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => {
      setLoading(true);
      if (dialogTitle === "Create") {
        createCategory({
          variables: {
            input: {
              ...submitValues,
              parentCategoryId: submitValues?.parentCategoryId || null,
            },
          },
        });
      } else {
        updateCategory({
          variables: {
            id: editData?._id,
            input: {
              ...submitValues,
              parentCategoryId: submitValues?.parentCategoryId || null,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("parentCategoryId", cateVal?.id || "");
  }, [cateVal]);
  useEffect(() => {
    if (!checked) setCateVal({ id: "", title: "" });
  }, [checked]);
  useEffect(() => {
    if (editData && open && dialogTitle === "Update") {
      setFieldValue("categoryNameKh", editData?.categoryNameKh || "");
      setFieldValue("categoryNameEn", editData?.categoryNameEn || "");
      setFieldValue("remark", editData?.remark || "");
      if (editData?.parentCategoryId) {
        setChecked(true);
        setCateVal({
          id: editData?.parentCategoryId?._id,
          title:
            language === "en"
              ? editData?.parentCategoryId?.categoryNameEn
              : editData?.parentCategoryId?.categoryNameKh,
        });
      }
    }
    if (open && dialogTitle === "Create") {
      resetForm();
      setChecked(false);
      setCateVal({ id: "", title: "" });
    }
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_category")
                : t("dialog_title_update_category")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("cate_kh")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("categoryNameKh")}
                  error={Boolean(
                    touched.categoryNameKh && errors.categoryNameKh
                  )}
                  helperText={touched.categoryNameKh && errors.categoryNameKh}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("cate_en")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("categoryNameEn")}
                  error={Boolean(
                    touched.categoryNameEn && errors.categoryNameEn
                  )}
                  helperText={touched.categoryNameEn && errors.categoryNameEn}
                />
              </Grid>
              <Grid item xs={2}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mt={2.5}
                >
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event?.target?.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("sub_cate")}
                </Typography>

                <SelectListCategory
                  checked={checked}
                  selectedVal={cateVal}
                  setSelectedVal={setCateVal}
                  selfId={dialogTitle === "Create" ? null : editData?._id}
                />

                {!!errors.parentCategoryId && touched.parentCategoryId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.parentCategoryId}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
                {/* {t("btn-create")} */}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
