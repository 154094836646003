import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import ApexCharts from "apexcharts";
import "./chart.scss";
import moment from "moment";
import { useQuery } from "@apollo/client";
import ReactApexChart from "react-apexcharts";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { GET_DASH_INCOME_PAYMENT_BY_MONTH } from "../../Schema/Dashboard";

const IncomePayment = () => {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [editData, setEditData] = useState([]);

  const shopId = window.localStorage.getItem("shopId");

  const { refetch } = useQuery(GET_DASH_INCOME_PAYMENT_BY_MONTH, {
    pollInterval: 1000,
    variables: {
      shopIds: [shopId],
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
    },
    onCompleted: ({ getDashIncomePaymentByMonth }) => {
      if (getDashIncomePaymentByMonth) setEditData(getDashIncomePaymentByMonth);
    },
  });
  useEffect(() => {
    refetch();
  }, []);

  const categoriesData = editData.map((e, index) => index + 1);

  const state = {
    series: [
      {
        name: "Income ",
        data: editData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        // type: "dat",
        categories: categoriesData,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <>
        <Stack direction="row">
          <Typography className={language === "en" ? "title" : "title-kh"}>
            Income Payment on {moment().format("MMMM")}
          </Typography>
        </Stack>
        <div id="chart">
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="area"
            height={330}
          />
        </div>
      </>
    </div>
  );
};

export default IncomePayment;
