import React, { useState, useContext, useEffect } from "react";
import Login from "./Pages/Login";
import Shop from "./Pages/Shop/Shop";
import Page404 from "./Pages/Page404";
import VerifyOtp from "./Pages/VerifyOtp";
import Report from "./Pages/Report/Report";
import { Navigate } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import Layout from "./Include/Layout/Layout";
import Setting from "./Pages/Setting/Setting";
import { User } from "./Pages/Setting/User/User";
import { Unit } from "./Pages/Setting/Unit/Unit";
import { Bank } from "./Pages/Setting/Bank/Bank";
import { PagePOS } from "./Pages/PagePOS/PagePOS";
import ResetPassword from "./Pages/ResetPassword";
import { Payment } from "./Pages/Payment/Payment";
import { AuthContext } from "./Context/AuthContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ForgotPassword from "./Pages/ForgotPassword";
import { Register } from "./Pages/Register/Register";
import ScreenView from "./Pages/ScreenView/ScreenView";
import { Voucher } from "./Pages/Setting/Voucher/Voucher";
import OpenSelling from "./Pages/OpenSelling/OpenSelling";
import { Product } from "./Pages/Setting/Product/Product";
import { Category } from "./Pages/Setting/Category/Category";
import { Supplier } from "./Pages/Setting/Supplier/Supplier";
import { Customer } from "./Pages/Setting/Customer/Customer";
import { Transaction } from "./Pages/Transaction/Transaction";
import TicketSelect from "./Components/TicketPos/TicketSelect";
import { Cashlabel } from "./Pages/Setting/Cashlabel/Cashlabel";
import { Warehouse } from "./Pages/Setting/Warehouse/Warehouse";
import { TablePage } from "./Pages/Setting/TablePage/TablePage";
import { CardNumber } from "./Pages/Setting/CardNumber/CardNumber";
import { TicketGroup } from "./Pages/Setting/TicketGroup/TicketGroup";
import { InventoryInShop } from "./Pages/InventoryInShop/InventoryInShop";
import { InventoryInWarehouse } from "./Pages/InventoryInWarehouse/InventoryInWarehouse";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {
  // ============================== check Token ===============
  const { state } = useContext(AuthContext);
  const [token, setToken] = useState(
    JSON.parse(window.localStorage.getItem("token"))
  );
  const [verifyToken, setVerifyToken] = useState(
    JSON.parse(window.localStorage.getItem("verifyToken"))
  );

  useEffect(() => {
    let userToken = JSON.parse(window.localStorage.getItem("token"));
    let verifyToken = JSON.parse(window.localStorage.getItem("verifyToken"));
    if (userToken) {
      setToken(userToken);
      return;
    }
    if (verifyToken) {
      setVerifyToken(userToken);
      return;
    }

    if (state?.user?.token) {
      setTimeout(() => {
        setToken(state?.user?.token);
        window.localStorage.setItem(
          "token",
          JSON.stringify(state?.user?.token)
        );
        // window.localStorage.setItem("token", state?.user?.token);
      }, 1200);
    } else {
      setToken(null);
    }
  }, [state?.user]);

  // ============================== End Token =========================================

  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/verifyOtp", element: <VerifyOtp /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const Content = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),

      children: [
        { path: "*", element: <Page404 /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "/", element: <Navigate to="/dashboard" /> },

        { path: "shop", element: <Shop /> },
        { path: "pos", element: <PagePOS /> },
        { path: "report", element: <Report /> },
        { path: "payment", element: <Payment /> },
        { path: "setting", element: <Setting /> },
        { path: "register", element: <Register /> },
        { path: "customer", element: <Customer /> },
        { path: "setting/user", element: <User /> },
        { path: "setting/unit", element: <Unit /> },
        { path: "setting/bank", element: <Bank /> },
        { path: "opensale", element: <OpenSelling /> },
        { path: "transaction", element: <Transaction /> },
        { path: "setting/table", element: <TablePage /> },
        { path: "setting/product", element: <Product /> },
        { path: "setting/voucher", element: <Voucher /> },
        { path: "inventory", element: <InventoryInShop /> },
        { path: "setting/customer", element: <Customer /> },
        { path: "setting/Supplier", element: <Supplier /> },
        { path: "setting/category", element: <Category /> },
        { path: "setting/warehouse", element: <Warehouse /> },
        { path: "setting/cash-label", element: <Cashlabel /> },
        { path: "pos/ticket-select", element: <TicketSelect /> },
        { path: "setting/card-number", element: <CardNumber /> },
        { path: "setting/ticket-group", element: <TicketGroup /> },
        { path: "inventory-in-warehouse", element: <InventoryInWarehouse /> },
      ],
    },
    {
      path: "screen-view", element: <ScreenView />
    }
  ]);

  // if (token !== undefined && token !== null) {
  //   return Content;
  // } else {
  //   return LoginPage;
  // }
  if (token !== undefined && token !== null) {
    return Content;
  } else if (verifyToken !== undefined && verifyToken !== null) {
    return LoginPage;
  } else {
    return LoginPage;
  }
}
