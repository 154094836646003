import React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// src
import "./headercard.scss";
import { FaUsers } from "react-icons/fa";
import { FaWalking } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa6";
import { RiUserStarFill } from "react-icons/ri";
import { AuthContext } from "../../../Context/AuthContext";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { translateLauguage } from "../../../Include/Function/Translate";

const HeaderCard = () => {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <div className="card-container">
      <Grid container spacing={5}> 
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
          <Stack
            direction={`row`}
            className="card-item"
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Stack
              direction={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              spacing={1}
            >
              <Box>
                <Stack className="icons-bg">
                  <MonetizationOnIcon className="icon" />
                </Stack>
              </Box>
              <Typography className={language === "en" ? "card-title" : "card-title-kh"}>{t("total_net_sale_today")}</Typography>
              <Typography className="card-sub-title">0</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
          <Stack
            direction={`row`}
            className="card-item"
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Stack
              direction={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              spacing={1}
            >
              <Box>
                <Stack className="icons-bg">
                  <FaUsers className="icon" />
                </Stack>
              </Box>
              <Typography className={language === "en" ? "card-title" : "card-title-kh"}>{t("total_customer_today")}</Typography>
              <Typography className="card-sub-title">0</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
          <Stack
            direction={`row`}
            className="card-item"
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Stack
              direction={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              spacing={1}
            >
              <Box>
                <Stack className="icons-bg">
                  <RiUserStarFill className="icon" />
                </Stack>
              </Box>
              <Typography className={language === "en" ? "card-title" : "card-title-kh"}>{t("total_membership_today")}</Typography>
              <Typography className="card-sub-title">0</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
          <Stack
            direction={`row`}
            className="card-item"
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Stack
              direction={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              spacing={1}
            >
              <Box>
                <Stack className="icons-bg">
                  <FaWalking className="icon" />
                </Stack>
              </Box>
              <Typography className={language === "en" ? "card-title" : "card-title-kh"}>{t("total_walk_in_today")}</Typography>
              <Typography className="card-sub-title">0</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
          <Stack
            direction={`row`}
            className="card-item"
            justifyContent={`center`}
            alignContent={`center`}
          >
            <Stack
              direction={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              spacing={1}
            >
              <Box>
                <Stack className="icons-bg">
                  <FaHandshake className="icon" />
                </Stack>
              </Box>
              <Typography className={language === "en" ? "card-title" : "card-title-kh"}>{t("total_sponsor_today")}</Typography>
              <Typography className="card-sub-title">0</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  ); 
};

export default HeaderCard;
