import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik"; 
import { AuthContext } from "../../../Context/AuthContext";
import SelectTransferProducts from "./SelectTransferProducts";
import { CREATE_TRANSFER_BACK } from "../../../Schema/InventoryInShop";
import { translateLauguage } from "../../../Include/Function/Translate";


export const TransferProduct = ({ open, handleClose, setRefetch, hideBackdrop, }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [transferProductsData, setTransferProductsData] = useState([]);

  const newItem = {
    qty: null,
    warehouseId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  }


  //======================== Create User =========================
  const [createTransferBack] = useMutation(CREATE_TRANSFER_BACK, {
    onCompleted: ({ createTransferBack }) => {
      setLoading(false);
      if (createTransferBack?.isSuccess === true) {
        setAlert(true, "success", createTransferBack?.message);
        handleClose();
        setRefetch();
        resetForm();
        setTransferProductsData([]);
      } else {
        setAlert(true, "error", createTransferBack?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (transferProductsData?.length > 0) {
        const requiredProduct = transferProductsData?.map((e) => e?.mainProductId?.id && e?.qty ? true : false)?.filter((e) => !e);
        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }

        const transferProducts = transferProductsData?.map((e) => ({
          transferQty: parseFloat(e?.qty),
          remainInStock: e?.remainInStock,
          mainProductId: e?.mainProductId?.id
        }));

        setLoading(true);
        createTransferBack({
          variables: {
            input: {
              transferBy: {
                remark: value?.remark
              },
              transferProducts: transferProducts
            }
          }
        });
      } else {
        setTransferProductsData([newItem]);
      }
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);



  useEffect(() => {
    resetForm();
    setTransferProductsData([newItem]);
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_transfer_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>

              <Grid item xs={12}>
                <SelectTransferProducts
                  required={required} 
                  transferProductsData={transferProductsData}
                  setTransferProductsData={setTransferProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-transfer")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
