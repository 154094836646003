import React from "react";
import {
  Table,
  TableRow,
  Collapse,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import { currencyFormat } from "../../../Include/Function/DynamicFn";

export default function ItemRow({ open, data, t }) {
  return (
    <>
      <TableRow className="field-row">
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="simple table" className="table-booking">
              <TableHead className="header-row">
                <TableRow>
                  <TableCell
                    className="header-title-booking-start"
                    sx={{ textAlign: "left !important" }}
                    width="10%"
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: "#1d4592",
                      fontWeight: "bold",
                      textAlign: "left !important",
                      textAlign: "left !important",
                    }}
                    width="30%"
                  >
                    {t("thead_product")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1d4592",
                      fontWeight: "bold",
                      textAlign: "left !important",
                      textAlign: "left !important",
                    }}
                    width="30%"
                  >
                    {t("thead_qty")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1d4592",
                      fontWeight: "bold",
                      textAlign: "left !important",
                      textAlign: "left !important",
                    }}
                    width="30%"
                  >
                    {t("thead_price")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((items, index) => (
                  <TableRow key={index} className="field-row">
                    <TableCell
                      className="field-column-start"
                      sx={{
                        textAlign: "left !important",
                        bgcolor: "#f6fcff !important",
                      }}
                    ></TableCell>
                    <TableCell
                      className="field-column-mid"
                      sx={{ bgcolor: "#f6fcff !important" }}
                    >
                      {items?.productId?.mainProductId?.mainProductEnName}
                    </TableCell>
                    <TableCell
                      className="field-column-mid"
                      sx={{ bgcolor: "#f6fcff !important" }}
                    >
                      {items?.qty} {items?.productId?.unitId?.unitKhName}
                    </TableCell>
                    <TableCell
                      className="field-column-mid"
                      sx={{ bgcolor: "#f6fcff !important" }}
                    >
                      {currencyFormat(items?.price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
