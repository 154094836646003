import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
// src
import "./dashboard.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import HeaderCard from "../../Components/Dashboard/HeaderCard/HeaderCard";
import TrasactionPaymentToday from "../../Components/Dashboard/TrasactionPaymentToday/TrasactionPaymentToday";
import IncomePayment from "../../Components/Dashboard/IncomePayment";
import UnpaidInvoice from "../../Components/Dashboard/UnpaidInvoice";
import AboutExpired from "../../Components/Dashboard/AboutExpired";
import AboutOutOfStock from "../../Components/Dashboard/AboutOutOfStock";
// icon
import SearchIcon from "@mui/icons-material/Search";

export default function Dashboard() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const navigate = useNavigate();

  return (
    <div className="dashboard-page">
      {/* <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-dashboard`)}
          </Typography>
        </Stack>
      </Stack> */}

      <Box  >
        {/* <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              size="small"
              className="search-field"
              // onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid> */}

        <Box className="dashboard-container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <HeaderCard />
            </Grid>
            <Grid item xs={12} sm={4.5} md={4} lg={3.5} xl={2.5}>
              <TrasactionPaymentToday />
            </Grid>
            <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9.5} >
              <IncomePayment />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <UnpaidInvoice />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AboutExpired />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AboutOutOfStock />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
