import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInputCreate!) {
    createUser(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_USER_PAGINATION = gql`
  query GetUsersPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
    $role: RoleEnum!
  ) {
    getUsersPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
      role: $role
    ) {
      data {
        userProfile
        role
        phoneNumber
        latinName
        khmerName
        isEnabledAccess
        gender
        email
        _id
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      data {
        user {
          _id
          userProfile
          khmerName
          latinName
          email
          gender
          role
          phoneNumber
          isEnabledAccess
        }
        token
      }
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query Data {
    getUserLogin {
      data {
        _id
        email
        gender
        isEnabledAccess
        khmerName
        latinName
        phoneNumber
        role
        userProfile
      }
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($id: ID!, $isEnabledAccess: Boolean!) {
    updateUserStatus(_id: $id, isEnabledAccess: $isEnabledAccess) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserInputUpdate) {
    updateUser(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($otp: String!, $email: String!) {
    verifyOTP(otp: $otp, email: $email) {
      isSuccess
      message {
        messageEn
        messageKh
      }
      data {
        token
        user {
          _id
          userProfile
          khmerName
          latinName
          email
          gender
          role
          phoneNumber
          isEnabledAccess
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordWhenForgot($email: String!, $password: String!) {
    resetPasswordWhenForgot(email: $email, password: $password) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
