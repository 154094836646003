import React, { useContext, useEffect } from "react";
import "../../../Style/actionStyle.scss";
import { useQuery } from "@apollo/client";
import { Stack, TextField } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { GET_REMAIN_BY_PRODUCT_IN_SHOP } from "../../../Schema/InventoryInShop";

export default function GetRemainByProductInShop({ e, required, handleUpdateItem, }) {
    // ===================== Change Language ==========================
    const { language, setAlert } = useContext(AuthContext);
    const { t } = translateLauguage(language);

    const { refetch, data } = useQuery(GET_REMAIN_BY_PRODUCT_IN_SHOP, {
        variables: {
            mainProductId: e?.mainProductId?.id
        },
        onCompleted: ({ getRemainByProductInShop }) => { 
            handleUpdateItem(e?.key, getRemainByProductInShop, "remainInStock")
        }
    });

    useEffect(() => {
        refetch();
    }, [e]);
 

    return <Stack sx={{ width: "100%" }} direction="row" alignItems="center" spacing={1}>

        <TextField
            fullWidth
            size="small"
            type="number"
            sx={{ width: "50%%" }}
            className="text-field"
            placeholder={t('thead_qty')}
            InputProps={{ endAdornment: e?.mainProductId?.unit, readOnly: true }}
            value={e?.remainInStock || 0}
        />

        <TextField
            fullWidth
            size="small"
            type="number"
            value={e?.qty}
            sx={{ width: "50%%" }}
            className="text-field"
            error={!e?.qty && required}
            placeholder={t('thead_qty')}
            InputProps={{ endAdornment: e?.mainProductId?.unit }}
            onChange={({ target }) => handleUpdateItem(e?.key, target.value > e?.remainInStock ? e?.remainInStock : target.value, "qty")}
        />

    </Stack>



}
