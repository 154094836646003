import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LogoCompany from "../../../Assets/it-logo-invoice.png";
import {
  Stack,
  Typography,
  Box,
  Tooltip,
  TableRow,
  TableCell,
} from "@mui/material";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";

function count_elements(arr) {
  let newA = arr.sort((a, b) => b - a);

  let row = [];
  for (let i = 0; i < newA[0]; i++) {
    row.push(i);
  }

  return row;
}

export default function InventoryReport({ componentRef, tableData, loading }) {
  //===================  Change Language ======================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  console.log("tableData ===>", tableData);
  return (
    <div
      style={{
        padding: "10px",
        marginTop: "10px",
        borderRadius: "15px",
        backgroundColor: "#fff",
      }}
    >
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {tableData === undefined || tableData === null ? (
            <Box className="page-container-report-main">
              <Stack
                direction="row"
                justifyContent="center"
                mb={1}
                spacing={4}
                height="100%"
              >
                <Stack direction="column" justifyContent="center" height="100%">
                  <Box height="100%" marginTop="15px">
                    <img src={LogoCompany} className="page-report-logo-size" />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Stack
                    className="page-header-report-title-main"
                    direction="row"
                    justifyContent="center"
                  >
                    Inventory Reports
                  </Stack>
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Typography className="date-time">DD-MMM-YYYY</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <EmptyData />
            </Box>
          ) : (
            <>
              <Box ref={componentRef} className="page-container-report-main">
                <Stack
                  direction="row"
                  justifyContent="center"
                  mb={1}
                  spacing={4}
                  height="100%"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Box height="100%" marginTop="15px">
                      <img
                        src={LogoCompany}
                        className="page-report-logo-size"
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      className="page-header-report-title-main"
                      direction="row"
                      justifyContent="center"
                    >
                      Inventory Reports
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography className="date-time">
                        {tableData?.date ? tableData?.date : ""}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography className="date-time">
                        {tableData?.startTime && tableData?.endTime
                          ? tableData?.startTime + " - " + tableData?.endTime
                          : ""}
                        {tableData?.from && tableData?.to
                          ? tableData?.from + " - " + tableData?.to
                          : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <table className="report-container-main">
                  <thead className="report-header-main">
                    <tr>
                      <td
                        className="header-title-report-main"
                        rowSpan={2}
                        width="20%"
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          width="100%"
                        >
                          PRODUCT
                        </Stack>
                      </td>
                      <td className="header-title-report-main" colSpan={2}>
                        Open
                      </td>
                      <td className="header-title-report-main" colSpan={2}>
                        In
                      </td>
                      <td className="header-title-report-main" colSpan={2}>
                        Out
                      </td>
                      <td className="header-title-report-main" colSpan={2}>
                        Ending
                      </td>
                    </tr>

                    <tr>
                      <td className="header-title-report-main" width="8%">
                        Qty
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Price
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Qty
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Price
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Qty
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Price
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Qty
                      </td>
                      <td className="header-title-report-main" width="8%">
                        Price
                      </td>
                    </tr>
                  </thead>

                  <tfoot className="report-footer-main">
                    <tr>
                      <td className="report-footer-cell" colSpan={9}>
                        <div className="footer-info-main">
                          <div className={"page-footer-main"}>
                            <div className="page-footer-line-main" />
                            <div className={"page-footer-text-main"}>
                              Boeungdonpa Village, Slokram Commune Siem Reap /
                              Telephone: 078 881 919
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                  <tbody>
                    {tableData?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <EmptyData />
                        </TableCell>
                      </TableRow>
                    ) : (
                      tableData?.map((rowOfTableData, indexCat) => (
                        <Fragment key={indexCat}>
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#1d4592",
                                color: "white",
                                textAlign: "center",
                              }}
                              className="report-content-cell-left-bold-main"
                            >
                              {language === "kh"
                                ? rowOfTableData?.category?.categoryNameKh
                                : rowOfTableData?.category?.categoryNameEn}
                            </td>
                            <td
                              className="report-content-cell-right-main"
                              colSpan={8}
                            ></td>
                          </tr>

                          {rowOfTableData?.mainProducts?.map(
                            (row, indexFiFi) => (
                              <Fragment key={indexFiFi}>
                                {count_elements([
                                  row?.ends?.length,
                                  row?.ins?.length,
                                  row?.opens?.length,
                                  row?.outs?.length,
                                ])?.map((index) =>
                                  index === 0 ? (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          indexFiFi % 2 === 0 ? "" : "#ECF0FE",
                                      }}
                                    >
                                      <td
                                        className="report-content-cell-left-bold-main"
                                        rowSpan={
                                          count_elements([
                                            row?.ends?.length,
                                            row?.ins?.length,
                                            row?.opens?.length,
                                            row?.outs?.length,
                                          ])?.length
                                        }
                                      >
                                        {language === "kh"
                                          ? row?.mainProduct?.mainProductKhName
                                          : row?.mainProduct?.mainProductEnName}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        {row?.opens[index]?.qty
                                          ? row?.opens[index]?.qty +
                                            " " +
                                            row?.mainProduct?.minimumUnitId
                                              ?.unitEnName
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.opens[index]?.price
                                          ? row?.opens[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        <Tooltip
                                          title={`${row?.ins[index]?.action}`}
                                        >
                                          <p style={{ margin: 0, padding: 0 }}>
                                            {row?.ins[index]?.qty
                                              ? row?.ins[index]?.qty +
                                                " " +
                                                row?.mainProduct?.minimumUnitId
                                                  ?.unitEnName
                                              : ""}
                                          </p>
                                        </Tooltip>
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.ins[index]?.price
                                          ? row?.ins[index]?.price?.toFixed(2) +
                                            " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        <Tooltip
                                          title={`${row?.outs[index]?.action}`}
                                        >
                                          <p style={{ margin: 0, padding: 0 }}>
                                            {row?.outs[index]?.qty
                                              ? row?.outs[index]?.qty +
                                                " " +
                                                row?.mainProduct?.minimumUnitId
                                                  ?.unitEnName
                                              : ""}
                                          </p>
                                        </Tooltip>
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.outs[index]?.price
                                          ? row?.outs[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        {row?.ends[index]?.qty
                                          ? row?.ends[index]?.qty +
                                            " " +
                                            row?.mainProduct?.minimumUnitId
                                              ?.unitEnName
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.ends[index]?.price
                                          ? row?.ends[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr key={index}>
                                      <td className="report-content-cell-center-bold-main">
                                        {row?.opens[index]?.qty ||
                                        row?.opens[index]?.qty === 0
                                          ? row?.opens[index]?.qty +
                                            " " +
                                            row?.mainProduct?.minimumUnitId
                                              ?.unitEnName
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.opens[index]?.price
                                          ? row?.opens[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        <Tooltip
                                          title={`${row?.ins[index]?.action}`}
                                        >
                                          <p style={{ margin: 0, padding: 0 }}>
                                            {row?.ins[index]?.qty ||
                                            row?.ins[index]?.qty === 0
                                              ? row?.ins[index]?.qty +
                                                " " +
                                                row?.mainProduct?.minimumUnitId
                                                  ?.unitEnName
                                              : ""}
                                          </p>
                                        </Tooltip>
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.ins[index]?.price
                                          ? row?.ins[index]?.price?.toFixed(2) +
                                            " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        <Tooltip
                                          title={`${row?.outs[index]?.action}`}
                                        >
                                          <p style={{ margin: 0, padding: 0 }}>
                                            {row?.outs[index]?.qty ||
                                            row?.outs[index]?.qty === 0
                                              ? row?.outs[index]?.qty +
                                                " " +
                                                row?.mainProduct?.minimumUnitId
                                                  ?.unitEnName
                                              : ""}
                                          </p>
                                        </Tooltip>
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.outs[index]?.price
                                          ? row?.outs[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-center-bold-main">
                                        {row?.ends[index]?.qty ||
                                        row?.ends[index]?.qty === 0
                                          ? row?.ends[index]?.qty +
                                            " " +
                                            row?.mainProduct?.minimumUnitId
                                              ?.unitEnName
                                          : ""}
                                      </td>

                                      <td className="report-content-cell-right-main">
                                        {row?.ends[index]?.price
                                          ? row?.ends[index]?.price?.toFixed(
                                              2
                                            ) + " $"
                                          : ""}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </Fragment>
                            )
                          )}
                        </Fragment>
                      ))
                    )}
                  </tbody>
                </table>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
}
