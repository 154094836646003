import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Grid,
    Table,
    Select,
    TableRow,
    MenuItem,
    TableCell,
    TextField,
    TableHead,
    TableBody,
    Typography,
    FormControl,
    InputAdornment,
    TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import RequestAction from "./RequestAction";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import { GET_TRANSFER_PAGINATION } from "../../../Schema/InventoryInShop";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";

export const Receive = () => {
    // ===================== Change Language ==========================
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);

    // ======================= Resize width Screen ======================
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth - 50);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);



    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyword, setKeyword] = useState();
    const [status, setStatus] = useState("All");
    const [type, setType] = useState("All");
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState();
    const [endDate, setEndDate] = useState(moment());
    const shopId = window.localStorage.getItem("shopId");
    const [paginationData, setPaginationData] = useState();
    const [startDate, setStartDate] = useState(moment().startOf("month"));

    const { refetch } = useQuery(GET_TRANSFER_PAGINATION, {
        variables: {
            type: type,
            page: page,
            limit: limit,
            shopId: shopId,
            status: status,
            pagination: true,
            transferNo: parseFloat(keyword),
            toDate: moment(endDate).format("YYYY-MM-DD"),
            fromDate: moment(startDate).format("YYYY-MM-DD"),
        },
        onCompleted: ({ getTransferPagination }) => { 
            setLoading(false);
            setTableData(getTransferPagination?.data);
            setPaginationData(getTransferPagination?.paginator);
        },
        onError: (err) => {
            console.log("Error::", err?.message);
        },
    });

    const handleLimit = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };


    const translateStatus = (status) => {
        if (status === "Transfer") return t('thead_incoming');
        if (status === "Received") return t('thead_received');
    }


    return (
        <>
            <Grid container spacing={3}>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t(`label-search`)}
                    </Typography>
                    <TextField
                        placeholder={t(`label-search`)}
                        size="small"
                        type="number"
                        className="search-field"
                        onChange={(e) => setKeyword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 1, color: "black" }}  >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"} >
                        {t(`thead_status`)}
                    </Typography>
                    <FormControl fullWidth size="small" className="search-field">
                        <Select value={status} onChange={(event) => setStatus(event.target.value)} >
                            <MenuItem value={"All"}> {t(`thead_all`)} </MenuItem>
                            <MenuItem value={"Transfer"}> {t(`thead_transfer`)} </MenuItem>
                            <MenuItem value={"Received"}> {t(`thead_incoming`)} </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"} >
                        {t(`thead_type`)}
                    </Typography>
                    <FormControl fullWidth size="small" className="search-field">
                        <Select value={type} onChange={(event) => setType(event.target.value)} >
                            <MenuItem value={"All"}> {t(`thead_all`)}</MenuItem>
                            <MenuItem value={"UponRequest"}> {t(`thead_upon_request`)}</MenuItem>
                            <MenuItem value={"NoRequest"}> {t(`thead_no_request`)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t("thead_start_date")}
                    </Typography>
                    <SelectDatePicker
                        date={startDate}
                        className={"search-field"}
                        setDate={(e) => setStartDate(e)}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t("thead_end_date")}
                    </Typography>
                    <SelectDatePicker
                        date={endDate}
                        className={"search-field"}
                        setDate={(e) => setEndDate(e)}
                    />
                </Grid>

            </Grid>
            <Box className="body-container">
                <TableContainer sx={{ maxWidth: `${width}px` }}>
                    <Table className="table" aria-label="simple table">
                        <TableHead className="header-row ">
                            <TableRow>
                                <TableCell className={language === "en" ? "header-title-start" : "header-title-start-kh"} width="10%"> {t("thead_no")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_date")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_transfer_by")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_recipient")}</TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_status")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_type")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("remark")} </TableCell>
                                <TableCell className={language === "en" ? "header-title-end" : "header-title-end-kh"} width="10%"  ></TableCell>
                            </TableRow>
                        </TableHead>

                        {loading ? (
                            <TableBody className="table-body">
                                <TableRow className="body-row">
                                    <TableCell colSpan={8} className="body-cell">
                                        <LoadingPage />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <>
                                {tableData?.length === 0 ? (
                                    <TableBody className="table-body">
                                        <TableRow className="body-row">
                                            <TableCell colSpan={8} className="body-cell">
                                                <EmptyData />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <>
                                        <TableBody className="table-body">
                                            {tableData?.map((row, index) => {
                                                return (
                                                    <TableRow className="body-row" key={index} >
                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-left" : ""} body-cell-start` : `${row?.isVoid ? "void-left" : ""} body-cell-start-kh`}>
                                                            {row?.transferNo}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {row?.receiveBy?.createdAt && (moment(row?.receiveBy?.createdAt).format("MM-DD-YYYY hh:mm a"))}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.transferBy?.userId?.latinName : row?.transferBy?.userId?.khmerName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.receiveBy?.userId?.latinName : row?.receiveBy?.userId?.khmerName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {translateStatus(row?.status)}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {row?.requestId ? t("thead_upon_request") : t("thead_no_request")}

                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {row?.receiveBy?.remark}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-right" : ""} body-cell-end` : `${row?.isVoid ? "void-right" : ""} body-cell-end-kh`} align="right">
                                                            <RequestAction editData={row} setRefetch={refetch} disabled={row?.status === "Received"} />
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
                {/* ==================== Pagination  ============================= */}
                <FooterPagination
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    handleLimit={handleLimit}
                    totalDocs={paginationData?.totalDocs}
                    totalPages={paginationData?.totalPages}
                />
            </Box>
        </>
    );
};
