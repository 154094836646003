import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip } from "@mui/material";

export default function CheckPayment({
  selectCustomerVal,
  setInvoiceIDSelected,
  invoiceIDSelected,
  editData,
}) {
  const hadleSelect = () => {
    if (invoiceIDSelected.includes(editData?._id)) {
      const data = invoiceIDSelected.filter((e) => e !== editData?._id);
      setInvoiceIDSelected([...data]);
    } else {
      setInvoiceIDSelected([...invoiceIDSelected, editData?._id]);
    }
  };

  return (
    <Tooltip title={!selectCustomerVal?.id ? "Please select customer " : ""}>
      <div>
        <Checkbox
          onChange={hadleSelect}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
          disabled={!selectCustomerVal?.id}
          checked={invoiceIDSelected?.includes(editData?._id)}
        />
      </div>
    </Tooltip>
  );
}
