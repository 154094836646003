import { gql } from "@apollo/client";

export const CREATE_BANK = gql`
  mutation CreateBank($input: BankInput) {
    createBank(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_BANK = gql`
  mutation UpdateBank($id: ID!, $input: BankInput) {
    updateBank(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_BANK = gql`
  mutation DeleteBank($id: ID!) {
    deleteBank(_id: $id) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_BANK_WITH_PAGINATION = gql`
  query GetBankWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getBankWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        bankName
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
