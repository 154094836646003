import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Dialog,
  Select,
  Tooltip,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import * as Yup from "yup";
import PropTypes from "prop-types";
import "../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import EmptyImage from "../../Assets/emptyImage.png";
import SocailContactList from "./SocailContactList";
import { deleteFileAPI } from "../../Include/api/api";
import CropImageFile from "../CropImage/CropImageFile";
import { AuthContext } from "../../Context/AuthContext";
import { useFormik, Form, FormikProvider } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import { CREATE_SHOP, UPDATE_SHOP } from "../../Schema/Shop";
import { useMutation as mutationDeleteImage } from "react-query";
import { SelectShop } from "../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../Include/Function/Translate";


export const ShopForm = ({ open, handleClose, dialogTitle, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [socailData, setSocailData] = useState([]);
  const [selectedShopVal, setSelectedShopVal] = useState({ id: "", title: "" });
  // ========================= upload Image =========================
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
  // ===============  Mutations delete image  =======================
  const mutationDelete = mutationDeleteImage(deleteFileAPI, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        console.log("delete sucess::", data?.data?.message);
      } else {
        console.log("error::", data?.data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
      // console.log("deleteFileAPI", deleteFileAPI);
    },
  });

  const handleCloseAction = () => {
    setProfileHook("");
    handleClose();
    if (profileHook) {
      let splitSrc = profileHook?.split(":")[4];
      mutationDelete.mutate({
        storage: "pos_go_globalit",
        folder: "it_pos_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  //======================== Create Shop =========================
  const [createShop] = useMutation(CREATE_SHOP, {
    onCompleted: ({ createShop }) => {
      setLoading(false);
      if (createShop?.isSuccess === true) {
        setAlert(true, "success", createShop?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createShop?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //======================== Update Shop =========================
  const [updateShop] = useMutation(UPDATE_SHOP, {
    onCompleted: ({ updateShop }) => {
      setLoading(false);
      if (updateShop?.isSuccess === true) {
        setAlert(true, "success", updateShop?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateShop?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    wifiName: Yup.string(),
    wifiPassword: Yup.string(),
    isShowWifi: Yup.boolean(),
    isShowQueueNo: Yup.boolean(),

    email: Yup.string(),
    phone: Yup.string(),
    remark: Yup.string(),
    address: Yup.string(),
    latitude: Yup.string(),
    shopType: Yup.string(),
    vattinNo: Yup.string(),
    longtitude: Yup.string(),
    isShowVattin: Yup.boolean(),
    returnPolicyEn: Yup.string(),
    returnPolicyKh: Yup.string(),
    copyProductShopId: Yup.string(),
    shopNameKh: Yup.string().required(t("require")),
    shopNameEn: Yup.string().required(t("require")),
    billingHead: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {

      email: "",
      phone: "",
      remark: "",
      address: "",
      latitude: "",
      vattinNo: "",
      longtitude: "",
      shopNameKh: "",
      shopNameEn: "",
      wifiName: "",
      billingHead: "",
      shopType: "Mart",
      returnPolicyEn: "",
      returnPolicyKh: "",
      wifiPassword: "",
      isShowWifi: false,
      isShowVattin: false,
      isShowQueueNo: false,
      copyProductShopId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => {
      const socailContact = socailData.map((e) => ({
        link: e?.link,
        name: e?.name,
        isShow: e?.isShow,
        platform: e?.platform,
      }));
      setLoading(true);
      if (dialogTitle === "Create") {
        createShop({
          variables: {
            input: {
              ...submitValues,
              logo: profileHook,
              socailContact: socailContact,
              copyProductShopId: submitValues?.copyProductShopId || null,
            },
          },
        });
      } else {
        updateShop({
          variables: {
            id: editData?._id,
            input: {
              ...submitValues,
              logo: profileHook,
              socailContact: socailContact,
              copyProductShopId: submitValues?.copyProductShopId || null,
            },
          },
        });
      }
    },
  });
  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };
  const {
    errors,
    touched,
    values,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setSocailData([]);
      setProfileHook("");
      setImageFile(null);
      setPhotoURL(null);
    }

    if (dialogTitle === "Update" && open && editData) {
      setProfileHook(editData?.logo);
      setFieldValue("phone", editData?.phone);
      setFieldValue("email", editData?.email);
      setFieldValue("remark", editData?.remark);
      setFieldValue("address", editData?.address);
      setFieldValue("address", editData?.address);
      setFieldValue("latitude", editData?.latitude);
      setFieldValue("wifiName", editData?.wifiName);
      setFieldValue("shopType", editData?.shopType);
      setFieldValue("vattinNo", editData?.vattinNo);
      setFieldValue("shopNameKh", editData?.shopNameKh);
      setFieldValue("isShowWifi", editData?.isShowWifi);
      setFieldValue("shopNameEn", editData?.shopNameEn);
      setFieldValue("billingHead", editData?.billingHead);
      setFieldValue("wifiPassword", editData?.wifiPassword);
      setFieldValue("isShowVattin", editData?.isShowVattin);
      setFieldValue("isShowQueueNo", editData?.isShowQueueNo);
      setFieldValue("returnPolicyEn", editData?.returnPolicyEn);
      setFieldValue("returnPolicyKh", editData?.returnPolicyKh);
      const socailContact = editData?.socailContact?.map((e) => ({
        link: e?.link,
        name: e?.name,
        isShow: e?.isShow,
        platform: e?.platform,
        key: Math.random() + Date.now(),
      }));
      setSocailData(socailContact);
    }
  }, [open, editData]);
  useEffect(() => {
    setFieldValue("copyProductShopId", selectedShopVal?.id);
  }, [selectedShopVal]);
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_shop")
                : t("dialog_title_update_shop")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleCloseAction}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12}>
                    <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                      {t("thead_vattin_no")}
                    </Typography>

                    <Stack
                      spacing={1.5}
                      direction="row"
                      justifyContent="center"
                    >
                      <TextField
                        fullWidth
                        size="small"
                        className="text-field"
                        placeholder={t("thead_vattin_no")}
                        {...getFieldProps("vattinNo")}
                        error={Boolean(touched.vattinNo && errors.vattinNo)}
                        helperText={touched.vattinNo && errors.vattinNo}
                      />
                      <Stack
                        spacing={0.5}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography className={language === "en" ? "field-title" : "field-title-kh"} whiteSpace="pre"    >
                          {t("thead_show_vattin")}
                        </Typography>
                        <Checkbox
                          color="success"
                          checked={values?.isShowVattin}
                          onChange={() =>
                            setFieldValue("isShowVattin", !values?.isShowVattin)
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                      {t("thead_billing_head")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="A001"
                      className="text-field"
                      {...getFieldProps("billingHead")}
                      error={Boolean(touched.billingHead && errors.billingHead)}
                      helperText={touched.billingHead && errors.billingHead}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={language === "en" ? "field-title" : "field-title-kh"}   >
                      {t("thead_email")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder="example@gmail.com"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      spacing={0.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography className={language === "en" ? "field-title" : "field-title-kh"} whiteSpace="pre"    >
                        {t("thead_show_queue_no")}
                      </Typography>
                      <Checkbox
                        color="success"
                        checked={values?.isShowQueueNo}
                        onChange={() => setFieldValue("isShowQueueNo", !values?.isShowQueueNo)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      spacing={0.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Typography className={language === "en" ? "field-title" : "field-title-kh"} whiteSpace="pre"    >
                        {t("thead_show_wifi")}
                      </Typography>
                      <Checkbox
                        color="success"
                        checked={values?.isShowWifi}
                        onChange={() => setFieldValue("isShowWifi", !values?.isShowWifi)}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {!openCrop ? (
                  <Box height="100%">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.logo
                                  ? editData?.logo
                                  : EmptyImage
                            }
                            alt=""
                            style={{ width: "140px" }}
                          />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setProgress={setProgress}
                    setStatusProgress={setStatusProgress}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}
                {statusProgress && progress < 100 ? (
                  <Box sx={{ width: "100%", marginLeft: "20px" }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                ) : (
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                    sx={{ textAlign: "center", fontWeight: "bold", mt: -2 }}
                  >
                    Logo
                  </Typography>
                )}
              </Grid>

              {
                values?.isShowWifi &&
                <Grid item xs={6}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {t("thead_wifi_name")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t("thead_wifi_name")}
                    className="text-field"
                    {...getFieldProps("wifiName")}
                    error={Boolean(touched.wifiName && errors.wifiName)}
                    helperText={touched.wifiName && errors.wifiName}
                  />
                </Grid>
              }

              {
                values?.isShowWifi &&
                <Grid item xs={6}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {t("thead_wifi_password")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t("thead_wifi_password")}
                    className="text-field"
                    {...getFieldProps("wifiPassword")}
                    error={Boolean(touched.wifiPassword && errors.wifiPassword)}
                    helperText={touched.wifiPassword && errors.wifiPassword}
                  />
                </Grid>
              }

              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("shop_name")} ({t("kh")})
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("shop_name")}
                  {...getFieldProps("shopNameKh")}
                  error={Boolean(touched.shopNameKh && errors.shopNameKh)}
                  helperText={touched.shopNameKh && errors.shopNameKh}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("shop_name")} ({t("en")})
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("shop_name")}
                  {...getFieldProps("shopNameEn")}
                  error={Boolean(touched.shopNameEn && errors.shopNameEn)}
                  helperText={touched.shopNameEn && errors.shopNameEn}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("shop_type")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={values?.shopType}
                    onChange={(e) => setFieldValue("shopType", e.target.value)}
                  >
                    <MenuItem value="Mart">Mart</MenuItem>
                    <MenuItem value="Restaurant">Restaurant</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                  Copy Product Shop
                </Typography>
                <SelectShop
                  selfId={editData?._id || null}
                  selectedVal={selectedShopVal}
                  setSelectedVal={setSelectedShopVal}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("shop_latitude")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("shop_latitude")}
                  {...getFieldProps("latitude")}
                  error={Boolean(touched.latitude && errors.latitude)}
                  helperText={touched.latitude && errors.latitude}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}  >
                  {t("shop_longitude")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("shop_longitude")}
                  {...getFieldProps("longtitude")}
                  error={Boolean(touched.longtitude && errors.longtitude)}
                  helperText={touched.longtitude && errors.longtitude}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_privacy_policy")} ({t("kh")})
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("thead_privacy_policy")}
                  {...getFieldProps("returnPolicyKh")}
                  error={Boolean(
                    touched.returnPolicyKh && errors.returnPolicyKh
                  )}
                  helperText={touched.returnPolicyKh && errors.returnPolicyKh}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("thead_privacy_policy")} ({t("en")})
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("thead_privacy_policy")}
                  {...getFieldProps("returnPolicyEn")}
                  error={Boolean(
                    touched.returnPolicyEn && errors.returnPolicyEn
                  )}
                  helperText={touched.returnPolicyEn && errors.returnPolicyEn}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                  {t("address")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder={t("address")}
                  {...getFieldProps("address")}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={language === "en" ? "field-title" : "field-title-kh"}
                >
                  {t("thead_tel")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("thead_tel")}
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <SocailContactList
                  socailData={socailData}
                  setSocailData={setSocailData}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={language === "en" ? "field-title" : "field-title-kh"}
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  placeholder={t("remark")}
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
