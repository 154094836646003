import React, { Fragment, useContext } from "react";
import { Stack, Typography, Box } from "@mui/material";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import LogoCompany from "../../../Assets/it-logo-invoice.png";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import moment from "moment";

const MainRecord = ({ title, totalAmount, discountAmount, finalAmount }) => {
  return (
    <tr>
      <td className="report-content-cell-left-bold-main">{title}</td>
      <td className="report-content-cell-left-bold-main"></td>
      <td className="report-content-cell-right-bg-main">
        {totalAmount?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-bg-main">
        {discountAmount?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-bg-main">
        {finalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

const SubRecord = ({
  title,
  qauntity,
  totalAmount,
  finalAmount,
  discountAmount,
}) => {
  return (
    <tr>
      <td
        className="report-content-cell-left-main"
        style={{ paddingLeft: "30px" }}
      >
        {title}
      </td>
      <td className="report-content-cell-center-main">{qauntity}</td>
      <td className="report-content-cell-right-main">
        {totalAmount?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-main">
        {discountAmount?.toFixed(2)} $
      </td>
      <td className="report-content-cell-right-main">
        {finalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

const FinalRecord = ({ title, totalAmount, discountAmount, finalAmount }) => {
  return (
    <tr style={{ height: "40px" }}>
      <td
        colSpan={2}
        className="tr-close-shift-sticky report-content-cell-right-bg-main"
        style={{ paddingLeft: "50px", paddingRight: "30px" }}
      >
        {title}
      </td>
      <td className="tr-close-shift-sticky report-content-cell-right-bg-main">
        {totalAmount?.toFixed(2)} $
      </td>
      <td className="tr-close-shift-sticky report-content-cell-right-bg-main">
        {discountAmount?.toFixed(2)} $
      </td>
      <td className="tr-close-shift-sticky report-content-cell-right-bg-main">
        {finalAmount?.toFixed(2)} $
      </td>
    </tr>
  );
};

export default function ProductSaleReport({
  loading,
  tableData,
  componentRef,
  startDate,
  endDate,
}) {
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const checkDate = () => {
    if (
      moment(startDate).format("DD-MMM-YYYY") ===
      moment(endDate).format("DD-MMM-YYYY")
    ) {
      return moment(startDate).format("DD.MMM.YYYY");
    } else {
      return (
        moment(startDate).format("DD.MMM.YYYY") +
        " - " +
        moment(endDate).format("DD.MMM.YYYY")
      );
    }
  };
  return (
    <div
      style={{
        padding: "10px",
        marginTop: "10px",
        borderRadius: "15px",
        backgroundColor: "#fff",
      }}
    >
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {tableData?.categories === undefined ? (
            <Box className="page-container-report-main">
              <Stack
                mb={1}
                spacing={4}
                height="100%"
                direction="row"
                justifyContent="center"
              >
                <Stack direction="column" justifyContent="center" height="100%">
                  <Box height="100%" marginTop="15px">
                    <img src={LogoCompany} className="page-report-logo-size" />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    className="page-header-report-title-main"
                  >
                    Product Sales Report
                  </Stack>

                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Typography color="#1d4592" fontSize={14}>
                      {checkDate()}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <EmptyData />
            </Box>
          ) : (
            <>
              <Box ref={componentRef} className="page-container-report-main">
                <Stack
                  direction="row"
                  justifyContent="center"
                  mb={1}
                  spacing={4}
                  height="100%"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Box height="100%" marginTop="15px">
                      <img
                        src={LogoCompany}
                        className="page-report-logo-size"
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      className="page-header-report-title-main"
                      direction="row"
                      justifyContent="center"
                    >
                      Product Sales Report
                    </Stack>

                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography color="#1d4592" fontSize={14}>
                        {checkDate()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <table className="report-container-main">
                  <thead className="report-header-main">
                    <tr>
                      <td className="header-title-report-main">
                        {t("thead_product")}
                      </td>
                      <td className="header-title-report-main">
                        {t("thead_qauntity")}
                      </td>
                      <td className="header-title-report-main">
                        {t("thead_amount")}
                      </td>
                      <td className="header-title-report-main">
                        {t("thead_discount")}
                      </td>
                      <td className="header-title-report-main">
                        {t("thead_final")}
                      </td>
                    </tr>
                  </thead>

                  <tfoot className="report-footer-main">
                    <tr>
                      <td className="report-footer-cell" colSpan={5}>
                        <div className="footer-info-main">
                          <div className={"page-footer-main"}>
                            <div className="page-footer-line-main" />
                            <div className={"page-footer-text-main"}>
                              Boeungdonpa Village, Slokram Commune Siem Reap /
                              Telephone: 078 881 919
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                  <tbody className="report-content-main">
                    {tableData?.categories?.map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <MainRecord
                            finalAmount={row?.final || 0}
                            totalAmount={row?.amount || 0}
                            discountAmount={row?.discount || 0}
                            title={row?.category?.categoryNameEn}
                          />

                          {row?.mainProducts?.map((pro, indexs) => (
                            <SubRecord
                              key={indexs}
                              finalAmount={pro?.final || 0}
                              totalAmount={pro?.amount || 0}
                              title={pro?.mainProductEnName}
                              discountAmount={pro?.discount || 0}
                              qauntity={pro?.qty + " " + pro?.unitEnName}
                            />
                          ))}
                        </Fragment>
                      );
                    })}

                    {/* ========================== Final ====================== */}
                    <FinalRecord
                      title={"Total"}
                      finalAmount={tableData?.final || 0}
                      totalAmount={tableData?.amount || 0}
                      discountAmount={tableData?.discount || 0}
                    />
                  </tbody>
                </table>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
}
