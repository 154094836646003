import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import {
  SelectMainProduct,
  SelectMainProductByShop,
  SelectSubProduct,
} from "../../../Include/Function/DynamicSelect";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
export default function SelectPurchaseProducts({
  purchaseProductsData,
  setPurchaseProductsData,
  required,
}) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newItem = {
    qty: null,
    expireDate: null,
    unitPrice: null,
    subProductId: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
  };

  const handleAddItem = () =>
    setPurchaseProductsData([...purchaseProductsData, newItem]);

  const handleRemoveItem = (key) => {
    const newData = purchaseProductsData.filter((e) => e.key !== key);
    setPurchaseProductsData([...newData]);
  };

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = purchaseProductsData.map((e) => {
      if (e.key === key && fieldName === "qty") e.qty = value;
      if (e.key === key && fieldName === "unitPrice") e.unitPrice = value;
      if (e.key === key && fieldName === "expireDate") e.expireDate = value;
      if (e.key === key && fieldName === "subProductId") e.subProductId = value;
      if (e.key === key && fieldName === "mainProductId") {
        e.mainProductId = value;
        e.subProductId = { id: "", title: "" };
      }
      return e;
    });
    setPurchaseProductsData([...newData]);
  };

  const shopId = window.localStorage.getItem("shopId");
  return (
    <Stack direction="column" width="100%" spacing={1} mt={1}>
      <Stack direction="row" alignItems="center" width="100%" spacing={2}>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_product")}
        </Typography>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_qty")}
        </Typography>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_unit")}
        </Typography>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_price")}
        </Typography>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_total_amount")}
        </Typography>
        <Typography
          className={language === "en" ? "field-title" : "field-title-kh"}
          fontWeight="bold"
          width="16.66%"
        >
          {t("thead_expire_date")}
        </Typography>
        <IconButton onClick={handleAddItem}>
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      <Stack direction="column" width="100%" spacing={2} minHeight={300}>
        {purchaseProductsData?.map((e, index) => (
          <Stack direction="row" alignItems="center" spacing={1} key={index}>
            <Stack sx={{ width: "16.66%" }}>
              <SelectMainProductByShop
                shopId={shopId}
                required={required}
                selectedVal={e?.mainProductId}
                dataList={purchaseProductsData}
                setSelectedVal={(value) =>
                  handleUpdateItem(e.key, value, "mainProductId")
                }
              />
            </Stack>

            <TextField
              fullWidth
              size="small"
              type="number"
              value={e?.qty}
              className="text-field"
              sx={{ width: "16.66%" }}
              error={!e?.qty && required}
              placeholder={t("thead_qty")}
              onChange={({ target }) =>
                handleUpdateItem(e.key, target.value, "qty")
              }
            />

            <Stack sx={{ width: "16.66%" }}>
              <SelectSubProduct
                required={required}
                selectedVal={e?.subProductId || { id: "", title: "" }}
                mainProductId={e?.mainProductId?.id}
                setSelectedVal={(value) =>
                  handleUpdateItem(e.key, value, "subProductId")
                }
              />
            </Stack>
            <TextField
              fullWidth
              size="small"
              type="number"
              value={e?.unitPrice}
              className="text-field"
              sx={{ width: "16.66%" }}
              placeholder={t("thead_price")}
              error={!e?.unitPrice && required}
              InputProps={{ endAdornment: "$" }}
              onChange={({ target }) =>
                handleUpdateItem(
                  e.key,
                  target.value < 0 ? 0 : target.value,
                  "unitPrice"
                )
              }
            />

            <TextField
              fullWidth
              size="small"
              type="number"
              className="text-field"
              sx={{ width: "16.66%" }}
              value={e?.unitPrice * e?.qty || ""}
              placeholder={t("thead_total_amount")}
              InputProps={{ endAdornment: "$", readOnly: true }}
            />

            <Box sx={{ width: "16.66%" }}>
              <SelectDatePicker
                date={e?.expireDate}
                setDate={(date) => handleUpdateItem(e.key, date, "expireDate")}
              />
            </Box>

            <IconButton onClick={() => handleRemoveItem(e?.key)}>
              <CloseOutlinedIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
