import moment from "moment";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_PAYMENT_REPORT } from "../../Schema/Report";

const useGetPaymentReport = ({
    selectedShift,
    startDate,
    viewType,
    endDate,
}) => {

    const [tableDataPayment, setTableDataPayment] = useState([]);
    const { refetch } = useQuery(GET_PAYMENT_REPORT, {
        variables: {
            viewType: viewType,
            shiftId: selectedShift?.id || null,
            endDate: viewType === "Shift" ? "" : moment(endDate).format("YYYY-MM-DD"),
            startDate: viewType === "Shift" ? "" : moment(startDate).format("YYYY-MM-DD"),
        },
        skip: true, // Prevent automatic query execution
        onCompleted: ({ getPaymentReport }) => {
            setTableDataPayment(getPaymentReport || []);
        },
        onError: ({ message }) => {
            console.error("getPaymentReport Error fetching data:", message);
        },
    });

    // Refetch data whenever `startDate`, `endDate`, or `viewType` changes
    useEffect(() => {
        refetch();
    }, [selectedShift, viewType, startDate, endDate,]);

    return {
        tableDataPayment,
    };
};

export default useGetPaymentReport;
