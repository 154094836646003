import React, { useContext } from "react";
import {
  Grid,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";

export const ViewAdjustOut = ({ open, editData, handleClose, }) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
 
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("dialog_title_correction_history")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_warehouse")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.warehouseId?.warehouseEnName : editData?.warehouseId?.warehouseKhName}
              </Typography>
            </Stack>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_product")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.mainStockId?.mainProductId?.mainProductEnName : editData?.mainStockId?.mainProductId?.mainProductKhName}
              </Typography>
            </Stack>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_reason")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {editData?.reason}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack width="100%" direction="column" spacing={2} mt={2}  >

              <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>

                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                  {t("thead_product")}
                </Typography>

                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="30%">
                  {t("thead_from_number")}
                </Typography>

                <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="30%">
                  {t("thead_to_number")}
                </Typography>

              </Stack>


              <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
                <Divider />
                {
                  editData?.adjustOutStockIds?.map((e) => <Stack width="100%" direction="column" spacing={1.7}>
                    <Stack width="100%" direction="row" spacing={2} sx={{ paddingX: 2 }}>
                      <Typography width="40%" > {language === "en" ? e?.mainStockId?.mainProductId?.mainProductEnName : e?.mainStockId?.mainProductId?.mainProductKhName} </Typography>
                      <Typography width="30%" > {e?.adjustFrom} {language === "en" ? e?.mainStockId?.mainProductId?.minimumUnitId?.unitEnName : e?.mainStockId?.mainProductId?.minimumUnitId?.unitKhName}</Typography>
                      <Typography width="30%" > {e?.adjustTo} {language === "en" ? e?.mainStockId?.mainProductId?.minimumUnitId?.unitEnName : e?.mainStockId?.mainProductId?.minimumUnitId?.unitKhName}</Typography>
                    </Stack>
                    <Divider />
                  </Stack>)
                }
              </Stack>

            </Stack>

          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            fullWidth
            onClick={handleClose}
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog >
  );
};
