import React, { useState, useContext, useEffect, Fragment } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
//icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// src
import "../../../Style/pageStyle.scss";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import CetegoryAction from "./CetegoryAction";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { AuthContext } from "../../../Context/AuthContext";
import UpdateStatusCategory from "./UpdateStatusCategory";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { translateLauguage } from "../../../Include/Function/Translate";

export const TableSub = ({ rowData, index, refetch, rowColor, sub }) => {
  return (
    <TableRow className="body-row" key={index}>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell-start bgcolor`
              : `body-cell-start`
            : index % 2 === 0
            ? `body-cell-start bgcolor`
            : `body-cell-start bgcolor`
        }
        align="left"
      >
        {/* {index + 1} */}
      </TableCell>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell-sub${sub} bgcolor`
              : `body-cell-sub${sub}`
            : index % 2 === 0
            ? `body-cell-sub${sub} bgcolor`
            : `body-cell-sub${sub} bgcolor`
        }
        align="left"
      >
        <FiberManualRecordIcon sx={{ fontSize: "8px", mr: "2px", mt: "3px" }} />
        {rowData?.categoryNameKh}
      </TableCell>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell bgcolor`
              : `body-cell`
            : index % 2 === 0
            ? `body-cell bgcolor`
            : `body-cell bgcolor`
        }
        align="left"
      >
        {rowData?.categoryNameEn}
      </TableCell>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell bgcolor`
              : `body-cell`
            : index % 2 === 0
            ? `body-cell bgcolor`
            : `body-cell bgcolor`
        }
        align="left"
      >
        {rowData?.remark}
      </TableCell>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell bgcolor`
              : `body-cell`
            : index % 2 === 0
            ? `body-cell bgcolor`
            : `body-cell bgcolor`
        }
        align="center"
      >
        <UpdateStatusCategory editData={rowData} setRefetch={refetch} />
      </TableCell>
      <TableCell
        className={
          rowColor === "green"
            ? index % 2 !== 0
              ? `body-cell-end bgcolor`
              : `body-cell-end`
            : index % 2 === 0
            ? `body-cell-end bgcolor`
            : `body-cell-end bgcolor`
        }
      >
        <CetegoryAction
          editData={rowData}
          setRefetch={refetch}
          index={
            rowColor === "green"
              ? index % 2 !== 0
                ? 2
                : 1
              : index % 2 === 0
              ? 2
              : 1
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default function CategoryData({
  page,
  limit,
  loading,
  keyword,
  setPage,
  handleLimit,
  setRefetch,
  tableData,
  paginationData,
}) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box className="body-container">
      <TableContainer sx={{ maxWidth: `${width}px` }}>
        <Table className="table" aria-label="simple table">
          <TableHead className="header-row ">
            <TableRow>
              <TableCell
                className={
                  language === "en"
                    ? "header-title-start"
                    : "header-title-start-kh"
                }
                width="5%"
              >
                {t("thead_no")}
              </TableCell>
              <TableCell
                className={
                  language === "en" ? "header-title" : "header-title-kh"
                }
                width="30%"
              >
                {t("cate_kh")}
              </TableCell>
              <TableCell
                className={
                  language === "en" ? "header-title" : "header-title-kh"
                }
                width="25%"
              >
                {t("cate_en")}
              </TableCell>
              <TableCell
                className={
                  language === "en" ? "header-title" : "header-title-kh"
                }
                width="25%"
              >
                {t("remark")}
              </TableCell>
              <TableCell
                className={
                  language === "en" ? "header-title" : "header-title-kh"
                }
                width="10%"
              >
                {t("thead_status")}
              </TableCell>
              <TableCell
                className={
                  language === "en" ? "header-title-end" : "header-title-end-kh"
                }
                width="5%"
              ></TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableBody className="table-body">
              <TableRow className="body-row">
                <TableCell colSpan={7} className="body-cell">
                  <LoadingPage />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              {tableData?.length === 0 ? (
                <TableBody className="table-body">
                  <TableRow className="body-row">
                    <TableCell colSpan={7} className="body-cell">
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableBody className="table-body">
                    {tableData?.map((row, index) => {
                      // console.log("row::", paginationData?.slNo);
                      return (
                        <Fragment key={index}>
                          <TableRow className="body-row">
                            <TableCell
                              className={
                                language === "en"
                                  ? "body-cell-start"
                                  : "body-cell-start-kh"
                              }
                            >
                              {index + paginationData?.slNo}
                            </TableCell>
                            <TableCell className="body-cell-kh">
                              {row?.categoryNameKh}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.categoryNameEn}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.remark}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                              align="center"
                            >
                              <UpdateStatusCategory
                                editData={row}
                                setRefetch={setRefetch}
                              />
                            </TableCell>
                            <TableCell
                              className={
                                language === "en"
                                  ? "body-cell-end"
                                  : "body-cell-end-kh"
                              }
                              align="right"
                            >
                              <CetegoryAction
                                editData={row}
                                setRefetch={setRefetch}
                              />
                            </TableCell>
                          </TableRow>

                          {/* ==============  Sub Row 1 =================== */}
                          {row?.subCategories?.length !== 0 ? (
                            <>
                              {row?.subCategories?.map((rowSub1, index1) => (
                                <>
                                  <TableSub
                                    sub={1}
                                    key={index1}
                                    rowData={rowSub1}
                                    setRefetch={setRefetch}
                                    index={index1}
                                    rowColor={
                                      index1 % 2 === 0 ? "green" : "normal"
                                    }
                                  />

                                  {/* ==============  Sub Row 2 =================== */}
                                  {rowSub1?.subCategories?.length !== 0 ? (
                                    <>
                                      {rowSub1?.subCategories?.map(
                                        (rowSub2, index2) => (
                                          <>
                                            <TableSub
                                              rowData={rowSub2}
                                              sub={2}
                                              key={index2}
                                              setRefetch={setRefetch}
                                              index={index2}
                                              rowColor={
                                                index2 % 2 === 0
                                                  ? "green"
                                                  : "normal"
                                              }
                                            />

                                            {/* ==============  Sub Row 3 =================== */}
                                            {rowSub2?.subCategories?.length !==
                                            0 ? (
                                              <>
                                                {rowSub2?.subCategories?.map(
                                                  (rowSub3, index3) => (
                                                    <>
                                                      <TableSub
                                                        rowData={rowSub3}
                                                        sub={3}
                                                        key={index3}
                                                        setRefetch={setRefetch}
                                                        index={index3}
                                                        rowColor={
                                                          index3 % 2 === 0
                                                            ? "green"
                                                            : "normal"
                                                        }
                                                      />

                                                      {/* ==============  Sub Row 4 =================== */}
                                                      {rowSub3?.subCategories
                                                        ?.length !== 0 ? (
                                                        <>
                                                          {rowSub3?.subCategories?.map(
                                                            (
                                                              rowSub4,
                                                              index4
                                                            ) => (
                                                              <>
                                                                <TableSub
                                                                  key={index4}
                                                                  rowData={
                                                                    rowSub4
                                                                  }
                                                                  sub={4}
                                                                  setRefetch={
                                                                    setRefetch
                                                                  }
                                                                  index={index4}
                                                                  rowColor={
                                                                    index4 %
                                                                      2 ===
                                                                    0
                                                                      ? "green"
                                                                      : "normal"
                                                                  }
                                                                />

                                                                {/* ==============  Sub Row 5 =================== */}
                                                                {rowSub4
                                                                  ?.subCategories
                                                                  ?.length !==
                                                                0 ? (
                                                                  <>
                                                                    {rowSub4?.subCategories?.map(
                                                                      (
                                                                        rowSub5,
                                                                        index5
                                                                      ) => (
                                                                        <>
                                                                          <TableSub
                                                                            key={
                                                                              index5
                                                                            }
                                                                            rowData={
                                                                              rowSub5
                                                                            }
                                                                            sub={
                                                                              5
                                                                            }
                                                                            setRefetch={
                                                                              setRefetch
                                                                            }
                                                                            index={
                                                                              index5
                                                                            }
                                                                            rowColor={
                                                                              index5 %
                                                                                2 ===
                                                                              0
                                                                                ? "green"
                                                                                : "normal"
                                                                            }
                                                                          />
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ))}
                            </>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>

      {/* ==================== Pagination  ============================= */}
      <FooterPagination
        page={page}
        limit={limit}
        setPage={setPage}
        handleLimit={handleLimit}
        totalDocs={paginationData?.totalDocs}
        totalPages={paginationData?.totalPages}
      />
    </Box>
  );
}
