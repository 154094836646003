import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  Box,
  Grid,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import "../../../Style/pageStyle.scss";
import { useLazyQuery } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import { AuthContext } from "../../../Context/AuthContext";
import useGetProductForScan from "./Hook/getProductForScan";
import { GET_INVOICE_BY_ID } from "../../../Schema/Invoice";
import useItemProductToPay from "./Hook/useItemProductToPay";
import ProductForSale from "../ProductPOS/ProductPOS";
import useGetProductPagination from "./Hook/getProductPagination";
import { translateLauguage } from "../../../Include/Function/Translate";
import ProductInvoiceForUpdateSuspend from "./ProductInvoiceForUpdateSuspend";
import { padNumber } from "../../../Include/Function/DynamicFn";
import moment from "moment";
import AllCategory from "../AllCategory/AllCategory";

export default function UpdateSuspendInvoice({ open, handleClose }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [selectedCustomerVal, setSelectedCustomerVal] = useState({
    id: "",
    title: "",
  });

  const [invoiceById, setInvoiceById] = useState();

  const {
    subTotal,
    totalAomunt,
    totalDiscount,
    itemProductToPay,
    setItemProductToPay,
    handleChangeDiscount,
    selectedCardNumberVal,
    handleDeleteItemInCard,
    handleSelectProductItem,
    loadingItemProductToPay,
    handleUpdateInputQtyItem,
    setSelectedCardNumberVal,
    handleMinusQtyItemFromCard,
    setLoadingItemProductToPay,
    handleIncreaseQtyItemInCard,
    handleDeleteAllItemsFromCard,
  } = useItemProductToPay();

  const {
    page,
    limit,
    shopId,
    setPage,
    tableData,
    categoryId,
    handleLimit,
    handleSearch,
    setCategoryId,
    paginationData,
    loadingProductForSale,
  } = useGetProductPagination();

  const { productBarcode, setProductBarcode } = useGetProductForScan(
    shopId,
    handleSelectProductItem
  );

  const invoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));

  const [getInvoiceById] = useLazyQuery(GET_INVOICE_BY_ID, {
    onCompleted: ({ getInvoiceById }) => {
      if (getInvoiceById) {
        const InvoiceById = getInvoiceById[0];
        setInvoiceById(InvoiceById);
        setSelectedCustomerVal({
          id: InvoiceById?.customerId?._id || "",
          title: InvoiceById?.customerId?.name || "",
        });

        const newProduct = InvoiceById?.products?.map((e) => ({
          discount: {
            type: e?.discount?.type,
            remark: e?.discount?.remark,
            amount: e?.discount?.amount || 0,
          },
          qty: e?.qty || 0,
          productId: e?.productId?._id,
          mainProductId: e?.productId?.mainProductId?._id,
          productName:
            language === "en"
              ? e?.productId?.mainProductId?.mainProductEnName
              : e?.productId?.mainProductId?.mainProductKhName,
          price: e?.price || 0,
          imageSrc: e?.productId?.imageSrc,
          key: Date.now() + Math.random(),
        }));
        if (open) setLoadingItemProductToPay(false);
        setItemProductToPay(newProduct);
      }
    },
  });
  useEffect(() => {
    getInvoiceById({
      variables: {
        id: [invoiceId],
      },
    });
  }, [invoiceId, open]);
  const handleCloseDialogSuspend = () => {
    handleClose();
    setInvoiceById("");
    setItemProductToPay([]);
    setLoadingItemProductToPay(true);
    setSelectedCustomerVal({ id: "", title: "" });
    window.localStorage.removeItem("CurrentInvoiceId");
  };
  return (
    <Dialog open={open} className="dialog-container" fullWidth fullScreen>
      <DialogTitle sx={{ padding: "2.5px 15px 2.5px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            {invoiceById?.invoiceNo && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  className={
                    language === "en" ? "dialog-title" : "dialog-title-kh"
                  }
                  sx={{
                    fontWeight: "normal !important",
                    fontSize: "14px !important",
                  }}
                >
                  {t("thead_invoice_no")} :
                </Typography>
                <Typography
                  className={
                    language === "en" ? "dialog-title" : "dialog-title-kh"
                  }
                  sx={{
                    fontSize: "16px !important",
                  }}
                >
                  {padNumber(
                    invoiceById?.invoiceNo,
                    5,
                    moment(invoiceById?.createdAt).format("YYYY")
                  )}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleCloseDialogSuspend}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ bgcolor: "#ebedef" }}>
        <Box sx={{ marginTop: "19px" }}>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={7}>
              <Stack direction="column" spacing={3}>
                <AllCategory
                  categoryId={categoryId}
                  setCategoryId={setCategoryId}
                />
                <ProductForSale
                  page={page}
                  limit={limit}
                  setPage={setPage}
                  tableData={tableData}
                  handleLimit={handleLimit}
                  handleSearch={handleSearch}
                  paginationData={paginationData}
                  loadingProductForSale={loadingProductForSale}
                  handleSelectProductItem={handleSelectProductItem}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7} xl={5}>
              <ProductInvoiceForUpdateSuspend
                handleCloseDialogSuspend={handleCloseDialogSuspend}
                subTotal={subTotal}
                totalAomunt={totalAomunt}
                handleClose={handleClose}
                totalDiscount={totalDiscount}
                productBarcode={productBarcode}
                itemProductToPay={itemProductToPay}
                setProductBarcode={setProductBarcode}
                setItemProductToPay={setItemProductToPay}
                selectedCustomerVal={selectedCustomerVal}
                handleChangeDiscount={handleChangeDiscount}
                selectedCardNumberVal={selectedCardNumberVal}
                setSelectedCustomerVal={setSelectedCustomerVal}
                handleDeleteItemInCard={handleDeleteItemInCard}
                loadingItemProductToPay={loadingItemProductToPay}
                setSelectedCardNumberVal={setSelectedCardNumberVal}
                handleUpdateInputQtyItem={handleUpdateInputQtyItem}
                handleMinusQtyItemFromCard={handleMinusQtyItemFromCard}
                handleIncreaseQtyItemInCard={handleIncreaseQtyItemInCard}
                handleDeleteAllItemsFromCard={handleDeleteAllItemsFromCard}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
