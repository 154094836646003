import { gql } from "@apollo/client";

export const GET_REFUND_PAGINATION = gql`
  query GetRefundPagination($page: Int, $limit: Int, $pagination: Boolean) {
    getRefundPagination(page: $page, limit: $limit, pagination: $pagination) {
      data {
        _id
        invoiceId {
          _id
          invoiceNo
          subTotal
        }
        totalAmount
        refundProducts {
          _id
          productId {
            qtyInMinimumUnit
            unitId {
              unitEnName
              unitKhName 
            }
            mainProductId {
              mainProductEnName
              mainProductKhName 
            }
          }
          refundQty
        }
        refundPayments {
          paymentMethod
          amount
          khr
          usd
        }
        remark
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
