import React, { Fragment, useContext } from "react";
import {
  Grid,
  Stack,
  Button,
  IconButton,
  TextField,
  Typography,
  DialogActions,
} from "@mui/material";
import "../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent"; 
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";

export const OpenCashUSD = ({
  open,
  handleClose,
  cashLabelUSD,
  handleChangeQtyCashLabelUSD,
}) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const totalOpenCashUSD = cashLabelUSD.reduce(
    (a, b) => a + b?.sheetQty * b?.cashLabel,
    0
  );
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_select_cash")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={1} columnSpacing={3}>
          {cashLabelUSD?.map((e, index) => (
            <Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  value={e?.sheetQty}
                  className="text-field"
                  onChange={({ target }) =>
                    handleChangeQtyCashLabelUSD(e?.caseLabelId, target?.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={`column`}
                  alignItems={`right`}
                  justifyContent={`center`}
                  alignSelf={`center`}
                  height="100%"
                >
                  <Typography>$ {e?.cashLabel}</Typography>
                </Stack>
              </Grid>
            </Fragment>
          ))}

          <Grid item xs={6}>
            <Stack
              direction={`column`}
              textAlign={`right`}
              justifyContent={`center`}
              alignSelf={`right`}
              height="100%"
            >
              <Typography
                className={language === "en" ? "field-title" : "field-title-kh"}
                sx={{ fontWeight: "bold" }}
              >
                {t("total")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction={`row`}
              alignItems={`center`}
              justifyContent={`space-between`}
              sx={{
                backgroundColor: "#C4FFB4",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
            >
              <Typography>$</Typography>
              <Typography className="field-title">
                {totalOpenCashUSD}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
            fullWidth
            onClick={handleClose}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
