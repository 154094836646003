import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  Button,
  Typography,
  IconButton,
  DialogTitle,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

//Icons
import { BiMinusCircle } from "react-icons/bi";
import thanks from "../../Assets/namaste.gif";

// src
import "../../Style/dialogStyle.scss";

export default function ThanksDialog({ open, handleClose }) {
  useEffect(() => {
    if (open) {
      // Set a timeout to close the dialog after 5 seconds
      const timer = setTimeout(() => {
        handleClose();
      }, 5000); // 5000 milliseconds = 5 seconds

      // Clean up the timer if the dialog is closed manually or if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [open, handleClose]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="right">
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <img src={thanks} style={{ width: "280px" }} />
          </Stack>

          <Typography className="thank-noted">សូមអរគុណ!</Typography>
          <Typography>សូមអញ្ជើញមកម្តងទៀតនៅពេលក្រោយ</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
