import { useState , useEffect , useContext } from "react";
import {
  Grid,
  Stack,
  Button,
  Box,
  Select,
  Tooltip,
  Checkbox,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  FormControl,
  DialogActions,
  FormHelperText,
  Tabs,
  Tab
} from "@mui/material";
import { SelectUnit, } from "../../../../Include/Function/DynamicSelect";
import { UPDATE_SPECAIL_FIELD } from "../../../../Schema/Product";
import { useMutation } from "@apollo/client";
import { BiSolidEdit } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { SiTicktick } from "react-icons/si";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";



export const FormEditFieldUnit = ({editData , values ,setRefetch, setFieldSpecialUpdate}) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //
  const [selectedValue,setSelectedValue] = useState({ id: "" , title: ""});
  
  useEffect( () => {
    setSelectedValue(values);
  },[values])

  //================ Mutation Update =================
  const [updateProductSpecailField] = useMutation(UPDATE_SPECAIL_FIELD,{
    onCompleted: ({updateProductSpecailField}) => {
      // console.log("updateProductSpecailField:::" , updateProductSpecailField?.message?.messageEn)
      if(updateProductSpecailField?.isSuccess) {
        setRefetch();
        setFieldSpecialUpdate("");
        setAlert(true, "success" , updateProductSpecailField?.message)
      } else {
        setAlert(true, "error" ,  updateProductSpecailField?.message)
        setFieldSpecialUpdate("");
      } 
    },
    onError: (error) => {
      // console.log("error updateProductSpecailField::::",error.message)
      setAlert(true, "error" , error.message)
      setFieldSpecialUpdate("");
    }
  })

  const handleSubmitUpdate = () => {
    updateProductSpecailField({
      variables: {
        id: editData?._id,
        input: {
          unitId: selectedValue?.id,
        }
      }
    })
  }

  return(
    <>
      <Stack direction="row" spacing={2}>
        <Typography
          className={
            language === "en" ? "field-title" : "field-title-kh"
          }
        >
          {t("unit")}
        </Typography> 
         
        <IconButton 
          disabled={selectedValue?.id ? false : true}
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            '&:hover': {
              backgroundColor: "transparent",
            }, 
          }}
          onClick={() => {  
            handleSubmitUpdate();
          }}
        >
          <SiTicktick style={{ color: "green", fontSize: 18 }} />
        </IconButton> 
      </Stack>

      <SelectUnit 
        selectedVal={selectedValue} 
        setSelectedVal={setSelectedValue} 
      />
      {/* {!!errors.unitId && touched.unitId && (
        <FormHelperText error id="outlined-adornment-password">
          {errors.unitId}
        </FormHelperText>
      )} */}
    </>
  )
}



export const FormEditFieldQuantityInUnit = ({editData , values , setRefetch , unitVal , mainProduct , setFieldSpecialUpdate}) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  //
  const [qtyInMinimumUnit,setQtyInMinimumUnit] = useState(0);
  
  useEffect( () => {
    setQtyInMinimumUnit(values);
  },[values])

  //================ Mutation Update =================
  const [updateProductSpecailField] = useMutation(UPDATE_SPECAIL_FIELD,{
    onCompleted: ({updateProductSpecailField}) => {
      // console.log("updateProductSpecailField:::" , updateProductSpecailField?.message?.messageEn)
      if(updateProductSpecailField?.isSuccess) {
        setRefetch();
        setFieldSpecialUpdate("");
        setAlert(true, "success" , updateProductSpecailField?.message)
      } else {
        setAlert(true, "error" ,  updateProductSpecailField?.message)
        setFieldSpecialUpdate("");
      } 
    },
    onError: (error) => {
      // console.log("error updateProductSpecailField::::",error.message)
      setAlert(true, "error" , error.message)
      setFieldSpecialUpdate("");
    }
  })

  const handleSubmitUpdate = () => {
    updateProductSpecailField({
      variables: {
        id: editData?._id,
        input: {
          qtyInMinimumUnit: parseFloat(qtyInMinimumUnit),
        }
      }
    })
  }

  return(
    <>
      <Stack direction="row" spacing={2}>
        <Typography
          className={
            language === "en" ? "field-title" : "field-title-kh"
          }
        >
          {t("thead_qty_in_unit")}
        </Typography> 
         
        <IconButton 
          disabled={qtyInMinimumUnit !== 0 && qtyInMinimumUnit !== undefined ? false : true}
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            '&:hover': {
              backgroundColor: "transparent",
            }, 
          }}
          onClick={() => {  
            handleSubmitUpdate();
          }}
        >
          <SiTicktick style={{ color: "green", fontSize: 18 }} />
        </IconButton> 
      </Stack>

      <TextField 
        fullWidth
        size="small"
        type="number"
        className="text-field"
        placeholder={t("label_qty_in_unit")}
        value={qtyInMinimumUnit}
        onChange={ (e) => {
          if(e.target.value) {
            setQtyInMinimumUnit(parseFloat(e.target.value))
          } else {
            setQtyInMinimumUnit(0)
          } 
        }}
        InputProps={{
          endAdornment: (
            <Typography whiteSpace="pre" fontSize={15}>
              {language === "en"
                ? mainProduct?.minimumUnitId?.unitEnName
                : mainProduct?.minimumUnitId?.unitKhName}/
              {unitVal?.title}
            </Typography>
          ),
        }} 
      />
       
    </>
  )
}