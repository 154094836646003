import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Badge,
  Button,
  Typography,
} from "@mui/material";
import "../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { Request } from "../../Components/InventoryInWarehouse/Request/Request";
import { Receive } from "../../Components/InventoryInWarehouse/Receive/Receive";
import { Transfer } from "../../Components/InventoryInWarehouse/Transfer/Transfer";
import { AdjustIn } from "../../Components/InventoryInWarehouse/AdjustIn/AdjustIn";
import { Purchase } from "../../Components/InventoryInWarehouse/Purchase/Purchase";
import { AdjustOut } from "../../Components/InventoryInWarehouse/AdjustOut/AdjustOut";
import { Inventory } from "../../Components/InventoryInWarehouse/Inventory/Inventory";
import { GET_COUNT_REQUEST_STATUS_BADGE, GET_COUNT_TRANSFER_BACK_BADGE } from "../../Schema/InventoryInWarehouse";

export const InventoryInWarehouse = () => {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext); 
  const { t } = translateLauguage(language);
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const [tabValue, setTabValue] = useState("inventory");
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);



  window.localStorage.getItem("")

  const { data } = useQuery(GET_COUNT_REQUEST_STATUS_BADGE, {
    variables: {
      shopId: null
    }
  })

  const { data: getCountTransferBackBadge } = useQuery(GET_COUNT_TRANSFER_BACK_BADGE,)



  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography className={language === "kh" ? "page-title-kh" : "page-title"}>
            {t(`page-warehouse`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={3} className="inventory-btn-container">
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("inventory")}
              className={tabValue === "inventory" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_inventory')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("purchase")}
              className={tabValue === "purchase" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('btn-purchase-receive')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("adjustin")}
              className={tabValue === "adjustin" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_adjust_in_warehouse')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("adjustout")}
              className={tabValue === "adjustout" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_adjust_out_warehouse')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("transfer")}
              className={tabValue === "transfer" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('btn-transfer-goods-shop')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Badge color="warning" badgeContent={data?.getCountRequestStatusBadge?.pending} sx={{ width: "100% !important" }} >
              <Button
                fullWidth

                onClick={() => setTabValue("request")}
                className={tabValue === "request" ?
                  language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                  language === "en" ? "inventory-btn" : "inventory-btn-kh"}
              >
                {t('btn-request-from-shop')}
              </Button>
            </Badge>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Badge color="warning" badgeContent={getCountTransferBackBadge?.getCountTransferBackBadge} sx={{ width: "100% !important" }} >
              <Button
                fullWidth
                onClick={() => setTabValue("receive")}
                className={tabValue === "receive" ?
                  language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                  language === "en" ? "inventory-btn" : "inventory-btn-kh"}
              >
                {t('btn-receive-product-from-shop')}
              </Button>
            </Badge>
          </Grid> 
        </Grid>
      </Box>

      {tabValue === "request" && <Request />}
      {tabValue === "receive" && <Receive />}
      {tabValue === "adjustin" && <AdjustIn />}
      {tabValue === "transfer" && <Transfer />}
      {tabValue === "purchase" && <Purchase />}
      {tabValue === "inventory" && <Inventory />}
      {tabValue === "adjustout" && <AdjustOut />}

    </div>
  );
};
