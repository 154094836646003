import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import "../../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import { AuthContext } from "../../../../Context/AuthContext";
import SelectTransferProducts from "./SelectTransferProducts";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { CREATE_TRANSFER } from "../../../../Schema/InventoryInWarehouse";


export const TransferProduct = ({ open, handleClose, setRefetch, hideBackdrop, editData }) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [transferProductsData, setTransferProductsData] = useState([]);
  const [selectedShopVal, setSelectedShopVal] = useState({ id: "", title: "" });

  const newItem = {
    approveQty: null,
    mainProductId: null,
    key: Math.random() + Date.now(),
    warehouseIds: [{ key: Math.random() + Date.now(), warehouseId: null, qty: null },],
  }



  //======================== Create User =========================
  const [createTransfer] = useMutation(CREATE_TRANSFER, {
    onCompleted: ({ createTransfer }) => {

      console.log("createTransfer ===>", createTransfer);

      setLoading(false);
      if (createTransfer?.isSuccess === true) {
        setAlert(true, "success", createTransfer?.message);
        handleClose();
        setRefetch();
        resetForm();
        setRequired(false);
        setTransferProductsData([]);
        setSelectedShopVal({ id: "", title: "" });
      } else {
        setRequired(false);
        setAlert(true, "error", createTransfer?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    shopId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      shopId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      if (transferProductsData?.length > 0) {

        const requiredProduct = transferProductsData?.map((e) => {
          const require = e?.warehouseIds?.map((i) => parseFloat(i?.qty) >= 0 && i?.warehouseId?.id ? true : false).filter((e) => !e)
          return require.length > 0 ? false : true
        })?.filter((e) => !e);

        if (requiredProduct?.length > 0) {
          setRequired(true);
          return;
        }


        const transferProducts = transferProductsData.map((e) => ({
          mainProductId: e?.mainProductId?.id,
          deliverQty: e?.warehouseIds?.reduce((a, b) => a + parseFloat(b.qty || 0), 0),
          warehouseIds: e?.warehouseIds?.map((i) => ({
            qty: parseFloat(i?.qty),
            warehouseId: i?.warehouseId?.id,
            remainInStock: parseFloat(i?.remainInStock),
          })).filter((e) => e?.qty > 0)
        }));

        console.log("transferProducts===>  ", transferProducts);

        const finalTransferProducts = transferProducts.filter((d) => d?.deliverQty || 0 > 0);

        console.log("finalTransferProducts===>  ", finalTransferProducts);

        setLoading(true);
        createTransfer({
          variables: {
            input: {
              requestId: editData?._id,
              shopId: editData?.shopId?._id,
              transferBy: {
                remark: value?.remark
              },
              transferProducts: finalTransferProducts,
            }
          }
        });

      } else {
        setTransferProductsData([newItem]);
      }
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);


  useEffect(() => {
    setFieldValue("shopId", selectedShopVal?.id);
  }, [selectedShopVal]);

  useEffect(() => {
    setSelectedShopVal({ id: editData?.shopId?._id, title: language === "en" ? editData?.shopId?.shopNameEn : editData?.shopId?.shopNameKh });


    const newData = editData?.approveMainProducts.map(e => ({
      approveQty: e?.qty,
      receiveQty: e?.receiveQty,
      deliveryQty: e?.deliveryQty,
      remainQty: e?.qty - e?.deliveryQty,
      mainProductId: {
        id: e?.mainProductId?._id,
        title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductEnName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName
      },
      key: Math.random() + Date.now(),
      warehouseIds: [{ key: Math.random() + Date.now(), warehouseId: null, qty: 0, remainInStock: null }],
    }))
    setTransferProductsData([...newData]);
  }, [open, editData]);


  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xl" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_transfer_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                    {t("thead_shop")}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                    :  {language === "en" ? editData?.shopId?.shopNameEn : editData?.shopId?.shopNameKh}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <SelectTransferProducts
                  required={required}
                  transferProductsData={transferProductsData}
                  setTransferProductsData={setTransferProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-transfer")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
