import React, { useContext } from "react";
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../Context/AuthContext";
import ItemProductPOS from "./ItemProductPOS/ItemProductPOS";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import { translateLauguage } from "../../../Include/Function/Translate";
import LoadingSkeletonItemProductForSale from "./ItemProductPOS/LoadingSkeletonItemProductForSale";
import FooterSaleProductPagination from "../../../Include/FooterPagination/FooterSaleProductPagination";

export default function ProductPOS({
  limit,
  page,
  setPage,
  tableData,
  handleSearch,
  handleLimit,
  paginationData,
  loadingProductForSale,
  handleSelectProductItem,
}) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <div className="food-menu-container">
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <Stack justifyContent="center">
            <Typography
              className={
                language === "en" ? "food-menu-header" : "food-menu-header-kh"
              }
            >
              {t("menu")}
            </Typography>
          </Stack>
        </Grid>

        <Box flexGrow={1} />
        <Grid item xs={6} sm={7} md={7} lg={6} xl={3}>
          <Stack justifyContent="center">
            <TextField
              onChange={(e) => {
                handleSearch(e.target?.value);
              }}
              className="search-field"
              size="small"
              placeholder={t("label-search")}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontFamily: "Siemreap",
                },
                "& .MuiFormLabel-root": {
                  fontFamily: "Siemreap",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Box className="box-item-container">
        <Grid container rowSpacing={1} columnSpacing={1}>
          {loadingProductForSale ? (
            <LoadingSkeletonItemProductForSale />
          ) : tableData?.length === 0 ? (
            <Stack
              direction="column"
              width="100%"
              justifyItems="center"
              bgcolor="white"
            >
              <EmptyData />
            </Stack>
          ) : (
            tableData?.map((row, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={3}
                  sm={2}
                  md={4}
                  lg={3}
                  xl={2}
                  sx={{ cursor: "pointer" }}
                >
                  <ItemProductPOS
                    handleSelectProductItem={handleSelectProductItem}
                    row={row}
                  />
                </Grid>
              );
            })
          )}
        </Grid>

        <FooterSaleProductPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </div>
  );
}
