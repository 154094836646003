import moment from "moment";
import "../../../Style/actionStyle.scss";
import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { Box, Checkbox, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { translateLauguage } from "../../../Include/Function/Translate";
import EmptyData from "../../../Include/EmptyData/EmptyData";

export default function ProductForSelect({ tableData, checkedProductId, setCheckedProductId, setTableData }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const handleSelectProduct = (product) => {
    const isHave = checkedProductId?.map(i => i?.id)?.includes(product?.id);
    if (isHave) {

      const newData = checkedProductId.filter(e => e?.id !== product?.id);
      setCheckedProductId(newData);

      const newTableData = tableData.map((e) => {
        if (e?.id === product?.id) e.adjustTo = parseFloat(e?.adjustFrom) - 1;
        return e
      });

      setTableData(newTableData);

    }
    else setCheckedProductId([...checkedProductId, product]);
  }

  const handleAdjustTo = (id, value) => {

    const newData = tableData.map((e) => {
      if (e?.id === id) e.adjustTo = parseFloat(value || 0) >= parseFloat(e?.adjustFrom) ? parseFloat(e?.adjustFrom) - 1 : parseFloat(value || 0);
      return e;
    });

    setTableData(newData);

  }

  return (
    <Stack width="100%" direction="column" spacing={2} mt={2}  >
      <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="30%">
          {t("thead_date")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_buy_in")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_price")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20%">
          {t("thead_remaining")}
        </Typography>

        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="25%">
          {t("thead_adjust_to")}
        </Typography>

        <Typography textAlign="center" className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" whiteSpace="pre" width="5%">
          {/* Adjust */}
        </Typography>
      </Stack>

      <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
        <Divider />
        {
          tableData?.length === 0 ? <EmptyData /> :
            tableData?.map((e, key) => (
              <Stack width="100%" direction="column" spacing={1.7} key={key}>
                <Stack width="100%" direction="row" alignItems="center" spacing={2} sx={{ paddingX: 2, }}>

                  <Stack sx={{ width: "30%" }}>
                    {moment(e?.createdAt).format("DD.MM.YYYY")}
                  </Stack>

                  <Stack sx={{ width: "20%" }}>
                    {e?.qty}  {e?.unit}
                  </Stack>

                  <Stack sx={{ width: "20%" }}>
                    $  {e?.unitPrice}
                  </Stack>

                  <Stack sx={{ width: "20%" }}>
                    {e?.adjustFrom} {e?.unit}
                  </Stack>

                  <Stack sx={{ width: "25%" }}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      value={e?.adjustTo}
                      disabled={!(checkedProductId?.map(i => i?.id)?.includes(e?.id))}
                      className="text-field"
                      onChange={({ target }) => handleAdjustTo(e?.id, target.value < 0 ? 0 : target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {e?.unit}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Stack direction="row" sx={{ width: "5%" }} justifyContent="center">
                    <Box>
                      <Checkbox
                        checked={checkedProductId?.map(i => i?.id)?.includes(e?.id)}
                        onChange={() => handleSelectProduct(e)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      />
                    </Box>
                  </Stack>
                </Stack>
                <Divider />
              </Stack>
            ))
        }
      </Stack>
    </Stack>
  );
}
