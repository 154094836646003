import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
//Dialog
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import "../../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SelectRequestProducts from "./SelectRequestProducts";
import { AuthContext } from "../../../../Context/AuthContext";
import { UPDATE_REQUEST } from "../../../../Schema/InventoryInShop";
import { CHECK_REQUEST } from "../../../../Schema/InventoryInWarehouse";
import { translateLauguage } from "../../../../Include/Function/Translate";

export const ApprovedAndRejectedRequest = ({ open, handleClose, hideBackdrop, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [remark, setRemark] = useState("");
  const [purchaseProductsData, setPurchaseProductsData] = useState([]);

  //======================== Create User =========================
  const [checkRequest] = useMutation(CHECK_REQUEST, {
    onCompleted: ({ checkRequest }) => {
      setLoading(false);
      if (checkRequest?.isSuccess === true) {
        setAlert(true, "success", checkRequest?.message);
        handleClose();
        setRefetch();
        setPurchaseProductsData([]);
      } else {
        setAlert(true, "error", checkRequest?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  const [updateRequest] = useMutation(UPDATE_REQUEST, {
    onCompleted: ({ updateRequest }) => {
      setLoading(false);
      if (updateRequest?.isSuccess === true) {
        setAlert(true, "success", updateRequest?.message);
        handleClose();
        setRefetch();
        setPurchaseProductsData([]);
      } else {
        setAlert(true, "error", updateRequest?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });




  useEffect(() => {

    const purchaseData = editData?.requestMainProducts.map((e) => ({
      qty: e?.qty,
      qtyApprove: e?.qty,
      mainProductId: {
        id: e?.mainProductId?._id,
        title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,
      },
      key: Math.random() + Date.now(),
    }));

    setPurchaseProductsData(purchaseData);

  }, [open]);

  const handleSubmitApproved = () => {

    const checkRequire = purchaseProductsData?.filter(e => !(parseFloat(e?.qtyApprove) >= 0));

    if (checkRequire.length === 0) {

      checkRequest({
        variables: {
          id: editData?._id,
          input: {
            checkBy: {
              remark: remark
            },
            status: "Approved",
            approveMainProducts: purchaseProductsData?.map((e) => ({
              qty: parseFloat(e?.qtyApprove),
              mainProductId: e?.mainProductId?.id
            })).filter((e) => e?.qty >= 0)
          }
        },
      });

    } else setRequired(true);

  }


  const handleSubmitRejected = () => {
    checkRequest({
      variables: {
        id: editData?._id,
        input: {
          checkBy: {
            remark: remark
          },
          status: "Rejected",
          approveMainProducts: []
        }
      },
    });

  }


  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_request_a_purchase")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_shop")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.shopId?.shopNameEn : editData?.shopId?.shopNameKh}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_requester")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.requestBy?.userId?.latinName : editData?.requestBy?.userId?.khmerName}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_date")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {moment(editData?.needDate).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          </Grid>



          <Grid item xs={12}>
            <SelectRequestProducts
              required={required}
              purchaseProductsData={purchaseProductsData}
              setPurchaseProductsData={setPurchaseProductsData}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
              {t("remark")}
            </Typography>
            <TextField
              fullWidth
              rows={3}
              multiline
              size="small"
              className="text-field"
              onChange={({ target }) => setRemark(target?.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-suspend-kh" : "btn-suspend"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-suspend-kh" : "btn-suspend"} fullWidth onClick={handleSubmitRejected}>
                {t("btn-reject")}
              </Button>)
            }
          </Grid>
          <Grid item xs={6} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmitApproved}>
                {t("btn-approve")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
