import { gql } from "@apollo/client";

export const GET_INVOICE_BY_ID_FOR_SCREEN_VIEW = gql`
  query GetInvoiceById($id: [ID]) {
    getInvoiceById(_id: $id) {
      _id
      allDiscount {
        amount
        remark
        type
      }
      paidAmount
      remainAmount
      subTotal
      total
    }
  }
`;
