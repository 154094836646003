import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Menu,
  Stack,
  Button,
  Avatar,
  Toolbar,
  Tooltip,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import "./topnavbar.scss";
import logo from "../Assets/it-logo.png";
import { FaShop } from "react-icons/fa6";
import TvIcon from "@mui/icons-material/Tv";
import CamFlag from "../Assets/Cambodia.png";
import { LogoutDialog } from "./LogoutDialog";
import EngFlag from "../Assets/United_Kingdom.png";
import { AuthContext } from "../Context/AuthContext";
import SettingsIcon from "@mui/icons-material/Settings";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { translateLauguage } from "../Include/Function/Translate";
import ExchangeRate from "../Components/ExchangeRate/ExchangeRate";
import AlertDialogSlide from "./AlertDialogSlide/AlertDialogSlide";

const LongTextComponent = ({ text }) => {
  // ===================== Change Language ==========================
  const { language, changeLanguage } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      className={language === "en" ? "username-text" : "username-text-kh"}
    >
      {text}
    </div>
  );
};
const handleGetLogo = () => {
  return window.localStorage.getItem("logo");
};
export default function TopNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  // ===================== Change Language ==========================
  const { language, changeLanguage } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const logoShop = handleGetLogo();
  // ============================ Resize width ============================
  const fixwith = 1370;
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // ============== log out ============
  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ================== Function open logout ===================
  const [oepnLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    handleClose();
  };
  const handleCloseLogout = () => setOpenLogout(false);

  //======================= List Menu ====================
  const listMenu = [
    // {
    //   title: t(`page-shop`),
    //   navigate: "/shop",
    //   icon: <FaShop className="link-icon" />,
    //   active: ["/shop"].includes(location?.pathname),
    // },
    // {
    //   navigate: "/dashboard",
    //   title: t(`page-dashboard`),
    //   icon: <DashboardIcon className="link-icon" />,
    //   active: ["/dashboard"].includes(location?.pathname),
    // },
    // {
    //   navigate: "/pos",
    //   title: t(`page_pos`),
    //   icon: <FaComputer className="link-icon" />,
    //   active: ["/pos"].includes(location?.pathname),
    // },
    // {
    //   navigate: "/payment",
    //   title: t(`page-payment`),
    //   icon: <PaymentIcon className="link-icon" />,
    //   active: ["/payment"].includes(location?.pathname),
    // },
    // {
    //   navigate: "/transaction",
    //   title: t(`page-transaction`),
    //   icon: <DescriptionIcon className="link-icon-a" />,
    //   active: ["/transaction"].includes(location?.pathname),
    // },
    // {
    //   title: t(`page-inventory`),
    //   active: ["/inventory"].includes(location?.pathname),
    //   navigate: "/inventory",
    //   icon: <WarehouseIcon className="link-icon-a" />,
    // },
    // {
    //   navigate: "/report",
    //   title: t(`page-report`),
    //   active: ["/report"].includes(location?.pathname),
    //   icon: <AssessmentIcon className="link-icon-a" />,
    // },
    // {
    //   title: t(`page-setting`),
    //   active: [
    //     "/setting",
    //     "/setting/user",
    //     "/setting/unit",
    //     "/setting/bank",
    //     "/setting/table",
    //     "/setting/product",
    //     "/setting/voucher",
    //     "/setting/customer",
    //     "/setting/category",
    //     "/setting/supplier",
    //     "/setting/warehouse",
    //     "/setting/cash-label",
    //     "/setting/card-number",
    //     "/setting/ticket-group",
    //   ].includes(location?.pathname),
    //   navigate: "/setting",
    //   icon: <SettingsIcon className="link-icon-a" />,
    // },
  ].filter(Boolean);

  //======================= List Menu ====================
  const listMenuShop = [
    {
      navigate: "/dashboard",
      title: t(`page-dashboard`),
      icon: <DashboardIcon className="link-icon" />,
      active: ["/dashboard"].includes(location?.pathname),
    },
    {
      navigate: "/register",
      title: t(`page-register-card`),
      icon: <GroupRoundedIcon className="link-icon" />,
      active: ["/register"].includes(location?.pathname),
    },
    {
      title: t(`page-shop`),
      navigate: "/shop",
      icon: <FaShop className="link-icon" />,
      active: ["/shop"].includes(location?.pathname),
    },
    {
      title: t(`page-warehouse`),
      active: ["/inventory-in-warehouse"].includes(location?.pathname),
      navigate: "/inventory-in-warehouse",
      icon: <WarehouseIcon className="link-icon-a" />,
    },
    {
      navigate: "/report",
      title: t(`page-report`),
      active: ["/report"].includes(location?.pathname),
      icon: <AssessmentIcon className="link-icon-a" />,
    },
    {
      title: t(`page-setting`),
      active: [
        "/setting",
        "/setting/user",
        "/setting/unit",
        "/setting/bank",
        "/setting/table",
        "/setting/product",
        "/setting/voucher",
        "/setting/customer",
        "/setting/category",
        "/setting/supplier",
        "/setting/warehouse",
        "/setting/cash-label",
        "/setting/card-number",
        "/setting/ticket-group",
      ].includes(location?.pathname),
      navigate: "/setting",
      icon: <SettingsIcon className="link-icon-a" />,
    },
    {
      navigate: "/screen-view",
      // title: t(`page-screen-view`),
      active: ["/screen-view"].includes(location?.pathname),
      icon: <TvIcon className="link-icon-a" />,
    },
    // {
    //   navigate: "/screen-view",
    //   title: t(`menu`),
    //   active: ["/screen-view"].includes(location?.pathname),
    //   icon: <WidgetsIcon className="link-icon-a" />,
    // },
  ].filter(Boolean);

  const shopId = window.localStorage.getItem("shopId");
  const shopStatus = window.localStorage.getItem("shopStatus");

  return (
    <Toolbar
      className="top-navbar-style"
      sx={{ minHeight: "45px !important", height: "45px !important" }}
    >
      <Stack direction="row" spacing={2} sx={{ width: "100%", height: "100%" }}>
        <Stack direction="row" alignItems="center" sx={{ height: "45px" }}>
          <IconButton onClick={() => navigate("/dashboard")}>
            <Stack
              direction={`row`}
              className="logo-container"
              spacing={2}
              justifyContent={`center`}
              alignItems={`center`}
            >
              <img
                src={logoShop ? logoShop : logo}
                alt="logo"
                className="logo-style"
              />
            </Stack>
          </IconButton>

          {/* <Stack width="100px" direction="row">
            <TruncateMarkup lines={1}>
              <Typography fontWeight="bold">Super AdminSuper AdminSuper AdminSuper AdminSuper Admin Super  </Typography>
            </TruncateMarkup>
          </Stack> */}
        </Stack>

        {/* ================================== Menu Item ========================================= */}
        <Stack direction="row" spacing={0} sx={{ height: "45px" }}>
          {shopStatus === "Open" && shopId ? (
            <>
              {listMenu?.map((listItem, index) => (
                <Tooltip title={listItem?.title} arrow key={index}>
                  <Button
                    className={
                      listItem?.active ? "list-item-active" : "list-item"
                    }
                    onClick={() => {
                      navigate(`${listItem?.navigate}`);
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      {listItem?.icon}
                      {width > fixwith ? (
                        <Stack direction="column" justifyContent="center">
                          <Typography
                            className={
                              language === "kh" ? "icon-title-kh" : "icon-title"
                            }
                          >
                            {listItem?.title}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Button>
                </Tooltip>
              ))}
              <AlertDialogSlide />
            </>
          ) : (
            listMenuShop?.map((listItem, index) => (
              <Tooltip title={listItem?.title} arrow key={index}>
                <Button
                  className={
                    listItem?.active ? "list-item-active" : "list-item"
                  }
                  onClick={() => {
                    navigate(`${listItem?.navigate}`);
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    {listItem?.icon}
                    {width > fixwith ? (
                      <Stack direction="column" justifyContent="center">
                        <Typography
                          className={
                            language === "kh" ? "icon-title-kh" : "icon-title"
                          }
                        >
                          {listItem?.title}
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Button>
              </Tooltip>
            ))
          )}
        </Stack>
        {/* ======== End  Menu Item ============ */}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          spacing={1}
          alignItems={`center`}
          sx={{ height: "45px" }}
        >
          <Box sx={{ flexGrow: 1 }} />

          {/* ========= Exchange ======== */}
          <ExchangeRate />

          {/* ========= Language ======== */}
          <Stack direction="column" justifyContent="center">
            {language === "en" ? (
              <Tooltip title="Change to Khmer" placement="left">
                <IconButton onClick={() => changeLanguage("kh")}>
                  <Avatar
                    src={EngFlag}
                    sx={{ width: "20px", height: "20px" }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Change to English" placement="left">
                <IconButton onClick={() => changeLanguage("en")}>
                  <Avatar
                    src={CamFlag}
                    sx={{ width: "20px", height: "20px" }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>

          {/* ========= Profile ======== */}
          <Stack direction="column" justifyContent="center">
            <Button className="btn-menu" onClick={handleClick}>
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <Avatar
                    src={userLogin?.userProfile}
                    alt="User"
                    className="avatar-user"
                  />
                </Stack>

                {/* {width > fixwith ? ( */}
                <Stack direction="column" justifyContent="center">
                  <Stack direction="row" alignItems="center" width={"100%"}>
                    <LongTextComponent
                      text={
                        language === "en"
                          ? userLogin
                            ? userLogin?.latinName
                            : "NO User"
                          : userLogin
                          ? userLogin?.khmerName
                          : "NO User"
                      }
                    />
                  </Stack>
                </Stack>
                {/* ): <Box sx={{ flexGrow: 1 }} /> } */}

                <Stack direction="column" justifyContent="center">
                  {openMenu ? (
                    <ArrowDropUpIcon className="icon-menu" />
                  ) : (
                    <ArrowDropDownIcon className="icon-menu" />
                  )}
                </Stack>
              </Stack>
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              // PaperProps={{
              //   elevation: 0,
              //   sx: {
              //     overflow: "visible",
              //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              //     mt: 1.5,
              //     "& .MuiAvatar-root": {
              //       width: 32,
              //       height: 32,
              //       ml: -0.5,
              //       mr: 1,
              //     },
              //     "&:before": {
              //       content: '""',
              //       display: "block",
              //       position: "absolute",
              //       top: 0,
              //       right: 14,
              //       width: 10,
              //       height: 10,
              //       bgcolor: "background.paper",
              //       transform: "translateY(-50%) rotate(45deg)",
              //       zIndex: 0,
              //     },
              //   },
              // }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleOpenLogout}>
                <Typography className="log-out-txt">{t("log_out")}</Typography>
              </MenuItem>
            </Menu>

            <LogoutDialog open={oepnLogout} handleClose={handleCloseLogout} />
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
}
