import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//Icons
import { AiOutlineDashboard } from "react-icons/ai";
//Src
import "./menunavbar.scss";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose }) {
  let location = useLocation();

  const navigate = useNavigate();

  return (
    <Drawer variant="permanent" open={open} className="drawer-menu">
      <DrawerHeader sx={{ mt: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <IconButton
            onClick={() => navigate("/dashboard")}
            className="image-container"
          >
            {/* <img src={logo} alt="logo" height="100%" width="100%" /> */}
          </IconButton>
        </Stack>
      </DrawerHeader>

      <List className="list-menu">
        {/* =========== Menu Item ========== */}
        <ListItem
          className={
            location.pathname === "/dashboard"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => {
            navigate("/");
            handleDrawerClose();
          }}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <AiOutlineDashboard className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Dashboard</Typography>
          </ListItemButton>
        </ListItem>
        {/* =========== End Menu Item ========== */}

        {/* =========== Menu Item ========== */}
        <ListItem
          className={
            location.pathname === "/report" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => {
            navigate("/report");
            handleDrawerClose();
          }}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <AiOutlineDashboard className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Report</Typography>
          </ListItemButton>
        </ListItem>
        {/* =========== End Menu Item ========== */}
      </List>
    </Drawer>
  );
}
