import React, { useState, useContext } from "react";
import "../../../Style/actionStyle.scss";
import { ViewAdjustOut } from "./ViewAdjustOut";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
export default function AdjustOutAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true); 
  const handleCloseView = () => setOpenView(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">

        <Tooltip title={t("thead_view")} placement="top">
          <IconButton onClick={handleOpenView} disabled={disabled}>
            <Box className="view-container" sx={disabled && { bgcolor: "grey !important" }}>
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>
      <ViewAdjustOut
        open={openView}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseView}
      />
    </div>
  );
}
