import React, { useEffect, useState } from "react";
import "../HeaderCard/headercard.scss";
import { useQuery } from "@apollo/client";
import { BsArrowUp } from "react-icons/bs";
import { ImCoinDollar } from "react-icons/im";
import { IoIosArrowDropright } from "react-icons/io";
import { AuthContext } from "../../../Context/AuthContext";
import { GET_DASH_TRANSACTION } from "../../../Schema/Dashboard";
import { translateLauguage } from "../../../Include/Function/Translate";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";

const TrasactionPaymentToday = () => {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [editData, setEditData] = useState();

  const shopId = window.localStorage.getItem("shopId");

  const { refetch } = useQuery(GET_DASH_TRANSACTION, {
    pollInterval: 1000,
    variables: {
      shopIds: shopId,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
    },
    onCompleted: ({ getDashTransactionPayment }) => {
      if (getDashTransactionPayment) setEditData(getDashTransactionPayment);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="trans-container">
      <Stack direction={`column`} spacing={1}>
        <Typography className={language === "en" ? "title" : "title-kh"}>
          {t("transaction_payment_today")}
        </Typography>

        <Box className="box-container">
          <Stack
            direction={`row`}
            alignItems={`center`}
            justifyContent={`space-between`}
          >
            <Stack direction={`row`} spacing={1} alignItems="center">
              <ImCoinDollar className="icon" />
              <Typography className={language === "en" ? "title" : "title-kh"}>
                {t("total_income")}
              </Typography>
            </Stack>
            <IconButton>
              <IoIosArrowDropright className="arrow-icon" />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction={`column`} spacing={1} mt={2}>
            <Typography
              className={
                editData?.differenceIncomePercent >= 0
                  ? "sub-title"
                  : "sub-title-red"
              }
            >
              $ {(editData?.currentIncome || 0).toFixed(2)}
            </Typography>
            <Stack direction={`row`} alignItems={`center`} spacing={2}>
              <Stack
                direction={`row`}
                spacing={1}
                className={
                  editData?.differenceIncomePercent >= 0
                    ? "desc-container"
                    : "desc-container-exp"
                }
                alignItems={`center`}
              >
                <BsArrowUp className="desc-arrow" />
                <Typography className="desc-txt">
                  {" "}
                  {editData?.differenceIncomePercent < 0 ? "" : "+"}{" "}
                  {(editData?.differenceIncomePercent || 0).toFixed(2)} %
                </Typography>
              </Stack>
              <Typography className="notice-txt">
                {" "}
                {t("thead_yesterday")}{" "}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Box className="box-container">
          <Stack
            direction={`row`}
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Stack direction={`row`} spacing={1} alignItems="center">
              <ImCoinDollar className="icon" />
              <Typography className={language === "en" ? "title" : "title-kh"}>
                {t("total_expense")}
              </Typography>
            </Stack>
            <IconButton>
              <IoIosArrowDropright className="arrow-icon" />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction={`column`} spacing={1} mt={2}>
            <Typography
              className={
                editData?.differenceExpensePercent >= 0
                  ? "sub-title"
                  : "sub-title-red"
              }
            >
              $ {(editData?.currentIncome || 0).toFixed(2)}
            </Typography>
            <Stack direction={`row`} alignItems={`center`} spacing={2}>
              <Stack
                direction={`row`}
                spacing={1}
                className={
                  editData?.differenceExpensePercent >= 0
                    ? "desc-container"
                    : "desc-container-exp"
                }
                alignItems={`center`}
              >
                <BsArrowUp className="desc-arrow" />
                <Typography className="desc-txt">
                  {editData?.differenceExpensePercent < 0 ? "" : "+"}{" "}
                  {(editData?.differenceExpensePercent || 0).toFixed(2)}%
                </Typography>
              </Stack>
              <Typography className="notice-txt">
                {" "}
                {t("thead_yesterday")}{" "}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default TrasactionPaymentToday;
