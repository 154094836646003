import { gql } from "@apollo/client";

export const CREATE_TABLE = gql`
  mutation CreateTable($input: TableInput) {
    createTable(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID, $input: TableInput) {
    updateTable(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;
export const DELETE_TABLE = gql`
  mutation DeleteTable($id: ID) {
    deleteTable(_id: $id) {
      message {
        messageEn
        messageKh
      }
      isSuccess
    }
  }
`;
export const GET_TABLE_WITH_PAGINATION = gql`
  query GetTablePagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
    $status: TableStatusEnum
  ) {
    getTablePagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
      status: $status
    ) {
      data {
        _id
        tableName
        capacity
        location
        status
        isUse
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
