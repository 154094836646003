import React, { useState, useContext } from "react";
import "../../../Style/actionStyle.scss";
import { VoidPurchase } from "./VoidPurchase"; 
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { IconButton, Stack, Box, Tooltip } from "@mui/material"; 
import { TransferProduct } from "./TransferProduct";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ViewTransfer } from "./ViewTransfer";
export default function TransferAction({ editData, setRefetch, disabled }) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language); 

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal update 
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title={t("thead_view")} placement="top">
          <IconButton onClick={handleOpenView} disabled={disabled}>
            <Box className="view-container" sx={disabled && { bgcolor: "grey !important" }}>
              <VisibilityOutlinedIcon className="view-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={t("thead_receive")} placement="top">
          <IconButton onClick={handleOpenEdit} disabled={disabled}>
            <Box className="update-container" sx={disabled && { bgcolor: "grey !important" }}>
              <AutoModeIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip-void")} placement="top">
          <IconButton onClick={handleOpenDel} disabled={editData?.isVoid}>
            <Box className="delete-container" sx={editData?.isVoid && { bgcolor: "grey !important" }}>
              <RemoveCircleOutlineSharpIcon className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip> */}
      </Stack>

      <ViewTransfer
        open={openView}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseView}
      />
      <TransferProduct
        open={openEdit}
        editData={editData}
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />
      <VoidPurchase
        open={openDel}
        setRefetch={setRefetch}
        editData={editData}
        handleClose={handleCloseDel}
      />
    </div>
  );
}
