import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import "../../../../src/Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import deletepic from "../../../Assets/delete.png";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { VOID_PURCHASE } from "../../../Schema/InventoryInWarehouse";

export const VoidPurchase = ({ open, handleClose, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);

  const [voidPurchase] = useMutation(VOID_PURCHASE, {
    onCompleted: ({ voidPurchase }) => {
      setLoading(false);
      if (voidPurchase?.isSuccess === true) {
        setAlert(true, "success", voidPurchase?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", voidPurchase?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    setLoading(true);
    voidPurchase({
      variables: {
        remark: remark,
        id: editData?._id,
      },
    });
  };

  useEffect(() => {
    setRemark("");
    setRequired(false);
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("thead_void")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1} className="delete-dialog-container">
          <Grid item xs={5}>
            <img src={deletepic} alt="deletepic" className="img-delete" />
          </Grid>
          <Grid item xs={7}>
            <Stack direction={`column`} spacing={2}>
              <Stack>
                <Typography className={language === "en" ? "title-text" : "title-text-kh"}>
                  {t("void_qa")}
                </Typography>
              </Stack>
              <Stack>
                <TextField
                  rows={3}
                  fullWidth
                  multiline
                  type="text"
                  size="small"
                  placeholder={t("remark")}
                  error={required && !remark}
                  onChange={(e) => setRemark(e.target.value)}
                  helperText={required && !remark && "require"}
                />
              </Stack>

              <Stack direction="row" justifyContent="center" fullWidth>
                {remark ? (
                  <Button
                    fullWidth
                    size="small"
                    onClick={handleDelete}
                    className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                    sx={{ ":hover": { backgroundColor: "#f26469", border: "none" }, }}
                  >
                    {loading ? t("loading") : t("btn-delete-now")}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    size="small"
                    onClick={() => setRequired(true)}
                    className={language === "kh" ? "btn-deleteKh" : "btn-deleteEn"}
                  >
                    {t("btn-void")}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
