import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { FaShop } from "react-icons/fa6";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { AuthContext } from "../../Context/AuthContext";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useLocation, useNavigate } from "react-router-dom";
import { translateLauguage } from "../../Include/Function/Translate";
import { Box, Stack, Button, Tooltip, Typography, Grid } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { FaComputer } from "react-icons/fa6";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />; // Changed to "down"
});
const handleRemoveLogo = () => {
  return window.localStorage.removeItem("logo");
};
export default function AlertDialogSlide() {
  // ===================== Change Language ==========================
  const { language, changeLanguage } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //======================= List Menu ====================
  const listMenuShop = [
    // {
    //     title: t(`page-shop`),
    //     navigate: "/shop",
    //     icon: <FaShop className="link-icon" style={{ fontSize: 32 }} />,
    //     active: ["/shop"].includes(location?.pathname),
    // },
    {
      navigate: "/dashboard",
      title: t(`page-dashboard`),
      icon: <DashboardIcon className="link-icon" sx={{ fontSize: 32 }} />,
      active: ["/dashboard"].includes(location?.pathname),
    },
    {
      navigate: "/pos",
      title: t(`page_pos`),
      icon: <FaComputer className="link-icon" style={{ fontSize: 32 }} />,
      active: ["/pos"].includes(location?.pathname),
    },
    {
      navigate: "/payment",
      title: t(`page-payment`),
      icon: <PaymentIcon className="link-icon" sx={{ fontSize: 32 }} />,
      active: ["/payment"].includes(location?.pathname),
    },
    {
      navigate: "/transaction",
      title: t(`page-transaction`),
      icon: <DescriptionIcon className="link-icon-a" sx={{ fontSize: 32 }} />,
      active: ["/transaction"].includes(location?.pathname),
    },
    {
      title: t(`page-inventory`),
      active: ["/inventory"].includes(location?.pathname),
      navigate: "/inventory",
      icon: <WarehouseIcon className="link-icon-a" sx={{ fontSize: 32 }} />,
    },
    {
      navigate: "/report",
      title: t(`page-report`),
      active: ["/report"].includes(location?.pathname),
      icon: <AssessmentIcon className="link-icon-a" sx={{ fontSize: 32 }} />,
    },
    {
      title: t(`page-setting`),
      active: [
        "/setting",
        "/setting/user",
        "/setting/unit",
        "/setting/bank",
        "/setting/table",
        "/setting/product",
        "/setting/voucher",
        "/setting/customer",
        "/setting/category",
        "/setting/supplier",
        "/setting/warehouse",
        "/setting/cash-label",
        "/setting/card-number",
        "/setting/ticket-group",
      ].includes(location?.pathname),
      navigate: "/setting",
      icon: <SettingsIcon className="link-icon-a" sx={{ fontSize: 32 }} />,
    },
  ].filter(Boolean);

  return (
    <React.Fragment>
      <Tooltip title={t("menu")} arrow>
        <Button onClick={handleClickOpen}>
          <Stack direction="row" spacing={1}>
            <WidgetsIcon className="link-icon-a" />
            <Stack direction="column" justifyContent="center">
              <Typography
                className={language === "kh" ? "icon-title-kh" : "icon-title"}
              >
                {t("menu")}
              </Typography>
            </Stack>
          </Stack>
        </Button>
      </Tooltip>
      <Dialog
        fullScreen
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          opacity: 0.955,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#1d4592",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              width: "min(100% , 80%)",
              height: "min(100% , 80%)",
              //  bgcolor: "gray",
              margin: "0 auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  paddingY={3}
                  sx={{ color: "white" }}
                >
                  <WidgetsIcon sx={{ fontSize: 32 }} />
                  <Typography fontSize={26}>{t("menu")}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Button
                  fullWidth
                  onClick={() => {
                    handleRemoveLogo();
                    handleClose();
                    navigate("/shop");
                  }}
                  sx={{
                    bgcolor: "white !important",
                    height: "100%",
                    color: "#1d4592",
                    ":hover": {
                      bgcolor: "#090D4A !important",
                      color: "white",
                      opacity: 1,
                    },
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    paddingY={3}
                  >
                    <ArrowBackIcon />
                    <Typography>{t("thead_back_shop")}</Typography>
                  </Stack>
                </Button>
              </Grid>

              {listMenuShop?.map((e, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                  <Button
                    fullWidth
                    onClick={() => {
                      navigate(`${e?.navigate}`);
                      handleClose();
                    }}
                    sx={{
                      bgcolor: "white !important",
                      height: "100%",
                      color: "#1d4592",
                      ":hover": {
                        bgcolor: "#090D4A !important",
                        color: "white",
                        opacity: 1,
                      },
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      paddingY={3}
                    >
                      {e?.icon}
                      <Typography>{e?.title}</Typography>
                    </Stack>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
