import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Grid,
    Stack,
    Table,
    Button,
    Select,
    TableRow,
    MenuItem,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    FormControl,
    TableContainer,
    TextField,
    InputAdornment,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import CreateIcon from "../../../Assets/add.png";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../Context/AuthContext";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectWarehouse } from "../../../Include/Function/DynamicSelect";
import SelectDatePicker from "../../../Include/SelectDatePicker/SelectDatePicker";
import { GET_ADJUST_IN_PAGINATION } from "../../../Schema/InventoryInWarehouse";
import { AdjustProductIn } from "./AdjustProductIn";
import AdjustInAction from "./AdjustInAction";

export const AdjustIn = () => {
    // ===================== Change Language ==========================
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);
    // ======================= Resize width Screen ======================
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth - 50);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [reason, setReason] = useState("All");
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState();
    const [endDate, setEndDate] = useState(moment());
    const [startDate, setStartDate] = useState(moment());
    const [paginationData, setPaginationData] = useState();
    const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({ id: "", title: "" });
    const { refetch } = useQuery(GET_ADJUST_IN_PAGINATION, {
        variables: {
            page: page,
            limit: limit,
            reason: reason,
            pagination: true,
            adjustInNo: parseFloat(keyword),
            warehouseId: selectedWarehouseVal?.id || null,
            endDate: moment(endDate).format("YYYY-MM-DD"),
            startDate: moment(startDate).format("YYYY-MM-DD"),
        },
        onCompleted: ({ getAdjustInPagination }) => {
            setLoading(false);
            setTableData(getAdjustInPagination?.data);
            setPaginationData(getAdjustInPagination?.paginator);
        },
        onError: (err) => {
            console.log("Error::", err?.message);
        },
    });

    const handleLimit = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const translateReason = (reason) => {
        if (reason === "Free") return t("thead_free");
        if (reason === "ReCount") return t("thead_recount");
        if (reason === "FromUse") return t("thead_for_use");
        if (reason === "FromPos") return t("thead_for_pos");
    }
    return (
        <>
            <Grid container spacing={3}>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t(`label-search`)}
                    </Typography>
                    <TextField
                        size="small"
                        type="number"
                        className="search-field"
                        placeholder={t(`label-search`)}
                        onChange={(e) => setKeyword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 1, color: "black" }}  >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}>
                        {t("thead_warehouse")}
                    </Typography>

                    <SelectWarehouse
                        className={"search-field"}
                        selectedVal={selectedWarehouseVal}
                        setSelectedVal={setSelectedWarehouseVal}
                    />

                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}>
                        {t("thead_reason")}
                    </Typography>
                    <FormControl fullWidth size="small" className="search-field">
                        <Select value={reason} onChange={(event) => setReason(event.target.value)}>
                            <MenuItem value={"All"}> {t("thead_all")}</MenuItem>
                            <MenuItem value={"Free"}> {t("thead_free")} </MenuItem>
                            <MenuItem value={"ReCount"}> {t("thead_recount")} </MenuItem>
                            <MenuItem value={"FromUse"}> {t("thead_for_use")} </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t("thead_start_date")}
                    </Typography>
                    <SelectDatePicker
                        date={startDate}
                        className={"search-field"}
                        setDate={(e) => setStartDate(e)}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}  >
                        {t("thead_end_date")}
                    </Typography>
                    <SelectDatePicker
                        date={endDate}
                        className={"search-field"}
                        setDate={(e) => setEndDate(e)}
                    />
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={9} xl={2}>
                    <Stack direction="row" justifyContent="right">
                        <Button onClick={handleOpen} className="btn-create-style" startIcon={<img src={CreateIcon} alt="CreateIcon" className="icon-add" />} >
                            <Typography className={language === "kh" ? "btn-txt-kh" : "btn-txt"}>
                                {t("thead_adjust_in")}
                            </Typography>
                        </Button>
                        <AdjustProductIn open={open} handleClose={handleClose} setRefetch={refetch} />
                    </Stack>
                </Grid>

            </Grid >

            <Box className="body-container">
                <TableContainer sx={{ maxWidth: `${width}px` }}>
                    <Table className="table" aria-label="simple table">
                        <TableHead className="header-row ">
                            <TableRow>
                                <TableCell className={language === "en" ? "header-title-start" : "header-title-start-kh"} width="10%"> {t("thead_no")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_date")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_warehouse")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_requester")}</TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_reason")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("remark")} </TableCell>
                                <TableCell className={language === "en" ? "header-title-end" : "header-title-end-kh"} width="10%"  ></TableCell>
                            </TableRow>
                        </TableHead>

                        {loading ? (
                            <TableBody className="table-body">
                                <TableRow className="body-row">
                                    <TableCell colSpan={7} className="body-cell">
                                        <LoadingPage />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <>
                                {tableData?.length === 0 ? (
                                    <TableBody className="table-body">
                                        <TableRow className="body-row">
                                            <TableCell colSpan={7} className="body-cell">
                                                <EmptyData />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <>
                                        <TableBody className="table-body">
                                            {tableData?.map((row, index) => {
                                                return (
                                                    <TableRow className="body-row" key={index} >
                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-left" : ""} body-cell-start` : `${row?.isVoid ? "void-left" : ""} body-cell-start-kh`}>
                                                            {row?.adjustInNo}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {/* {row?.adjustInDate && moment(row?.adjustInDate).format("MM-DD-YYYY hh:mm a")} */}
                                                            {moment(row?.createdAt).format("MM-DD-YYYY hh:mm a")}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.warehouseId?.warehouseEnName : row?.warehouseId?.warehouseKhName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.createdBy?.latinName : row?.createdBy?.khmerName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {translateReason(row?.reason)}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {row?.remark}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-right" : ""} body-cell-end` : `${row?.isVoid ? "void-right" : ""} body-cell-end-kh`} align="right">
                                                            <AdjustInAction editData={row} setRefetch={refetch} disabled={row?.status === "Received"} />
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </>
                                )}
                            </>
                        )}
                    </Table> 
                </TableContainer>
                {/* ==================== Pagination  ============================= */}
                <FooterPagination
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    handleLimit={handleLimit}
                    totalDocs={paginationData?.totalDocs}
                    totalPages={paginationData?.totalPages}
                />
            </Box>
        </>
    );
};
