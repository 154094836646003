import React, { useContext, useEffect, useState } from "react";
import {
  padNumber,
  currencyFormat,
  currencyFormatKh,
} from "../../../../Include/Function/DynamicFn";
import moment from "moment/moment";
import TableInvoice from "./TableInvoice";
import { useLazyQuery } from "@apollo/client";
import logo from "../../../../Assets/it-logo-invoice.png";
import { AuthContext } from "../../../../Context/AuthContext";
import { GET_INVOICE_BY_ID } from "../../../../Schema/Invoice";
import { Box, Grid, Stack, Divider, Typography } from "@mui/material";
import LoadingPage from "../../../../Include/LoadingPage/LoadingPage";
import useGetExchangeRate from "../../../../Hooks/POS/useGetExchangeRate";
import { translateLauguage } from "../../../../Include/Function/Translate";
import useGetPaymentByInvoices from "../../../../Hooks/POS/useGetPaymentByInvoices";
const handleGetLogo = () => {
  return window.localStorage.getItem("logo");
};
export default function Invoice({
  componentRef,
  muiltipleInvoiceId,
  paymentByInvoiceData,
}) {
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const logoShop = handleGetLogo();

  const [loading, setLoading] = useState(true);
  const { exchangeRate } = useGetExchangeRate();
  const [invoiceById, setInvoiceById] = useState([]);

  //===================== Get Invoice ID ====================

  const [getInvoiceById, { refetch }] = useLazyQuery(GET_INVOICE_BY_ID, {
    onCompleted: ({ getInvoiceById }) => {
      setLoading(false);
      if (getInvoiceById) setInvoiceById(getInvoiceById);
    },
  });

  useEffect(() => {
    getInvoiceById({
      variables: {
        id: muiltipleInvoiceId,
      },
    });
  }, [muiltipleInvoiceId]);

  const {
    changeCashKHR,
    changeCashUSD,
    refetchPayment,
    cashReceivedKHR,
    cashReceivedUSD,
  } = useGetPaymentByInvoices([...muiltipleInvoiceId]);

  useEffect(() => {
    refetch();
    refetchPayment();
  }, [paymentByInvoiceData]);

  return loading ? (
    <LoadingPage />
  ) : (
    invoiceById?.map((e, index) => {
      let totalDiscount = e?.products
        ?.map((e) =>
          e?.discount?.type === "Percentage"
            ? (e?.qty * e?.price * (e?.discount?.amount || 0)) / 100
            : e?.discount?.amount || 0
        )
        ?.reduce((a, b) => a + b || 0, 0);

      return (
        <Box className="print-invoice-view" key={index}>
          <Stack spacing={8} direction="column" alignItems="center">
            <Box ref={componentRef}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack
                    spacing={1}
                    direction={`column`}
                    alignItems={`center`}
                    justifyContent={`center`}
                  >
                    <Stack direction={`row`} justifyContent={`center`}>
                      <img
                        alt="logo"
                        className="img-views"
                        style={{ height: "80px" }}
                        src={logoShop ? logoShop : logo}
                      />
                    </Stack>
                    <Stack
                      direction={`column`}
                      justifyContent={`center`}
                      alignItems={`center`}
                      spacing={0.5}
                      sx={{ textAlign: "center" }}
                    >
                      <Typography className="text-invoice">
                        {language == "en"
                          ? e?.shopId?.shopNameEn
                          : e?.shopId?.shopNameKh}
                      </Typography>
                      <Typography className="text-invoice">
                        {e?.shopId?.phone}
                      </Typography>
                      <Typography
                        className="text-invoice"
                        sx={{ textAlign: "center" }}
                      >
                        {e?.shopId?.address}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={2.5}></Grid>
                {e?.shopId?.isShowQueueNo && (
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Stack direction="column" alignItems="center">
                        <Typography className="text-invoice">
                          លេខរងចាំទី: Queue No
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "2rem !important",
                            fontWeight: "bold",
                          }}
                        >
                          {e?.queueNo}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction={`row`}>
                      <Typography className="text-invoice" width={`30%`}>
                        លេខវិកាយបត្រ
                      </Typography>
                      <Typography className="text-invoice" width={`5%`}>
                        :
                      </Typography>
                      <Typography className="text-invoice" width={`65%`}>
                        {padNumber(
                          e?.invoiceNo,
                          5,
                          moment(e?.createdAt).format("YYYY")
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction={`row`}>
                      <Typography className="text-invoice" width={`30%`}>
                        កាលបរិច្ឆេទ
                      </Typography>
                      <Typography className="text-invoice" width={`5%`}>
                        :
                      </Typography>
                      <Typography className="text-invoice" width={`65%`}>
                        {moment(e?.createdAt).format("YYYY-MM-DD hh:mm a")}
                      </Typography>
                    </Stack>
                    <Stack direction={`row`}>
                      <Typography className="text-invoice" width={`30%`}>
                        អ្នកគិតលុយ
                      </Typography>
                      <Typography className="text-invoice" width={`5%`}>
                        :
                      </Typography>
                      <Typography className="text-invoice" width={`65%`}>
                        {e?.createdBy?.khmerName}
                      </Typography>
                    </Stack>
                    <Stack direction={`row`}>
                      <Typography className="text-invoice" width={`30%`}>
                        អតិថិជន
                      </Typography>
                      <Typography className="text-invoice" width={`5%`}>
                        :
                      </Typography>
                      <Typography className="text-invoice" width={`65%`}>
                        {e?.customerId?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <TableInvoice tableData={e?.products} />
                </Grid>
              </Grid>

              <Grid item container spacing={0}>
                <Grid item xs={7}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          សរុប​/Sub Total
                        </Typography>
                        <Typography className="body-cell-text">
                          បញ្ចុះតម្លៃ/Discount
                        </Typography>
                        <Typography className="body-cell-text">
                          សរុបចុងក្រោយ/Grand Total (USD)
                        </Typography>
                        <Typography className="body-cell-text">
                          សរុបចុងក្រោយ/Grand Total (KHR)
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={5}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          {currencyFormat(e?.subTotal || 0)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormat(totalDiscount)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormat(e?.total)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormatKh(e?.total * exchangeRate)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ borderTop: "1px solid #0f81c2" }} />
                </Grid>

                <Grid item xs={7}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          Exchange Rate
                        </Typography>
                        <Typography className="body-cell-text">
                          លុយទទួល/Cash Received (USD)
                        </Typography>
                        <Typography className="body-cell-text">
                          លុយទទួល/Cash Received (KHR)
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={5}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          {currencyFormatKh(exchangeRate)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormat(cashReceivedUSD)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormatKh(cashReceivedKHR)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ borderTop: "1px solid #0f81c2" }} />
                </Grid>

                <Grid item xs={7}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          Banlance
                        </Typography>
                        <Typography className="body-cell-text">
                          លុយអាប់/Change cash (USD)
                        </Typography>
                        <Typography className="body-cell-text">
                          លុយអាប់/Change cash (KHR)
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={5}>
                  <Stack direction="column" spacing={0.5}>
                    <Stack
                      direction={`row`}
                      justifyContent={`right`}
                      alignItems={`right`}
                    >
                      <Stack direction="column" textAlign={`right`}>
                        <Typography className="body-cell-text">
                          {currencyFormat(e?.remainAmount)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormat(changeCashUSD)}
                        </Typography>
                        <Typography className="body-cell-text">
                          {currencyFormatKh(changeCashKHR)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Stack direction="column" alignItems="center">
              {e?.shopId?.isShowWifi && (
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: "13px",
                      }}
                    >
                      {t("thead_wifi_name")} :
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      {e?.shopId?.wifiName || "--:--"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: "13px",
                      }}
                    >
                      {t("thead_password")} :
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      {e?.shopId?.wifiPassword || "--:--"}
                    </Typography>
                  </Stack>
                </Stack>
              )}

              <Typography className="thx-text">
                សូមអរគុណ ឱកាសក្រោយអញ្ជើញមកម្តងទៀត
              </Typography>
              <Typography className="thx-text">
                THANK YOU FOR COMING AGAIN
              </Typography>
            </Stack>
          </Stack>
        </Box>
      );
    })
  );
}
