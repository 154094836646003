import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import "../../Style/dialogStyle.scss";
import { useQuery } from "@apollo/client";
import { BiMinusCircle } from "react-icons/bi";
import { AuthContext } from "../../Context/AuthContext";
import LoadingPage from "../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../Include/Function/Translate";
import { GET_PRODUCT_BY_MAIN_PRODUCT_ID } from "../../Schema/Product";

export const AdjustProduct = ({
  open,
  handleClose,
  itemProductToPay,
  adjustProductData,
  setItemProductToPay,
  setAdjustProductData,
}) => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [valueSelect, setValueSelect] = useState();

  //=========== Get Product =============
  const { refetch } = useQuery(GET_PRODUCT_BY_MAIN_PRODUCT_ID, {
    variables: {
      mainProductId: adjustProductData?.mainProductId || "",
    },
    onCompleted: ({ getProductByMainProductId }) => {
      setLoading(false);
      if (getProductByMainProductId) {
        const productByMainProductId = getProductByMainProductId?.map((e) => ({
          productId: e?._id,
          price: e?.salePrice,
          imageSrc: e?.imageSrc,
          key: Date.now() + Math.random(),
          qtyInMinimumUnit: e?.qtyInMinimumUnit,
          unit:
            language === "en" ? e?.unitId?.unitEnName : e?.unitId?.unitKhName,
        }));
        setTableData(productByMainProductId);
      } else {
        setTableData([]);
      }
    },
    onError: ({ message }) => {
      console.log("onError AdjustProductt::", message);
    },
  });

  useEffect(() => {
    refetch();
  }, [adjustProductData?.mainProductId, open]);

  const handleConfirm = () => {
    const lengthFilter = itemProductToPay?.filter(
      (e) => e?.productId === valueSelect?.productId
    ).length;

    if (adjustProductData?.productId === valueSelect?.productId) {
      handleClose();
      return;
    }

    if (lengthFilter > 0) {
      const newItemAdd = itemProductToPay
        ?.filter((e) => e?.productId === adjustProductData?.productId)
        ?.reduce((a, b) => a + b?.qty, 0);

      const newItem = itemProductToPay
        ?.filter((e) => e?.productId !== adjustProductData?.productId)
        ?.map((e) => {
          if (e?.mainProductId === adjustProductData?.mainProductId)
            e.qty = e?.qty + newItemAdd;
          return e;
        });

      setItemProductToPay(newItem);

      handleClose();
    } else {
      const newItem = itemProductToPay?.map((e) => {
        if (e?.productId === adjustProductData?.productId) {
          e.price = valueSelect?.price;
          e.imageSrc = valueSelect?.imageSrc;
          e.productId = valueSelect?.productId;
        }
        return e;
      });

      setItemProductToPay(newItem);

      handleClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setValueSelect();
      setTableData([]);
      setLoading(true);
      setAdjustProductData("");
    }
    if (open) setValueSelect(adjustProductData);
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("thead_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton
              onClick={() => {
                handleClose();
                setLoading(true);
              }}
            >
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container columnSpacing={4} rowSpacing={2.5} minHeight={150}>
          {loading ? (
            <LoadingPage height={150} />
          ) : (
            tableData?.map((e, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={4}>
                <Button
                  onClick={() => {
                    setValueSelect(e);
                  }}
                  variant="contained"
                  fullWidth
                  sx={{
                    color:
                      valueSelect?.productId === e?.productId
                        ? "white"
                        : "#545454",
                    bgcolor:
                      valueSelect?.productId === e?.productId
                        ? "#604BE8"
                        : "#F4F4F4",
                    fontWeight: "bold",
                    ":hover": {
                      bgcolor:
                        valueSelect?.productId === e?.productId
                          ? "#604BE8"
                          : "#F4F4F4",
                      color:
                        valueSelect?.productId === e?.productId
                          ? "white"
                          : "#545454",
                    },
                  }}
                >
                  {e?.qtyInMinimumUnit + " " + e?.unit} ({e?.price} $)
                </Button>
              </Grid>
            ))
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6} className="create-container">
            <Button
              className={language === "kh" ? "btn-suspend-kh" : "btn-suspend"}
              fullWidth
              onClick={handleClose}
            >
              {t("btn-cancel")}
            </Button>
          </Grid>
          <Grid item xs={6} className="create-container">
            <Button
              className={language === "kh" ? "btn-create-kh" : "btn-create"}
              fullWidth
              onClick={() => handleConfirm()}
            >
              {t("btn-confirm")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
