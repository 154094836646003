import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { IconButton, Stack, Grid } from "@mui/material";
import { translateLauguage } from "../../Include/Function/Translate";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  SelectCardForMemeber,
  SelectCustomerWithAddNew,
} from "../../Include/Function/DynamicSelect";

export default function MemberList({
  memberDataList,
  setMemberDataList,
  required,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();
  const handleRemoveItem = (key) => {
    if (memberDataList.length === 1) return;
    const data = memberDataList.filter((e) => e?.key !== key);
    setMemberDataList(data);
  };

  const handleUpdateItem = (key, value, fieldName) => {
    const data = memberDataList?.map((e) => {
      if (e?.key === key && fieldName === "cardId") e.cardId = value;
      if (e?.key === key && fieldName === "customerId") e.customerId = value;
      return e;
    });
    setMemberDataList(data);
  };
  return memberDataList?.map((e, index) => (
    <Fragment key={index}>
      <Grid item xs={3.5}>
        <SelectCardForMemeber
          required={required}
          selectedVal={e?.cardId}
          memberDataList={memberDataList}
          setSelectedVal={(val) => handleUpdateItem(e?.key, val, "cardId")}
        />
      </Grid>
      <Grid item xs={8.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <SelectCustomerWithAddNew
            required={required}
            disabled={index === 0}
            selectedVal={e?.customerId}
            memberDataList={memberDataList}
            setSelectedVal={(val) =>
              handleUpdateItem(e?.key, val, "customerId")
            }
          />
          <IconButton
            onClick={() => handleRemoveItem(e?.key)}
            disabled={index === 0}
          >
            <IndeterminateCheckBoxOutlinedIcon sx={{ color: "red" }} />
          </IconButton>
        </Stack>
      </Grid>
    </Fragment>
  ));
}
