import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { CREATE_UNIT, UPDATE_UNIT } from "../../../Schema/Unit";

export const UnitForm = ({
  open,
  editData,
  handleClose,
  dialogTitle,
  setRefetch,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);

  //======================== Create Bank =========================
  const [createUnit] = useMutation(CREATE_UNIT, {
    onCompleted: ({ createUnit }) => {
      // console.log("createUnit::", createUnit);
      setLoading(false);
      if (createUnit?.isSuccess === true) {
        setAlert(true, "success", createUnit?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createUnit?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
      setAlert(true, "error", error?.message);
    },
  });

  //======================== update Bank =========================
  const [updateUnit] = useMutation(UPDATE_UNIT, {
    onCompleted: ({ updateUnit }) => {
      console.log("updateUnit::", updateUnit);
      setLoading(false);
      if (updateUnit?.isSuccess === true) {
        setAlert(true, "success", updateUnit?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateUnit?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    unitKhName: Yup.string().required(t("require")),
    unitEnName: Yup.string().required(t("require")),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      unitEnName: "",
      unitKhName: "",
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => { 
      setLoading(true);
      if (dialogTitle === "Create") {
        createUnit({
          variables: {
            input: {
              ...submitValues,
            },
          },
        });
      } else {
        updateUnit({
          variables: {
            id: editData?._id,
            input: {
              ...submitValues,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (dialogTitle === "Create") {
      resetForm();
    }
    if (editData && dialogTitle === "Update" && open) {
      setFieldValue("remark", editData?.remark);
      setFieldValue("unitKhName", editData?.unitKhName);
      setFieldValue("unitEnName", editData?.unitEnName);
    }
  }, [open, editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_unit")
                : t("dialog_title_update_unit")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("unit_kh")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("unitKhName")}
                  error={Boolean(touched.unitKhName && errors.unitKhName)}
                  helperText={touched.unitKhName && errors.unitKhName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("unit_en")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("unitEnName")}
                  error={Boolean(touched.unitEnName && errors.unitEnName)}
                  helperText={touched.unitEnName && errors.unitEnName}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
