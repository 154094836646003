import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import RefundAction from "./RefundAction";
import CreateIcon from "../../../Assets/add.png";
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import { GET_REFUND_PAGINATION } from "../../../Schema/Refund";
import { padNumber } from "../../../Include/Function/DynamicFn";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { translateLauguage } from "../../../Include/Function/Translate";
import { RefundProductForm } from "./RefundProductForm/RefundProductForm";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";

export const Refund = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true); 
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_REFUND_PAGINATION, { 
    variables: {
      page: page,
      limit: limit,
      pagination: true,
    },
    onCompleted: ({ getRefundPagination }) => {
      setLoading(false);

      if (getRefundPagination?.data) {
        setTableData(getRefundPagination?.data);
        setPaginationData(getRefundPagination?.paginator);
      }
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  useEffect((e) => {
    refetch();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Stack direction="row" justifyContent="right">
            <Button
              onClick={handleOpen}
              className="btn-create-style"
              startIcon={
                <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
              }
            >
              <Typography
                className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
              >
                {t("thead_return")}
              </Typography>
            </Button>
            <RefundProductForm
              open={open}
              handleClose={handleClose}
              dailogTitle={"Create"}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                >
                  {t("thead_no")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                >
                  {t("thead_date")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                >
                  {t("thead_product")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                >
                  {t("thead_total_amount")}
                </TableCell>
                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                >
                  {t("remark")}
                </TableCell>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        const totalAmount = row?.refundPayments?.reduce(
                          (a, b) => a + parseFloat(b?.amount),
                          0
                        );
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                              sx={{ color: "#1d4592", fontWeight: "600" }}
                            >
                              {padNumber(
                                row?.invoiceId?.invoiceNo,
                                5,
                                moment(row?.createdAt).format("YYYY")
                              )}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "MM-DD-YYYY hh:mm a"
                              )}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              <Stack direction="column" spacing={0.5}>
                                {row?.refundProducts?.map((e) => (
                                  <Stack>
                                    {
                                      e?.productId?.mainProductId
                                        ?.mainProductKhName
                                    }{" "}
                                    ({e?.refundQty}{" "}
                                    {e?.productId?.unitId?.unitKhName})
                                  </Stack>
                                ))}
                              </Stack>
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              $ {row?.totalAmount || totalAmount}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.remark}
                            </TableCell>

                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <Box sx={{ width: "40px", height: "40px" }} />
                              {/* <RefundAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.status !== "Pending"}  
                              /> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </>
  );
};
