import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from "../../../Context/AuthContext.js";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { translateLauguage } from "../../../Include/Function/Translate";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import GetRemainMainProductByIdAndByWarehouse from "./GetRemainMainProductByIdAndByWarehouse.jsx";
import { SelectMainProductByShop, SelectWarehouse } from "../../../Include/Function/DynamicSelect";

export default function SelectTransferProducts({ transferProductsData, setTransferProductsData, required, selectedShopId }) {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newSubItem = { key: Math.random() + Date.now(), warehouseId: null, qty: null }
  const newItem = {
    mainProductId: null,
    warehouseIds: [newSubItem],
    key: Math.random() + Date.now(),
  }

  const handleAddItem = () => setTransferProductsData([...transferProductsData, newItem]);



  const handleAddSubItem = (mainKey) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = [...e?.warehouseIds, newSubItem]
      return e
    })
    setTransferProductsData([...transferData]);
  }


  const handleRemoveSubItem = (mainKey, subItem) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = e?.warehouseIds.length > 1 ? e?.warehouseIds.filter(e => e.key !== subItem) : e?.warehouseIds
      return e
    })
    setTransferProductsData([...transferData]);
  }



  const handleRemoveItem = (key) => {
    const newData = transferProductsData.filter((e) => e.key !== key);
    setTransferProductsData([...newData]);
  }

  const handleUpdateItem = (key, value, fieldName) => {
    const newData = transferProductsData.map((e) => {
      if (e.key === key && fieldName === "mainProductId") e.mainProductId = value;
      return e;
    });
    setTransferProductsData([...newData]);
  }


  const handleUpdateSubItem = (mainKey, subKey, value, fieldName) => {

    const newData = transferProductsData.map((e) => {
      if (e?.key === mainKey) {
        e?.warehouseIds.map((sub) => {
          if (sub?.key === subKey && fieldName === "qty") sub.qty = value;
          if (sub?.key === subKey && fieldName === "warehouseId") sub.warehouseId = value;
          if (sub?.key === subKey && fieldName === "remainInStock") sub.remainInStock = value;
        })
      }
      return e;
    });
    setTransferProductsData([...newData]);
  }


  return (
    <Stack direction="column" width="100%" spacing={1}>

      <Stack direction="row" alignItems="center" width="100%" spacing={1}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="27.5%">
          {t("thead_product")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="21.5%">
          {t("thead_warehouse")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="16%">
          {t("thead_qty_in_warehouse")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="18.5%">
          {t("thead_transfer")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="16.5%" textAlign="center">
          {t("thead_total")}
        </Typography>
        <IconButton onClick={handleAddItem}  >
          <AddBoxOutlinedIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>

      <Stack direction="column" width="100%" spacing={2} minHeight={300}>
        {
          transferProductsData?.map((e) => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack sx={{ width: "25%" }}>
                <Box sx={{ width: "100%", height: "48px" }} />
                <SelectMainProductByShop
                  required={required}
                  shopId={selectedShopId}
                  disabled={!selectedShopId}
                  selectedVal={e?.mainProductId}
                  dataList={transferProductsData}
                  setSelectedVal={(value) => handleUpdateItem(e.key, value, "mainProductId")}
                />
              </Stack>
              <Stack direction="column" alignItems="center" spacing={1} sx={{ width: "55%" }} >
                <Stack direction="row" alignItems="center" justifyContent='flex-end' spacing={1} sx={{ width: "100%" }}>
                  <IconButton onClick={() => handleAddSubItem(e?.key)}>
                    <AddIcon sx={{ color: "green" }} />
                  </IconButton>
                </Stack>
                {
                  e?.warehouseIds?.map((sub) => (
                    <Stack direction="row" alignItems="center" spacing={1.5} sx={{ width: "100%" }}>
                      <Stack sx={{ width: "40%" }}>
                        <SelectWarehouse
                          required={required}
                          dataList={e?.warehouseIds}
                          selectedVal={sub?.warehouseId}
                          setSelectedVal={(value) => handleUpdateSubItem(e.key, sub.key, value, "warehouseId")}
                        />
                      </Stack>
                      <Stack sx={{ width: "60%" }}>
                        <GetRemainMainProductByIdAndByWarehouse
                          mainData={e}
                          subData={sub}
                          required={required}
                          handleUpdateSubItem={handleUpdateSubItem}
                        />
                      </Stack>
                      <IconButton onClick={() => handleRemoveSubItem(e?.key, sub?.key)}>
                        <CloseOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Stack>
                  ))
                }

              </Stack>
              <Stack sx={{ width: "15%" }} >
                <Box sx={{ height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" >
                    {e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty || 0), 0)}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>
              <Stack >
                <Box sx={{ height: "48px" }} />
                <Box>
                  <IconButton onClick={() => handleRemoveItem(e?.key)}>
                    <RemoveCircleOutlineIcon sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          )
          )
        }
      </Stack>
    </Stack>


  );
}
