import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//Srcs 
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import { SelectUnit } from "../../../Include/Function/DynamicSelect";
import { translateLauguage } from "../../../Include/Function/Translate";
import SelectMainCategory from "./SubProduct/SelectMainCategory";
import { CREATE_MAIN_PRODUCT, UPDATE_MAIN_PRODUCT } from "../../../Schema/Product";
import { BiSolidEdit } from "react-icons/bi";
import { FormEditFieldUnitMainProduct } from "./UpdateSpacialFieldMainProduct";

export const ProductForm = ({
  open,
  editData,
  setRefetch,
  handleClose,
  dialogTitle,
}) => {

  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [required, setRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitVal, setUnitVal] = useState({ id: "", title: "" });
  const [multipleSelectCategoryVal, setMultipleSelectCategoryVal] = useState([]);

  const [fieldSpecialUpdate, setFieldSpecialUpdate] = useState("");

  //======================== Create Bank =========================
  const [createMainProduct] = useMutation(CREATE_MAIN_PRODUCT, {
    onCompleted: ({ createMainProduct }) => {
      setLoading(false);
      if (createMainProduct?.isSuccess === true) {
        setAlert(true, "success", createMainProduct?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createMainProduct?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  //======================== update Bank =========================
  const [updateMainProduct] = useMutation(UPDATE_MAIN_PRODUCT, {
    onCompleted: ({ updateMainProduct }) => {
      setLoading(false);
      if (updateMainProduct?.isSuccess === true) {
        setAlert(true, "success", updateMainProduct?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", updateMainProduct?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    minimumUnitId: Yup.string().required(t("require")),
    mainProductEnName: Yup.string().required(t("require")),
    mainProductKhName: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      minimumUnitId: "",
      mainProductEnName: "",
      mainProductKhName: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);

      if (required) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return
      }

      //================ ===============
      if (dialogTitle === "Create") {
        createMainProduct({
          variables: {
            input: {
              ...value,
              categoryIds: multipleSelectCategoryVal
                .map((e) => e)
                .filter((e) => e),
            },
          },
        });
      } else {
        updateMainProduct({
          variables: {
            id: editData?._id,
            input: {
              ...value,
              categoryIds: multipleSelectCategoryVal
                .map((e) => e)
                .filter((e) => e),
            },
          },
        });
      }

    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("minimumUnitId", unitVal?.id || "");
  }, [unitVal]);

  useEffect(() => {
    if (dialogTitle === "Create" && open) {
      resetForm();
      setRequired(false);
      setUnitVal({ id: "", title: "" });
    }

    if ((dialogTitle === "Update" && open) || editData) {
      setUnitVal({
        id: editData?.minimumUnitId?._id,
        title: language === "en" ? editData?.minimumUnitId?.unitEnName : editData?.minimumUnitId?.unitKhName,
      });

      setFieldValue("remark", editData?.remark);
      setFieldValue("mainProductKhName", editData?.mainProductKhName);
      setFieldValue("mainProductEnName", editData?.mainProductEnName);
    }
  }, [open, editData]);


  useEffect(() => {
    setFieldSpecialUpdate("");
  }, [open])

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {dialogTitle === "Create"
                ? t("dialog_title_create_product")
                : t("dialog_title_update_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("product_name")} ({t("kh")})
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  placeholder={t("label_product_name")}
                  size="small"
                  {...getFieldProps("mainProductKhName")}
                  error={Boolean(
                    touched.mainProductKhName && errors.mainProductKhName
                  )}
                  helperText={
                    touched.mainProductKhName && errors.mainProductKhName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("product_name")} ({t("en")})
                </Typography>
                <TextField
                  className="text-field"
                  placeholder={t("label_product_name")}
                  fullWidth
                  size="small"
                  {...getFieldProps("mainProductEnName")}
                  error={Boolean(
                    touched.mainProductEnName && errors.mainProductEnName
                  )}
                  helperText={
                    touched.mainProductEnName && errors.mainProductEnName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("category")}
                </Typography>
                <SelectMainCategory
                  editData={editData}
                  selectedVal={multipleSelectCategoryVal}
                  setSelectedVal={setMultipleSelectCategoryVal}
                />
              </Grid>
              <Grid item xs={6}>

                {
                  dialogTitle === "Create" ?
                    <> 
                      <Typography
                        className={
                          language === "en" ? "field-title" : "field-title-kh"
                        }
                      >
                        {t("minimum_unit")}
                      </Typography>
                      <SelectUnit
                        selectedVal={unitVal}
                        setSelectedVal={setUnitVal} 
                      />
                      {!!errors.minimumUnitId && touched.minimumUnitId && (
                        <FormHelperText error id="outlined-adornment-password">
                          {errors.minimumUnitId}
                        </FormHelperText>
                      )}
                    </>
                  : null
                } 

                {
                  fieldSpecialUpdate !== "unitMainProduct" && dialogTitle !== "Create" ?
                    <>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          className={
                            language === "en" ? "field-title" : "field-title-kh"
                          }
                        >
                          {t("unit")}
                        </Typography>
                        <IconButton
                          sx={{
                            padding: 0,
                            backgroundColor: "transparent",
                            '&:hover': {
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => {
                            setFieldSpecialUpdate("unitMainProduct")
                          }}
                        >
                          <BiSolidEdit style={{ color: "orange", fontSize: 18 }} />
                        </IconButton>
                      </Stack>

                      <SelectUnit
                        selectedVal={unitVal}
                        setSelectedVal={setUnitVal}
                        disabled={true}
                      />
                      {!!errors.unitId && touched.unitId && (
                        <FormHelperText error id="outlined-adornment-password">
                          {errors.unitId}
                        </FormHelperText>
                      )}
                    </>
                  : null
                }

                {
                  fieldSpecialUpdate === "unitMainProduct" && dialogTitle !== "Create" ?
                    <>
                      <FormEditFieldUnitMainProduct
                        values={unitVal}
                        setRefetch={setRefetch}
                        setFieldSpecialUpdate={setFieldSpecialUpdate}
                        editData={editData}
                      />
                    </>
                    : null
                } 
                
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  rows={3}
                  fullWidth
                  multiline
                  placeholder={t("label_remark")}
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit} 
              >
                {dialogTitle === "Create" ? t("btn-create") : t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
