import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import "../../../../Style/dialogStyle.scss";
import { BiMinusCircle } from "react-icons/bi";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../../Context/AuthContext";
import SelectTransferProducts from "./SelectTransferProducts";
import { translateLauguage } from "../../../../Include/Function/Translate";
import {
  CREATE_RECEIVE_ORDER,
  GET_REMAIN_PRODUCT_FOR_RECEIVE,
} from "../../../../Schema/InventoryInWarehouse";

export const ReceiveProduct = ({
  open,
  handleClose,
  setRefetch,
  hideBackdrop,
  editData,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [transferProductsData, setTransferProductsData] = useState([]);

  //======================== Create User =========================
  const [createReceiveOrder] = useMutation(CREATE_RECEIVE_ORDER, {
    onCompleted: ({ createReceiveOrder }) => {
      setLoading(false);
      if (createReceiveOrder?.isSuccess === true) {
        setAlert(true, "success", createReceiveOrder?.message);
        handleClose();
        setRefetch();
        resetForm();
        setTransferProductsData([]);
      } else {
        setAlert(true, "error", createReceiveOrder?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  const { refetch } = useQuery(GET_REMAIN_PRODUCT_FOR_RECEIVE, {
    variables: {
      purchaseId: editData?._id,
    },
    onCompleted: ({ getRemainProductForReceive }) => {
      const newData = getRemainProductForReceive?.map((e) => ({
        qty: e?.qty,
        remain: e?.remain,
        unitPrice: e?.unitPrice,
        productId: e?.productId?._id,
        mainProductId: e?.productId?.mainProductId?._id,
        qtyInMinimumUnit: e?.productId?.qtyInMinimumUnit,

        mainProductId: {
          id: e?.productId?.mainProductId?._id,
          title:
            language === "en"
              ? e?.productId?.mainProductId?.mainProductEnName
              : e?.productId?.mainProductId?.mainProductEnName,
          unit:
            language === "en"
              ? e?.productId?.unitId?.unitEnName
              : e?.productId?.unitId?.unitKhName,
        },
        key: Math.random() + Date.now(),
        warehouseIds: [
          {
            key: Math.random() + Date.now(),
            warehouseId: null,
            qty: null,
            expireDate: null,
          },
        ],
      }));

      setTransferProductsData([...newData]);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      // const requiredProduct = transferProductsData?.map((e) => {
      //   const require = e?.warehouseIds?.map((i) => i?.warehouseId?.id ? true : false).filter((e) => !e)
      //   return require.length > 0 ? false : true
      // })?.filter((e) => !e);

      // if (requiredProduct?.length > 0) {
      //   setRequired(true);
      //   return;
      // }

      const submitReceiveProducts = transferProductsData.map((e) => ({
        qty: e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty), 0),
        unitPrice: e?.unitPrice,
        productId: e?.productId,
        mainProductId: e?.mainProductId?.id,
        qtyInMinimumUnit: e?.qtyInMinimumUnit,

        warehouseIds: e?.warehouseIds
          ?.map((i) => ({
            qty: parseFloat(i?.qty || 0),
            warehouseId: i?.warehouseId?.id,
            expireDate: i?.expireDate
              ? moment(i?.expireDate).format("YYYY-MM-DD")
              : "",
          }))
          .filter((e) => e?.qty > 0),
      }));

      setLoading(true);
      createReceiveOrder({
        variables: {
          input: {
            remark: value?.remark,
            purchaseId: editData?._id,
            receiveProducts: submitReceiveProducts,
          },
        },
      });
    },
  });

  const { resetForm, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);

  const date = Date.now();

  useEffect(() => {
    refetch();
  }, [open]);

  return (
    <Dialog
      open={open}
      className="dialog-container"
      fullWidth
      maxWidth="lg"
      hideBackdrop={hideBackdrop}
    >
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_receive_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <SelectTransferProducts
                  required={required}
                  transferProductsData={transferProductsData}
                  setTransferProductsData={setTransferProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
              >
                {t("btn-confirm")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
