import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";

export default function DialogKeyboardNumber({
  onClick,
  display,
  disabled,
  handleClear,
  handleClick,
  handleSetValue,
  handleRemoveLastValue,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        className="keyboard-number"
        onClick={() => {
          handleOpen();
          onClick();
        }}
      >
        <KeyboardAltOutlinedIcon />
      </Button>

      <Dialog
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="view-invoice-container" sx={{ height: "100%" }}>
          <Box padding={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  width="100%"
                  sx={{
                    border: "1px solid #64b5f6",
                    minHeight: "60px",
                    borderRadius: "5px",
                  }}
                  justifyContent="end"
                  alignItems="center"
                >
                  <Box p={2} mb={1}>
                    <Typography className="number-sum-calcul" align="right">
                      {display}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <table className="tb-view">
                  <tr>
                    {["", "C", "Backspace"].map((value, index) => (
                      <td key={index}>
                        <Button
                          fullWidth
                          key={value}
                          className="calcul-text"
                          onClick={() => {
                            if (value === "C") handleClear();
                            if (value === "Backspace") handleRemoveLastValue();
                          }}
                        >
                          {value === "Backspace" ? (
                            <BackspaceOutlinedIcon />
                          ) : (
                            value
                          )}
                        </Button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {["7", "8", "9"].map((value, index) => (
                      <td key={index}>
                        <Button
                          fullWidth
                          key={value}
                          className="calcul-text"
                          onClick={() => handleClick(value)}
                        >
                          {value}
                        </Button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {["4", "5", "6"].map((value, index) => (
                      <td key={index}>
                        <Button
                          fullWidth
                          key={value}
                          className="calcul-text"
                          onClick={() => handleClick(value)}
                        >
                          {value}
                        </Button>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {["1", "2", "3"].map((value, index) => (
                      <td key={index}>
                        <Button
                          fullWidth
                          key={value}
                          className="calcul-text"
                          onClick={() => handleClick(value)}
                        >
                          {value}
                        </Button>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    {["0", ".", "Ok"].map((value, index) => (
                      <td key={index}>
                        <Button
                          fullWidth
                          key={value}
                          className="calcul-text"
                          onClick={() => {
                            if (value === "Ok") {
                              handleSetValue();
                              handleClose();
                            } else handleClick(value);
                          }}
                        >
                          {value}
                        </Button>
                      </td>
                    ))}
                  </tr>
                </table>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
