import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Button,
  IconButton,
  Typography,
  DialogActions,
  Divider,
} from "@mui/material";
import "../../../Style/dialogStyle.scss";
import Dialog from "@mui/material/Dialog";
import { BiMinusCircle } from "react-icons/bi";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import moment from "moment";


export const ViewTransfer = ({ open, editData, handleClose }) => {
  // ===================== Change Language ==========================  
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const tableData = [];

  editData?.transferProducts.map((e) => { 
    e?.warehouseIds?.map((i) => {
      tableData?.push({
        qty: i?.qty,
        warehouse: language === "en" ? i?.warehouseId?.warehouseEnName : i?.warehouseId?.warehouseKhName,
        mainProductId: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,
      })
    })

  }) 
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}  >
              {t("dialog_title_transfer_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={1} columnSpacing={3}>


          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_transfer_by")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.transferBy?.userId?.latinName : editData?.transferBy?.userId?.khmerName}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_shop")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {language === "en" ? editData?.shopId?.shopNameEn : editData?.shopId?.shopNameKh}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center" sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="40%">
                {t("thead_date")}
              </Typography>
              <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="60%">
                :  {moment(editData?.createdAt).format("DD-MM-YYYY hh:mm a")}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack width="100%" direction="column" spacing={2} mt={2}  >
              <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33.33%" fontWeight="bold" >  {t('thead_product')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33.33%" fontWeight="bold" >  {t('thead_warehouse')} </Typography>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"} width="33.33%" fontWeight="bold" >  {t('thead_transfer')} </Typography>
              </Stack>
              <Stack width="100%" direction="column" spacing={1.7} minHeight={300}>
                <Divider />
                {
                  tableData.length === 0 ? <EmptyData /> :
                    tableData?.map((e) => (
                      <Stack width="100%" direction="column" spacing={1.7}>
                        <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ paddingX: 2 }}>
                          <Typography width="33.33%" >  {e?.mainProductId} </Typography>
                          <Typography width="33.33%" >  {e?.warehouse} </Typography>
                          <Typography width="33.33%" >  {e?.qty}  {e?.unit}  </Typography>
                        </Stack>
                        <Divider />
                      </Stack>
                    ))
                }
              </Stack>
            </Stack>

          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} width={`100%`} className="create-container">
          <Button
            className={language === "kh" ? "btn-create-kh" : "btn-create"}
            fullWidth
            onClick={handleClose}
          >
            {t("btn-confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
