import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import RegisterAction from "./RegisterAction";
import { AuthContext } from "../../Context/AuthContext";
import { UpdateCheckInMember } from "./UpdateCheckInMember";
import { UpdateCheckInOutStatus } from "./UpdateCheckInOutStatus";
import { translateLauguage } from "../../Include/Function/Translate";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { GET_CHECK_IN_MEMBER_BY_CHECK_IN } from "../../Schema/CardNumber";

export default function RegisterCard({ item, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState();
  const [tableData, setTableData] = useState([]);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => setOpenStatus(false);

  const { refetch } = useQuery(GET_CHECK_IN_MEMBER_BY_CHECK_IN, {
    variables: {
      checkInId: item?._id,
    },
    onCompleted: ({ getCheckInMemberByCheckIn }) => {
      setLoading(false);
      setTableData(getCheckInMemberByCheckIn);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });
  useEffect(() => {
    refetch();
  }, [item]);
  return (
    <Stack
      spacing={1}
      paddingX={3}
      paddingTop={1}
      paddingBottom={2.5}
      direction={`column`}
      sx={{ height: "100%", bgcolor: "white", borderRadius: 2 }}
    >
      <Stack direction="column" width="100%">
        <Stack
          width="100%"
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Typography fontWeight="bold" color="#1d4592">
            {item?.leaderId?.name}
          </Typography>
          <Box>
            <RegisterAction editData={item} setRefetch={setRefetch} />
          </Box>
        </Stack>
        <Divider />
      </Stack>
      <Stack direction="column" sx={{ overflow: "auto", height: 400 }}>
        {tableData?.map((e, index) => (
          <Stack
            key={index}
            onClick={() => {
              setEditData(e);
              if (item?.status !== "Out") handleOpenEdit();
            }}
            direction="row"
            sx={{
              paddingY: 1,
              paddingX: 1.5,
              cursor: "pointer",
              ":hover": {
                borderRadius: 1,
                bgcolor: "#F4F4F4",
                transition: "all 0.2s",
              },
            }}
          >
            <Typography width="12%">{index + 1}</Typography>
            <Typography width="22%">{e?.cardId?.cardNo}</Typography>
            <Typography width="66%">{e?.customerId?.name}</Typography>
          </Stack>
        ))}
      </Stack>
      <UpdateCheckInMember
        open={openEdit}
        editData={editData}
        dialogTitle="Update"
        setRefetch={setRefetch}
        handleClose={handleCloseEdit}
      />
      <UpdateCheckInOutStatus
        open={openStatus}
        editData={item}
        setRefetch={setRefetch}
        handleClose={handleCloseStatus}
      />

      {
        <Stack direction="column" width="100%">
          {loading ? (
            <Button
              className={language === "kh" ? "btn-create-kh" : "btn-create"}
              fullWidth
              variant="outlined"
            >
              {t("loading")}
            </Button>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleOpenStatus}
              disabled={item?.status === "Out"}
              sx={{
                color:
                  item?.status === "Waiting"
                    ? "#6440CD"
                    : item?.status === "In"
                    ? "green"
                    : "",
                borderColor:
                  item?.status === "Waiting"
                    ? "#6440CD"
                    : item?.status === "In"
                    ? "green"
                    : "",
                ":hover": {
                  borderColor:
                    item?.status === "Waiting"
                      ? "#6440CD"
                      : item?.status === "In"
                      ? "green"
                      : "",
                },
              }}
            >
              {item?.status === "Waiting"
                ? t("btn-waiting")
                : item?.status === "In"
                ? t("btn-check-in")
                : t("btn-done")}
            </Button>
          )}
        </Stack>
      }
    </Stack>
  );
}
