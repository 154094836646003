import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { deleteFileAPI } from "../../../Include/api/api";
import EmptyUser from "../../../Assets/user.png";
import { BiMinusCircle } from "react-icons/bi";
import { UPDATE_USER } from "../../../Schema/Users";
import CropImageFile from "../../CropImage/CropImageFile";
import { AuthContext } from "../../../Context/AuthContext";
import LinearProgress from "@mui/material/LinearProgress";
import { translateLauguage } from "../../../Include/Function/Translate";
import { useMutation as mutationDeleteImage } from "react-query";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const UpdateUser = ({ open, handleClose, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [multipleSelectShopVal, setMultipleSelectShopVal] = useState([]);
  // ========================= upload Image =========================
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };
  // ===============  Mutations delete image  =======================
  const mutationDelete = mutationDeleteImage(deleteFileAPI, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        console.log("delete sucess::", data?.data?.message);
      } else {
        console.log("error::", data?.data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
      // console.log("deleteFileAPI", deleteFileAPI);
    },
  });

  const handleCloseAction = () => {
    setProfileHook("");
    handleClose();
    if (profileHook) {
      let splitSrc = profileHook?.split(":")[4];
      mutationDelete.mutate({
        storage: "pos_go_globalit",
        folder: "it_pos_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  //======================== update User =========================
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      console.log("updateUser::", updateUser);
      setLoading(false);
      if (updateUser?.isSuccess === true) {
        setAlert(true, "success", updateUser?.message);
        handleClose();
        resetForm();
        setRefetch();
        if (editData?.userProfile) {
          let splitSrc = editData?.userProfile?.split(":")[4];
          console.log("splitSrc", splitSrc);
          mutationDelete.mutate({
            storage: "pos_go_globalit",
            folder: "it_pos_images",
            file: splitSrc.split("/")[0],
          });
        }

        setImageFile(null);
        setProfileHook("");
      } else {
        setAlert(true, "error", updateUser?.message);
        console.log("updateUser?.message:::", updateUser?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    userProfile: Yup.string(),
    khmerName: Yup.string().required(t("require")),
    latinName: Yup.string().required(t("require")),
    gender: Yup.string().required(t("require")),
    phoneNumber: Yup.string(),
    role: Yup.string().required(t("require")),
    email: Yup.string().email().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      userProfile: "",
      khmerName: "",
      latinName: "",
      gender: "Female",
      phoneNumber: "",
      email: "",
      role: "Admin",
    },

    validationSchema: CheckValidation,
    onSubmit: async (submitValues) => { 
      setLoading(true);
      updateUser({
        variables: {
          id: editData?._id,
          input: {
            userProfile: editData?.userProfile
              ? editData?.userProfile
              : profileHook,
            role: submitValues?.role,
            email: submitValues?.email,
            gender: submitValues?.gender,
            khmerName: submitValues?.khmerName,
            latinName: submitValues?.latinName,
            phoneNumber: submitValues?.phoneNumber,
            // accessibleShop: multipleSelectShopVal?.map((e) => e?.id), 
          },
        },
      });
    },
  });
  useEffect(() => {
    if (editData) {
      setFieldValue("role", editData?.role);
      setFieldValue("email", editData?.email);
      setFieldValue("gender", editData?.gender);
      setFieldValue("khmerName", editData?.khmerName);
      setFieldValue("latinName", editData?.latinName);
      setFieldValue("phoneNumber", editData?.phoneNumber);

      // const data = editData?.accessibleShop?.map((e) => ({
      //   id: e?._id,
      //   title: e?.shopNameKh,
      // }));
      // setMultipleSelectShopVal(data);
    }
  }, [open, editData]);

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_update_user")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleCloseAction}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.userProfile
                                  ? editData?.userProfile
                                  : EmptyUser
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setProgress={setProgress}
                    setStatusProgress={setStatusProgress}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}

                {statusProgress && progress < 100 ? (
                  <Box sx={{ width: "100%", marginLeft: "20px" }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                ) : (
                  <Typography
                    className={
                      language === "en" ? "field-title" : "field-title-kh"
                    }
                    sx={{ textAlign: "center", marginTop: "10px" }}
                  >
                    {t("label_image")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_khmer_name")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={t("label_khmer_name")}
                  {...getFieldProps("khmerName")}
                  error={Boolean(touched.khmerName && errors.khmerName)}
                  helperText={touched.khmerName && errors.khmerName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_latin_name")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={t("label_latin_name")}
                  {...getFieldProps("latinName")}
                  error={Boolean(touched.latinName && errors.latinName)}
                  helperText={touched.latinName && errors.latinName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_gender")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={values?.gender}
                    onChange={(e) => setFieldValue("gender", e.target.value)}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_tel")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={t("label_tel")}
                  {...getFieldProps("phoneNumber")}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_role")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={values?.role}
                    onChange={(e) => setFieldValue("role", e.target.value)}
                  >
                    <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Cashier">Cashier</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("label_email")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  Access Shops
                </Typography>
                <MultipleSelectShop
                  selectedVal={multipleSelectShopVal}
                  setSelectedVal={setMultipleSelectShopVal}
                />
              </Grid> */}
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                disabled={statusProgress && progress < 100 ? true : false}
                onClick={handleSubmit}
              >
                {t("btn-update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
