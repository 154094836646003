import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Stack,
  Select,
  TableRow,
  MenuItem,
  TextField,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import "../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData/EmptyData";
import LoadingPage from "../../Include/LoadingPage/LoadingPage";
import { GET_INVOICE_WITH_PAGINATION } from "../../Schema/Invoice";
import { translateLauguage } from "../../Include/Function/Translate";
import { MultipleSelectShop } from "../../Include/Function/DynamicSelect";
import { currencyFormat, padNumber } from "../../Include/Function/DynamicFn";
import FooterPagination from "../../Include/FooterPagination/FooterPagination";
import TransactionAction from "../../Components/Transaction/TransactionAction";
import SelectDatePicker from "../../Include/SelectDatePicker/SelectDatePicker";

export const Transaction = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isVoid, setIsVoid] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [paginationData, setPaginationData] = useState();
  const [paymentStatus, setPaymentStatus] = useState("All");
  const [multipleSelectShopVal, setMultipleSelectShopVal] = useState([]);

  const { refetch } = useQuery(GET_INVOICE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      paginator: true,
      paymentStatus: paymentStatus,
      invoiceNo: parseInt(invoiceNo),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      shopIds: multipleSelectShopVal.map((e) => e?.id),
      startDate: moment(startDate).format("YYYY-MM-DD"),
      isVoid: isVoid === "All" ? null : isVoid === "Yes" ? true : false,
    },
    onCompleted: ({ getInvoicePagination }) => {
      setLoading(false);
      setTableData(getInvoicePagination?.data);
      setPaginationData(getInvoicePagination?.paginator);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    setPage(1);
  }, [
    limit,
    paymentStatus,
    invoiceNo,
    endDate,
    multipleSelectShopVal,
    startDate,
    isVoid,
  ]);

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const translateStatus = (status) => {
    if (status === "Owe") return t("thead_owe");
    if (status === "Paid") return t("thead_paid");
    if (status === "Unpaid") return t("thead_unpaid");
    else return status;
  };

  const colorStatus = (status) => {
    if (status === "Owe") return "#FFB514";
    if (status === "Paid") return "green";
    if (status === "Unpaid") return "red";
    else return "";
  };
  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography
            className={language === "kh" ? "page-title-kh" : "page-title"}
          >
            {t(`page-transaction`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t(`label-search`)}
            </Typography>
            <TextField
              size="small"
              type="number"
              className="search-field"
              placeholder={t("label_invoice_number")}
              onChange={(e) => setInvoiceNo(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_shop")}
            </Typography>
            <MultipleSelectShop
              className={"search-field"}
              selectedVal={multipleSelectShopVal}
              setSelectedVal={setMultipleSelectShopVal}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_status")}
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <MenuItem value="All">{t("thead_all")}</MenuItem>
                <MenuItem value="Unpaid">{t("thead_unpaid")}</MenuItem>
                <MenuItem value="Owe">{t("thead_owe")}</MenuItem>
                <MenuItem value="Paid">{t("thead_paid")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_start_date")}
            </Typography>
            <SelectDatePicker
              date={startDate}
              setDate={(e) => setStartDate(e)}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              {t("thead_end_date")}
            </Typography>
            <SelectDatePicker
              date={endDate}
              setDate={(e) => setEndDate(e)}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Typography
              className={language === "kh" ? "header-text-kh" : "header-text"}
            >
              Is Void
            </Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={isVoid}
                onChange={(e) => setIsVoid(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6} lg={12} xl={3}>
            <Stack direction="row" justifyContent="right">
              <Button
                className="btn-create-style"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                <Typography
                  className={language === "kh" ? "btn-txt-kh" : "btn-txt"}
                >
                  {t("btn-create")}
                </Typography>
              </Button>
            </Stack>
          </Grid> */}
        </Grid>
      </Box>

      <Box className="body-container">
        {/* <TableContainer sx={{ maxWidth: `${width}px` }}> */}
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell
                  className={
                    language === "en"
                      ? "header-title-start"
                      : "header-title-start-kh"
                  }
                  width="17%"
                >
                  {t("thead_invoice")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="22%"
                >
                  {t("thead_customer")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("thead_date")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("thead_total_amount")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="12%"
                >
                  {t("thead_status")}
                </TableCell>

                <TableCell
                  className={
                    language === "en" ? "header-title" : "header-title-kh"
                  }
                  width="17%"
                >
                  {t("remark")}
                </TableCell>

                <TableCell
                  className={
                    language === "en"
                      ? "header-title-end"
                      : "header-title-end-kh"
                  }
                  width="10%"
                ></TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody className="table-body">
                <TableRow className="body-row">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="table-body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="table-body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start`
                                  : `${
                                      row?.isVoid ? "void-left" : ""
                                    } body-cell-start-kh`
                              }
                            >
                              {padNumber(
                                row?.invoiceNo,
                                5,
                                moment(row?.createdAt).format("YYYY")
                              )}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.customerId?.name}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY HH:mm a"
                              )}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {currencyFormat(row?.total || 0)}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                              sx={{
                                textTransform: "uppercase",
                                color: colorStatus(row?.paymentStatus),
                              }}
                            >
                              {translateStatus(row?.paymentStatus)}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en" ? "body-cell" : "body-cell-kh"
                              }
                            >
                              {row?.remark}
                            </TableCell>
                            <TableCell
                              className={
                                language === "en"
                                  ? `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end`
                                  : `${
                                      row?.isVoid ? "void-right" : ""
                                    } body-cell-end-kh`
                              }
                              align="right"
                            >
                              <TransactionAction
                                editData={row}
                                setRefetch={refetch}
                                disabled={row?.isVoid}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <FooterPagination
          page={page}
          limit={limit}
          setPage={setPage}
          handleLimit={handleLimit}
          totalDocs={paginationData?.totalDocs}
          totalPages={paginationData?.totalPages}
        />
      </Box>
    </div>
  );
};
