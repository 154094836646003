import { gql } from "@apollo/client";

export const CREATE_TICKET_GROUP = gql`
  mutation CreateTicketGroup($input: TicketGroupInput) {
    createTicketGroup(input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const UPDATE_TICKET_GROUP = gql`
  mutation UpdateTicketGroup($id: ID!, $input: TicketGroupInput) {
    updateTicketGroup(_id: $id, input: $input) {
      isSuccess
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const DELETE_TICKET_GROUP = gql`
  mutation DeleteTicketGroup($id: ID!) {
    deleteTicketGroup(_id: $id) {
      isSuccess 
      message {
        messageEn
        messageKh
      }
    }
  }
`;

export const GET_TICKET_GROUP_WITH_PAGINATION = gql`
  query GetTicketGroupPagination(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $keyword: String
  ) {
    getTicketGroupPagination(
      page: $page
      limit: $limit
      pagination: $pagination
      keyword: $keyword
    ) {
      data {
        _id
        titleEn
        titleKh
        headerCode
        validFrom
        expireDate
        shopId {
          shopNameKh
          shopNameEn
          _id
        }
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
