import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  Typography,
  IconButton,
  DialogActions,
  FormHelperText,
  TextField,
} from "@mui/material";
//Dialog
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import "../../../../Style/dialogStyle.scss";
import { BiMinusCircle } from "react-icons/bi";
import ProductForSelect from "./ProductForSelect";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../../Context/AuthContext";
import { translateLauguage } from "../../../../Include/Function/Translate";
import { SelectRefundInvoices } from "../../../../Include/Function/DynamicSelect";
import {
  CREATE_REFUND,
  GET_REFUND_ITEMS_BY_IN_VOICE,
} from "../../../../Schema/SelectSchema";

export const RefundProductForm = ({
  open,
  handleClose,
  setRefetch,
  hideBackdrop,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const [checkedProductId, setCheckedProductId] = useState([]);
  const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({
    id: "",
    title: "",
  });
  const [selectedInvoiceVal, setSelectedInvoiceVal] = useState({
    id: "",
    title: "",
  });

  const { refetch } = useQuery(GET_REFUND_ITEMS_BY_IN_VOICE, {
    variables: {
      invoiceId: selectedInvoiceVal?.id,
    },
    onCompleted: ({ getRefundItemsByInvoice }) => {
      setLoading(false);

      const newData = getRefundItemsByInvoice
        .map((e) => ({
          refund: 0,
          id: e?.productId?._id,
          qty: e?.remainRefundQty,
          unitPrice: e?.price,
          product:
            language === "en"
              ? e?.productId?.mainProductId?.mainProductEnName
              : e?.productId?.mainProductId?.mainProductKhName,
          unit:
            language === "en"
              ? e?.productId?.mainProductId?.minimumUnitId?.unitEnName
              : e?.productId?.mainProductId?.minimumUnitId?.unitKhName,
        }))
        .filter((e) => e?.qty > 0);

      setTableData(newData);
    },
    onError: (err) => {
      setTableData([]);
      console.log("Error::", err?.message);
    },
  });

  //======================== Create User =========================
  const [createRefund] = useMutation(CREATE_REFUND, {
    onCompleted: ({ createRefund }) => {
      setLoading(false);
      if (createRefund?.isSuccess === true) {
        setAlert(true, "success", createRefund?.message);
        handleClose();
        setRefetch();
        resetForm();
        setSelectedWarehouseVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createRefund?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
    invoiceId: Yup.string().required(t("require")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      invoiceId: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      setLoading(true);
      const refundProductData = tableData
        .filter((d) => checkedProductId.map((e) => e?.id).includes(d?.id))
        .map((e) => ({
          productId: e?.id,
          refundQty: e?.refund,
        }));

      const refundPaymentData = tableData
        .filter((d) => checkedProductId.map((e) => e?.id).includes(d?.id))
        .map((e) => ({
          khr: null,
          paymentMethod: "Cash",
          usd: e?.unitPrice * e?.qty,
          amount: e?.unitPrice * e?.qty,
        }));

      if (refundProductData.length === 0) {
        setAlert(true, "error", {
          messageKh: "ទាមទារបរិមាណសម្រាប់កែតម្រូវ",
          messageEn: "Quantity required for adjust out!",
        });
        return;
      }

    

      createRefund({
        variables: {
          input: {
            remark: value?.remark,
            invoiceId: selectedInvoiceVal?.id,
            refundProducts: refundProductData,
            refundPayments: refundPaymentData,
          },
        },
      });
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setCheckedProductId([]);
  }, [selectedWarehouseVal, selectedInvoiceVal]);

  useEffect(() => {
    setFieldValue("invoiceId", selectedInvoiceVal?.id);
  }, [selectedInvoiceVal]);

  useEffect(() => {
    resetForm();
    setSelectedWarehouseVal({ id: "", title: "" });
    setSelectedInvoiceVal({ id: "", title: "" });
  }, [open]);

  useEffect(() => {
    refetch();
  }, []);
  const shopId = window.localStorage.getItem("shopId");
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      hideBackdrop={hideBackdrop}
      className="dialog-container"
    >
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("thead_return_the_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("thead_invoice")}
                </Typography>

                <SelectRefundInvoices
                  shopId={shopId}
                  selectedVal={selectedInvoiceVal}
                  setSelectedVal={setSelectedInvoiceVal}
                />

                {!!errors.invoiceId && touched.invoiceId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.invoiceId}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <ProductForSelect
                  tableData={tableData}
                  setTableData={setTableData}
                  checkedProductId={checkedProductId}
                  setCheckedProductId={setCheckedProductId}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    language === "en" ? "field-title" : "field-title-kh"
                  }
                >
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
                onClick={handleSubmit}
                disabled={!checkedProductId}
              >
                {t("btn-confirm")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
