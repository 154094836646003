import React, { useContext, useMemo, useState, useEffect } from "react";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
//Srcs
import "./App.css";
import Router from "./routes";
import { AuthContext } from "./Context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import AlertMessage from "./Components/AlertMessage/AlertMessage";


function App() {
  //==============
  const { state } = useContext(AuthContext);
  const { user } = state;

  // ====================== set token =======================
  const [token, setToken] = useState(
    JSON.parse(window.localStorage.getItem("token"))
  );

  useEffect(() => {
    let userToken = JSON.parse(window.localStorage.getItem("token"));
    if (userToken) {
      setToken(userToken);
      return;
    }

    if (user?.token) {
      setToken(user?.token);
      // window.localStorage.setItem("token", JSON.stringify(user?.token));
      window.localStorage.setItem("token", user?.token);
    } else {
      setToken("");
    }
  }, [user?.token]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: "light",
          background: {
            default: "#EBEDEF",
          },
        },
        typography: {
          // fontFamily: ["Century Gothic", "Siemreap"].join(","),
          fontFamily: ["Poppin-Regular", "Siemreap"].join(","),
        },
      }),
    [] 
  );

  const httpLink = createHttpLink({
    // uri: "http://192.168.2.12:6370/graphql",
    uri: process.env.REACT_APP_END_POINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
        path: window.location.pathname,
        shopId: window.localStorage.getItem("shopId") || "",
        shiftId: window.localStorage.getItem("shiftId") || "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    // link: ApolloLink.from([logoutLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const queryClient = new QueryClient();

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routers>
            <Router />
          </Routers>
          <CssBaseline />
        </ThemeProvider>
        <AlertMessage />
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
