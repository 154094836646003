import React from "react";
import {
  Stack, Typography, Avatar, TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmptyImage from "../../Assets/empty-box.png"
//Srcs
import "./emptydata.scss";

export default function EmptyData({ colSpan }) {

  // return (
  // <Stack direction="row" justifyContent="center" className="empty-container">
  //   <Stack direction="column" justifyContent="center" height="300px" spacing={1}>
  //     <Stack direction="row" justifyContent="center">
  //       <img src={EmptyImage} alt="icon" className="empty-image"/>
  //     </Stack>
  //     <Typography className="text-title">No data</Typography>
  //   </Stack>
  // </Stack>
  // );
  // console.log("colSpan==>", colSpan)
  return (
    <>
      {colSpan ?
        <TableBody className="body">
          <TableRow className="body-row">
            <TableCell colSpan={colSpan} className="body-cell">
              <Stack direction="row" justifyContent="center" className="empty-container">
                <Stack direction="column" justifyContent="center" height="300px" spacing={1}>
                  <Stack direction="row" justifyContent="center">
                    <img src={EmptyImage} alt="icon" className="empty-image" />
                  </Stack>
                  <Typography className="text-title">No data</Typography>
                </Stack> 
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
        : (<Stack direction="row" justifyContent="center" className="empty-container" bgcolor="white">
          <Stack direction="column" justifyContent="center" height="300px" spacing={1}>
            <Stack direction="row" justifyContent="center">
              <img src={EmptyImage} alt="icon" className="empty-image" />
            </Stack>
            <Typography className="text-title">No data</Typography>
          </Stack>
        </Stack>)
      }
    </>
  )
}
