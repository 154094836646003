import React, { useContext } from "react";
import "../../../Style/actionStyle.scss";
import AddIcon from '@mui/icons-material/Add';
import { AuthContext } from "../../../Context/AuthContext.js";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectWarehouse } from "../../../Include/Function/DynamicSelect";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import GetRemainMainProductByIdAndByWarehouse from "./GetRemainMainProductByIdAndByWarehouse.jsx";

export default function SelectReceiveProducts({ transferProductsData, setTransferProductsData, required, }) {

  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newSubItem = { key: Math.random() + Date.now(), warehouseId: null, qty: 0 }


  const handleAddSubItem = (mainKey) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = [...e?.warehouseIds, newSubItem]
      return e
    })
    setTransferProductsData([...transferData]);
  }

  const handleRemoveSubItem = (mainKey, subItem) => {
    const transferData = transferProductsData.map((e) => {
      if (e?.key === mainKey) e.warehouseIds = e?.warehouseIds.length > 1 ? e?.warehouseIds.filter(e => e.key !== subItem) : e?.warehouseIds
      return e
    })
    setTransferProductsData([...transferData]);
  }

  const handleUpdateSubItem = (mainKey, subKey, value, fieldName) => {


    const newData = transferProductsData.map((e) => {
      if (e?.key === mainKey) {
        e?.warehouseIds.map((sub) => {
          if (sub?.key === subKey && fieldName === "warehouseId") sub.warehouseId = value;
          if (sub?.key === subKey && fieldName === "remainInStock") sub.remainInStock = value;

          if (sub?.key === subKey && fieldName === "qty") {

            const total = e?.warehouseIds.filter((e) => e?.key !== subKey)?.reduce((a, b) => a + (b?.qty || 0), 0) + value - parseFloat(e?.transferQty || 0);
            const remainTotal = total > 0 ? value - total : value
            sub.qty = remainTotal
          }


          if (!sub.warehouseId?.id) sub.qty = 0;
        })
      }
      return e;
    });
    setTransferProductsData([...newData]);
  }



  return (
    <Stack direction="column" width="100%" spacing={1}>

      <Stack direction="row" alignItems="center" width="100%" spacing={1}>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="21%">
          {t("thead_product")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="20.5%">
          {t("thead_delivery")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="16.5%">
          {t("thead_warehouse")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="12%">
          {t("thead_qty_in_warehouse")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="13%">
          {t("thead_receive")}
        </Typography>
        <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" width="17%" textAlign="center">
          {t("thead_total")}
        </Typography>

      </Stack>

      <Stack direction="column" width="100%" spacing={2}>
        { 
          transferProductsData?.map((e) => (
            <Stack direction="row" alignItems="center" spacing={2}>

              <Stack sx={{ width: "20%" }} >
                <Box sx={{ height: "48px" }} />
                <Stack direction="row" alignItems="center" spacing={0.5} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" >
                    {e?.mainProductId?.title}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ width: "20%" }} >
                <Box sx={{ height: "48px" }} />
                <Stack direction="row" alignItems="center" spacing={0.5} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" >
                    {e?.transferQty}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="column" alignItems="center" spacing={1} sx={{ width: "45%" }} >
                <Stack direction="row" alignItems="center" justifyContent='flex-end' spacing={1} sx={{ width: "100%" }}>
                  <IconButton onClick={() => handleAddSubItem(e?.key)}>
                    <AddIcon sx={{ color: "green" }} />
                  </IconButton>
                </Stack>
                {
                  e?.warehouseIds?.map((sub) => (
                    <Stack direction="row" alignItems="center" spacing={1.5} sx={{ width: "100%" }}>
                      <Stack sx={{ width: "40%" }}>
                        <SelectWarehouse
                          required={required}
                          dataList={e?.warehouseIds}
                          selectedVal={sub?.warehouseId}
                          setSelectedVal={(value) => handleUpdateSubItem(e.key, sub.key, value, "warehouseId")}
                        />
                      </Stack>
                      <Stack sx={{ width: "60%" }}>
                        <GetRemainMainProductByIdAndByWarehouse
                          mainData={e}
                          subData={sub}
                          required={required}
                          handleUpdateSubItem={handleUpdateSubItem}
                        />
                      </Stack>

                      <IconButton onClick={() => handleRemoveSubItem(e?.key, sub?.key)}>
                        <CloseOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Stack>
                  ))
                }

              </Stack>

              <Stack sx={{ width: "15%" }} >
                <Box sx={{ height: "48px" }} />
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} sx={{ bgcolor: "#F0F0F0", borderRadius: 1, paddingY: 1, paddingX: 2 }}>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} fontWeight="bold" >
                    {e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty || 0), 0)}
                  </Typography>
                  <Typography className={language === "en" ? "field-title" : "field-title-kh"} >
                    {e?.mainProductId?.unit}
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          )
          )
        }
      </Stack>
    </Stack>


  );
}
