import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Badge,
  Button,
  Typography,
} from "@mui/material";
import "../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { Refund } from "../../Components/InventoryInShop/Refund/Refund";
import { Request } from "../../Components/InventoryInShop/Request/Request";
import { Receive } from "../../Components/InventoryInShop/Receive/Receive";
import { GET_COUNT_TRANSFER_IN_BY_SHOP } from "../../Schema/InventoryInShop";
import { Transfer } from "../../Components/InventoryInShop/Transfer/Transfer";
import { AdjustIn } from "../../Components/InventoryInShop/AdjustIn/AdjustIn";
import { Inventory } from "../../Components/InventoryInShop/Inventory/Inventory";
import { AdjustOut } from "../../Components/InventoryInShop/AdjustOut/AdjustOut";


export const InventoryInShop = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions); 
  }, []);
  const [tabValue, setTabValue] = useState("inventory");


  const { data } = useQuery(GET_COUNT_TRANSFER_IN_BY_SHOP, {
    variables: {
      shopId: null
    }
  });

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems={`center`}>
          <Typography className={language === "kh" ? "page-title-kh" : "page-title"}>
            {t(`page-inventory`)}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container spacing={3} className="inventory-btn-container">
          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("inventory")}
              className={tabValue === "inventory" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_inventory')}
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("request")}
              className={tabValue === "request" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('btn-request-to-warehouse')}
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Badge color="warning" badgeContent={data?.getCountTransferInByShopBadge || 0} sx={{ width: "100% !important" }} >
              <Button
                fullWidth
                onClick={() => setTabValue("receive")}
                className={tabValue === "receive" ?
                  language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                  language === "en" ? "inventory-btn" : "inventory-btn-kh"
                }
              >
                {t('btn-receive-product-from-warehouse')}
              </Button>
            </Badge>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("adjustin")}
              className={tabValue === "adjustin" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_adjust_in_shop')}
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("adjustout")}
              className={tabValue === "adjustout" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"
              }
            >
              {t('thead_adjust_out_from_out')}
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("transfer")}
              className={tabValue === "transfer" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('btn-transfer-goods-to-warehouse')}
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1.71}>
            <Button
              fullWidth
              onClick={() => setTabValue("return")}
              className={tabValue === "return" ?
                language === "en" ? "inventory-btn-active" : "inventory-btn-active-kh" :
                language === "en" ? "inventory-btn" : "inventory-btn-kh"}
            >
              {t('thead_return_the_product')}
            </Button>
          </Grid>

        </Grid>
      </Box>

      {tabValue === "return" && <Refund />} 
      {tabValue === "request" && <Request />}
      {tabValue === "receive" && <Receive />}
      {tabValue === "transfer" && <Transfer />}
      {tabValue === "adjustin" && <AdjustIn />}
      {tabValue === "adjustout" && <AdjustOut />}
      {tabValue === "inventory" && <Inventory />}

    </div>
  );
};
