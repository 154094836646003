import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  TextField,
  IconButton,
  colors,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";

import "./login.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { string, ref } from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
// Src
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Include/Function/Translate";
import logo from "../Assets/it-logo.png";
import CamFlag from "../Assets/Cambodia.png";
import EngFlag from "../Assets/United_Kingdom.png";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import reset from "../Assets/resetpassword.png";
import { RESET_PASSWORD } from "../Schema/Users";

const ResetPassword = () => {
  // ===================== Change Language ==========================
  const { language, changeLanguage, setAlert, dispatch } =
    useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [iconReload, setIconReload] = useState(false);
  const navigate = useNavigate();

  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  //Get email by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  const [resetPasswordWhenForgot] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ resetPasswordWhenForgot }) => {
      setIconReload(false);
      if (resetPasswordWhenForgot?.isSuccess) {
        //===================== remove token ===========================
        window.localStorage.clear();
        navigate(`/login`);
        setAlert(true, "success", resetPasswordWhenForgot?.message);
      } else {
        setAlert(true, "error", resetPasswordWhenForgot?.message);
      }
    },
    onError: (error) => {
      setIconReload(false);
      console.log("error::::", error);
      setAlert(true, "error", error?.message);
    },
  });

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

  const resetPassSchema = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string().required(t("require")).matches(passwordRules, {
      message: "Please enter a stronger password!",
    }),
    confirmPassword: Yup.string()
      .required("Please retype your password!")
      .oneOf([Yup.ref("password")], "Your passwords do not match!"),
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: resetPassSchema,
    onSubmit: async (values) => {
      setIconReload(true);
      resetPasswordWhenForgot({
        variables: {
          email: values?.email,
          password: values?.password,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      className="login-container-page"
    >
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container className="box-container">
              <Grid item xs={6} alignSelf="center">
                <Stack direction="row" justifyContent="center" width="100%">
                  <Stack direction="column" spacing={2}>
                    <img width="100%" src={reset} alt="reset" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ padding: "40px 10px 30px 20px" }}>
                <Stack direction="column" spacing={3}>
                  <Stack direction={`row`} justifyContent={`center`}>
                    <img src={logo} alt="logo" className="img" style={{borderRadius: "50%"}}/>
                  </Stack>
                  <Stack
                    direction={`column`}
                    justifyContent={`center`}
                    // alignItems={`center`}
                    spacing={0.5}
                  >
                    <Typography
                      className={
                        language === "en" ? "noted-txt" : "noted-txt-kh"
                      }
                    >
                      {t("input_new_pwd")}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    width="100%"
                    className="box-login"
                    spacing={2}
                  >
                    <Stack direction="column">
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("label_pwd")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        className="input-login"
                        placeholder={t("label_pwd")}
                        {...getFieldProps("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        type={show ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={handleClick}
                            >
                              {show ? (
                                <VisibilityIcon
                                  className="viewpw-icon"
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  className="viewpw-icon"
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction="column">
                      <Typography
                        className={
                          language === "en" ? "label-login" : "label-login-kh"
                        }
                      >
                        {t("label_cfpwd")}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder={t("label_cfpwd")}
                        type={show ? "text" : "password"}
                        {...getFieldProps("confirmPassword")}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Stack>
                    <Link to="/forgotpassword">
                      <Typography
                        className={
                          language === "en" ? "forgot-pwd" : "forgot-pwd-kh"
                        }
                      >
                        {t("back_to_email")}
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                      <Button
                        onClick={handleSubmit}
                        className={
                          language === "en" ? "btn-sign-in" : "btn-sign-in-kh"
                        }
                        type="submit"
                        endIcon={
                          iconReload ? (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size="20px"
                            />
                          ) : null
                        }
                      >
                        {t("btn-submit")}
                      </Button>
                    </Stack>
                  </Stack>
                  <Typography className="footer-copy-right">
                    Copyright © {moment().format("YYYY")} Go Global IT. All
                    rights reserved.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
      <Stack
        sx={{
          right: "20px",
          top: "10px",
          position: "absolute",
        }}
      >
        <Stack direction="column" justifyContent="center">
          {language === "en" ? (
            <Tooltip title="Change to Khmer" placement="left">
              <IconButton onClick={() => changeLanguage("kh")}>
                <Avatar src={EngFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change to English" placement="left">
              <IconButton onClick={() => changeLanguage("en")}>
                <Avatar src={CamFlag} sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
