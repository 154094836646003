import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import "./productinvoice.scss";
import DialogPayment from "../Payment/DialogPayment";
import Accordion from "@mui/material/Accordion";
import { AdjustProduct } from "../AdjustProduct";
import AccordionDiscount from "../AccordionDiscount";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UPDATE_INVOICE } from "../../../Schema/Invoice";
import ItemProductSelected from "../ItemProductSelected";
import { AuthContext } from "../../../Context/AuthContext";
import AccordionDetails from "@mui/material/AccordionDetails";
import FlipOutlinedIcon from "@mui/icons-material/FlipOutlined";
import {
  GET_CHECK_IN_MEMBER_BY_CARD,
  GET_EXCHANGE_RATE,
} from "../../../Schema/ExchangeRate";
import { translateLauguage } from "../../../Include/Function/Translate";
import {
  currencyFormat,
  currencyFormatKh,
} from "../../../Include/Function/DynamicFn";
import {
  SelectCheckInMember,
  SelectCustomer,
} from "../../../Include/Function/DynamicSelect";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";

export default function ProductInvoiceForUpdateSuspend({
  subTotal,
  totalAomunt,
  handleClose,
  totalDiscount,
  productBarcode,
  itemProductToPay,
  setProductBarcode,
  setItemProductToPay,
  selectedCustomerVal,
  handleChangeDiscount,
  selectedCardNumberVal,
  setSelectedCustomerVal,
  handleDeleteItemInCard,
  loadingItemProductToPay,
  setSelectedCardNumberVal,
  handleUpdateInputQtyItem,
  handleMinusQtyItemFromCard,
  handleIncreaseQtyItemInCard,
  handleDeleteAllItemsFromCard,
  handleCloseDialogSuspend,
}) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [adjustProductData, setAdjustProductData] = useState("");

  //========== Dialog Payment =============
  const [openPayment, setOpenPayment] = useState(false);
  const handleOpenPayment = () => setOpenPayment(true);
  const handleClosePayment = () => setOpenPayment(false);

  const [openAdjust, setOpenAdjust] = useState(false);
  const handleOpenAdjust = () => setOpenAdjust(true);
  const handleCloseAdjust = () => setOpenAdjust(false);

  //========= Get Shift Id ==================
  const invoiceId = JSON.parse(window.localStorage.getItem("CurrentInvoiceId"));
  //========= Get Shift Id ==================
  const shiftId = window.localStorage?.getItem("shiftId");
  const [getCheckInMemberIsCheckInByCard, { data }] = useLazyQuery(
    GET_CHECK_IN_MEMBER_BY_CARD
  );

  useEffect(() => {
    getCheckInMemberIsCheckInByCard({
      variables: {
        cardId: selectedCardNumberVal?.id,
      },
    });
    setSelectedCustomerVal({ id: "", title: "" });
  }, [selectedCardNumberVal]);

  //============ Get Exchange Rate =================
  const [exchangeRate, setExchangeRate] = useState(0);
  useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangeRate }) => {
      setExchangeRate(getExchangeRate?.exchangeRate);
    },
  });

  useEffect(() => {
    setSelectedItem("");
  }, [itemProductToPay?.length]);

  // ======================== Create =========================
  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    onCompleted: ({ updateInvoice }) => {
      setLoading(false);
      if (updateInvoice?.isSuccess === true) {
        //============= Set Value to Local Storage =================
        if (submitType === "Suspend") {
          setAlert(true, "success", updateInvoice?.message);
        }
        if (submitType === "PayNow") {
          handleOpenPayment();
          setRequired(false);
        }
      } else {
        setAlert(true, "error", updateInvoice?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error.message);
    },
  });

  //============ Submit Invoice ================
  const handleSubmit = () => {
    const products = itemProductToPay?.map((e) => ({
      qty: parseFloat(e?.qty),
      total: parseFloat(e?.qty) * parseFloat(e?.price),
      price: parseFloat(e?.price),
      productId: e?.productId,
      discount: Boolean(e?.discount?.amount) ? e?.discount : null,
    }));

    if (selectedCustomerVal?.id || selectedCardNumberVal?.id) {
      setLoading(true);
      updateInvoice({
        variables: {
          id: invoiceId,
          input: {
            shiftId: shiftId,
            products: products,
            subTotal: subTotal,
            total: subTotal - totalDiscount,
            customerId: selectedCardNumberVal?.id
              ? selectedCardNumberVal?.customerId
              : selectedCustomerVal?.id || null,
            checkInMemberId: selectedCardNumberVal?.id || null,
            remark: null,
            tableId: null,
            allDiscount: null,
          },
        },
      });
    } else setRequired(true);
  };

  useEffect(() => {
    if (itemProductToPay?.length === 0) setRequired(false);
  }, [itemProductToPay]);

  return (
    <>
      <div className="invoice-container">
        <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ padding: "0px 20px" }}
          >
            <Stack justifyContent="center">
              <TextField
                autoFocus={true}
                autoComplete="off"
                onFocus={(e) => e.target.select()}
                value={productBarcode}
                onChange={(e) => {
                  setProductBarcode(e?.target?.value);
                }}
                size="small"
                placeholder={t("label-scan")}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    fontFamily: "Siemreap",
                  },
                  "& .MuiFormLabel-root": {
                    fontFamily: "Siemreap",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ mr: 1, color: "black" }}
                    >
                      <FlipOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Box flexGrow={1} />

            <Button
              className="btn-clear"
              onClick={() => {
                handleDeleteAllItemsFromCard();
                setRequired(false);
              }}
            >
              {t("btn-clear")}
            </Button>
          </Stack>

          <Stack spacing={2} direction="row" sx={{ padding: "0px 20px" }}>
            <Stack sx={{ width: "50%" }}>
              <SelectCustomer
                title={"Suspend"}
                required={required}
                label={t("choose_customer")}
                selectedVal={selectedCustomerVal}
                disabled={selectedCardNumberVal?.id}
                setSelectedVal={setSelectedCustomerVal}
              />
            </Stack>

            <Stack sx={{ width: "50%" }}>
              <SelectCheckInMember
                disabled={selectedCustomerVal?.id}
                selectedVal={selectedCardNumberVal}
                setSelectedVal={setSelectedCardNumberVal}
              />
            </Stack>
          </Stack>

          {/* ======================== Dialog Payment Invoice  ===============*/}
          <DialogPayment
            shiftId={shiftId}
            open={openPayment}
            handleClose={handleClosePayment}
            handleCloseDialogSuspend={handleCloseDialogSuspend}
          />

          {/* ============= Dialog Adjust Product ===============*/}
          <AdjustProduct
            open={openAdjust}
            handleClose={handleCloseAdjust}
            itemProductToPay={itemProductToPay}
            adjustProductData={adjustProductData}
            setItemProductToPay={setItemProductToPay}
            setAdjustProductData={setAdjustProductData}
          />

          <Stack direction="column" className="item-product-container">
            {!data?.getCheckInMemberIsCheckInByCard &&
              selectedCardNumberVal?.id && (
                <Stack
                  sx={{
                    fontSize: 15,
                    color: "red",
                    borderRadius: 1,
                    bgcolor: "#FFEDED",
                    textAlign: "center",
                    padding: "8px 14px",
                    border: "1px solid red",
                    margin: "0px 20px 10px 20px",
                  }}
                >
                  {t("thead_card_number")} {selectedCardNumberVal?.title}{" "}
                  {t("thead_not_checked_in_yet")}
                </Stack>
              )}
            {loadingItemProductToPay ? (
              <LoadingPage />
            ) : itemProductToPay?.length > 0 ? (
              itemProductToPay?.map((item, index) => {
                return (
                  <Box key={index} className="stack-item">
                    <Accordion className="accordion-container">
                      <ItemProductSelected
                        item={item}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        handleOpenAdjust={handleOpenAdjust}
                        setAdjustProductData={setAdjustProductData}
                        handleDeleteItemInCard={handleDeleteItemInCard}
                        handleUpdateInputQtyItem={handleUpdateInputQtyItem}
                        handleMinusQtyItemFromCard={handleMinusQtyItemFromCard}
                        handleIncreaseQtyItemInCard={
                          handleIncreaseQtyItemInCard
                        }
                      />
                      <AccordionDetails sx={{ padding: "10px", pb: 1 }}>
                        <AccordionDiscount
                          handleChangeDiscount={handleChangeDiscount}
                          item={item}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })
            ) : (
              <Stack
                height={400}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className="total-title" sx={{ color: "gray" }}>
                  {t("label_please_select_product")}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Box flexGrow={1} />
          <Stack direction="column" spacing={1} className="footer-invoice">
            <Stack direction="column" spacing={1} className="sub-total-invoice">
              <Grid container rowSpacing={1}>
                <Grid item xs={4}>
                  <Typography className="total-title">Discount</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography className="total-title">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    className="total-name"
                    sx={{ textAlign: "right" }}
                  >
                    {currencyFormat(totalDiscount || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="total-amount-title">
                    Total ({itemProductToPay?.length} Item)
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography className="total-amount-title">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    className="total-amount-value"
                    sx={{ textAlign: "right" }}
                  >
                    {currencyFormat(totalAomunt || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className="total-amount-title"></Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    className="total-amount-value"
                    sx={{ textAlign: "right" }}
                  >
                    {currencyFormatKh(totalAomunt * exchangeRate || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} mt={1}>
              <Button
                disabled={
                  itemProductToPay?.length === 0 ? true : loading ? true : false
                }
                className={language === "en" ? "btn-suspend" : "btn-suspend-kh"}
                onClick={() => {
                  setSubmitType("Suspend");
                  handleSubmit();
                }}
              >
                {t("btn-suspended-bill")}
              </Button>
              <Button
                disabled={
                  itemProductToPay?.length === 0 ? true : loading ? true : false
                }
                className={language === "en" ? "btn-pay" : "btn-pay-kh"}
                onClick={() => {
                  setSubmitType("PayNow");
                  handleSubmit();
                }}
              >
                {loading ? t("loading") : t("btn-pay-now")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
}
