import React, { useContext, useEffect } from "react";
import "../../../Style/actionStyle.scss";
import { useQuery } from "@apollo/client";
import { Stack, TextField } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { GET_REMAIN_MAIN_PRODUCT_BY_ID_AND_BY_WAREHOUSE } from "../../../Schema/InventoryInWarehouse";
export default function GetRemainMainProductByIdAndByWarehouse({ mainData, subData, required, handleUpdateSubItem, }) {

    // ===================== Change Language ==========================
    const { language, setAlert } = useContext(AuthContext);
    const { t } = translateLauguage(language);




    const { refetch } = useQuery(GET_REMAIN_MAIN_PRODUCT_BY_ID_AND_BY_WAREHOUSE, {
        variables: {
            warehouseId: subData?.warehouseId?.id,
            mainProductId: mainData?.mainProductId?.id
        },
        onCompleted: ({ getRemainMainProductByIdAndByWarehouse }) => {
            handleUpdateSubItem(mainData?.key, subData?.key, getRemainMainProductByIdAndByWarehouse, "remainInStock")
        }
    });

    useEffect(() => {
        refetch();
    }, [mainData, subData,]);




    return <Stack sx={{ width: "100%" }} direction="row" alignItems="center" spacing={1.5} >
        <TextField
            fullWidth
            size="small"
            type="number"
            sx={{ width: "50%" }}
            className="text-field"
            placeholder={t('thead_qty')}
            value={subData?.remainInStock || 0}
            InputProps={{ endAdornment: mainData?.mainProductId?.unit, readOnly: true }}
        />
        <TextField
            fullWidth
            size="small"
            type="number"
            sx={{ width: "50%" }}
            className="text-field"
            placeholder={t('thead_qty')}
            value={parseFloat(subData?.qty)}
            error={!subData?.qty && required}
            InputProps={{ endAdornment: mainData?.mainProductId?.unit }}
            onChange={({ target }) => handleUpdateSubItem(mainData?.key, subData?.key, parseFloat(target.value < 0 ? 0 : target.value), "qty",)}
        />

    </Stack>



}
