import React, { useContext } from "react";
import "../../Style/pageStyle.scss";
import { Stack, Box, Grid } from "@mui/material";
import { AuthContext } from "../../Context/AuthContext";
import useGetProductForScan from "./Hook/getProductForScan";
import useItemProductToPay from "./Hook/useItemProductToPay";
import useGetProductPagination from "./Hook/getProductPagination";
import ProductInvoice from "../../Components/Pos/ProductInvoice";
import { translateLauguage } from "../../Include/Function/Translate";
import ProductForSale from "../../Components/Pos/ProductPOS/ProductPOS";
import AllCategory from "../../Components/Pos/AllCategory/AllCategory";

export const PagePOS = () => {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const {
    subTotal,
    totalAomunt,
    totalDiscount,
    itemProductToPay,
    setItemProductToPay,
    handleChangeDiscount,
    handleDeleteItemInCard,
    handleSelectProductItem,
    handleUpdateInputQtyItem,
    handleMinusQtyItemFromCard,
    handleIncreaseQtyItemInCard,
    handleDeleteAllItemsFromCard,
  } = useItemProductToPay();

  const {
    page,
    limit,
    shopId,
    setPage,
    tableData,
    categoryId,
    handleLimit,
    handleSearch,
    setCategoryId,
    paginationData,
    loadingProductForSale,
  } = useGetProductPagination();

  const { productBarcode, setProductBarcode } = useGetProductForScan(
    shopId,
    handleSelectProductItem
  );
  return (
    <div className="page-container">
      <Box>
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={7}>
            <Stack direction="column" spacing={3}>
              <AllCategory
                setCategoryId={setCategoryId}
                categoryId={categoryId}
              />
              <ProductForSale
                page={page}
                limit={limit}
                setPage={setPage}
                tableData={tableData}
                handleLimit={handleLimit}
                handleSearch={handleSearch}
                paginationData={paginationData}
                loadingProductForSale={loadingProductForSale}
                handleSelectProductItem={handleSelectProductItem}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={5}>
            <ProductInvoice
              subTotal={subTotal}
              totalAomunt={totalAomunt}
              totalDiscount={totalDiscount}
              productBarcode={productBarcode}
              itemProductToPay={itemProductToPay}
              setProductBarcode={setProductBarcode}
              setItemProductToPay={setItemProductToPay}
              handleChangeDiscount={handleChangeDiscount}
              handleDeleteItemInCard={handleDeleteItemInCard}
              handleUpdateInputQtyItem={handleUpdateInputQtyItem}
              handleMinusQtyItemFromCard={handleMinusQtyItemFromCard}
              handleIncreaseQtyItemInCard={handleIncreaseQtyItemInCard}
              handleDeleteAllItemsFromCard={handleDeleteAllItemsFromCard}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
