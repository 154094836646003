import moment from "moment";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_INVENTORY_REPORT } from "../../Schema/Report";

const useGetInventoryReport = ({
    selectedShift,
    startDate,
    viewType,
    endDate,
}) => {

    const [tableDataInventory, setTableDataInventory] = useState([]);
    const { refetch } = useQuery(GET_INVENTORY_REPORT, {
        variables: {
            isHaveStock: true,
            viewType: viewType,
            shiftId: selectedShift?.id || null, 
            endDate: viewType === "Shift" ? "" : moment(endDate).format("YYYY-MM-DD"),
            startDate: viewType === "Shift" ? "" : moment(startDate).format("YYYY-MM-DD"),
        },
        skip: true, // Prevent automatic query execution
        onCompleted: ({ getInventoryReport }) => {
            setTableDataInventory(getInventoryReport || []);
        },
        onError: ({ message }) => {
            console.error("getInventoryReport Error fetching data:", message);
        },
    });

    // Refetch data whenever `startDate`, `endDate`, or `viewType` changes
    useEffect(() => {
        refetch();
    }, [selectedShift, viewType, startDate, endDate,]);

    return {
        tableDataInventory,
    };
};

export default useGetInventoryReport;
