import React, { useContext } from "react";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//Icons

//Srcs
import "../../Style/actionStyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";

export default function SocailContactList({
  socailData,
  setSocailData,
  required,
}) {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const newData = {
    link: null,
    name: null,
    isShow: true,
    platform: "Facebook",
    key: Math.random() + Date.now(),
  };
  const handleAddData = () => {
    setSocailData([...socailData, newData]);
  };
  const handleRemoveData = (key) => {
    const data = socailData.filter((e) => e?.key !== key);
    setSocailData([...data]);
  };
  const handleChangeData = (key, value, fieldName) => {
    const data = socailData?.map((e) => {
      if (key === e.key && fieldName === "link") e.link = value;
      if (key === e.key && fieldName === "name") e.name = value;
      if (key === e.key && fieldName === "platform") e.platform = value;
      return e;
    });
    setSocailData([...data]);
  };

  return (
    <Stack direction="column" alignItems="center" spacing={1.5}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        bgcolor={"#F0F0F0"}
        sx={{
          width: "100%",
          borderRadius: 1,
          paddingLeft: 1.5,
          bgcolor: "#F0F0F0",
        }}
      >
        <Typography sx={{ fontWeight: "bold", width: "25%" }}>
          Platform
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "30%" }}>
          {t("label_name")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "45%" }}>Link</Typography>
        <IconButton onClick={handleAddData}>
          <AddBoxIcon sx={{ color: "green" }} />
        </IconButton>
      </Stack>
      {socailData.map((e) => (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Stack sx={{ width: "25%" }}>
            <FormControl size="small" fullWidth>
              <Select
                value={e?.platform}
                onChange={({ target }) =>
                  handleChangeData(e.key, target?.value, "platform")
                }
              >
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="YouTube">YouTube</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                <MenuItem value="TikTok">TikTok</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ width: "30%" }}>
            <TextField
              fullWidth
              onChange={({ target }) =>
                handleChangeData(e.key, target?.value, "name")
              }
              size="small"
              value={e?.name}
              placeholder={t("label_name")}
              className="text-field"
            />
          </Stack>
          <Stack sx={{ width: "45%" }}>
            <TextField
              fullWidth
              value={e?.link}
              size="small"
              onChange={({ target }) =>
                handleChangeData(e.key, target?.value, "link")
              }
              placeholder="URL / LINK"
              className="text-field"
            />
          </Stack>
          <IconButton onClick={() => handleRemoveData(e.key)}>
            <IndeterminateCheckBoxIcon sx={{ color: "red" }} />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
}
