import React, { useContext, useState } from "react";
import {
  Stack,
  Button,
  Typography,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
//Icons
import CircularProgress from "@mui/material/CircularProgress";
//Srcs
import "../../src/Style/dialogStyle.scss";
import { AuthContext } from "../Context/AuthContext"; 
import logout from "../Assets/log-out.png";
import { translateLauguage } from "../Include/Function/Translate";

export const LogoutDialog = ({ open, handleClose }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [iconReload, setIconReload] = useState(false);

  //======================== function logout ======================
  const handleLogout = async () => {
    setIconReload(true);
    setTimeout(() => {
      handleClose();
      setIconReload(false);
      //===================== remove token ===========================
      window.localStorage.clear();
      window.location.reload("/login");
    }, 800);
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="space-between"
          className="logout-container"
        >
          <Stack direction={`row`} justifyContent="center">
            <img src={logout} alt="logout" className="img-log" />
          </Stack>
          <Stack direction={`row`} justifyContent="center">
            <Stack
              direction={`column`}
              justifyContent="center"
              alignItems={`center`}
              spacing={1}
            >
              <Typography
                className={
                  language === "en" ? "question-txt" : "question-txt-kh"
                }
              >
                {t("log_out_qa")}
                {/* Are you logging out? */}
              </Typography>
              <Typography
                className={language === "en" ? "desc-txt" : "desc-txt-kh"}
              >
                {/* You can always log back in at anytime. */}
                {t("log_out_sub")}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            width="400px"
            marginTop="10px"
            spacing={1}
          >
            <Stack direction="row" justifyContent="center" width="200px">
              <Button
                onClick={handleClose}
                autoFocus
                fullWidth
                sx={{
                  backgroundColor: "#f75257",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#f75257",
                  },
                }}
              >
                <Typography className="log-out-txt">បោះបង់</Typography>
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="center" width="200px">
              <Button
                onClick={handleLogout}
                autoFocus
                fullWidth
                loading={iconReload}
                sx={{
                  backgroundColor: "#0f81c2",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#0f81c2",
                  },
                  // fontFamily: "Kantumruy Pro",
                }}
                endIcon={
                  iconReload ? (
                    <CircularProgress sx={{ color: "#fff" }} size="20px" />
                  ) : null
                }
              >
                <Typography className="log-out-txt">ចាកចេញ</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
