import { gql } from "@apollo/client";

export const GET_PRODUCT_WITH_PAGINATION = gql`
  query GetProductPagination(
    $page: Int
    $limit: Int
    $shopId: ID
    $categoryId: ID
    $keyword: String
    $pagination: Boolean
  ) {
    getProductPagination(
      page: $page
      limit: $limit
      shopId: $shopId
      categoryId: $categoryId
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        barcode
        price
        imageSrc
        salePrice
        mainProductId {
          _id
          mainProductEnName
          mainProductKhName
          minimumUnitId {
            unitEnName
            unitKhName
            _id
          }
        }
        productFor
        qtyInMinimumUnit
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
