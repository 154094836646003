import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
import SelectReceiveProducts from "./SelectReceiveProducts";
import { translateLauguage } from "../../../Include/Function/Translate";
import { RECEIVE_TRANSFER_FROM_POS } from "../../../Schema/InventoryInWarehouse";


export const ReceiveProduct = ({ open, handleClose, setRefetch, hideBackdrop, editData }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [transferProductsData, setTransferProductsData] = useState([]);

  //======================== Create User ========================= 
  const [receiveTransferFromPos] = useMutation(RECEIVE_TRANSFER_FROM_POS, {
    onCompleted: ({ receiveTransferFromPos }) => {

      setLoading(false);

      if (receiveTransferFromPos?.isSuccess === true) {
        setAlert(true, "success", receiveTransferFromPos?.message);
        handleClose();
        setRefetch();
        resetForm();
      } else {
        setAlert(true, "error", receiveTransferFromPos?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error?.message);
    },
  });


  //fromik
  const CheckValidation = Yup.object().shape({
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (value) => {
      const requiredProduct = transferProductsData?.map((e) => e?.warehouseIds?.map((i) => i?.warehouseId?.id && parseFloat(i?.qty) ? true : false)?.filter((e) => !e).length === 0 ? true : false)?.filter((e) => !e);

      if (requiredProduct?.length > 0) {
        setRequired(true);
        return;
      }

      const transferProducts = transferProductsData.map((e) => ({
        transferQty: e?.transferQty,
        mainProductId: e?.mainProductId?.id,
        receiveQty: e?.warehouseIds?.reduce((a, b) => a + parseFloat(b?.qty || 0), 0),
        warehouseIds: e?.warehouseIds?.map((i) => ({
          qty: parseFloat(i?.qty || 0),
          warehouseId: i?.warehouseId?.id,
          remainInStock: i?.remainInStock,
        })).filter((f) => f?.qty > 0),
      }));

      setLoading(true);
      receiveTransferFromPos({
        variables: {
          id: editData?._id,
          input: {
            receiveBy: {
              remark: value?.remark,
            },
            transferProducts: transferProducts,
          }
        }
      });
    },
  });

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    resetForm();
    setRequired(false);
  }, [open]);



  useEffect(() => {
    const newDataItem = editData?.transferProducts.map((e) => ({
      mainProductId: {
        id: e?.mainProductId?._id,
        title: language === "en" ? e?.mainProductId?.mainProductEnName : e?.mainProductId?.mainProductKhName,
        unit: language === "en" ? e?.mainProductId?.minimumUnitId?.unitEnName : e?.mainProductId?.minimumUnitId?.unitKhName,
      },
      receiveQty: e?.receiveQty,
      transferQty: e?.transferQty,
      key: Math.random() + Date.now(),
      warehouseIds: [
        {
          qty: null,
          warehouseId: null,
          remainInStock: null,
          key: Math.random() + Date.now(),
        }
      ],
    }));

    setTransferProductsData(newDataItem);
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xl" hideBackdrop={hideBackdrop}>
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "en" ? "dialog-title" : "dialog-title-kh"}>
              {t("dialog_title_receive_product")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <SelectReceiveProducts
                  required={required}
                  transferProductsData={transferProductsData}
                  setTransferProductsData={setTransferProductsData}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "en" ? "field-title" : "field-title-kh"}>
                  {t("remark")}
                </Typography>
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  size="small"
                  className="text-field"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ?
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth  >
                {t("loading")}
              </Button>)
              :
              (<Button className={language === "kh" ? "btn-create-kh" : "btn-create"} fullWidth onClick={handleSubmit}>
                {t("btn-receive")}
              </Button>)
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
