import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { BiMinusCircle } from "react-icons/bi";
import "../../../Style/dialogStyle.scss";
import { useMutation } from "@apollo/client";
import deletepic from "../../../Assets/delete.png";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import { DELETE_TICKET_GROUP } from "../../../Schema/TicketGroup";

export const DeleteTicketGroup = ({ open, handleClose, editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [confirmVal, setConfirmVal] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [deleteTicketGroup] = useMutation(DELETE_TICKET_GROUP, {
    onCompleted: ({ deleteTicketGroup }) => {
      console.log("deleteTicketGroup::", deleteTicketGroup);
      setLoading(false);
      if (deleteTicketGroup?.isSuccess === true) {
        setAlert(true, "success", deleteTicketGroup?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deleteTicketGroup?.message);
      }
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
  const handleDelete = () => {
    setLoading(true);
    deleteTicketGroup({
      variables: {
        id: editData,
      },
    });
  };

  useEffect(() => {
    setConfirmVal("");
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("dialog_title_delete_ticket")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1} className="delete-dialog-container">
          <Grid item xs={5}>
            <img src={deletepic} alt="deletepic" className="img-delete" />
          </Grid>
          <Grid item xs={7}>
            <Stack direction={`column`} spacing={2}>
              <Stack>
                <Typography
                  className={language === "en" ? "title-text" : "title-text-kh"}
                >
                  {t("del_qa")}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography
                  className={
                    language === "kh" ? "sub-title-text-kh" : "sub-title-text"
                  }
                >
                  {t("please-input")}
                </Typography>
                <Typography className="body-text">TICKET</Typography>
                <Typography
                  className={
                    language === "kh" ? "sub-title-text-kh" : "sub-title-text"
                  }
                >
                  {t("to-delete")}
                </Typography>
              </Stack>
              <Stack>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  onChange={(e) => setConfirmVal(e.target.value)}
                />
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                //    width="360px"
                fullWidth
              >
                {confirmVal === "TICKET" ? (
                  <Button
                    sx={{
                      ":hover": { backgroundColor: "red", border: "none" },
                    }}
                    className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                    fullWidth
                    size="small"
                    onClick={handleDelete}
                  >
                    {loading ? t("loading") : t("btn-delete-now")}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    className={
                      language === "kh" ? "btn-deleteKh" : "btn-deleteEn"
                    }
                    fullWidth
                  >
                    {t("btn-delete")}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
