import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Grid,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    TableContainer,
} from "@mui/material";
import "../../../Style/pageStyle.scss";
import { useQuery } from "@apollo/client";
import EmptyData from "../../../Include/EmptyData/EmptyData";
import InventoryAction from "./InventoryAction";
import LoadingPage from "../../../Include/LoadingPage/LoadingPage";
import { AuthContext } from "../../../Context/AuthContext";
import FooterPagination from "../../../Include/FooterPagination/FooterPagination";
import { translateLauguage } from "../../../Include/Function/Translate";
import { SelectCategory, SelectMainProduct, SelectWarehouse } from "../../../Include/Function/DynamicSelect";
import { GET_MAIN_STOCK_PAFINATION } from "../../../Schema/InventoryInWarehouse";

export const Inventory = () => {
    // ===================== Change Language ==========================
    const { language } = useContext(AuthContext);
    const { t } = translateLauguage(language);
    // ======================= Resize width Screen ======================
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth - 50);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState();
    const [paginationData, setPaginationData] = useState();
    const [selectedCategoryVal, setSelectedCategoryVal] = useState({ id: "", title: "" });
    const [selectedWarehouseVal, setSelectedWarehouseVal] = useState({ id: "", title: "" });
    const [selectedMainProductVal, setSelectedMainProductVal] = useState({ id: "", title: "" });

    const { refetch } = useQuery(GET_MAIN_STOCK_PAFINATION, {
        variables: {
            page: page,
            limit: limit,
            pagination: true,
            isHaveStock: null,
            categoryId: selectedCategoryVal?.id || null,
            warehouseId: selectedWarehouseVal?.id || null,
            mainProductId: selectedMainProductVal?.id || null,
        },
        onCompleted: ({ getMainStockPagination }) => {
            setLoading(false);
            setTableData(getMainStockPagination?.data);
            setPaginationData(getMainStockPagination?.paginator);
        },
        onError: (err) => {
            console.log("Error::", err?.message);
        },
    });

    const handleLimit = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    return (
        <>
            <Grid container spacing={3}>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}>
                        {t("thead_warehouse")}
                    </Typography>

                    <SelectWarehouse
                        className={"search-field"}
                        selectedVal={selectedWarehouseVal}
                        setSelectedVal={setSelectedWarehouseVal}
                    />

                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}>
                        {t("thead_product")}
                    </Typography>

                    <SelectMainProduct
                        className={"search-field"}
                        selectedVal={selectedMainProductVal}
                        setSelectedVal={setSelectedMainProductVal}
                    />

                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                    <Typography className={language === "kh" ? "header-text-kh" : "header-text"}>
                        {t('thead_category')}
                    </Typography>
                    <SelectCategory
                        className={"search-field"}
                        selectedVal={selectedCategoryVal}
                        setSelectedVal={setSelectedCategoryVal}
                    />
                </Grid>

            </Grid >

            <Box className="body-container">
                <TableContainer sx={{ maxWidth: `${width}px` }}>
                    <Table className="table" aria-label="simple table">
                        <TableHead className="header-row ">
                            <TableRow>
                                <TableCell className={language === "en" ? "header-title-start" : "header-title-start-kh"} width="10%"> {t("thead_no")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_product")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_unit")} </TableCell>
                                <TableCell className={language === "en" ? "header-title" : "header-title-kh"} width="16%"> {t("thead_remaining")}</TableCell>
                                <TableCell className={language === "en" ? "header-title-end" : "header-title-end-kh"} width="10%"  ></TableCell>
                            </TableRow>
                        </TableHead>

                        {loading ? (
                            <TableBody className="table-body">
                                <TableRow className="body-row">
                                    <TableCell colSpan={7} className="body-cell">
                                        <LoadingPage />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <>
                                {tableData?.length === 0 ? (
                                    <TableBody className="table-body">
                                        <TableRow className="body-row">
                                            <TableCell colSpan={7} className="body-cell">
                                                <EmptyData />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <>
                                        <TableBody className="table-body">
                                            {tableData?.map((row, index) => {
                                                return (
                                                    <TableRow className="body-row" key={index} >
                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-left" : ""} body-cell-start` : `${row?.isVoid ? "void-left" : ""} body-cell-start-kh`}>
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.mainProductEnName : row?.mainProductKhName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {language === "en" ? row?.minimumUnitId?.unitEnName : row?.minimumUnitId?.unitKhName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? "body-cell" : "body-cell-kh"}>
                                                            {row?.remain} {language === "en" ? row?.minimumUnitId?.unitEnName : row?.minimumUnitId?.unitKhName}
                                                        </TableCell>

                                                        <TableCell className={language === "en" ? `${row?.isVoid ? "void-right" : ""} body-cell-end` : `${row?.isVoid ? "void-right" : ""} body-cell-end-kh`} align="right">
                                                            <InventoryAction editData={row} setRefetch={refetch} disabled={row?.status === "Received"} />
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
                {/* ==================== Pagination  ============================= */}
                <FooterPagination
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    handleLimit={handleLimit}
                    totalDocs={paginationData?.totalDocs}
                    totalPages={paginationData?.totalPages}
                />
            </Box>
        </>
    );
};
