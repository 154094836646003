import { useLazyQuery } from "@apollo/client";
import useGetExchangeRate from "../../../../Hooks/POS/useGetExchangeRate";
import { GET_INVOICE_BY_ID } from "../../../../Schema/Invoice";
import { useEffect, useState } from "react";

const useAmountToPaidMuiltipleInvoice = ({
    paymentType,
    selectedBankVal,
    changeAmountUSD,
    changeAmountKHR,
    receiveAmountKHR,
    receiveAmountUSD,
    muiltipleInvoiceId,
    paymentByInvoiceData,
}) => {
    // Fetch exchange rate
    const { exchangeRate } = useGetExchangeRate();
    const [invoiceById, setInvoiceById] = useState([]);
    const shiftId = window.localStorage?.getItem("shiftId");

    // Get invoice data by ID
    const [getInvoiceById, { refetch }] = useLazyQuery(GET_INVOICE_BY_ID, {
        onCompleted: ({ getInvoiceById }) => {
            if (getInvoiceById) setInvoiceById(getInvoiceById);
        },
    });

    useEffect(() => {
        if (muiltipleInvoiceId) {
            getInvoiceById({
                variables: {
                    id: muiltipleInvoiceId,
                },
            });
        }
    }, [muiltipleInvoiceId]);


    useEffect(() => {
        refetch();
    }, [paymentByInvoiceData])
    const data = [];
    let remainingUSD = receiveAmountUSD;
    let remainingKHR = receiveAmountKHR;

    invoiceById.forEach((invoice) => {
        const remainAmount = invoice?.remainAmount || 0;
        let paidUSD = 0;
        let paidKHR = 0;

        if (remainingUSD > 0) {
            if (remainingUSD >= remainAmount) {
                paidUSD = remainAmount;
                remainingUSD -= remainAmount;
            } else {
                paidUSD = remainingUSD;
                remainingUSD = 0;
            }
        }

        if (remainingKHR > 0 && remainingUSD === 0) {
            const convertedKHR = remainAmount * exchangeRate;
            if (remainingKHR >= convertedKHR) {
                paidKHR = convertedKHR;
                remainingKHR -= convertedKHR;
            } else {
                paidKHR = remainingKHR;
                remainingKHR = 0;
            }
        }

        const totalPaid = paidUSD + paidKHR / exchangeRate;

        if (totalPaid > 0) {
            data.push({
                remark: "",
                shiftId: shiftId,
                paymentMethod: paymentType,
                exchangeRate: exchangeRate,
                invoiceId: invoice?._id,
                bankId: paymentType === "Bank" ? selectedBankVal?.id : null,
                change: {
                    khr: Math.max(changeAmountKHR, 0), // Change in KHR
                    usd: Math.max(changeAmountUSD, 0), // Change in USD 
                    amount: 0,
                },
                paid: {
                    amount: totalPaid,
                    khr: paidKHR, // Paid in KHR
                    usd: paidUSD, // Paid in USD
                },

            });
        }
    });

    const changeAmount = Math.max(changeAmountUSD + changeAmountKHR / exchangeRate, 0)

    if (data.length > 0 && changeAmount > 0) {

        data.map((e, index) => {
            if (index === data.length - 1) {
                e.change = {
                    khr: Math.max(changeAmountKHR, 0), // Change in KHR
                    usd: Math.max(changeAmountUSD, 0), // Change in USD 
                    amount: changeAmount,
                }
            }
            return e
        })


    } 
    return data
};

export default useAmountToPaidMuiltipleInvoice;
