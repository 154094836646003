import React, { useContext, useState } from "react";
import "../../Style/dialogStyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Include/Function/Translate";
import { useMutation } from "@apollo/client";
import { FormControlLabel, styled, Switch } from "@mui/material";
import { UPDATE_WAREHOUSE } from "../../Schema/Warehouse";
export const UpdateStatusWarehouse = ({ editData, setRefetch }) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  //======================== update Bank =========================
  const [updateWarehouse] = useMutation(UPDATE_WAREHOUSE, {
    onCompleted: ({ updateWarehouse }) => {
      console.log("updateWarehouse::", updateWarehouse);
      setLoading(false);
      if (updateWarehouse?.isSuccess === true) {
        setAlert(true, "success", updateWarehouse?.message);
        setRefetch();
      } else {
        setAlert(true, "error", updateWarehouse?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <FormControlLabel
      control={
        <Android12Switch
          checked={editData?.isUse}
          onChange={() =>
            updateWarehouse({
              variables: {
                id: editData?._id,
                input: {
                  isUse: !editData?.isUse,
                },
              },
            })
          }
        />
      }
    />
  );
};
