import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
// src
import "../../../Style/dialogStyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Include/Function/Translate";
import {
  GET_CASHIER_BY_SHOT,
  KICK_ALL_ACTIVE_CASHIRE,
} from "../../../Schema/Shop";
import BadgeAvatars from "./BadgeAvatars";
export const LogOutCashier = ({
  open,
  shopId,
  editData,
  setRefetch,
  handleClose,
  setOpenBackdropCloseShift,
}) => {
  // ===================== Change Language ==========================
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  //======================== Create Shop =========================

  const [kickAllActiveCashier] = useMutation(KICK_ALL_ACTIVE_CASHIRE, {
    onCompleted: ({ kickAllActiveCashier }) => {
      setLoading(false);
      if (kickAllActiveCashier?.isSuccess === true) {
        setAlert(true, "success", kickAllActiveCashier?.message);
        handleClose()
        setOpenBackdropCloseShift(true);
        setRefetch();
      } else {
        setAlert(true, "error", kickAllActiveCashier?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log("error:::", error);
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    kickAllActiveCashier({
      variables: {
        shopId: shopId,
      },
    });
  };
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography
              className={language === "en" ? "dialog-title" : "dialog-title-kh"}
            >
              {t("log_out")}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  width="60%"
                  sx={{ fontWeight: "bold" }}
                  className={language === "en" ? "field-title" : "field-title-kh"}
                >
                  {t("user")}
                </Typography>
                <Typography
                  width="40%"
                  sx={{ fontWeight: "bold" }}
                  className={language === "en" ? "field-title" : "field-title-kh"}
                >
                  {t("thead_status")}
                </Typography>

              </Stack>
              {editData?.map((e, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    width="60%"
                    spacing={1.5}
                    direction="row"
                    alignItems="center"
                  >
                    <BadgeAvatars userProfile={e?.userId?.userProfile} status={e?.status} />
                    <Typography className={"field-title-kh"} sx={{ fontSize: "17px !important" }}   >
                      {e?.userId?.khmerName}
                    </Typography>
                  </Stack>
                  <Typography
                    width="40%"
                    className={"field-title-kh"}
                    color={e?.status ? "#44b700 !important" : "red !important"}
                  >
                    {e?.status ? "Active" : "Inactive"}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className="create-container">
            {loading ? (
              <Button
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                fullWidth
              >
                {t("loading")}
              </Button>
            ) : (
              <Button
                fullWidth
                className={language === "kh" ? "btn-create-kh" : "btn-create"}
                onClick={handleSubmit}
              >
                {t("btn-confirm")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
