import React, { useContext } from "react";
import "../../../../../Style/actionStyle.scss";
import { AuthContext } from "../../../../../Context/AuthContext";
import EmptyData from "../../../../../Include/EmptyData/EmptyData";
import { translateLauguage } from "../../../../../Include/Function/Translate";
import { Stack, TextField, Typography, InputAdornment } from "@mui/material";
import { SelectMainProductForIngredient } from "../../../../../Include/Function/DynamicSelect";

export default function ViewProductIngredients({
  ownId,
  required,
  ingredientData,
}) {
  // ===================== Change Language ==========================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);


  return (
    <Stack direction="column" alignItems="center" spacing={1.5}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        bgcolor={"#F0F0F0"}

        sx={{
          width: "100%",
          height: "40px",
          borderRadius: 1,
          paddingLeft: 1.5,
          bgcolor: "#F0F0F0",
        }}
      >
        <Typography sx={{ fontWeight: "bold", width: "36%" }}>
          {t("thead_ingredients")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "32%" }}>
          {t("thead_qty")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", width: "32%" }}>
          {t("thead_percentage")}
        </Typography>

      </Stack>
      <Stack direction="column" alignItems="center" spacing={1.5} minHeight={120} width="100%">
        {
          ingredientData?.length === 0 ? <EmptyData /> :
            ingredientData?.map((e) => (
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack sx={{ width: "36%" }}>
                  <SelectMainProductForIngredient
                    ownId={ownId}
                    required={required}
                    disabled={true}
                    dataList={ingredientData}
                    selectedVal={e?.mainProductId}
                    setSelectedVal={() => { }}
                  />
                </Stack>
                <Stack sx={{ width: "32%" }}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    value={e?.qty}
                    disabled={e?.percentage > 0}
                    placeholder={t("label_qty")}
                    className="text-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {e?.mainProductId?.unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <Stack sx={{ width: "32%" }}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    disabled={e?.qty > 0}
                    value={e?.percentage}
                    className="text-field"
                    placeholder={t("label_percentage")}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end"> % </InputAdornment>),
                    }}
                  />
                </Stack>

              </Stack>
            ))}
      </Stack>
    </Stack>
  );
}
